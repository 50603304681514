import React from 'react';
import { typeAccountOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxTypeAccounts = ({ prefix = '', name = 'type', label = 'Loại tài khoản', ...props }) => {
  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="select"
      options={typeAccountOptions}
      allowClear
      {...props}
    />
  );
};

SelectBoxTypeAccounts.propTypes = propTypes;

export default SelectBoxTypeAccounts;
