import { useAsync } from 'react-async';
import {
  getAccountAll,
  list,
  detail,
  update,
  meDetail,
  create,
  deleteById,
  putChangePasswordAccount
} from '../api/account';
import {
  useSendPostAPI,
  useSendPutAPI,
  useGetListTable,
  useGetDetailById,
  useSendAPIById,
  useGetDetail
} from './hookAPI';
import { resetPassword, detailByCode } from '../api/auth';
import { useCallback, useRef } from 'react';
import { map, uniqBy } from 'lodash';

const useGetAccountAllOptions = (excludedIds = []) => {
  const { isPending, data, error } = useAsync({
    promiseFn: getAccountAll
  });
  return {
    loading: isPending,
    data: (data?.data?.data ?? [])
      .filter((x) => !excludedIds.includes(x.id))
      .map((item) => ({
        label: item.displayName,
        value: item.id
      })),
    error
  };
};

const usePutChangePasswordAccount = () => {
  const { loading, action, error, setError } = useSendPutAPI(putChangePasswordAccount);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useGetEmailByCode = () => {
  const { loading, get, error, setError } = useGetDetail(detailByCode);
  return {
    loading,
    get,
    error,
    setError
  };
};

const usePostResetPassword = () => {
  const { loading, action, error, setError } = useSendPostAPI(resetPassword);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useGetListAccount = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateAccount = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDetailAccount = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateAccount = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useMeDetailAccount = () => {
  const { loading, get, error, setError } = useGetDetailById(meDetail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useDeleteAccount = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const useAccountQuickSearch = (type) => {
  const latestAbortToken = useRef(null);
  const get = useCallback(
    ({ name }) => {
      return new Promise((resolve, reject) => {
        if (latestAbortToken.current) {
          clearTimeout(latestAbortToken.current);
        }
        const cancelToken = setTimeout(() => {
          list(
            {
              type,
              name: name || undefined,
              isActive: true
            },
            {}
          )
            .then(({ data }) => {
              return resolve(
                uniqBy(
                  map(data?.data?.items, (item) => ({
                    label: item.name,
                    value: item.id
                  })),
                  (item) => item.value
                )
              );
            })
            .catch((error) => {
              return reject(error);
            });
        }, 1000);
        latestAbortToken.current = cancelToken;
      });
    },
    [type]
  );
  return {
    get
  };
};

export {
  useGetAccountAllOptions,
  usePutChangePasswordAccount,
  usePostResetPassword,
  useGetListAccount,
  useCreateAccount,
  useDetailAccount,
  useUpdateAccount,
  useMeDetailAccount,
  useDeleteAccount,
  useAccountQuickSearch,
  useGetEmailByCode
};
