import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import CardForm from '../../../common/components/CardForm';

const CountComponent = ({ title, count, colorClass }) => {
  return (
    <Col span={12} sm={6} md={6}>
      <CardForm title={title} className="p-card">
        <p className={colorClass}>
          <Tooltip placement="bottomLeft" title="Tổng">
            <span>{count?.total}</span>
          </Tooltip>
        </p>
        <Row className="col-2">
          <Col style={{ width: '20%' }} className="p-active">
            <Tooltip placement="bottomLeft" title="Chờ xác nhận">
              <span>{count?.waitingConfirm}</span>
            </Tooltip>
          </Col>
          <Col style={{ width: '20%' }} className="p-active">
            <Tooltip placement="bottomLeft" title="Chờ lấy hàng">
              <span>{count?.waitingReceive}</span>
            </Tooltip>
          </Col>
          <Col style={{ width: '20%' }} className="p-active">
            <Tooltip placement="bottomLeft" title="Đang giao">
              <span>{count?.delivering}</span>
            </Tooltip>
          </Col>
          <Col style={{ width: '20%' }} className="p-active">
            <Tooltip placement="bottomLeft" title="Đã giao">
              <span>{count?.delivered}</span>
            </Tooltip>
          </Col>
          <Col style={{ width: '20%' }} className="p-inactive">
            <Tooltip placement="bottomLeft" title="Đã hủy">
              <span>{count?.canceled}</span>
            </Tooltip>
          </Col>
        </Row>
      </CardForm>
    </Col>
  );
};

export default CountComponent;
