import React from 'react';
import { Row } from 'antd';
import Button from '../../Button';
import { useAddEventListenerEnter } from '../../../../helpers/common.hooks';
// import { confirmModal } from '../../Modal';

const GroupCreateButton = ({
  onOk,
  onCancel,
  // contentOK,
  // contentCancel,
  labelOk = 'Lưu',
  labelCancel = 'Đóng',
  hideOk,
  hideCancel,
  disabledOk
}) => {
  useAddEventListenerEnter(onOk, 'form-create');

  const handleSave = () => {
    onOk();
    return;
    // if (!contentOK) {
    //   onOk();
    //   return;
    // }

    // confirmModal({ content: contentOK, onOk });
  };

  const handleCancel = () => {
    onCancel();
    return;
    // if (!contentCancel) {
    //   onCancel();
    //   return;
    // }

    // confirmModal({
    //   content: contentCancel,
    //   onOk: onCancel
    // });
  };

  return (
    <Row gutter={8} justify="center">
      {!hideCancel && (
        <Button className="mr-2" onClick={handleCancel}>
          {labelCancel}
        </Button>
      )}
      {!hideOk && (
        <Button type="primary" className="ml-2" onClick={handleSave} disabled={disabledOk} htmlType="submit">
          {labelOk}
        </Button>
      )}
    </Row>
  );
};

export default GroupCreateButton;
