import { Form } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { customToast } from '../../../common/components/Toast';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import {
  useDeletePostComment,
  useGetListPostComment,
  usePutPostCommentChangeStatus
} from '../../../helpers/hookPostComment';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import ListSearch from '../components/listSearch';
import { SEARCH_DEFAULT } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';
import { confirmModal } from '../../../common/components/Modal';
import { setLocalStorage } from '../../../utils/localStorage';

const Find = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const { get: getListPostComment } = useGetListPostComment();
  const { error: errorDelete, delete: deletePostComment } = useDeletePostComment();
  const { put: putChangeStatusPostComment } = usePutPostCommentChangeStatus();

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  const { loading, reload, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, null],
    promiseFn: getListPostComment
  });

  useHandleErrorCode(APP_MESSAGE.postComment.name, [errorList], APP_MESSAGE.postComment.missData);
  useHandleErrorCode(APP_MESSAGE.postComment.name, [errorDelete], APP_MESSAGE.postComment.deleteError);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onDelete = useCallback(
    (record) => {
      setLocalStorage('type-enter', 'modal');
      confirmModal({
        title: getMessageFromName(APP_MESSAGE.postComment.deleteConfirm, record.title),
        onOk: async () => {
          setLocalStorage('type-enter', '');
          const resData = await deletePostComment(record.id);
          if (resData?.success) {
            customToast({
              content: getMessageFromName(APP_MESSAGE.postComment.deleteSuccess, record.title),
              type: 'success'
            });
            reload();
          }
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [deletePostComment, reload]
  );

  const onFinish = useCallback(
    (values) => {
      setSearchParams(mapFormDataToSearchRecord(values));
    },
    [setSearchParams]
  );

  const onChangeStatus = useCallback(
    (record, values) => {
      confirmModal({
        title: 'Xác nhận thay đổi trạng thái',
        onOk: async () => {
          const resData = await putChangeStatusPostComment(record.id, values);
          if (resData?.success) {
            customToast({
              content: 'Xác nhận thay đổi trạng thái thành công',
              type: 'success'
            });
            reload();
          } else {
            customToast({ content: 'Xác nhận thay đổi trạng thái thất bại', type: 'error' });
          }
        }
      });
    },
    [putChangeStatusPostComment, reload]
  );

  const columns = useMemo(() => getTableColumns({ page, order, onDelete, onChangeStatus }), [
    order,
    page,
    onDelete,
    onChangeStatus
  ]);

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.postComment.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <RemoteTable
          columns={columns}
          data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
          loading={loading}
          page={page}
          limit={limit}
          total={data?.total ?? 0}
          onChange={onChange(() => {})}
          rowKey="id"
          scroll={{
            x: 1800
          }}
        />
      </CardForm>
    </PageContent>
  );
};

export default Find;
