import { Table } from 'antd';
import React from 'react';

const TableComponent = ({ page, total, pageSize, onChange, columns, dataSource }) => {
  return (
    <Table
      pagination={{
        current: page,
        total,
        pageSize
      }}
      onChange={onChange}
      dataSource={dataSource}
      columns={columns}
      style={{ minHeight: '180px' }}
    />
  );
};

export default TableComponent;
