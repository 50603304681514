import { Col, Row, Form, Select } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImage from '../../../../common/components/UploadImage';
import { PREFIX, FORM_FIELD } from '../../constants';
import { FormItemLayoutArea } from '../../../../constants/form';
import { BANK_CODE } from '../../../../constants/qrcode';

const BankInfo = ({ disabled, requiredItems = [], disabledItems = [], defaultFileList }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankName.name}
          label={FORM_FIELD.bankName.label}
          required={requiredItems.includes(FORM_FIELD.bankName.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankName.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankAccount.name}
          label={FORM_FIELD.bankAccount.label}
          required={requiredItems.includes(FORM_FIELD.bankAccount.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankAccount.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankNumber.name}
          label={FORM_FIELD.bankNumber.label}
          required={requiredItems.includes(FORM_FIELD.bankNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankNumber.name) || disabled}
        />
        <Form.Item {...FormItemLayoutArea} name={`${PREFIX}_${FORM_FIELD.bankQR.name}`} label={FORM_FIELD.bankQR.label}>
          <UploadImage defaultFileList={defaultFileList} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankTitle.name}
          label={FORM_FIELD.bankTitle.label}
          required={requiredItems.includes(FORM_FIELD.bankTitle.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankTitle.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankDescription.name}
          label={FORM_FIELD.bankDescription.label}
          required={requiredItems.includes(FORM_FIELD.bankDescription.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankDescription.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankNote.name}
          label={FORM_FIELD.bankNote.label}
          required={requiredItems.includes(FORM_FIELD.bankNote.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankNote.name) || disabled}
        />
        {/* <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankCode.name}
          label={FORM_FIELD.bankCode.label}
          required={requiredItems.includes(FORM_FIELD.bankCode.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankCode.name) || disabled}
        /> */}
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankCode.name}
          label={FORM_FIELD.bankCode.label}
          required={requiredItems.includes(FORM_FIELD.bankCode.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankCode.name) || disabled}
          component={
            <Select
              showSearch
              disabled={disabledItems.includes(FORM_FIELD.bankCode.name) || disabled}
              placeholder={FORM_FIELD.bankCode.label}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={Object.entries(BANK_CODE).map(([key, value]) => ({
                value: value,
                label: key
              }))}
            />
          }
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.bankPrefix.name}
          label={FORM_FIELD.bankPrefix.label}
          required={requiredItems.includes(FORM_FIELD.bankPrefix.name)}
          disabled={disabledItems.includes(FORM_FIELD.bankPrefix.name) || disabled}
        />
      </Col>
    </Row>
  );
};

export default BankInfo;
