import { map, uniqBy } from 'lodash';
import { useCallback, useRef } from 'react';
import { detail, list, update, create } from '../api/user';
import { useGetDetailById, useGetListTable, useSendPutAPI, useSendPostAPI } from './hookAPI';

const useGetListUser = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateUser = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailUser = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateUser = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useUserQuickSearch = (type) => {
  const latestAbortToken = useRef(null);

  const get = useCallback(
    ({ keyword, name, email, phone }) => {
      return new Promise((resolve, reject) => {
        if (latestAbortToken.current) {
          clearTimeout(latestAbortToken.current);
        }
        const cancelToken = setTimeout(() => {
          list(
            {
              type,
              keyword: keyword || undefined,
              name: name || undefined,
              email: email || undefined,
              phone: phone || undefined,
              isActive: true
            },
            {}
          )
            .then(({ data }) => {
              return resolve(
                uniqBy(
                  map(data?.data?.items, (item) => ({
                    value: item.id,
                    label: item.name,
                    name: item.name,
                    userId: item.id,
                    email: item.email,
                    address: item.address,
                    phone: item.phone
                  })),
                  (item) => item.value
                )
              );
            })
            .catch((error) => {
              return reject(error);
            });
        }, 1000);
        latestAbortToken.current = cancelToken;
      });
    },
    [type]
  );
  return {
    get
  };
};

export { useGetListUser, useCreateUser, useDetailUser, useUpdateUser, useUserQuickSearch };
