import { Checkbox, Col, Form, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormItem from '../../../../common/components/FormItem';
import SelectBoxProductTitleQuickSearch from '../../../../common/components/SelectBox/SelectBoxProductTitle';
import SelectBoxBranchQuickSearch from '../../../../common/components/SelectBox/SelectBoxBranch';
import SelectBoxCategoryQuickSearch from '../../../../common/components/SelectBox/SelectBoxCategory';
import SelectBoxProductType from '../../../../common/components/SelectBox/SelectBoxProductType';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import DeliverySelect from '../../../../common/components/MasterData/Delivery';
import GuaranteeSelect from '../../../../common/components/MasterData/Guarantee';
import WarrantyPeriodSelect from '../../../../common/components/MasterData/WarrantyPeriod';
import { FormCreateProductLayout, FormCreateType } from '../../../../constants/form';
import { FORM_FIELD, PREFIX } from '../../constants';
import CustomCKEditor from '../../../../common/components/CKEditor';

import './style.scss';
import UploadImagesAsync from '../../../../common/components/UploadImagesAsync';

const GeneralInfo = ({
  mode = FormCreateType,
  disabled,
  requiredItems = [],
  disabledItems = [],
  form,
  defaultThumbnail = null,
  defaultImages = [],
  onChangeProductTitleId,
  defaultContent = null,
  defaultDescription = null,
  defaultNoteForAdmin = null
}) => {
  const thumbnailImageRef = useRef(defaultThumbnail);
  const [thumbnailImage, setThumbnailImage] = useState(defaultThumbnail);
  const [contentsValue, setContentsValue] = useState(defaultContent);
  const [descriptionValue, setDescriptionValue] = useState(defaultDescription);
  const [noteForAdminValue, setNoteForAdminValue] = useState(defaultNoteForAdmin);

  useEffect(() => {
    let thumbnailValue = null;
    if (!isEmpty(thumbnailImage)) {
      // eslint-disable-next-line no-unused-vars
      const { url, ...res } = thumbnailImage;
      thumbnailValue = { ...res };
    }
    form &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.thumbnail.name}`]: thumbnailValue
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailImage]);

  // eslint-disable-next-line react/display-name
  const handleItemRenderImg = (thumbnailRef, setThumbnail) => (originNode, file) => {
    const { fileName, key, url, mimeType, size } = file;

    return (
      <>
        <div>
          <Checkbox
            checked={thumbnailRef?.current?.key === key || thumbnailImage?.key === key}
            onChange={(e) => {
              if (e.target.checked) {
                thumbnailRef.current = { key, fileName, url, mimeType, size };
                setThumbnail(thumbnailRef.current);
              }
              if (!e.target.checked) {
                thumbnailRef.current = null;
                setThumbnail(thumbnailRef.current);
              }
            }}
          />
        </div>
        {originNode}
      </>
    );
  };

  const handleDeleteThumbnail = (file) => {
    if (file?.url === thumbnailImage?.url) {
      setThumbnailImage(null);
    }
  };

  const handleChangeImage = (file) => {
    if (isEmpty(thumbnailImage)) {
      setThumbnailImage(file[0]);
    }
  };

  const renderUpload = useCallback(() => {
    return (
      <UploadImagesAsync
        disabled={disabled}
        defaultFileList={defaultImages}
        itemRender={handleItemRenderImg(thumbnailImageRef, setThumbnailImage)}
        thumbnailMsg="Click vào ô checkbox để chọn ảnh đại diện"
        onDelete={handleDeleteThumbnail}
        onChange={handleChangeImage}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailImage, disabled]);

  const handleGetContent = (value) => {
    form &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.content.name}`]: value
      });
    setContentsValue(value);
  };

  const handleGetSpecification = (value) => {
    form &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.noteForAdmin.name}`]: value
      });
    setNoteForAdminValue(value);
  };

  const handleGetDescription = (value) => {
    form &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.description.name}`]: value
      });
    setDescriptionValue(value);
  };

  const togglePrice = (val) => {
    if (val) {
      form &&
        form.setFieldsValue({
          [`${PREFIX}_${FORM_FIELD.price.name}`]: undefined
        });
    } else {
      form &&
        form.setFieldsValue({
          [`${PREFIX}_${FORM_FIELD.price1.name}`]: undefined,
          [`${PREFIX}_${FORM_FIELD.price2.name}`]: undefined,
          [`${PREFIX}_${FORM_FIELD.price3.name}`]: undefined
        });
    }
  };

  return (
    <Row gutter={24} className="product-info">
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.categoryId.name}
          label={FORM_FIELD.categoryId.label}
          required={requiredItems.includes(FORM_FIELD.categoryId.name)}
          component={
            <SelectBoxCategoryQuickSearch
              allowClear
              showSearch
              hideInputLabel
              disabled={disabledItems.includes(FORM_FIELD.categoryId.name) || disabled}
            />
          }
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.branchId.name}
          label={FORM_FIELD.branchId.label}
          required={requiredItems.includes(FORM_FIELD.branchId.name)}
          component={
            <SelectBoxBranchQuickSearch
              allowClear
              showSearch
              hideInputLabel
              disabled={disabledItems.includes(FORM_FIELD.branchId.name) || disabled}
            />
          }
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.productTitleId.name}
          label={FORM_FIELD.productTitleId.label}
          required={requiredItems.includes(FORM_FIELD.productTitleId.name)}
          component={
            <SelectBoxProductTitleQuickSearch
              allowClear
              showSearch
              hideInputLabel
              onChangeSelectObject={onChangeProductTitleId}
              disabled={disabledItems.includes(FORM_FIELD.productTitleId.name) || disabled}
            />
          }
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.title.name}
          label={FORM_FIELD.title.label}
          required={requiredItems.includes(FORM_FIELD.title.name)}
          disabled={disabledItems.includes(FORM_FIELD.title.name) || disabled}
        />
        <SelectBoxStatus prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.isActive.name)} />
        <SelectBoxProductType prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.type.name)} />
        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />

        {mode === FormCreateType && (
          <FormItem
            prefix={PREFIX}
            type="number"
            min={0}
            name={FORM_FIELD.amount.name}
            label={FORM_FIELD.amount.label}
            required={requiredItems.includes(FORM_FIELD.amount.name)}
            disabled={disabledItems.includes(FORM_FIELD.amount.name) || disabled}
          />
        )}
        <DeliverySelect
          form={form}
          isDefault={mode === FormCreateType}
          prefix={PREFIX}
          label={FORM_FIELD.deliveryId.label}
          name={FORM_FIELD.deliveryId.name}
          required={requiredItems.includes(FORM_FIELD.deliveryId.name)}
          disabled={disabledItems.includes(FORM_FIELD.deliveryId.name) || disabled}
        />
        <GuaranteeSelect
          form={form}
          isDefault={mode === FormCreateType}
          prefix={PREFIX}
          label={FORM_FIELD.guaranteeId.label}
          name={FORM_FIELD.guaranteeId.name}
          required={requiredItems.includes(FORM_FIELD.guaranteeId.name)}
          disabled={disabledItems.includes(FORM_FIELD.guaranteeId.name) || disabled}
        />
        <WarrantyPeriodSelect
          form={form}
          isDefault={mode === FormCreateType}
          prefix={PREFIX}
          label={FORM_FIELD.warrantyPeriodId.label}
          name={FORM_FIELD.warrantyPeriodId.name}
          required={requiredItems.includes(FORM_FIELD.warrantyPeriodId.name)}
          disabled={disabledItems.includes(FORM_FIELD.warrantyPeriodId.name) || disabled}
        />
      </Col>

      <Col span={12}>
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.importPrice.name}
          label={FORM_FIELD.importPrice.label}
          required={requiredItems.includes(FORM_FIELD.importPrice.name)}
          disabled={disabledItems.includes(FORM_FIELD.importPrice.name) || disabled}
          type="CurrencyVNDInput"
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.price.name}
          label={FORM_FIELD.price.label}
          required={requiredItems.includes(FORM_FIELD.price.name)}
          disabled={disabledItems.includes(FORM_FIELD.price.name) || disabled}
          type="CurrencyVNDInput"
          onChange={() => togglePrice(false)}
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.price1.name}
          label={FORM_FIELD.price1.label}
          required={requiredItems.includes(FORM_FIELD.price1.name)}
          disabled={disabledItems.includes(FORM_FIELD.price1.name) || disabled}
          type="CurrencyVNDInput"
          onChange={() => togglePrice(true)}
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.price2.name}
          label={FORM_FIELD.price2.label}
          required={requiredItems.includes(FORM_FIELD.price2.name)}
          disabled={disabledItems.includes(FORM_FIELD.price2.name) || disabled}
          type="CurrencyVNDInput"
          onChange={() => togglePrice(true)}
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.price3.name}
          label={FORM_FIELD.price3.label}
          required={requiredItems.includes(FORM_FIELD.price3.name)}
          disabled={disabledItems.includes(FORM_FIELD.price3.name) || disabled}
          type="CurrencyVNDInput"
          onChange={() => togglePrice(true)}
        />
        <Row>
          <Col span={12}>
            <FormItem
              prefix={PREFIX}
              {...FormCreateProductLayout}
              name={FORM_FIELD.isHot.name}
              label={FORM_FIELD.isHot.label}
              type="switch"
              required={requiredItems.includes(FORM_FIELD.isHot.name)}
              disabled={disabledItems.includes(FORM_FIELD.isHot.name) || disabled}
            />
            <FormItem
              prefix={PREFIX}
              {...FormCreateProductLayout}
              name={FORM_FIELD.isRequiredVip.name}
              label={FORM_FIELD.isRequiredVip.label}
              type="switch"
              required={requiredItems.includes(FORM_FIELD.isRequiredVip.name)}
              disabled={disabledItems.includes(FORM_FIELD.isRequiredVip.name) || disabled}
            />
            <FormItem
              prefix={PREFIX}
              {...FormCreateProductLayout}
              name={FORM_FIELD.isFlashSale.name}
              label={FORM_FIELD.isFlashSale.label}
              type="switch"
              required={requiredItems.includes(FORM_FIELD.isFlashSale.name)}
              disabled={disabledItems.includes(FORM_FIELD.isFlashSale.name) || disabled}
            />
          </Col>
          <Col span={12}>
            <FormItem
              prefix={PREFIX}
              {...FormCreateProductLayout}
              name={FORM_FIELD.isPreOrder.name}
              label={FORM_FIELD.isPreOrder.label}
              type="switch"
              required={requiredItems.includes(FORM_FIELD.isPreOrder.name)}
              disabled={disabledItems.includes(FORM_FIELD.isPreOrder.name) || disabled}
            />
            <FormItem
              prefix={PREFIX}
              {...FormCreateProductLayout}
              name={FORM_FIELD.isViewWeb.name}
              label={FORM_FIELD.isViewWeb.label}
              type="switch"
              required={requiredItems.includes(FORM_FIELD.isViewWeb.name)}
              disabled={disabledItems.includes(FORM_FIELD.isViewWeb.name) || disabled}
            />
            <FormItem
              prefix={PREFIX}
              {...FormCreateProductLayout}
              name={FORM_FIELD.isViewMobile.name}
              label={FORM_FIELD.isViewMobile.label}
              type="switch"
              required={requiredItems.includes(FORM_FIELD.isViewMobile.name)}
              disabled={disabledItems.includes(FORM_FIELD.isViewMobile.name) || disabled}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          rows={3}
          name={FORM_FIELD.noteForAdmin.name}
          label={FORM_FIELD.noteForAdmin.label}
          required={requiredItems.includes(FORM_FIELD.noteForAdmin.name)}
          disabled={disabledItems.includes(FORM_FIELD.noteForAdmin.name) || disabled}
          component={<CustomCKEditor handleOnBlur={handleGetSpecification} defaultValue={noteForAdminValue} />}
        />
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          labelAlign="left"
          name={`${PREFIX}_${FORM_FIELD.images.name}`}
          label={FORM_FIELD.images.label}
          valuePropName="fileList"
        >
          {renderUpload()}
        </Form.Item>
      </Col>
      <Col offset={3}>
        <p>Kích thước hình ảnh tỉ lệ 4x3 (Ví dụ: 640x480)</p>
      </Col>
      <Col span={24}>
        <Form.Item
          style={{ display: 'none' }}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          labelAlign="left"
          name={`${PREFIX}_${FORM_FIELD.thumbnail.name}`}
          label={FORM_FIELD.thumbnail.label}
        >
          {!isEmpty(thumbnailImage) && <img className="thumbnail-img" alt="thumbnail" src={thumbnailImage.url} />}
        </Form.Item>
      </Col>
      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          rows={3}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
          component={<CustomCKEditor handleOnBlur={handleGetDescription} defaultValue={descriptionValue} />}
        />
      </Col>

      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          name={FORM_FIELD.content.name}
          label={FORM_FIELD.content.label}
          required={requiredItems.includes(FORM_FIELD.content.name)}
          disabled={disabledItems.includes(FORM_FIELD.content.name) || disabled}
          component={<CustomCKEditor height={'550px'} handleOnBlur={handleGetContent} defaultValue={contentsValue} />}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
