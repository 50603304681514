import { Form, Tabs } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useGetListCart, useGetCountCart } from '../../../helpers/hookCart';
import { useRemoteTable } from '../../../helpers/hookTable';
import ListSearch from '../components/listSearch';
import { SEARCH_DEFAULT, ECartStatus } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';
import ROUTER_URL from '../../../constants/router';
import { useHistory } from 'react-router';

const Find = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const [cartCounts, setCartCounts] = useState(null);
  const { get: getListCart } = useGetListCart();
  const { get: getCountCart } = useGetCountCart();

  const onGetCountCart = useCallback(async () => {
    const response = await getCountCart({});
    if (response) {
      setCartCounts(response);
    }
  }, [getCountCart]);

  useEffect(() => {
    onGetCountCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  const { loading, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, 'descend', false, false, false, false, false, false, false, false],
    columnSchema: [
      null,
      'code',
      'createdAt',
      null,
      'price',
      'voucherPrice',
      'shipPrice',
      'finalPrice',
      'status',
      'paymentDate',
      null
    ],
    promiseFn: getListCart
  });

  useHandleErrorCode(APP_MESSAGE.cart.name, [errorList], APP_MESSAGE.cart.detailError);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    (values) => {
      setSearchParams((pre) => ({
        ...pre,
        ...mapFormDataToSearchRecord(values, searchParams.status)
      }));
    },
    [setSearchParams, searchParams]
  );

  const onChangeTab = (value) => {
    setSearchParams((pre) => ({
      ...pre,
      status: value || undefined
    }));
  };

  const onEdit = useCallback(
    (id) => {
      history.push(`${ROUTER_URL.CART_DETAIL}/${id}`);
    },
    [history]
  );

  const columns = useMemo(() => getTableColumns({ page, order, onEdit, limit }), [order, page, onEdit, limit]);

  const getContent = () => {
    return (
      <RemoteTable
        columns={columns}
        data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
        loading={loading}
        page={page}
        limit={limit}
        total={data?.total ?? 0}
        onChange={onChange(() => {})}
        rowKey="id"
      />
    );
  };

  const items = [
    {
      key: '',
      label: `Tất cả (${cartCounts?.total || 0})`,
      children: getContent()
    },
    {
      key: ECartStatus.WaitingConfirm,
      label: `Chờ xác nhận (${cartCounts?.waitingConfirm || 0})`,
      children: getContent()
    },
    {
      key: ECartStatus.WaitingReceive,
      label: `Chờ lấy hàng (${cartCounts?.waitingReceive || 0})`,
      children: getContent()
    },
    {
      key: ECartStatus.Delivering,
      label: `Đang giao (${cartCounts?.delivering || 0})`,
      children: getContent()
    },
    {
      key: ECartStatus.Delivered,
      label: `Đã giao (${cartCounts?.delivered || 0})`,
      children: getContent()
    },
    {
      key: ECartStatus.Canceled,
      label: `Đơn hủy (${cartCounts?.canceled || 0})`,
      children: getContent()
    }
  ];

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.cart.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm>
        <Tabs defaultActiveKey="" items={items} onChange={onChangeTab} type="card" />
      </CardForm>
    </PageContent>
  );
};

export default Find;
