import { useGetListTable, useGetDetailById, useSendPutAPI, useSendAPIById, useSendPostAPI } from './hookAPI';
import { create, list, detail, update, changeStatus, deleteById, approved } from '../api/feedback';

const useGetListFeedback = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateFeedback = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailFeedback = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateFeedback = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const usePutFeedbackChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const usePutFeedbackApprovedStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(approved);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteFeedback = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

export {
  useGetListFeedback,
  useDetailFeedback,
  useUpdateFeedback,
  usePutFeedbackChangeStatus,
  useDeleteFeedback,
  useCreateFeedback,
  usePutFeedbackApprovedStatus
};
