import React from 'react';
import { Tag } from 'antd';
import { cartStatusOptions } from '../../../constants/data';

const CartStatus = ({ status }) => {
  const record = cartStatusOptions.find((x) => x.value === status);

  return <Tag color={record?.color}>{record?.label || ''}</Tag>;
};

export default CartStatus;
