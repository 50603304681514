export const PREFIX = 'commentReport';

export const FORM_FIELD = {
  userId: { name: 'userId', label: 'Người tạo' },
  comment: { name: 'comment', label: 'Nội dung' },
  status: { name: 'status', label: 'Tình trạng' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {};
