import React from 'react';
import FormItem from '../../FormItem';
import { useGetListGroupValueOption } from '../../../../helpers/hookGroupValue';

const propTypes = {};

const SelectBoxGroupValue = ({ prefix = '', name = 'groupValue', label = 'Nhóm giá trị cha', ...props }) => {
  const { data } = useGetListGroupValueOption();

  return <FormItem prefix={prefix} name={name} label={label} type="select" options={data} allowClear {...props} />;
};

SelectBoxGroupValue.propTypes = propTypes;

export default SelectBoxGroupValue;
