export const PREFIX = 'config';

export const FORM_FIELD = {
  logo: { name: 'logo', label: 'Logo' },
  description: { name: 'description', label: 'Mô tả' },
  emailSupport: { name: 'email_support', label: 'Email' },
  hotline: { name: 'hotline', label: 'Hotline' },
  facebookLink: { name: 'facebookLink', label: 'Facebook Link' },
  twitterLink: { name: 'twitterLink', label: 'Tiktok Link' },
  googleLink: { name: 'googleLink', label: 'Google Link' },
  hangoutLink: { name: 'hangoutLink', label: 'Youtube Link' },
  hideProductMobile: { name: 'hideProductMobile', label: 'Ẩn sản phẩm trên mobile' },
  address: { name: 'address', label: 'Địa chỉ' },
  address1: { name: 'address1', label: 'Địa chỉ' },
  address2: { name: 'address2', label: 'Địa chỉ' },
  workingTime: { name: 'workingTime', label: 'Giờ làm việc' },
  bankName: { name: 'bankName', label: 'Tên ngân hàng' },
  bankAccount: { name: 'bankAccount', label: 'Chủ tài khoản' },
  bankNumber: { name: 'bankNumber', label: 'Số tài khoản' },
  bankQR: { name: 'bankQR', label: 'Mã QR' },
  bankTitle: { name: 'bankTitle', label: 'Tiêu đề' },
  bankDescription: { name: 'bankDescription', label: 'Mô tả' },
  productDescription: { name: 'productDescription', label: 'Mô tả sản phẩm' },
  bankNote: { name: 'bankNote', label: 'Lưu ý cho khách hàng' },
  bankCode: { name: 'bankCode', label: 'Mã ngân hàng' },
  bankPrefix: { name: 'bankPrefix', label: 'Prefix' },
  gallery: { name: 'gallery', label: 'Thư viện ảnh' },
  footerInfo: {
    mst: { name: 'footerInfo_mst', label: 'Mã số thuế' },
    googleMap1: { name: 'footerInfo_googleMap1', label: 'Google Map' },
    googleMap2: { name: 'footerInfo_googleMap2', label: 'Google Map' },
    bankVietcombank: { name: 'footerInfo_bankVietcombank', label: 'Vietcombank' },
    bankTechcombank: { name: 'footerInfo_bankTechcombank', label: 'Techcombank' },
    purchaseMethodLink: { name: 'footerInfo_purchaseMethodLink', label: 'Phương thức mua hàng' },
    guaranteeLink: { name: 'footerInfo_guaranteeLink', label: 'Chính sách bảo hành' },
    paymentAndDeliveryLink: { name: 'footerInfo_paymentAndDeliveryLink', label: 'Thanh toán và giao hàng' },
    repairAndMaintenanceLink: { name: 'footerInfo_repairAndMaintenanceLink', label: 'Dịch vụ sửa chữa và bảo trì' },
    privacyPolicyLink: { name: 'footerInfo_privacyPolicyLink', label: 'Chính sách bảo mật' },
    hotline: { name: 'footerInfo_hotline', label: 'Hotline' },
    hotline1: { name: 'footerInfo_hotline1', label: 'Hotline' },
    hotline2: { name: 'footerInfo_hotline2', label: 'Hotline' },
    emailSupport: { name: 'footerInfo_email_support', label: 'Email' },
    emailSupport1: { name: 'footerInfo_email_support1', label: 'Email' },
    emailSupport2: { name: 'footerInfo_email_support2', label: 'Email' },
    address: { name: 'footerInfo_address', label: 'Địa chỉ' },
    address1: { name: 'footerInfo_address1', label: 'Địa chỉ' },
    address2: { name: 'footerInfo_address2', label: 'Địa chỉ' },
    workingTime: { name: 'footerInfo_workingTime', label: 'Giờ làm việc' },
    workingTime1: { name: 'footerInfo_workingTime1', label: 'Giờ làm việc' },
    workingTime2: { name: 'footerInfo_workingTime2', label: 'Giờ làm việc' }
  }
};
