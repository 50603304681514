import find from 'lodash/find';

const getOrderFromTableToAPI = (orderArray) => {
  const found = find(orderArray, (item) => item);
  if (!found) {
    return undefined;
  }
  return found === 'ascend' ? 'asc' : 'desc';
};

export { getOrderFromTableToAPI };
