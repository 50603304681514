/* eslint-disable */
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import FormItem from '../../../../common/components/FormItem';
import { FORM_FIELD, PREFIX } from '../../constants';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';

const GeneralInfo = ({
  disabled,
  requiredItems = [],
  disabledItems = [],
}) => {

  useEffect(() => {

  }, []);

  return (
    <Row gutter={24} className="product-title-info">
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.title.name}
          label={FORM_FIELD.title.label}
          required={requiredItems.includes(FORM_FIELD.title.name)}
          disabled={disabledItems.includes(FORM_FIELD.title.name) || disabled}
        />
        
        <FormItem
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
        <SelectBoxStatus
          prefix={PREFIX}
          name={FORM_FIELD.isActive.name}
          label={FORM_FIELD.isActive.label}
          required={requiredItems.includes(FORM_FIELD.isActive.name)}
          disabled={disabledItems.includes(FORM_FIELD.isActive.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
