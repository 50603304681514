export const PREFIX = 'Content';

export const FORM_FIELD = {
  parentId: { name: 'parentId', label: 'Danh mục cha' },
  type: { name: 'type', label: 'Loại' },
  productId: { name: 'productId', label: 'Sản phẩm' },
  title: { name: 'title', label: 'Tiêu đề' },
  description: { name: 'description', label: 'Mô tả' },
  link: { name: 'link', label: 'Link tới trang' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  image: { name: 'image', label: 'Hình ảnh' },
  mobileImage: { name: 'mobileImage', label: 'Hình ảnh (mobile)' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};

export const EContentType = {
  HomeBanner: 'Home_Banner',
  HomeService: 'Home_Service',
  HomeSipping: 'Home_Sipping',
  HomeAboutUs: 'Home_AboutUs',
  HomeOurPartner: 'Home_OurPartner'
};
