const MODEL_MESSAGE = {
  SUCCESS: 'Thành công',
  ERROR: 'Lỗi',
  ERROR_UNKNOWN: 'Lỗi không xác định',
  INFO: 'Thông tin',
  WARNING: 'Lưu ý',
  CONFIRM_ACTION: 'Bạn có muốn thực hiện?',
  CONFIRM_CANCEL: 'Bạn có muốn hủy?',
  YES: 'Đồng ý',
  NO: 'Không',
  SETUP: 'Cài đặt'
};

const MESSAGES = {
  ACCOUNT_MANAGEMENT: 'Quản lý Nhân viên',
  ACCOUNT_SEARCH: 'Tìm Nhân viên',
  ACCOUNT_CREATE: 'Tạo Nhân viên',
  SYSTEM: 'Hệ thống',
  ACCOUNT: 'Tài khoản',
  GROUP_ACCOUNT: 'Nhóm tài khoản',
  SETUP: 'Cài đặt',
  BUTTON_FIND: 'Tìm',
  BUTTON_DELETE: 'Xóa'
};

const DATA_ERROR_MESSAGES = {
  default: 'Giá trị không hợp lệ'
};

const APP_MESSAGE = {
  common: {
    missData: 'Thông tin chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái là không hợp lệ'
  },
  account: {
    name: 'Nhân viên',
    createConfirm: 'Bạn muốn tạo mới Nhân viên?',
    createSuccess: 'Tạo mới Nhân viên thành công',
    createError: 'Tạo mới Nhân viên thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Nhân viên?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Nhân viên?',
    editSuccess: 'Cập nhật Nhân viên thành công',
    editError: 'Tạo mới Nhân viên thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Nhân viên?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Nhân viên: ${name}?',
    deleteSuccess: 'Xóa Nhân viên: ${name} thành công',
    deleteError: 'Xóa Nhân viên: ${name} thất bại',
    detailError: 'Lấy thông tin Nhân viên thất bại',
    missData: 'Thông tin Nhân viên chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái là không hợp lệ'
  },
  user: {
    name: 'Khách hàng',
    createConfirm: 'Bạn muốn tạo mới Khách hàng?',
    createSuccess: 'Tạo mới Khách hàng thành công',
    createError: 'Tạo mới Khách hàng thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Khách hàng?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Khách hàng?',
    editSuccess: 'Cập nhật Khách hàng thành công',
    editError: 'Tạo mới Khách hàng thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Khách hàng?. Mọi thay đổi sẽ bị mất',
    detailError: 'Lấy thông tin Khách hàng thất bại',
    missData: 'Thông tin Khách hàng chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái là không hợp lệ'
  },
  groupValue: {
    name: 'Nhóm giá trị',
    createConfirm: 'Bạn muốn tạo mới Nhóm giá trị?',
    createSuccess: 'Tạo mới Nhóm giá trị thành công',
    createError: 'Tạo mới Nhóm giá trị thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Nhóm giá trị?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Nhóm giá trị?',
    editSuccess: 'Cập nhật Nhóm giá trị thành công',
    editError: 'Tạo mới Nhóm giá trị thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Nhóm giá trị?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Nhóm giá trị: ${name}?',
    deleteSuccess: 'Xóa Nhóm giá trị: ${name} thành công',
    deleteError: 'Xóa Nhóm giá trị: ${name} thất bại',
    detailError: 'Lấy thông tin Nhóm giá trị thất bại',
    missData: 'Thông tin Nhóm giá trị chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Nhóm giá trị là không hợp lệ'
  },
  masterValue: {
    name: 'Giá trị',
    createConfirm: 'Bạn muốn tạo mới Giá trị?',
    createSuccess: 'Tạo mới Giá trị thành công',
    createError: 'Tạo mới Giá trị thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Giá trị?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Giá trị?',
    editSuccess: 'Cập nhật Giá trị thành công',
    editError: 'Tạo mới Giá trị thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Giá trị?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Giá trị?',
    deleteSuccess: 'Xóa Giá trị thành công',
    deleteError: 'Xóa Giá trị thất bại',
    detailError: 'Lấy thông tin Giá trị thất bại',
    missData: 'Thông tin Giá trị chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Giá trị là không hợp lệ'
  },
  category: {
    name: 'Danh mục',
    createConfirm: 'Bạn muốn tạo mới Danh mục?',
    createSuccess: 'Tạo mới Danh mục thành công',
    createError: 'Tạo mới Danh mục thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Danh mục?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Danh mục?',
    editSuccess: 'Cập nhật Danh mục thành công',
    editError: 'Tạo mới Danh mục thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Danh mục?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Danh mục: ${name}?',
    deleteSuccess: 'Xóa Danh mục: ${name} thành công',
    deleteError: 'Xóa Danh mục: ${name} thất bại',
    detailError: 'Lấy thông tin Danh mục thất bại',
    missData: 'Thông tin Danh mục chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Danh mục là không hợp lệ'
  },
  branch: {
    name: 'Thương hiệu',
    createConfirm: 'Bạn muốn tạo mới Thương hiệu?',
    createSuccess: 'Tạo mới Thương hiệu thành công',
    createError: 'Tạo mới Thương hiệu thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Thương hiệu?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Thương hiệu?',
    editSuccess: 'Cập nhật Thương hiệu thành công',
    editError: 'Tạo mới Thương hiệu thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Thương hiệu?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Thương hiệu: ${name}?',
    deleteSuccess: 'Xóa Thương hiệu: ${name} thành công',
    deleteError: 'Xóa Thương hiệu: ${name} thất bại',
    detailError: 'Lấy thông tin Thương hiệu thất bại',
    missData: 'Thông tin Thương hiệu chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Thương hiệu là không hợp lệ'
  },
  product: {
    name: 'Sản phẩm',
    createConfirm: 'Bạn muốn tạo mới Sản phẩm?',
    createSuccess: 'Tạo mới Sản phẩm thành công',
    createError: 'Tạo mới Sản phẩm thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Sản phẩm?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Sản phẩm?',
    editSuccess: 'Cập nhật Sản phẩm thành công',
    editError: 'Cập nhật Sản phẩm thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Sản phẩm?. Mọi thay đổi sẽ bị mất',
    detailError: 'Lấy thông tin Sản phẩm thất bại',
    missData: 'Thông tin Sản phẩm chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái là không hợp lệ',
    deleteConfirm: 'Bạn muốn xóa Sản phẩm?',
    deleteSuccess: 'Xóa Sản phẩm thành công'
  },
  serialNumber: {
    name: 'Serial number',
    createConfirm: 'Bạn muốn tạo mới Serial number?',
    createSuccess: 'Tạo mới Serial number thành công',
    createError: 'Tạo mới Serial number thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Serial number?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Serial number?',
    editSuccess: 'Cập nhật Serial number thành công',
    editError: 'Cập nhật Serial number thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Serial number?. Mọi thay đổi sẽ bị mất',
    detailError: 'Lấy thông tin Serial number thất bại',
    missData: 'Thông tin Serial number chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái là không hợp lệ',
    deleteConfirm: 'Bạn muốn xóa Serial number?',
    deleteSuccess: 'Xóa Serial number thành công'
  },
  config: {
    name: 'Thông tin chung',
    editConfirm: 'Bạn muốn cập nhật Thông tin chung?',
    editSuccess: 'Cập nhật Thông tin chung thành công',
    editError: 'Cập nhật Thông tin chung thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Thông tin chung?. Mọi thay đổi sẽ bị mất'
  },
  chapter: {
    name: 'Chương',
    createConfirm: 'Bạn muốn tạo mới Chương?',
    createSuccess: 'Tạo mới Chương thành công',
    createError: 'Tạo mới Chương thất bại',
    editConfirm: 'Bạn muốn cập nhật Chương?',
    editSuccess: 'Cập nhật Chương thành công',
    editError: 'Cập nhật Chương thất bại',
    deleteConfirm: 'Bạn muốn xóa Chương?',
    deleteSuccess: 'Xóa Chương thành công',
    deleteError: 'Xóa Chương thất bại'
  },
  lesson: {
    name: 'Bài học',
    createConfirm: 'Bạn muốn tạo mới Bài học?',
    createSuccess: 'Tạo mới Bài học thành công',
    createError: 'Tạo mới Bài học thất bại',
    editConfirm: 'Bạn muốn cập nhật Bài học?',
    editSuccess: 'Cập nhật Bài học thành công',
    editError: 'Cập nhật Bài học thất bại',
    deleteConfirm: 'Bạn muốn xóa Bài học?',
    deleteVideoConfirm: 'Bạn muốn xóa Video?',
    deleteSuccess: 'Xóa Bài học thành công',
    deleteError: 'Xóa Bài học thất bại'
  },
  cart: {
    name: 'Đơn hàng',
    createConfirm: 'Bạn muốn tạo mới Đơn hàng?',
    createSuccess: 'Tạo mới Đơn hàng thành công',
    createError: 'Tạo mới Đơn hàng thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Đơn hàng?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Đơn hàng?',
    editSuccess: 'Cập nhật Đơn hàng thành công',
    editError: 'Tạo mới Đơn hàng thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Đơn hàng?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Đơn hàng: ${name}?',
    deleteSuccess: 'Xóa Đơn hàng: ${name} thành công',
    deleteError: 'Xóa Đơn hàng: ${name} thất bại',
    detailError: 'Lấy thông tin Đơn hàng thất bại',
    missData: 'Thông tin Đơn hàng chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Đơn hàng là không hợp lệ'
  },
  feedback: {
    name: 'Góp ý',
    createConfirm: 'Bạn muốn tạo mới Góp ý?',
    createSuccess: 'Tạo mới Góp ý thành công',
    createError: 'Tạo mới Góp ý thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Góp ý?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Góp ý?',
    editSuccess: 'Cập nhật Góp ý thành công',
    editError: 'Tạo mới Góp ý thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Góp ý?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Góp ý: ${name}?',
    deleteSuccess: 'Xóa Góp ý: ${name} thành công',
    deleteError: 'Xóa Góp ý: ${name} thất bại',
    detailError: 'Lấy thông tin Góp ý thất bại',
    missData: 'Thông tin Góp ý chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Góp ý là không hợp lệ'
  },
  userByProduct: {
    name: 'Người đăng ký',
    detailError: 'Lấy thông tin Người đăng ký thất bại'
  },
  review: {
    name: 'Đánh giá',
    detailError: 'Lấy thông tin Đánh giá thất bại',
    deleteConfirm: 'Bạn muốn xóa Đánh giá  học?',
    deleteSuccess: 'Xóa Đánh giá  học thành công',
    deleteError: 'Xóa Đánh giá  học thất bại',
    missData: 'Thông tin Đánh giá  học chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Đánh giá  học là không hợp lệ'
  },
  eVoucher: {
    name: 'E-Voucher',
    createConfirm: 'Bạn muốn tạo mới E-Voucher?',
    createSuccess: 'Tạo mới E-Voucher thành công',
    createError: 'Tạo mới E-Voucher thất bại',
    editConfirm: 'Bạn muốn cập nhật E-Voucher?',
    editSuccess: 'Cập nhật E-Voucher thành công',
    editError: 'Cập nhật E-Voucher thất bại',
    deleteConfirm: 'Bạn muốn xóa E-Voucher?',
    deleteSuccess: 'Xóa E-Voucher thành công',
    deleteError: 'Xóa E-Voucher thất bại',
    missData: 'Thông tin  chưa đầy đủ. Vui lòng kiểm tra lại'
  },
  content: {
    name: 'Nội dung',
    createConfirm: 'Bạn muốn tạo mới Nội dung?',
    createSuccess: 'Tạo mới Nội dung thành công',
    createError: 'Tạo mới Nội dung thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới Nội dung?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật Nội dung?',
    editSuccess: 'Cập nhật Nội dung thành công',
    editError: 'Tạo mới Nội dung thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật Nội dung?. Mọi thay đổi sẽ bị mất',
    deleteConfirm: 'Bạn muốn xóa Nội dung: ${name}?',
    deleteSuccess: 'Xóa Nội dung: ${name} thành công',
    deleteError: 'Xóa Nội dung: ${name} thất bại',
    detailError: 'Lấy thông tin Nội dung thất bại',
    missData: 'Thông tin Nội dung chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Nội dung là không hợp lệ'
  },
  userWithDrawal: {
    name: 'Rút tiền',
    editConfirm: 'Bạn muốn cập nhật Yêu cầu rút tiền?',
    editSuccess: 'Cập nhật Yêu cầu rút tiền thành công',
    editError: 'Cập nhật Yêu cầu rút tiền thất bại',
    deleteConfirm: 'Bạn muốn xóa Yêu cầu rút tiền?',
    deleteSuccess: 'Xóa Yêu cầu rút tiền thành công',
    deleteError: 'Xóa Yêu cầu rút tiền thất bại',
    missData: 'Thông tin chưa đầy đủ. Vui lòng kiểm tra lại'
  },
  productComment: {
    name: 'Bình luận khóa học',
    deleteConfirm: 'Bạn muốn xóa Bình luận khóa học?',
    deleteSuccess: 'Xóa Bình luận khóa học thành công',
    deleteError: 'Xóa Bình luận khóa học thất bại',
    missData: 'Thông tin Bình luận khóa học chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Bình luận khóa học là không hợp lệ'
  },
  productTitle: {
    name: 'Tên sản phẩm',
    deleteConfirm: 'Bạn muốn xóa tên sản phẩm?',
    deleteSuccess: 'Xóa tên sản phẩm thành công',
    deleteError: 'Xóa tên sản phẩm thất bại',
    createConfirm: 'Bạn muốn tạo mới tên sản phẩm?',
    createSuccess: 'Tạo mới tên sản phẩm thành công',
    createError: 'Tạo mới tên sản phẩm thất bại',
    createCancel: 'Bạn muốn hủy quá trình tạo mới tên sản phẩm?. Mọi thay đổi sẽ bị mất',
    editConfirm: 'Bạn muốn cập nhật tên sản phẩm?',
    editSuccess: 'Cập nhật tên sản phẩm thành công',
    editError: 'Tạo mới tên sản phẩm thất bại',
    editCancel: 'Bạn muốn hủy quá trình cập nhật tên sản phẩm?. Mọi thay đổi sẽ bị mất',
    missData: 'Thông tin tên sản phẩm chưa đầy đủ. Vui lòng kiểm tra lại'
  },
  postComment: {
    name: 'Bình luận bài đăng',
    deleteConfirm: 'Bạn muốn xóa Bình luận khóa học?',
    deleteSuccess: 'Xóa Bình luận khóa học thành công',
    deleteError: 'Xóa Bình luận khóa học thất bại',
    missData: 'Thông tin Bình luận khóa học chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Bình luận khóa học là không hợp lệ'
  },
  registerReceiptEmail: {
    name: 'Đăng ký nhận email',
    deleteConfirm: 'Bạn muốn xóa Đăng ký nhận email  ${name}?',
    deleteSuccess: 'Xóa Đăng ký nhận email  ${name} thành công',
    deleteError: 'Xóa Đăng ký nhận email  ${name} thất bại',
    missData: 'Thông tin Đăng ký nhận email chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Đăng ký nhận email là không hợp lệ'
  },
  post: {
    name: 'Bài đăng',
    createConfirm: 'Bạn muốn tạo mới Bài đăng?',
    createSuccess: 'Tạo mới Bài đăng thành công',
    createError: 'Tạo mới Bài đăng thất bại',
    editConfirm: 'Bạn muốn cập nhật Bài đăng?',
    editSuccess: 'Cập nhật Bài đăng thành công',
    editError: 'Cập nhật Bài đăng thất bại',
    deleteConfirm: 'Bạn muốn xóa Bài đăng?',
    deleteSuccess: 'Xóa Bài đăng thành công',
    deleteError: 'Xóa Bài đăng thất bại',
    missData: 'Thông tin Bài đăng chưa đầy đủ. Vui lòng kiểm tra lại',
    detailError: 'Lấy thông tin Bài đăng thất bại'
  },
  notification: {
    name: 'Thông báo',
    detailError: 'Lấy thông tin Thông báo thất bại'
  },
  forum: {
    name: 'Diễn đàn',
    createConfirm: 'Bạn muốn tạo mới diễn đàn?',
    createSuccess: 'Tạo mới diễn đàn thành công',
    createError: 'Tạo mới diễn đàn thất bại',
    editConfirm: 'Bạn muốn cập nhật diễn đàn?',
    editSuccess: 'Cập nhật diễn đàn thành công',
    editError: 'Cập nhật diễn đàn thất bại',
    deleteConfirm: 'Bạn muốn xóa diễn đàn?',
    deleteSuccess: 'Xóa diễn đàn thành công',
    deleteError: 'Xóa diễn đàn thất bại',
    missData: 'Thông tin diễn đàn chưa đầy đủ. Vui lòng kiểm tra lại',
    detailError: 'Lấy thông tin diễn đàn thất bại'
  },
  forumComment: {
    name: 'Bình luận diễn đàn',
    deleteConfirm: 'Bạn muốn xóa Bình luận diễn đàn?',
    deleteSuccess: 'Xóa Bình luận diễn đàn thành công',
    deleteError: 'Xóa Bình luận diễn đàn thất bại',
    missData: 'Thông tin Bình luận diễn đàn chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Bình luận diễn đàn là không hợp lệ'
  },
  commentReport: {
    name: 'Báo xấu',
    deleteConfirm: 'Bạn muốn xóa Báo xấu?',
    deleteSuccess: 'Xóa Báo xấu thành công',
    deleteError: 'Xóa Báo xấu thất bại',
    missData: 'Thông tin Báo xấu chưa đầy đủ. Vui lòng kiểm tra lại',
    invalidStatus: 'Trạng thái Báo xấu là không hợp lệ'
  },
  tag: {
    name: 'Tag',
    createConfirm: 'Bạn muốn tạo mới Tag?',
    createSuccess: 'Tạo mới Tag thành công',
    createError: 'Tạo mới Tag thất bại',
    editConfirm: 'Bạn muốn cập nhật Tag?',
    editSuccess: 'Cập nhật Tag thành công',
    editError: 'Cập nhật Tag thất bại',
    deleteConfirm: 'Bạn muốn xóa Tag?',
    deleteSuccess: 'Xóa Tag thành công',
    deleteError: 'Xóa Tag thất bại',
    missData: 'Thông tin Tag chưa đầy đủ. Vui lòng kiểm tra lại',
    detailError: 'Lấy thông tin Tag thất bại'
  }
};

export { MODEL_MESSAGE, MESSAGES, APP_MESSAGE, DATA_ERROR_MESSAGES };
