import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/carts`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/carts/${id}`);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/carts/${id}`, body);
};

export const list = async (params) => {
  return await axiosInstance.get(`/carts`, { params });
};

export const search = async (params) => {
  return await axiosInstance.get(`/carts/search`, { params });
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/carts/change-status/${id}`, body);
};

export const approved = async (id, body) => {
  return await axiosInstance.put(`/carts/approved/${id}`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/carts/${id}`);
};

export const count = async (params) => {
  return await axiosInstance.get(`/carts/count`, { params });
};
