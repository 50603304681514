import React from 'react';
import { useCategoryQuickSearch } from '../../../../helpers/hookCategory';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxCategoryQuickSearch = ({ parentId, exceptId, exceptParentId, type = 'Product', ...props }) => {
  const { get: getCodeQuickSearch } = useCategoryQuickSearch(parentId, exceptId, exceptParentId, type);

  return <SuggestionInput promiseFn={getCodeQuickSearch} hideInputLabel allowClear modeLikeSearch {...props} />;
};

export default SelectBoxCategoryQuickSearch;
