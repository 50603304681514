/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, Form, Image, Input, Modal, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
// import { useParams } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import CustomButton from '../../../common/components/Button';
import LoadingOverlay from '../../../common/components/LoadingOverlay';
import { IMAGE_APP } from '../../../constants';
import config from '../../../constants/config';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useGetEmailByCode, usePostResetPassword } from '../../../helpers/hookAccount';
import { customToast } from '../../../common/components/Toast';

const { LoginLeftBannerImage, LogoImage } = IMAGE_APP;

const divLeftLoginStyle = {
  backgroundImage: `url(${LoginLeftBannerImage})`
};

const FormItemLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 24
  },
  labelAlign: 'left'
};

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const verifyCode = query.get('verifyCode');

  const { error: getError, get: getEmailByCode } = useGetEmailByCode();
  const { error: postError, post: postResetPassword } = usePostResetPassword();

  useHandleErrorCode('Khôi phục mật khẩu', [getError], 'Không tìm thấy tài khoản');

  const onGetEmail = useCallback(async () => {
    const response = await getEmailByCode({ verifyCode });
    if (response) {
      form.setFieldsValue({ email: response?.email });
    }
  }, [getEmailByCode, verifyCode]);

  const onPostResetPassword = useCallback(
    (values) => {
      postResetPassword({ ...values }).then((rs) => {
        if (rs) {
          customToast({ content: 'Khôi phục mật khẩu thành công!', type: 'success' });
          history.push(ROUTER_URL.LOGIN);
        }
      });
    },
    [history, postResetPassword]
  );

  useEffect(() => {
    onGetEmail();
  }, [onGetEmail]);

  useEffect(() => {
    if (postError) {
      let content = '';
      switch (postError?.code) {
        case config.constants.errorCode.AccountWrongVerifyCode:
          content = 'Mã xác thực không đúng, vui lòng kiểm tra lại';
          break;
        case config.constants.errorCode.AccountVerifyCodeExpired:
          content = 'Mã xác thực đã hết hạn, vui lòng gửi lại yêu cầu thay đổi mật khẩu';
          break;
        default:
          break;
      }

      customToast({ content: 'Khôi phục mật khẩu thất bại', type: 'error' });
    }
  }, [postError]);

  const onFinish = useCallback(() => {
    form.validateFields().then((values) => {
      const { email, confirmNewPassword, newPassword } = values;

      Modal.confirm({
        title: 'Bạn xác nhận muốn khôi phục mật khẩu?',
        okText: 'Đồng ý',
        cancelText: 'Không',
        onOk: () => {
          if (confirmNewPassword !== newPassword) {
            customToast({ content: 'Xác nhận mật khẩu không đúng', type: 'error' });
          } else {
            const body = {
              verifyCode,
              newPassword
            };
            onPostResetPassword(body);
          }
        }
      });
    });
  }, [dispatch]);

  const handleOnClose = useCallback(() => {
    dispatch(push(ROUTER_URL.PASSWORD_FORGOT));
  }, [dispatch]);

  // TODO will implement
  const loading = false;

  return (
    <div>
      <div className="login-container forgot-container w-full">
        <LoadingOverlay loading={loading} />
        <Row>
          <Col span={12}>
            <div className="image-left" style={divLeftLoginStyle} />
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col span={6}>
                <Image className="w-56" preview={false} src={LogoImage} />
              </Col>
            </Row>
            <Col span={10} offset={8} className="login-form-wrapper">
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <span className="text-2xl font-bold">Khôi phục mật khẩu</span>
                </Col>
                <Form
                  name="forgot-request-form"
                  className="forgot-form mt-5"
                  onFinish={onFinish}
                  layout="vertical"
                  style={{ width: '100%' }}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24 }}
                  form={form}
                >
                  <Form.Item name="email" label="Email" {...FormItemLayout}>
                    <Input disabled />
                  </Form.Item>

                  <Form.Item
                    name="newPassword"
                    label="Mật khẩu mới"
                    rules={[{ required: true, message: 'Vui lòng nhập Mật khẩu mới' }]}
                    {...FormItemLayout}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirmNewPassword"
                    label="Xác nhận mật khẩu mới"
                    rules={[{ required: true, message: 'Vui lòng nhập Xác nhận mật khẩu mới' }]}
                    {...FormItemLayout}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Row className="mt-5">
                    <Col span={24}>
                      <CustomButton type="primary" htmlType="submit">
                        Xác nhận
                      </CustomButton>
                      <CustomButton className="ml-3" onClick={handleOnClose}>
                        Đóng
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default compose(withRouter)(ResetPassword);
