import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    groupCode: payload[`${PREFIX}_${FORM_FIELD?.groupCode?.name}`] || undefined,
    groupName: payload[`${PREFIX}_${FORM_FIELD?.groupName?.name}`] || undefined,
    valueCode: payload[`${PREFIX}_${FORM_FIELD?.valueCode?.name}`] || undefined,
    valueName: payload[`${PREFIX}_${FORM_FIELD?.valueName?.name}`] || undefined,
    parentId: payload[`${PREFIX}_${FORM_FIELD?.parentId?.name}`] || undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`] || undefined,
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`] || undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined
  };
};

export const mapFormDataToCreateRecord = (values) => {
  return {
    groupId: values[`${PREFIX}_${FORM_FIELD?.groupId?.name}`] || null,
    parentId: values[`${PREFIX}_${FORM_FIELD?.parentId?.name}`] || null,
    valueCode: values[`${PREFIX}_${FORM_FIELD?.valueCode?.name}`] || null,
    valueName: values[`${PREFIX}_${FORM_FIELD?.valueName?.name}`] || null,
    customData: values[`${PREFIX}_${FORM_FIELD?.customData?.name}`] || null,
    orderNumber: values[`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`] || null,
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`]
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.groupId?.name}`]: record?.groupId,
    [`${PREFIX}_${FORM_FIELD?.parentId?.name}`]: record?.parentId,
    [`${PREFIX}_${FORM_FIELD?.valueCode?.name}`]: record?.valueCode,
    [`${PREFIX}_${FORM_FIELD?.valueName?.name}`]: record?.valueName,
    [`${PREFIX}_${FORM_FIELD?.customData?.name}`]: record?.customData,
    [`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`]: record?.orderNumber || 100,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive
  };
};
