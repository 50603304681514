import React from 'react';
import { useBranchQuickSearch } from '../../../../helpers/hookBranch';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxBranchQuickSearch = ({ exceptId, ...props }) => {
  const { get: getCodeQuickSearch } = useBranchQuickSearch(exceptId);

  return <SuggestionInput promiseFn={getCodeQuickSearch} hideInputLabel allowClear modeLikeSearch {...props} />;
};

export default SelectBoxBranchQuickSearch;
