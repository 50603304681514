import { Col, Row, Form } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImage from '../../../../common/components/UploadImage';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import SelectBoxTypeAccount from '../../../../common/components/SelectBox/SelectBoxTypeAccount';
import SelectBoxAccountRole from '../../../../common/components/SelectBox/SelectBoxAccountRole';
import { PREFIX, FORM_FIELD } from '../../constants';
import { FormType } from '../../../../constants/form';
import { FormItemLayoutArea } from '../../../../constants/form';

const GeneralInfo = ({
  formType = FormType.create,
  disabled,
  requiredItems = [],
  disabledItems = [],
  defaultFileList
}) => {
  return (
    <Row gutter={24}>
      <Col span={24} lg={12}>
        <FormItem
          prefix={PREFIX}
          name="name"
          label="Họ tên"
          required={requiredItems.includes('name')}
          disabled={disabledItems.includes('name') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name="email"
          label="Email"
          required={requiredItems.includes('email')}
          disabled={disabledItems.includes('email') || disabled || formType === FormType.update}
        />
        <SelectBoxTypeAccount prefix={PREFIX} required={requiredItems.includes('type')} />
        <SelectBoxAccountRole prefix={PREFIX} required={requiredItems.includes('roleId')} />
        <SelectBoxStatus prefix={PREFIX} required={requiredItems.includes('isActive')} />
      </Col>
      <Col span={24} lg={12}>
        <FormItem
          prefix={PREFIX}
          name="phone"
          label="Số điện thoại"
          required={requiredItems.includes('phone')}
          disabled={disabledItems.includes('phone') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name="birthday"
          label="Ngày sinh"
          type="date"
          required={requiredItems.includes('birthday')}
          disabled={disabledItems.includes('birthday') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name="joinDay"
          label="Ngày gia nhập"
          type="date"
          required={requiredItems.includes('joinDay')}
          disabled={disabledItems.includes('joinDay') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.roleName.name}
          label={FORM_FIELD.roleName.label}
          required={requiredItems.includes(FORM_FIELD.roleName.name)}
          disabled={disabledItems.includes(FORM_FIELD.roleName.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
      </Col>
      <Col span={24} lg={12}>
        <Form.Item {...FormItemLayoutArea} name={`${PREFIX}_avatar`} label={'Hình ảnh'}>
          <UploadImage defaultFileList={defaultFileList} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default GeneralInfo;
