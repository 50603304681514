import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateUser } from '../../../helpers/hookUser';
import GeneralInfo from '../components/generalInfo';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { error: errorCreateUser, post: createUser } = useCreateUser();

  useHandleErrorCode(APP_MESSAGE.user.name, [errorCreateUser], APP_MESSAGE.user.createError);

  const onCreate = useCallback(
    (values) => {
      createUser({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.user.createSuccess, type: 'success' });
          history.push(`${ROUTER_URL.USER_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createUser]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.USER_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.user.name}`} footerCreatorVisible>
      <CustomForm
        name="user"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.user.createConfirm}
        contentCancel={APP_MESSAGE.user.createCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo requiredItems={['name', 'email', 'phone', 'type', 'password', 'isActive', 'orderNumber']} />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
