import { isEmpty } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';
import { textAreaReplaceNewLine } from '../../utils';

export const mapFormDataToSearchRecord = (payload, isActive) => {
  return {
    description: payload[`${PREFIX}_${FORM_FIELD?.description?.name}`],
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    paymentDateFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    paymentDateTo: payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    isActive,
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`],
    categoryId: payload[`${PREFIX}_${FORM_FIELD?.categoryId?.name}`],
    branchId: payload[`${PREFIX}_${FORM_FIELD?.branchId?.name}`],
    title: payload[`${PREFIX}_${FORM_FIELD?.title?.name}`] || undefined,
    code: payload[`${PREFIX}_${FORM_FIELD?.code?.name}`] || undefined,
    isHot: payload[`${PREFIX}_${FORM_FIELD?.isHot?.name}`],
    isRequiredVip: payload[`${PREFIX}_${FORM_FIELD?.isRequiredVip?.name}`]
  };
};

export const mapFormDataToCreateRecord = (values, serialNumbers) => {
  let images = values[`${PREFIX}_${FORM_FIELD?.images?.name}`];

  if (!isEmpty(images)) {
    images = images.map((image) => {
      // eslint-disable-next-line no-unused-vars
      const { url, uid, ...res } = image;
      return { ...res };
    });
  } else {
    images = null;
  }

  let thumbnail = values[`${PREFIX}_${FORM_FIELD?.thumbnail?.name}`] || null;
  if (!isEmpty(thumbnail)) {
    // eslint-disable-next-line no-unused-vars
    const { url, uid, ...res } = thumbnail;
    thumbnail = { ...res };
  } else {
    thumbnail = null;
  }

  const serialNumbersInput = serialNumbers?.map((item) => {
    return {
      id: item?.id || null,
      orderNumber: item?.orderNumber,
      isUsed: item?.isUsed,
      title: values[`${PREFIX}_${FORM_FIELD?.serialNumber?.name}-${item.orderNumber}`]
    };
  });

  return {
    orderNumber: values[`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`] || 100,
    title: values[`${PREFIX}_${FORM_FIELD?.title?.name}`] || null,
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    content: values[`${PREFIX}_${FORM_FIELD?.content?.name}`] || null,
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`] || null,
    noteForAdmin: values[`${PREFIX}_${FORM_FIELD?.noteForAdmin?.name}`] || null,
    importPrice: values[`${PREFIX}_${FORM_FIELD?.importPrice?.name}`] || 0,
    price: values[`${PREFIX}_${FORM_FIELD?.price?.name}`] || null,
    price1: values[`${PREFIX}_${FORM_FIELD?.price1?.name}`] || null,
    price2: values[`${PREFIX}_${FORM_FIELD?.price2?.name}`] || null,
    price3: values[`${PREFIX}_${FORM_FIELD?.price3?.name}`] || null,
    categoryId: values[`${PREFIX}_${FORM_FIELD?.categoryId?.name}`] || null,
    branchId: values[`${PREFIX}_${FORM_FIELD?.branchId?.name}`] || null,
    amount: values[`${PREFIX}_${FORM_FIELD?.amount?.name}`] || 0,
    inventoryAmount: values[`${PREFIX}_${FORM_FIELD?.inventoryAmount?.name}`] || 0,
    isExistTitle: values[`${PREFIX}_${FORM_FIELD?.isExistTitle?.name}`] || false,
    productTitleId: values[`${PREFIX}_${FORM_FIELD?.productTitleId?.name}`] || null,
    isPreOrder: values[`${PREFIX}_${FORM_FIELD?.isPreOrder?.name}`] || false,
    isViewWeb: values[`${PREFIX}_${FORM_FIELD?.isViewWeb?.name}`] === false ? false : true,
    isViewMobile: values[`${PREFIX}_${FORM_FIELD?.isViewMobile?.name}`] === false ? false : true,
    isHot: values[`${PREFIX}_${FORM_FIELD?.isHot?.name}`] || false,
    isRequiredVip: values[`${PREFIX}_${FORM_FIELD?.isRequiredVip?.name}`] || false,
    isFlashSale: values[`${PREFIX}_${FORM_FIELD?.isFlashSale?.name}`] || false,
    type: values[`${PREFIX}_${FORM_FIELD?.type?.name}`] || null,
    deliveryId: values[`${PREFIX}_${FORM_FIELD?.deliveryId?.name}`] || null,
    guaranteeId: values[`${PREFIX}_${FORM_FIELD?.guaranteeId?.name}`] || null,
    warrantyPeriodId: values[`${PREFIX}_${FORM_FIELD?.warrantyPeriodId?.name}`] || null,
    serialNumbers: serialNumbersInput,
    images,
    thumbnail
  };
};

export const fieldFromRecord = (record) => {
  const values = {
    [`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`]: record?.orderNumber || 100,
    [`${PREFIX}_${FORM_FIELD?.code?.name}`]: record?.code,
    [`${PREFIX}_${FORM_FIELD?.title?.name}`]: record?.title,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: textAreaReplaceNewLine(record?.description),
    [`${PREFIX}_${FORM_FIELD?.noteForAdmin?.name}`]: textAreaReplaceNewLine(record?.noteForAdmin),
    [`${PREFIX}_${FORM_FIELD?.importPrice?.name}`]: record?.importPrice || 0,
    [`${PREFIX}_${FORM_FIELD?.price?.name}`]: record?.price,
    [`${PREFIX}_${FORM_FIELD?.price1?.name}`]: record?.price1,
    [`${PREFIX}_${FORM_FIELD?.price2?.name}`]: record?.price2,
    [`${PREFIX}_${FORM_FIELD?.price3?.name}`]: record?.price3,
    [`${PREFIX}_${FORM_FIELD?.categoryId?.name}`]: record?.categoryId,
    [`${PREFIX}_${FORM_FIELD?.branchId?.name}`]: record?.branchId,
    [`${PREFIX}_${FORM_FIELD?.amount?.name}`]: record?.amount,
    [`${PREFIX}_${FORM_FIELD?.inventoryAmount?.name}`]: record?.inventoryAmount,
    [`${PREFIX}_${FORM_FIELD?.soldAmount?.name}`]: record?.soldAmount || 0,
    [`${PREFIX}_${FORM_FIELD?.isExistTitle?.name}`]: record?.isExistTitle,
    [`${PREFIX}_${FORM_FIELD?.productTitleId?.name}`]: record?.productTitleId,
    [`${PREFIX}_${FORM_FIELD?.isPreOrder?.name}`]: record?.isPreOrder,
    [`${PREFIX}_${FORM_FIELD?.isViewWeb?.name}`]: record?.isViewWeb === true ? true : false,
    [`${PREFIX}_${FORM_FIELD?.isViewMobile?.name}`]: record?.isViewMobile === true ? true : false,
    [`${PREFIX}_${FORM_FIELD?.isHot?.name}`]: record?.isHot,
    [`${PREFIX}_${FORM_FIELD?.isRequiredVip?.name}`]: record?.isRequiredVip,
    [`${PREFIX}_${FORM_FIELD?.isFlashSale?.name}`]: record?.isFlashSale,
    [`${PREFIX}_${FORM_FIELD?.type?.name}`]: record?.type || 'New',
    [`${PREFIX}_${FORM_FIELD?.deliveryId?.name}`]: record?.deliveryId,
    [`${PREFIX}_${FORM_FIELD?.guaranteeId?.name}`]: record?.guaranteeId,
    [`${PREFIX}_${FORM_FIELD?.warrantyPeriodId?.name}`]: record?.warrantyPeriodId
  };

  record?.serialNumbers?.map((item) => {
    values[`${PREFIX}_${FORM_FIELD?.serialNumber?.name}-${item.orderNumber}`] = item.title;
  });

  return values;
};
