import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';
import { isEmpty } from 'lodash';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`],
    categoryId: payload[`${PREFIX}_${FORM_FIELD?.categoryId?.name}`],
    accountId: payload[`${PREFIX}_${FORM_FIELD?.accountId?.name}`],
    status: payload[`${PREFIX}_${FORM_FIELD?.status?.name}`] || undefined,
    title: payload[`${PREFIX}_${FORM_FIELD?.title?.name}`] || undefined,
    description: payload[`${PREFIX}_${FORM_FIELD?.description?.name}`] || undefined
  };
};

export const mapFormDataToCreateRecord = (values) => {
  const cover = values[`${PREFIX}_${FORM_FIELD?.cover?.name}`] || [];

  let images = values[`${PREFIX}_${FORM_FIELD?.images?.name}`];
  if (!isEmpty(images)) {
    images = images.map((image) => {
      // eslint-disable-next-line no-unused-vars
      const { url, uid, ...res } = image;
      return { ...res };
    });
  } else {
    images = null;
  }

  let thumbnail = values[`${PREFIX}_${FORM_FIELD?.thumbnail?.name}`] || null;
  if (!isEmpty(thumbnail)) {
    // eslint-disable-next-line no-unused-vars
    const { url, uid, ...res } = thumbnail;
    thumbnail = { ...res };
  } else {
    thumbnail = null;
  }

  return {
    title: values[`${PREFIX}_${FORM_FIELD?.title?.name}`],
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`] || null,
    content: values[`${PREFIX}_${FORM_FIELD?.content?.name}`] || null,
    source: values[`${PREFIX}_${FORM_FIELD?.source?.name}`] || null,
    accountId: values[`${PREFIX}_${FORM_FIELD?.accountId?.name}`],
    tags: values[`${PREFIX}_${FORM_FIELD?.tags?.name}`] || [],
    status: values[`${PREFIX}_${FORM_FIELD?.status?.name}`],
    isHot: values[`${PREFIX}_${FORM_FIELD?.isHot?.name}`] || false,
    images,
    thumbnail,
    cover: !isEmpty(cover) ? cover[0] : null,
    categoryId: values[`${PREFIX}_${FORM_FIELD?.categoryId?.name}`],
    orderNumber: +values[`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`] || 100
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.title?.name}`]: record?.title,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: record?.description,
    [`${PREFIX}_${FORM_FIELD?.source?.name}`]: record?.source,
    [`${PREFIX}_${FORM_FIELD?.accountId?.name}`]: record?.accountId,
    [`${PREFIX}_${FORM_FIELD?.status?.name}`]: record?.status,
    [`${PREFIX}_${FORM_FIELD?.categoryId?.name}`]: record?.categoryId,
    [`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`]: record?.orderNumber || 100,
    [`${PREFIX}_${FORM_FIELD?.content?.name}`]: record?.content,
    [`${PREFIX}_${FORM_FIELD?.tags?.name}`]: record?.tags,
    [`${PREFIX}_${FORM_FIELD?.thumbnail?.name}`]: record?.thumbnail,
    [`${PREFIX}_${FORM_FIELD?.cover?.name}`]: record?.cover,
    [`${PREFIX}_${FORM_FIELD?.isHot?.name}`]: record?.isHot,
    [`${PREFIX}_${FORM_FIELD?.reasonForRefusal?.name}`]: record?.reasonForRefusal
  };
};
