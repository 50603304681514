import { create, detail, list, update, deleteById } from '../api/branch';
import { useGetDetailById, useGetListTable, useSendPostAPI, useSendPutAPI, useSendAPIById } from './hookAPI';
import { useCallback, useRef } from 'react';
import { map, uniqBy } from 'lodash';

const useGetListBranch = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateBranch = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailBranch = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateBranch = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteBranch = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const useBranchQuickSearch = (exceptId) => {
  const latestAbortToken = useRef(null);
  const get = useCallback(
    ({ name }) => {
      return new Promise((resolve, reject) => {
        if (latestAbortToken.current) {
          clearTimeout(latestAbortToken.current);
        }
        const cancelToken = setTimeout(() => {
          list({
            exceptId: exceptId || undefined,
            title: name || undefined,
            isActive: true
          })
            .then(({ data }) => {
              return resolve(
                uniqBy(
                  map(data?.data?.items, (item) => ({
                    label: item.title,
                    value: item.id
                  })),
                  (item) => item.value
                )
              );
            })
            .catch((error) => {
              return reject(error);
            });
        }, 1000);
        latestAbortToken.current = cancelToken;
      });
    },
    [exceptId]
  );
  return {
    get
  };
};

export { useGetListBranch, useCreateBranch, useDetailBranch, useUpdateBranch, useDeleteBranch, useBranchQuickSearch };
