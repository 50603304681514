export const PREFIX = 'masterValue';

export const FORM_FIELD = {
  groupId: { name: 'groupId', label: 'Nhóm giá trị' },
  groupName: { name: 'groupName', label: 'Tên nhóm' },
  groupCode: { name: 'groupCode', label: 'Mã nhóm' },
  groupParentCode: { name: 'groupParentCode', label: 'Mã giá trị cha' },
  valueCode: { name: 'valueCode', label: 'Mã' },
  valueName: { name: 'valueName', label: 'Tên' },
  customData: { name: 'customData', label: 'Dữ liệu' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  parentId: { name: 'parentId', label: 'Giá trị cha' },
  parentValueName: { name: 'parentValueName', label: 'Tên Giá trị cha' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};
