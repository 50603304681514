import { Col, Row, Form } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImage from '../../../../common/components/UploadImage';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import SelectBoxCategory from '../../../../common/components/SelectBox/SelectBoxCategory';
import { PREFIX, FORM_FIELD } from '../../constants';
import { FormItemLayoutArea } from '../../../../constants/form';
import SelectBoxCategoryType from '../../../../common/components/SelectBox/SelectBoxCategoryType';

const GeneralInfo = ({
  disabled,
  requiredItems = [],
  disabledItems = [],
  defaultFileList,
  categoryId = 0,
  type = 'Product'
}) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <SelectBoxCategoryType prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.type.name)} />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.title.name}
          label={FORM_FIELD.title.label}
          required={requiredItems.includes(FORM_FIELD.title.name)}
          disabled={disabledItems.includes(FORM_FIELD.title.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.parentId.name}
          label={FORM_FIELD.parentId.label}
          required={requiredItems.includes(FORM_FIELD.parentId.name)}
          disabled={disabledItems.includes(FORM_FIELD.parentId.name) || disabled}
          component={
            <SelectBoxCategory allowClear showSearch hideInputLabel type={type} parentId={0} exceptId={categoryId} />
          }
        />
        <FormItem
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.isShowHome.name}
          label={FORM_FIELD.isShowHome.label}
          type="switch"
          required={requiredItems.includes(FORM_FIELD.isShowHome.name)}
          disabled={disabledItems.includes(FORM_FIELD.isShowHome.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
        <SelectBoxStatus prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.isActive.name)} />
        <Form.Item {...FormItemLayoutArea} name={`${PREFIX}_${FORM_FIELD.image.name}`} label={FORM_FIELD.image.label}>
          <UploadImage defaultFileList={defaultFileList} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default GeneralInfo;
