const localStorageKeyPrefix = 'Ecommerce';

export const localStorageKey = {
  token: `${localStorageKeyPrefix}/token`,
  infoApp: `${localStorageKeyPrefix}/infoAppNew`
};

const getLocalToken = () => {
  const token = window.localStorage.getItem(localStorageKey.token);
  if (token) {
    return token;
  }
  return '';
};

const setLocalToken = (token) => {
  window.localStorage.setItem(localStorageKey.token, token);
};

const removeLocalToken = () => {
  window.localStorage.removeItem(localStorageKey.token);
};

const getLocalStorage = (keyName) => {
  const item = window.localStorage.getItem(keyName);
  return item;
};

const setLocalStorage = (keyName, value) => {
  window.localStorage.setItem(keyName, value);
};

const removeLocalStorage = (keyName) => {
  window.localStorage.removeItem(keyName);
};

const clearLocalStorage = () => {
  window.localStorage.clear();
};

const setLocalInfoApp = (userId, version) => {
  if (userId) {
    window.localStorage.setItem(`${localStorageKey.infoApp}/${userId}`, version);
  }
};

const getLocalInfoApp = (userId) => {
  if (userId) {
    return window.localStorage.getItem(`${localStorageKey.infoApp}/${userId}`);
  }
  return null;
};

export {
  getLocalToken,
  setLocalToken,
  removeLocalToken,
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  clearLocalStorage,
  setLocalInfoApp,
  getLocalInfoApp
};
