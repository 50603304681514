import React from 'react';
import { useGroupValueInputCodeQuickSearch } from '../../../../helpers/hookGroupValue';
import AutoCompleteInput from '../../AutoCompleteInput';
import PropTypes from 'prop-types';

const propTypes = {
  onSelect: PropTypes.func
};

const SelectBoxGroupValueAutoComplete = ({ onSelect, ...props }) => {
  const { get: getInputGroupCodeQuickSearch } = useGroupValueInputCodeQuickSearch();

  return <AutoCompleteInput promiseFn={getInputGroupCodeQuickSearch} onSelect={onSelect} {...props} />;
};

SelectBoxGroupValueAutoComplete.propTypes = propTypes;

export default SelectBoxGroupValueAutoComplete;
