import { list, deleteById } from '../api/registerReceiptEmail';
import { useGetListTable, useSendAPIById } from './hookAPI';

const useGetListRegisterReceiptEmail = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useDeleteRegisterReceiptEmail = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

export { useGetListRegisterReceiptEmail, useDeleteRegisterReceiptEmail };
