import React from 'react';
import { useProductQuickSearch } from '../../../../helpers/hookProduct';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxProductQuickSearch = ({ ...props }) => {
  const { get: getProduct } = useProductQuickSearch();

  return <SuggestionInput promiseFn={getProduct} hideInputLabel allowClear {...props} />;
};

export default SelectBoxProductQuickSearch;
