export const PREFIX = 'product';

export const FORM_FIELD = {
  serialNumbers: 'serialNumbers',
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  code: { name: 'code', label: 'Mã sản phẩm' },
  title: { name: 'title', label: 'Tên sản phẩm' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  content: { name: 'content', label: 'Mô tả' },
  description: { name: 'description', label: 'Mô tả ngắn' },
  noteForAdmin: { name: 'noteForAdmin', label: 'Ghi chú cho quản trị viên' },
  importPrice: { name: 'importPrice', label: 'Giá nhập' },
  price: { name: 'price', label: 'Giá bán' },
  price1: { name: 'price1', label: 'Giá BH1' },
  price2: { name: 'price2', label: 'Giá BH2' },
  price3: { name: 'price3', label: 'Giá BH3' },
  images: { name: 'images', label: 'Hình ảnh' },
  thumbnail: { name: 'thumbnail', label: 'Ảnh đại diện' },
  categoryId: { name: 'categoryId', label: 'Danh mục' },
  branchId: { name: 'branchId', label: 'Thương hiệu' },
  productTitleId: { name: 'productTitleId', label: 'Tên sản phẩm mẫu' },
  isExistTitle: { name: 'isExistTitle', label: 'Chọn sản phẩm' },
  amount: { name: 'amount', label: 'Tổng số lượng' },
  soldAmount: { name: 'soldAmount', label: 'Số lượng đã bán' },
  inventoryAmount: { name: 'inventoryAmount', label: 'Tồn kho' },
  serialNumber: { name: 'serialNumber', label: 'Serial Number' },
  isPreOrder: { name: 'isPreOrder', label: 'Yêu cầu đặt trước' },
  isViewWeb: { name: 'isViewWeb', label: 'Hiển thị trên Website' },
  isViewMobile: { name: 'isViewMobile', label: 'Hiển thị trên Mobile' },
  isHot: { name: 'isHot', label: 'Hot' },
  isRequiredVip: { name: 'isRequiredVip', label: 'Yêu cầu VIP' },
  isFlashSale: { name: 'isFlashSale', label: 'Flash sale' },
  type: { name: 'type', label: 'Loại sản phẩm' },
  deliveryId: { name: 'deliveryId', label: 'Giao hàng' },
  guaranteeId: { name: 'guaranteeId', label: 'Bảo hành' },
  warrantyPeriodId: { name: 'warrantyPeriodId', label: 'Thời gian bảo hành' },
  createdAt: { name: 'createdAt', label: 'Ngày mua hàng' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToPaymentDate: { name: 'fromToPaymentDate', label: 'Ngày thanh toán' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: true,
  inventoryAmount: 1
};
