import { Form, Modal } from 'antd';
import find from 'lodash/find';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
// import LoadingOverlay from '../../../common/components/LoadingOverlay';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useGetListGroupValueOption } from '../../../helpers/hookGroupValue';
import { useGetListValue, useGetListValueOptionByGroup, useDeleteMasterValue } from '../../../helpers/hookMasterValue';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import { alertErrorPreLoadingPage } from '../../../utils/modal';
import { FORM_FIELD } from '../constants';
import { mapFormDataToSearchRecord } from '../utils';
import { getMasterValueTableColumns } from './columns';
import ListSearch from '../components/listSearch';
import { customToast } from '../../../common/components/Toast';
import { setLocalStorage } from '../../../utils/localStorage';

const Find = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [groupParentCode, setGroupParentCode] = useState();
  // for table
  const [searchParams, setSearchParams] = useState({});

  const { error: errorList, get: getListValue } = useGetListValue();
  const { error: errorDelete, delete: deleteMasterValue } = useDeleteMasterValue();

  useHandleErrorCode(APP_MESSAGE.masterValue.name, [errorList], APP_MESSAGE.masterValue.missData);
  useHandleErrorCode(APP_MESSAGE.masterValue.name, [errorDelete], APP_MESSAGE.masterValue.deleteError);

  const { loading, reload, data, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, 'descend', false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, 'updatedAt', null, null, null],
    promiseFn: getListValue
  });

  // list for fiter search
  const { data: listGroup, error: errorGroup } = useGetListGroupValueOption();
  const { data: listValue, error: errorValue } = useGetListValueOptionByGroup(groupParentCode);
  // const loadingOptions = useMemo(() => loadingGroup || loadingValue, [loadingGroup, loadingValue]);

  useEffect(() => {
    if (errorGroup || errorValue) {
      alertErrorPreLoadingPage();
    }
  }, [errorGroup, errorValue]);

  useEffect(() => {
    form.resetFields(['parentId', 'parentValueName']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupParentCode]);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onEdit = useCallback(
    (id) => {
      history.push(`${ROUTER_URL.MASTER_VALUE_EDIT}/${id}`);
    },
    [history]
  );

  const onDelete = useCallback(
    (record) => {
      setLocalStorage('type-enter', 'modal');
      Modal.confirm({
        title: getMessageFromName(APP_MESSAGE.masterValue.deleteConfirm, record.title),
        onOk: async () => {
          setLocalStorage('type-enter', '');
          deleteMasterValue(record.id).then((response) => {
            if (response?.success) {
              customToast({ content: APP_MESSAGE.masterValue.deleteSuccess, type: 'success' });

              reload();
            }
          });
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [deleteMasterValue, reload]
  );

  const onFinish = useCallback(
    (values) => {
      setSearchParams((pre) => ({
        ...pre,
        ...mapFormDataToSearchRecord(values)
      }));
    },
    [setSearchParams]
  );

  const handleOnSelectedInputGroupCodeQuickSearch = (text, option) => {
    if (option?.value) {
      form.setFieldsValue({ groupCode: option.label });
      form.setFieldsValue({ groupName: option?.name });
    }
  };

  const onFormChange = useCallback(
    (fields) => {
      if (fields.length > 0) {
        switch (fields[0]?.name[0]) {
          case FORM_FIELD.groupParentCode: {
            setGroupParentCode(fields[0].value);
            break;
          }
          case FORM_FIELD.parentId: {
            const recordValue = find(listValue, (item) => item.value === fields[0].value);
            form.setFieldsValue({ parentValueName: recordValue?.name });
            break;
          }
          default:
            break;
        }
      }
    },
    [listValue, form]
  );

  const columns = useMemo(() => getMasterValueTableColumns({ page, order, onEdit, onDelete, limit }), [
    page,
    order,
    onEdit,
    onDelete,
    limit
  ]);

  return (
    <PageContent title="Giá trị">
      {/* <LoadingOverlay loading={loadingOptions} /> */}
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish} onFieldsChange={onFormChange}>
          <ListSearch
            onSelectedInputGroupCodeQuickSearch={handleOnSelectedInputGroupCodeQuickSearch}
            listGroup={listGroup}
            listValue={listValue}
          />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <RemoteTable
          columns={columns}
          data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
          loading={loading}
          page={page}
          limit={limit}
          total={data?.total ?? 0}
          onChange={onChange(() => {})}
          rowKey="id"
        />
      </CardForm>
    </PageContent>
  );
};

export default Find;
