/* eslint-disable react/display-name */
import { Button, Col, Form, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CartStatus from '../../../../common/components/CartStatus';
import FormItem from '../../../../common/components/FormItem';
import { DATE_TIME_FORMAT } from '../../../../constants';
import ROUTER_URL from '../../../../constants/router';
import { displayDateFromString } from '../../../../utils/dateFormat';

const FindProduct = ({ visible, setVisible, cart }) => {
  const [cartForm] = Form.useForm();

  useEffect(() => {
    cartForm.setFieldsValue({
      code: cart?.code,
      userName: cart?.userName,
      address: cart?.deliveryAddress,
      email: cart?.userEmail,
      numberPhone: cart?.userPhone,
      createdAt: displayDateFromString(cart?.createdAt, DATE_TIME_FORMAT),
      finalPrice: cart?.finalPrice
    });
  }, [cart, cartForm]);

  const onClosePopupHandle = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      className="popup-find-many-ksdg-for-letter"
      width="600px"
      title="Đơn hàng"
      onCancel={onClosePopupHandle}
      footer={null}
    >
      <Form form={cartForm}>
        <div className="condition-wrapper">
          <Row gutter={24}>
            <Col span={24}>
              <FormItem readOnly prefix={''} label={'Mã đơn hàng'} name={'code'} />
              <FormItem readOnly prefix={''} label={'Trạng thái'} component={<CartStatus status={cart?.status} />} />
              <FormItem readOnly prefix={''} label={'Tên người mua'} name={'userName'} />
              <FormItem readOnly prefix={''} label={'Địa chỉ'} name={'address'} type="textArea" rows={3} />
              <FormItem readOnly prefix={''} label={'Email'} name={'email'} />
              <FormItem readOnly prefix={''} label={'Số điện thoại'} name={'numberPhone'} />
              <FormItem readOnly prefix={''} label={'Ngày tạo'} name={'createdAt'} />
              <FormItem readOnly prefix={''} label={'Tổng tiền'} name={'finalPrice'} type="CurrencyVNDInput" showUnit />
            </Col>
          </Row>
          <Row gutter={8} justify="center">
            <Link to={`${ROUTER_URL.CART_DETAIL}/${cart?.id}`} target="_blank">
              <Button type="primary" className="ml-b-2 button-container">
                Chi tiết
              </Button>
            </Link>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default FindProduct;
