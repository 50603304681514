import React from 'react';
import { useContentQuickSearch } from '../../../../helpers/hookContent';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxContentQuickSearch = ({ parentId, exceptId, ...props }) => {
  const { get: getCodeQuickSearch } = useContentQuickSearch(parentId, exceptId);

  return <SuggestionInput promiseFn={getCodeQuickSearch} hideInputLabel allowClear modeLikeSearch {...props} />;
};

export default SelectBoxContentQuickSearch;
