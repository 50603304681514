export const FeedbackStatus = {
  New: 'New',
  Pending: 'Pending',
  Reject: 'Reject',
  Success: 'Success',
  Processing: 'Processing',
  Canceled: 'Canceled'
};

export const PREFIX = 'feedback';

export const FORM_FIELD = {
  mvFunctionId: { name: 'mvFunctionId', label: 'Chức năng' },
  title: { name: 'title', label: 'Tiêu đề' },
  name: { name: 'name', label: 'Tên' },
  email: { name: 'email', label: 'Email' },
  status: { name: 'status', label: 'Tình trạng' },
  phone: { name: 'phone', label: 'Số điện thoại' },
  attachments: { name: 'attachments', label: 'Hình ảnh' },
  description: { name: 'description', label: 'Mô tả' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' },
  reasonForRefusal: { name: 'reasonForRefusal', label: 'Lý do từ chối' }
};

export const SEARCH_DEFAULT = {};
