import { list, deleteById, changeStatus } from '../api/productReview';
import { useGetListTable, useSendAPIById, useSendPutAPI } from './hookAPI';

const useGetListReview = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useDeleteReview = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const usePutReviewChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

export { useGetListReview, useDeleteReview, usePutReviewChangeStatus };
