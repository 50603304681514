import { useCallback, useRef, useMemo, useState } from 'react';
import { dumpPromise } from '../utils';

const useGetListTableHasLoading = (actionApi) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const get = useCallback(
    async ({ searchOption, pagination }) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        res = await actionApi({ ...searchOption, ...pagination });
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionApi]
  );
  return { loading, get, error, setError };
};

const useGetListTable = (actionApi) => {
  const get = useCallback(
    ({ searchOption, pagination }) => {
      return new Promise((resolve, reject) => {
        actionApi({ ...searchOption, ...pagination })
          .then(({ data }) => {
            return resolve(data?.data);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    [actionApi]
  );
  return {
    get
  };
};

/**
 * Use support quick search for sugestion input
 * @param {*} actionApi API call get data
 * @param {*} checkSkipSearch Function check is search or return empty list
 * @param {*} convertToPayload Function convert payload request send API
 * @param {*} convertToOptions Function convert to select options
 * @returns
 */
const useGetQuickSearch = (actionApi, checkSkipSearch, convertToPayload, convertToOptions = (data) => data) => {
  const latestAbortToken = useRef(null);
  const memoDumpPromise = useMemo(() => dumpPromise([]), []);
  const get = useCallback(
    ({ name }) => {
      if (checkSkipSearch && checkSkipSearch(name)) {
        return memoDumpPromise;
      }
      return new Promise((resolve, reject) => {
        if (latestAbortToken.current) {
          clearTimeout(latestAbortToken.current);
        }
        const cancelToken = setTimeout(() => {
          actionApi(convertToPayload(name))
            .then(({ data }) => {
              return resolve(convertToOptions(data));
            })
            .catch((error) => {
              return reject(error);
            });
        }, 1000);
        latestAbortToken.current = cancelToken;
      });
    },
    [actionApi, memoDumpPromise, checkSkipSearch, convertToPayload, convertToOptions]
  );
  return {
    get
  };
};

const useSendAPIById = (actionAPI) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const action = useCallback(
    async (id) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        res = await actionAPI(id);
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionAPI]
  );
  return {
    loading,
    action: action,
    error,
    setError
  };
};

const useSendPostAPI = (actionAPI, id) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const action = useCallback(
    async (body) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        if (id) {
          res = await actionAPI(id, body);
        } else {
          res = await actionAPI(body);
        }
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionAPI, id]
  );
  return {
    loading,
    action: action,
    error,
    setError
  };
};

const useSendForumAPI = (actionAPI, id) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const action = useCallback(
    async (body) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        if (id) {
          res = await actionAPI(id, body);
        } else {
          res = await actionAPI(body);
        }
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionAPI, id]
  );
  return {
    loading,
    action: action,
    error,
    setError
  };
};

const useSendTagAPI = (actionAPI, id) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const action = useCallback(
    async (body) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        if (id) {
          res = await actionAPI(id, body);
        } else {
          res = await actionAPI(body);
        }
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionAPI, id]
  );
  return {
    loading,
    action: action,
    error,
    setError
  };
};

const useSendProductTitleAPI = (actionAPI, id) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const action = useCallback(
    async (body) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        if (id) {
          res = await actionAPI(id, body);
        } else {
          res = await actionAPI(body);
        }
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionAPI, id]
  );
  return {
    loading,
    action: action,
    error,
    setError
  };
};

const useGetListById = (id, actionGetList) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const get = useCallback(
    async (options = {}) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        res = await actionGetList(id, options);
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionGetList, id]
  );
  return { loading, get, error, setError };
};

const handleError = (errors, setError) => {
  if (Array.isArray(errors) && errors.length > 0) {
    setError(errors[0]);
  } else {
    setError(new Error('Something went wrong'));
  }
};

const useSendPutAPI = (actionAPI) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const action = useCallback(
    async (id, body) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        if (id) {
          res = await actionAPI(id, body);
        } else {
          res = await actionAPI(body);
        }
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [actionAPI]
  );
  return {
    loading,
    action: action,
    error,
    setError
  };
};

const useGetDetailById = (action) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const get = useCallback(
    async (id, options = {}) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        res = await action(id, options);
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [action]
  );
  return { loading, get, error, setError };
};

const useGetDetail = (action) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const get = useCallback(
    async (options = {}) => {
      try {
        setLoading(true);
        setError(null);
        let res = null;
        res = await action(options);
        if (res?.data?.data) {
          return res.data.data;
        }
        handleError(res?.data?.errors, setError);
        return undefined;
      } catch (errorAPI) {
        setError(errorAPI);
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [action]
  );
  return { loading, get, error, setError };
};

export {
  useGetListTable,
  useGetQuickSearch,
  useSendAPIById,
  useSendPostAPI,
  useGetListById,
  useSendPutAPI,
  useGetDetailById,
  useGetListTableHasLoading,
  useGetDetail,
  useSendProductTitleAPI,
  useSendTagAPI,
  useSendForumAPI
};
