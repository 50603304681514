import { Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import { FORM_FIELD, PREFIX } from '../../constants';
import { FormCreateCartLayout, FormViewType } from '../../../../constants/form';

const PaymentInfo = ({ mode, disabled, requiredItems = [], disabledItems = [] }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          type="textArea"
          rows={9}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
      </Col>
      {mode !== FormViewType && (
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.totalAmount.name}
            label={FORM_FIELD.totalAmount.label}
            disabled
          />
          <FormItem
            showUnit
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.totalPrice.name}
            label={FORM_FIELD.totalPrice.label}
            disabled
            type="CurrencyVNDInput"
          />
          <FormItem
            showUnit
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.voucherPrice.name}
            label={FORM_FIELD.voucherPrice.label}
            disabled={disabledItems.includes(FORM_FIELD.voucherPrice.name) || disabled}
            type="CurrencyVNDInput"
          />
          <FormItem
            showUnit
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.shipPrice.name}
            label={FORM_FIELD.shipPrice.label}
            disabled={disabledItems.includes(FORM_FIELD.shipPrice.name) || disabled}
            type="CurrencyVNDInput"
          />
          <FormItem
            showUnit
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.finalPrice.name}
            label={FORM_FIELD.finalPrice.label}
            disabled
            type="CurrencyVNDInput"
          />
        </Col>
      )}
    </Row>
  );
};

export default PaymentInfo;
