/* eslint-disable new-cap */
/* eslint-disable func-names */
/* eslint-disable no-console */
/**
 * @file init sagas
 */
import { all } from 'redux-saga/effects';

import { sagas as Auth } from './modules/Auth';
// import { sagas as Socket } from './modules/Socket';
// import { sagas as Notifications } from './modules/Notifications';

/**
 * @name ConfigureStore
 * @description This class config sages management
 * @module Sagas
 * @memberof module:Sagas
 */
export default function* rootSaga() {
  yield all([
    Auth()
    // Notifications()
    // Socket()
  ]);
}
