import { Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import { FORM_FIELD, PREFIX } from '../../constants';

const SerialNumberInfo = ({ serialNumbers = [], onSelectCart }) => {
  const onHandleSelectCart = (cart) => {
    onSelectCart(cart);
  };
  return (
    <Row gutter={24}>
      {serialNumbers?.map((item) => (
        <Col span={24} key={new Date().getTime() + item.orderNumber}>
          <Row>
            <Col span={12} key={new Date().getTime() + item.orderNumber}>
              <FormItem
                prefix={PREFIX}
                name={`${FORM_FIELD.serialNumber.name}-${item.orderNumber}`}
                label={`${FORM_FIELD.serialNumber.label} ${item.orderNumber}`}
                value={item.title}
                required
                disabled={item.isUsed}
              />
            </Col>
            {item?.cart && item.isUsed && (
              <Col span={12} style={{ paddingLeft: '6px' }} onClick={() => onHandleSelectCart(item?.cart)}>
                <p>
                  Đã bán:
                  <a className="ant-typography" style={{ paddingLeft: '6px' }}>
                    {item?.cart?.code}
                  </a>{' '}
                  | {item?.cart?.userName} | {item?.cart?.userPhone}
                </p>
              </Col>
            )}
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default SerialNumberInfo;
