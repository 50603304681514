import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

const PermissionFeatureAccess = ({ children, permissionAction, isCheck = false }) => {
  const { user } = useSelector((state) => state.Auth);
  const { path } = useRouteMatch();

  const allowAccess = useMemo(() => {
    const resource = user?.resources?.find((item) => item.path === path.replace('/:id', ''));
    if (!resource) return false;

    const features = user?.features?.find((item) => item.resourceId === resource.id);
    if (!features) return false;

    return features.action === permissionAction;
  }, [path, permissionAction, user]);

  if (!isCheck) return children;

  return allowAccess && children;
};

export default PermissionFeatureAccess;
