export const PREFIX = 'productTitle';

export const FORM_FIELD = {
  title: { name: 'title', label: 'Tên sản phẩm' },
  description: { name: 'description', label: 'Mô tả' },
  orderNumber: { name: 'orderNumber', label: 'Số thứ tự' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};
