import { DatePicker } from 'antd';
import React from 'react';
import { DATE_FORMAT } from '../../../constants';

const { RangePicker } = DatePicker;

const CustomDateRangePicker = ({ ...props }) => {
  return <RangePicker format={DATE_FORMAT} className="w-full" {...props} />;
};

export default CustomDateRangePicker;
