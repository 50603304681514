import { useAsync } from 'react-async';
import { getMasterValuesAll } from '../../../api/masterValue';
import map from 'lodash/map';

const useGetMasterValues = (groupCode, parentId) => {
  const { isPending, data, error } = useAsync({
    promiseFn: getMasterValuesAll,
    groupCode,
    parentId,
    watch: parentId
  });
  return {
    loading: isPending,
    data: map(data?.data?.data, (item) => ({
      label: item.valueName,
      value: item.id
    })),
    error
  };
};

export { useGetMasterValues };
