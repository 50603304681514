import { axiosInstance } from './common';

const rootRouteApi = '/master-values';

export const getMasterValues = async (params) => {
  return await axiosInstance.get(rootRouteApi, {
    params: {
      limit: params.limit,
      skip: params.skip,
      order: params.order,
      orderField: params.orderField,
      groupCode: params.groupCode || undefined,
      groupName: params.groupName || undefined,
      valueCode: params.valueCode || undefined,
      valueName: params.valueName || undefined,
      parentId: params.parentId || undefined,
      isActive: params.isActive,
      createdAtFrom: params.createdAtFrom || undefined,
      createdAtTo: params.createdAtTo || undefined,
      updatedAtFrom: params.updatedAtFrom || undefined,
      updatedAtTo: params.updatedAtTo || undefined,
      createdById: params.createdById || undefined,
      updatedById: params.updatedById || undefined
    }
  });
};

export const getMasterValuesAll = async (params) => {
  return await axiosInstance.get(`${rootRouteApi}/all`, {
    params: {
      limit: params.limit,
      skip: params.skip,
      order: params.order,
      orderField: params.orderField,
      groupCode: params.groupCode || undefined,
      groupName: params.groupName || undefined,
      valueCode: params.valueCode || undefined,
      valueName: params.valueName || undefined,
      parentId: params.parentId || undefined,
      isActive: params.isActive,
      createdAtFrom: params.createdAtFrom || undefined,
      createdAtTo: params.createdAtTo || undefined,
      updatedAtFrom: params.updatedAtFrom || undefined,
      updatedAtTo: params.updatedAtTo || undefined,
      createdById: params.createdById || undefined,
      updatedById: params.updatedById || undefined
    }
  });
};

export const create = async (body) => {
  return await axiosInstance.post(`/master-values`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/master-values/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/master-values/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/master-values`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/master-values/${id}`);
};
