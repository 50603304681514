import React from 'react';
import FormItem from '../../FormItem';
import { roleList } from '../../../../api/account';
import { get, isEmpty } from 'lodash';
import { useAsync } from 'react-async';

const propTypes = {};

const SelectBoxAccountRole = ({ prefix = '', name = 'roleId', label = 'Vai trò', ...props }) => {
  const { data } = useAsync({
    promiseFn: roleList
  });

  if (isEmpty(data)) return [[], () => []];

  const items = get(data, 'data.data.items', []);

  const options = items.map(({ name, id }) => ({ label: name, value: id }));

  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="select"
      options={options}
      hideInputLabel
      allowClear
      {...props}
    />
  );
};

SelectBoxAccountRole.propTypes = propTypes;

export default SelectBoxAccountRole;
