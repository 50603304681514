export const PREFIX = 'groupValue';

export const FORM_FIELD = {
  code: { name: 'code', label: 'Mã' },
  name: { name: 'name', label: 'Tên' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  parentId: { name: 'parentId', label: 'Nhóm giá trị cha' },
  parentName: { name: 'parentName', label: 'Tên giá trị cha' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};
