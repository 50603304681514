/* eslint-disable */
import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import GeneralInfo from '../components/generalInfo';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';
import { useCreateTag } from '../../../helpers/hookTag';
import { FORM_FIELD } from '../constants';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { error: errorCreateTag, tag: createTag } = useCreateTag();

  useHandleErrorCode(APP_MESSAGE.tag.name, [errorCreateTag], APP_MESSAGE.tag.createError);

  const onCreate = useCallback(
    (values) => {
      createTag({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.tag.createSuccess, type: 'success' });

          history.push(`${ROUTER_URL.TAG_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createTag]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.TAG_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.tag.name}`} footerCreatorVisible>
      <CustomForm
        name="tag"
        defaultValue={fieldFromRecord({ isActive: true, status: 'New' })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.tag.createConfirm}
        contentCancel={APP_MESSAGE.tag.createCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
            ]}
            form={form}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
