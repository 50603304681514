import React from 'react';
import { Row, Typography, Descriptions, Tooltip, Button } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useIsCheckFeature } from '../../../helpers/hookPermission';

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  padding: 20px;
  background-color: white;
`;

const PageContent = ({
  children,
  title,
  footerCreatorVisible = false,
  createdAt,
  updatedAt,
  createdBy,
  updatedBy,
  className,
  onCreate = null,
  module = null
}) => {
  const isCreated = useIsCheckFeature(module, 'Create');
  const { user } = useSelector((state) => state.Auth);

  const formatTime = (dateTime) =>
    dateTime === undefined ? moment().format(DATE_TIME_FORMAT) : displayDateFromString(dateTime, DATE_TIME_FORMAT);

  return (
    <Container className={className}>
      <Row justify="space-between">
        <Typography.Title level={2}>{title}</Typography.Title>
        {isCreated && onCreate && (
          <Tooltip title="Tạo mới" placement="left">
            <Button
              className="mr-2 add-btn"
              onClick={onCreate}
              type="text"
              icon={<PlusCircleOutlined />}
              size="large"
            />
          </Tooltip>
        )}
      </Row>
      {Array.isArray(children) ? children.map((item, index) => <div key={index}>{item}</div>) : children}
      {footerCreatorVisible && (
        <Row>
          <Descriptions className="mt-5" column={2} size="small">
            <Descriptions.Item className="float-left mr-5 text-xs" label="Người tạo">
              {createdBy?.name || user?.name}
            </Descriptions.Item>
            <Descriptions.Item className="float-right ml-5 text-xs" label="Người chỉnh sửa">
              {updatedBy?.name || user?.name}
            </Descriptions.Item>
            <Descriptions.Item className="float-left mr-5 text-xs" label="Ngày tạo">
              {formatTime(createdAt)}
            </Descriptions.Item>
            <Descriptions.Item className="float-right ml-5 text-xs" label="Ngày sửa">
              {formatTime(updatedAt)}
            </Descriptions.Item>
          </Descriptions>
        </Row>
      )}
    </Container>
  );
};

export default PageContent;
