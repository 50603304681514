import React, { useEffect, useState } from 'react';
import { Upload, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { confirmModal, previewModal } from '../Modal';
import {
  useHandleGetPublicPresignedUrl,
  useHandleUploadFile,
  useHandleUploadFileWithPresignedUrl
} from '../../../helpers/hookFile';
import { folderUpload } from '../UploadFiles/constants';
import { customToast } from '../Toast';

const UploadImagesAsync = ({
  onChange,
  defaultFileList = [],
  disabled,
  onDelete,
  isUploadWithPresignedUrl = false
}) => {
  const [fileList, setFileList] = useState(
    defaultFileList && defaultFileList.length > 0 ? defaultFileList.map((f) => ({ ...f, uid: f.key })) : []
  );

  const { post: postFile } = useHandleUploadFile();
  const { post: postFileWithPresignedUrl } = useHandleUploadFileWithPresignedUrl();
  const { post: postFilePublicWithPresignedUrl } = useHandleGetPublicPresignedUrl();

  useEffect(() => {
    let files = fileList.map((f) => {
      const { key, mimeType, url, fileName, size } = f;
      return { key, mimeType, url, fileName, size };
    });
    files = files.filter((f) => f.key);
    onChange(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const onRemove = (file) => {
    confirmModal({
      content: `Bạn muốn xóa file ${file.fileName}`,
      onOk: () => {
        setFileList((files) => files.filter((f) => f.key !== file.key));
        onDelete && onDelete(file);
      }
    });
  };

  const handleCustomUpload = async (file) => {
    try {
      let data = null;
      if (isUploadWithPresignedUrl) {
        data = await postFileWithPresignedUrl(file, folderUpload.privateImages);

        if (data.key) {
          const urlPublic = await postFilePublicWithPresignedUrl(data.key);
          if (urlPublic) {
            data.url = urlPublic;
          }
        }
      } else {
        data = await postFile(file, folderUpload.images);
      }
      setFileList((files) => {
        const newFiles = files.filter((f) => f.key !== file.key);
        return [...newFiles, { ...data, uid: file.uid }];
      });
      customToast({ content: 'Tải hình thành công', type: 'success' });
    } catch (error) {
      customToast({ content: 'Lỗi trong quá trình tải lên', type: 'error' });
    }
  };

  const handleUpload = async () => {
    try {
      for (let i = 0; i < fileList.length; i++) {
        if (!fileList[i].originFileObj) {
          continue;
        }
        await handleCustomUpload(fileList[i].originFileObj);
      }
    } catch (error) {
      message.error(error.message || 'File upload failed');
    }
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const onPreview = (file) => {
    previewModal({ url: file.url });
  };

  return (
    <div>
      <Upload
        fileList={fileList}
        listType="picture-card"
        accept="image/*"
        onChange={handleChange}
        beforeUpload={() => false} // Disable default file upload behavior
        customRequest={handleCustomUpload}
        onPreview={onPreview}
        onRemove={onRemove}
        disabled={disabled}
        multiple
      >
        <PlusOutlined />
      </Upload>
      <Button onClick={handleUpload} disabled={fileList.length === 0}>
        Upload hình ảnh
      </Button>
    </div>
  );
};

export default UploadImagesAsync;
