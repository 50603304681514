/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import ActionButton from '../../../common/components/ActionButton';
import ActiveLabel from '../../../common/components/ActiveLabel';
import EImage from '../../../common/components/EImage';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';

export const getTableColumns = ({ onEdit, onDelete, page, limit }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    fixed: 'left',
    render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
  },
  {
    title: 'Hình ảnh',
    dataIndex: 'image',
    fixed: 'left',
    align: 'center',
    render: (text, record) => <EImage imageUrl={record?.avatar?.url} />
  },
  {
    title: 'Họ Tên',
    fixed: 'left',
    columnIndex: 1,
    render: (text, record) => <Typography.Link onClick={() => onEdit(record.id)}>{record.name}</Typography.Link>
  },
  {
    title: 'Email',
    columnIndex: 2,
    dataIndex: ['email']
  },
  {
    title: 'Loại tài khoản',
    columnIndex: 2,
    dataIndex: ['type']
  },
  {
    title: 'Chức vụ',
    dataIndex: 'roleName'
  },
  {
    title: 'Số điện thoại',
    sorter: false,
    columnIndex: 3,
    dataIndex: ['phone']
  },
  {
    title: 'Thứ tự',
    align: 'center',
    dataIndex: 'orderNumber'
  },
  {
    title: 'Tình Trạng',
    align: 'center',
    columnIndex: 6,
    render: (text, record) => <ActiveLabel isActive={record.isActive} />
  },
  {
    title: 'Người tạo',
    dataIndex: ['createdBy', 'name']
  },
  {
    title: 'Người cập nhật',
    dataIndex: ['updatedBy', 'name']
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Hành động',
    dataIndex: undefined,
    fixed: 'right',
    width: '80px',
    render: (text, record) => {
      return <ActionButton id={record.id} record={record} onEdit={onEdit} onDelete={onDelete} module={'Account'} />;
    }
  }
];
