import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/e-vouchers`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/e-vouchers/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/e-vouchers/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/e-vouchers`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/e-vouchers/${id}`);
};
