import { folderUpload } from '../common/components/UploadFiles/constants';
import { axiosInstance, axiosExternalInstance } from './common';

export const postFile = async (file, folder = folderUpload.images) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder', folder);
  return await axiosInstance.post(`/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const postFileCKEditor = async (file, folder = folderUpload.images) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder', folder);
  return await axiosInstance.post(`/files/CKEditor`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const postGeneratePresignedUrl = async (body) => {
  return await axiosInstance.post(`/files/generate-upload-presigned-url`, body);
};

export const postGeneratePublicPresignedUrl = async (body) => {
  return await axiosInstance.post(`/files/generate-public-presigned-url`, body);
};

export const putUploadFileToPresignedUrl = async (presignedS3Url, file) => {
  return await axiosExternalInstance.put(presignedS3Url, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getUrlImageToDownload = async (key, fileName) => {
  return await axiosInstance.get(`/files/download_url/${key}?fileName=${fileName}`);
};
