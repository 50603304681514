import { Col, Input, Row, Typography } from 'antd';
import React from 'react';
import './style.scss';

const { TextArea } = Input;

const TextInput = ({
  label,
  value,
  onChange,
  placeholder,
  hideInputLabel,
  capitalize,
  isReadOnly,
  isTextArea,
  autoSize = true,
  ...inputProps
}) => {
  return (
    <div className="textinput-container">
      <Row align="middle" justify="start">
        {!hideInputLabel && (
          <Col span={8} className="textinput-label-block">
            <Typography.Text className="textinput-label">{label}</Typography.Text>
          </Col>
        )}
        <Col span={!hideInputLabel ? 16 : 24}>
          {isTextArea ? (
            <TextArea
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              size="middle"
              className={capitalize ? 'uppcase textinput-value' : 'textinput-value'}
              autoComplete="off"
              {...inputProps}
              readOnly={isReadOnly}
              autoSize={autoSize}
            />
          ) : (
            <Input
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              size="middle"
              className={capitalize ? 'uppcase textinput-value' : 'textinput-value'}
              autoComplete="off"
              {...inputProps}
              readOnly={isReadOnly}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TextInput;
