import { EyeInvisibleOutlined, EyeOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Form, Image, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { IMAGE_APP } from '../../../constants';
import APP_MESSAGE from '../../../constants/message';
import ROUTER_URL from '../../../constants/router';
import { loginFailed, loginPending } from '../actions';
import CustomButton from '../../../common/components/Button';
import { validateEmail } from '../../../utils/formValidators';
import './style.scss';
import { customToast } from '../../../common/components/Toast';

const { LoginLeftBannerImage, LogoImage } = IMAGE_APP;

const propTypes = {
  user: PropTypes.object,
  loggingIn: PropTypes.bool,
  loginErrors: PropTypes.any
};

const divLeftLoginStyle = {
  backgroundImage: `url(${LoginLeftBannerImage})`
};

class LoginPage extends React.Component {
  componentDidMount = () => {
    document.title = 'Đăng nhập';
  };

  componentDidUpdate = async () => {
    const { loginErrors, loginFail } = this.props;
    if (loginErrors) {
      customToast({
        title: loginErrors === 'LoginError' ? 'Phát hiện đăng nhập từ thiết bị khác' : 'Lỗi đăng nhập',
        content:
          loginErrors === 'LoginError'
            ? 'Mã xác thực đã được gửi vào email của bạn.'
            : 'Email hoặc Mật khẩu không chính xác. Vui lòng kiểm tra lại',
        type: 'error'
      });
      loginFail(null);
    }
  };

  onFinish = (values) => {
    const { login } = this.props;
    const data = {
      email: values.username,
      password: values.password
    };
    login(data);
  };

  onFinishFailed = () => {
    customToast({ title: 'Lỗi đăng nhập', content: APP_MESSAGE.FIELD_INPUT_ERROR, type: 'error' });
  };

  render() {
    const isMobile = window.matchMedia('(max-width: 1024px)').matches;
    return (
      <div>
        <Row className="login-container">
          {!isMobile ? (
            <Col span={12}>
              <div className="image-left" style={divLeftLoginStyle} />
            </Col>
          ) : (
            <></>
          )}

          <Col span={!isMobile ? 12 : 24}>
            <Row justify="center">
              <Col>
                <Image className="w-56" preview={false} src={LogoImage} />
              </Col>
            </Row>
            {/* <Col span={isMobile ? 20 : 10} offset={isMobile ? 2 : 8} className="login-form-wrapper"> */}
            <Col
              style={{
                padding: `${isMobile ? '20px' : '20px 150px'}`
              }}
            >
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <span className="text-2xl font-bold">Đăng nhập</span>
                </Col>
                <Form
                  name="normal_login"
                  className="login-form mt-5"
                  initialValues={{ remember: true }}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  layout="vertical"
                  style={{ width: '100%' }}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: APP_MESSAGE.FIELD_REQUIRED
                      },
                      {
                        validator: validateEmail
                      }
                    ]}
                    label="Email"
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email address" />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: '20px' }}
                    name="password"
                    rules={[{ required: true, message: APP_MESSAGE.FIELD_REQUIRED }]}
                    label="Mật khẩu"
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Password"
                      type="password"
                      iconRender={(visible) => (!visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                  <Row>
                    <Col style={{ width: '100%' }}>
                      <div className="my-5 font-italic">
                        <Link
                          to={ROUTER_URL.PASSWORD_FORGOT}
                          className="login-form-forgot"
                          style={{ fontStyle: 'italic' }}
                        >
                          Quên mật khẩu?
                        </Link>
                      </div>
                      <Col>
                        <Form.Item>
                          <CustomButton
                            type="primary"
                            htmlType="submit"
                            className={`login-form-button ${isMobile ? 'w-full' : 'w-48'}`}
                          >
                            Đăng nhập
                          </CustomButton>
                        </Form.Item>
                      </Col>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

LoginPage.propTypes = propTypes;

function mapStateToProps(state) {
  const { Auth } = state;
  if (Auth.user) {
    return { user: Auth.user, loggingIn: Auth.loggingIn, loginErrors: '' };
  }
  return { user: null, loggingIn: Auth.loggingIn, loginErrors: Auth.loginErrors };
}

function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(loginPending, dispatch),
    loginFail: bindActionCreators(loginFailed, dispatch)
  };
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LoginPage);
