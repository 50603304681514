import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/tags`, body);
};

export const quickCreate = async (body) => {
  return await axiosInstance.post(`/tags/quick-create`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/tags/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/tags`, {
    params
  });
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/tags/${id}`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/tags/${id}`);
};
