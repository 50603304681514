import { Row, Space } from 'antd';
import React from 'react';
import CustomButton from '../../../common/components/Button';
import ROUTER_URL from '../../../constants/router';
import { useHistory } from 'react-router';

const StatusButton = ({ id }) => {
  const history = useHistory();
  const onProcessing = async () => {
    history.push(`${ROUTER_URL.CART_CREATE}?userId=${id}`);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Row justify="end">
        <Space wrap size="large">
          <CustomButton type="primary" className="button-container button-processing" onClick={onProcessing}>
            Tạo đơn hàng
          </CustomButton>
        </Space>
      </Row>
    </div>
  );
};

export default StatusButton;
