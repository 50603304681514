import { list, deleteById, changeStatus } from '../api/forumComment';
import { useGetListTable, useSendAPIById, useSendPutAPI } from './hookAPI';

const useGetListForumComment = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useDeleteForumComment = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const usePutForumCommentChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

export { useGetListForumComment, useDeleteForumComment, usePutForumCommentChangeStatus };
