import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Switch } from 'antd';
import FormItem from '../../../../common/components/FormItem';
import { PREFIX, FORM_FIELD } from '../../constants';
import SelectBoxAccountQuickSearch from '../../../../common/components/SelectBox/SelectBoxAccount';
// import { toFormatCurrencyVND } from '../../../../utils/numberFormat';
// import SelectBoxUserQuickSearch from '../../../../common/components/SelectBox/SelectBoxUser';

const ListSearch = () => {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const onSwitchFilter = useCallback((checked) => {
    setIsAdvanced(checked);
  }, []);
  return (
    <>
      <Switch className="mb-5" checkedChildren="Nâng cao" unCheckedChildren="Nâng cao" onChange={onSwitchFilter} />
      <Row gutter={24}>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.code.name} label={FORM_FIELD.code.label} />
          <FormItem prefix={PREFIX} name={FORM_FIELD.userName.name} label={FORM_FIELD.userName.label} />
          {isAdvanced && (
            <>
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.createdById.name}
                label={FORM_FIELD.createdById.label}
                component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.fromToCreatedAt.name}
                type="dateRange"
                label={FORM_FIELD.fromToCreatedAt.label}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.priceFrom.name}
                type="number"
                label={FORM_FIELD.priceFrom.label}
                min={0}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.shipPriceFrom.name}
                type="number"
                label={FORM_FIELD.shipPriceFrom.label}
                min={0}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.voucherPriceFrom.name}
                type="number"
                label={FORM_FIELD.voucherPriceFrom.label}
                min={0}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.deliveryAddress.name}
                label={FORM_FIELD.deliveryAddress.label}
              />
            </>
          )}
        </Col>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.userEmail.name} label={FORM_FIELD.userEmail.label} />
          <FormItem prefix={PREFIX} name={FORM_FIELD.userPhone.name} label={FORM_FIELD.userPhone.label} />

          {isAdvanced && (
            <>
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.updatedById.name}
                label={FORM_FIELD.updatedById.label}
                component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.fromToUpdatedAt.name}
                type="dateRange"
                label={FORM_FIELD.fromToUpdatedAt.label}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.priceTo.name}
                type="number"
                label={FORM_FIELD.priceTo.label}
                min={0}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.shipPriceTo.name}
                type="number"
                label={FORM_FIELD.shipPriceTo.label}
                min={0}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.voucherPriceTo.name}
                type="number"
                label={FORM_FIELD.voucherPriceTo.label}
                min={0}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {
  listAvailable: PropTypes.array
};

export default ListSearch;
