import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateMasterValue } from '../../../helpers/hookMasterValue';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { error: errorCreateMasterValue, post: createMasterValue } = useCreateMasterValue();

  useHandleErrorCode(APP_MESSAGE.masterValue.name, [errorCreateMasterValue], APP_MESSAGE.masterValue.createError);

  const onCreate = useCallback(
    (values) => {
      createMasterValue({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.masterValue.createSuccess, type: 'success' });

          history.push(`${ROUTER_URL.MASTER_VALUE_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createMasterValue]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.MASTER_VALUE_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.masterValue.name}`} footerCreatorVisible>
      <CustomForm
        name="masterValue"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.masterValue.createConfirm}
        contentCancel={APP_MESSAGE.masterValue.createCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.groupId.name,
              FORM_FIELD.valueCode.name,
              FORM_FIELD.valueName.name,
              FORM_FIELD.isActive.name,
              FORM_FIELD.orderNumber.name
            ]}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
