import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import history from './history';
import createRootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

/**
 * @name ConfigureStore
 * @description This class config store.
 * @module Redux
 * @memberof module:Redux
 */
const middleWare = [routerMiddleware(history), sagaMiddleware];
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(...middleWare)));
sagaMiddleware.run(rootSaga);

export default store;
