import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDetailCategory, useUpdateCategory } from '../../../helpers/hookCategory';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const { error: errorDetailCategory, get: detailCategory } = useDetailCategory();
  const { error: errorUpdateCategory, put: updateCategory } = useUpdateCategory(id);

  useHandleErrorCode(APP_MESSAGE.category.name, [errorDetailCategory], APP_MESSAGE.category.detailError);
  useHandleErrorCode(APP_MESSAGE.category.name, [errorUpdateCategory], APP_MESSAGE.category.editError);

  const onGetDetail = useCallback(async () => {
    const response = await detailCategory(id);
    if (response) {
      setData(response);
    }
  }, [detailCategory, id]);

  const onUpdate = useCallback(
    (values) => {
      updateCategory(id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.category.editSuccess, type: 'success' });
          history.push(`${ROUTER_URL.CATEGORY_EDIT}/${id}`);
        }
      });
    },
    [history, id, updateCategory]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.CATEGORY_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onUpdate(mapFormDataToCreateRecord(values));
    });
  };

  const onCreate = () => {
    history.push(ROUTER_URL.CATEGORY_CREATE);
  };

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.category.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
      onCreate={onCreate}
    >
      <CustomForm
        name="category"
        module="Category"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.category.editConfirm}
        contentCancel={APP_MESSAGE.category.editCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          {/* <StatusButton id={id} status={data.status} createdById={data.createdById} /> */}
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.type.name,
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
              FORM_FIELD.orderNumber.name
            ]}
            disabledItems={[]}
            imageUrl={data?.image?.url}
            categoryId={data?.id}
            defaultFileList={data?.image ? [data?.image] : []}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Edit;
