import { list, deleteById, changeStatus } from '../api/commentReport';
import { useGetListTable, useSendAPIById, useSendPutAPI } from './hookAPI';

const useGetListCommentReport = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useDeleteCommentReport = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const usePutCommentReportChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

export { useGetListCommentReport, useDeleteCommentReport, usePutCommentReportChangeStatus };
