import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import TextInput from '../../../../common/components/TextInput';
import APP_MESSAGE from '../../../../constants/message';
import '../style.scss';

const propTypes = {};

const ForgotRequest = () => {
  return (
    <Form.Item
      name="identityName"
      rules={[
        {
          required: true,
          message: APP_MESSAGE.FIELD_REQUIRED
        }
      ]}
      label="Email"
    >
      <TextInput prefix={<UserOutlined />} hideInputLabel />
    </Form.Item>
  );
};

ForgotRequest.propTypes = propTypes;

export default ForgotRequest;
