import { Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import FormItem from '../../../../common/components/FormItem';
import SelectBoxUserQuickSearch from '../../../../common/components/SelectBox/SelectBoxUser';
import { FormCreateCartLayout, FormViewType } from '../../../../constants/form';
import { FORM_FIELD, PREFIX } from '../../constants';

const UserInfo = ({ mode, disabled, requiredItems = [], disabledItems = [], isNewUser, form, userDefault = null }) => {
  const [user, setUser] = useState(userDefault);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeUserId = (value) => {
    form.setFieldsValue({
      [`${PREFIX}_${FORM_FIELD?.userId?.name}`]: value?.id || value?.userId,
      [`${PREFIX}_${FORM_FIELD?.userEmail?.name}`]: value?.email,
      [`${PREFIX}_${FORM_FIELD?.userPhone?.name}`]: value?.phone,
      [`${PREFIX}_${FORM_FIELD?.deliveryAddress?.name}`]: value?.address
    });
    setUser(value);
  };

  useEffect(() => {
    if (userDefault) {
      onChangeUserId(userDefault);
    }
  }, [userDefault]); // eslint-disable-line

  return (
    <Row gutter={24}>
      <Col span={24} style={{ minHeight: '300px' }}>
        {mode !== FormViewType && (
          <FormItem
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.isNewUser.name}
            label={FORM_FIELD.isNewUser.label}
            type="switch"
            required={requiredItems.includes(FORM_FIELD.isNewUser.name)}
            disabled={disabledItems.includes(FORM_FIELD.isNewUser.name) || disabled}
          />
        )}

        {isNewUser || mode === FormViewType ? (
          <FormItem
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.userName.name}
            label={FORM_FIELD.userName.label}
            required={requiredItems.includes(FORM_FIELD.userName.name)}
            disabled={disabledItems.includes(FORM_FIELD.userName.name) || disabled}
          />
        ) : (
          <FormItem
            prefix={PREFIX}
            {...FormCreateCartLayout}
            name={FORM_FIELD.userId.name}
            label={FORM_FIELD.userId.label}
            required={requiredItems.includes(FORM_FIELD.userId.name)}
            component={
              <SelectBoxUserQuickSearch
                onChangeSelectObject={onChangeUserId}
                placeholder={'Tìm theo tên, email hoặc số điện thoại'}
                prefix={PREFIX}
                disabled={disabledItems.includes(FORM_FIELD.userId.name) || disabled}
              />
            }
          />
        )}

        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.userEmail.name}
          label={FORM_FIELD.userEmail.label}
          required={requiredItems.includes(FORM_FIELD.userEmail.name)}
          disabled={disabledItems.includes(FORM_FIELD.userEmail.name) || disabled || (!isNewUser && !user)}
        />

        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.userPhone.name}
          label={FORM_FIELD.userPhone.label}
          required={requiredItems.includes(FORM_FIELD.userPhone.name)}
          disabled={disabledItems.includes(FORM_FIELD.userPhone.name) || disabled || (!isNewUser && !user)}
        />

        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          type="textArea"
          rows={4}
          name={FORM_FIELD.deliveryAddress.name}
          label={FORM_FIELD.deliveryAddress.label}
          required={requiredItems.includes(FORM_FIELD.deliveryAddress.name)}
          disabled={disabledItems.includes(FORM_FIELD.deliveryAddress.name) || disabled || (!isNewUser && !user)}
        />
        {mode !== FormViewType && isNewUser && (
          <>
            <FormItem
              prefix={PREFIX}
              {...FormCreateCartLayout}
              name="password"
              label="Mật khẩu"
              required={requiredItems.includes(FORM_FIELD.password.name)}
              disabled={disabledItems.includes(FORM_FIELD.password.name) || disabled}
            />
            <FormItem
              prefix={PREFIX}
              {...FormCreateCartLayout}
              name="isSendPassword"
              label="Gửi mật khẩu vào email"
              type="switch"
              required={requiredItems.includes('isSendPassword')}
              disabled={disabledItems.includes('isSendPassword') || disabled}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default UserInfo;
