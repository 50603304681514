import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

// export const SEARCH_GROUP_VALUE_FORM_FIELD = {
//   code: 'code',
//   parentId: 'parentId',
//   name: 'name',
//   parentName: 'parentName',
//   isActive: 'isActive'
// };

export const mapFormDataToSearchRecord = (payload) => {
  return {
    code: payload[`${PREFIX}_${FORM_FIELD?.code?.name}`] || undefined,
    name: payload[`${PREFIX}_${FORM_FIELD?.name?.name}`] || undefined,
    parentId: payload[`${PREFIX}_${FORM_FIELD?.parentId?.name}`] || undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`] || undefined,
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`] || undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined
  };
};

export const mapFormDataToCreateRecord = (values) => {
  return {
    parentId: values[`${PREFIX}_${FORM_FIELD?.parentId?.name}`] || null,
    code: values[`${PREFIX}_${FORM_FIELD?.code?.name}`] || null,
    name: values[`${PREFIX}_${FORM_FIELD?.name?.name}`] || null,
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`]
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.parentId?.name}`]: record?.parentId,
    [`${PREFIX}_${FORM_FIELD?.code?.name}`]: record?.code,
    [`${PREFIX}_${FORM_FIELD?.name?.name}`]: record?.name,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive
  };
};
