import { isNull, isUndefined } from 'lodash';
import validator from 'validator';

export const validateEmail = (_, text) => {
  if (!text) {
    return Promise.resolve();
  }
  const result = validator.isEmail(text);
  if (result) {
    return Promise.resolve();
  }
  return Promise.reject('Thông tin email không hợp lệ');
};

export const validatePhoneNumber = (_, text) => {
  if (!text) {
    return Promise.resolve();
  }
  const result = validator.isMobilePhone(text, 'any', {
    strictMode: false
  });
  if (result) {
    return Promise.resolve();
  }
  return Promise.reject('Thông tin số điện thoại không hợp lệ');
};

export const isValidCalculateNumber = (text) => {
  if (text === '' || isNaN(text) || isNull(text) || isUndefined(text)) {
    return false;
  }
  if (typeof text === 'number') {
    return true;
  }
  if (validator.isNumeric(text)) {
    return true;
  }
  return false;
};

export const validateNumber = {
  onInput: (e: any) => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))
};

export const validateVoucherCode = {
  onInput: (e: any) => (e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, ''))
};

export const validateVoucherPercent = {
  onInput: (e: any) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    return +value > 100 ? (e.target.value = 100) : (e.target.value = value);
  }
};
