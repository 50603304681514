import { Modal } from 'antd';

export const confirmModal = ({ title, content, onOk = () => {}, onCancel = () => {} }) => {
  Modal.confirm({
    title: title || 'Xác nhận',
    okText: 'Đồng ý',
    cancelText: 'Không',
    content,
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    }
  });
};
