import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { confirmModal, previewModal } from '../Modal';
import config from '../../../constants/config';
import { isEmpty, sumBy } from 'lodash';
import ModalProcessUpload from '../ModalProcessUpload';
import { folderUpload } from '../UploadFiles/constants';
import { customToast } from '../Toast';
import {
  useHandleGetPublicPresignedUrl,
  useHandleUploadFile,
  useHandleUploadFileWithPresignedUrl
} from '../../../helpers/hookFile';

const UploadImages = ({
  onChange,
  defaultFileList = [],
  disabled,
  thumbnailMsg,
  onDelete,
  isUploadWithPresignedUrl = false,
  ...uploadProps
}) => {
  const [fileList, setFileList] = useState(defaultFileList ? defaultFileList.map((f) => ({ ...f, uid: f.key })) : []);
  const [fileListCustom, setFileListCustom] = useState(fileList);
  const [fileListUploading, setFileListUploading] = useState([]);

  const { post: postFile } = useHandleUploadFile();
  const { post: postFileWithPresignedUrl } = useHandleUploadFileWithPresignedUrl();
  const { post: postFilePublicWithPresignedUrl } = useHandleGetPublicPresignedUrl();

  useEffect(() => {
    const files = fileList.map((f) => {
      const { key, mimeType, url, fileName, size } = f;
      return { key, mimeType, url, fileName, size };
    });
    onChange(files);
    setFileListCustom([...fileList].map((x) => ({ ...x, url: x.urlPublic || x.url })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const customRequest = async ({ file }) => {
    try {
      let data = null;
      if (isUploadWithPresignedUrl) {
        data = await postFileWithPresignedUrl(file, folderUpload.privateImages);

        if (data.key) {
          const urlPublic = await postFilePublicWithPresignedUrl(data.key);
          if (urlPublic) {
            data.url = urlPublic;
          }
        }
      } else {
        data = await postFile(file, folderUpload.images);
      }

      setFileList((files) => [...files, { ...data, uuid: file.uid }]);
      customToast({ content: 'Tải hình thành công', type: 'success' });
    } catch (error) {
      customToast({ content: 'Lỗi trong quá trình tải lên', type: 'error' });
    }
  };

  const onRemove = (file) => {
    confirmModal({
      content: `Bạn muốn xóa file ${file.fileName}`,
      onOk: () => {
        setFileList((files) => files.filter((f) => f.key !== file.key));
        onDelete && onDelete(file);
      }
    });
  };

  const onPreview = (file) => {
    previewModal({ url: file.url });
  };

  const beforeUpload = (file) => {
    let okToUpload = true;
    let contentError = '';

    const { name, uid } = file;

    if (file.size > config.uploadMaxFileSize) {
      contentError = 'Dung lượng file tối đa 10MB';
      okToUpload = false;
    }

    const totalSize = sumBy([...fileList, file], (f) => Number(f.size));
    if (totalSize > config.uploadMaxTotalSize) {
      contentError = `File ${file.name} upload không thành công vì tổng dung lượng của các file upload vào hồ sơ không được quá 80Mb`;
      okToUpload = false;
    }

    if (fileList.length > config.uploadMaxFile - 1) {
      contentError = 'Tổng số file upload vào hồ sơ không được quá 20 file';
      okToUpload = false;
    }

    if (!okToUpload) {
      setFileListUploading((f) => [...f, { name, uid, fail: contentError }]);
    } else {
      setFileListUploading((f) => [...f, { name, uid }]);
    }

    return okToUpload;
  };

  return (
    <>
      {thumbnailMsg && !isEmpty(fileListCustom) && <span>{thumbnailMsg}</span>}
      <Upload
        fileList={fileListCustom}
        listType="picture-card"
        customRequest={customRequest}
        accept="image/*"
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        onPreview={onPreview}
        disabled={disabled}
        multiple
        {...uploadProps}
      >
        <PlusOutlined />
      </Upload>
      <ModalProcessUpload
        fileList={fileListCustom}
        filesUploading={fileListUploading}
        onCancel={() => setFileListUploading([])}
      />
    </>
  );
};

export default UploadImages;
