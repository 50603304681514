import { findIndex, isEmpty, map } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-async';
import { getOrderFromTableToAPI } from '../utils/table';

const useRemoteTable = ({ promiseFn, searchOption, defaultOrder, columnSchema = [], defaultLimit = 50 }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimit);
  const [order, setOrder] = useState(defaultOrder);
  const [reloadTimeStamp, setTimeStamp] = useState(Date.now());

  const onChange = (onChangeNest) => (pagination, filter, sorter, ...args) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
    }
    if (pagination.pageSize !== limit) {
      setPage(1);
      setLimit(pagination.pageSize);
    }
    if (!isEmpty(sorter) && sorter.column) {
      const newOrder = map(columnSchema, (item, index) =>
        index === sorter.column?.columnIndex ? sorter.order : false
      );
      setOrder(newOrder);
    } else {
      setOrder(undefined);
    }
    onChangeNest(pagination, filter, sorter, ...args);
  };

  const reload = useCallback(() => {
    setTimeStamp(Date.now());
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchOption]);

  const watchWalue = useMemo(
    () => ({
      limit,
      page,
      order,
      searchOption,
      reload: reloadTimeStamp
    }),
    [limit, order, page, reloadTimeStamp, searchOption]
  );

  const { data, error, isPending } = useAsync({
    promiseFn,
    searchOption,
    watch: watchWalue,
    pagination: {
      order: searchOption?.order || getOrderFromTableToAPI(order),
      orderField: searchOption?.orderField || columnSchema[findIndex(order, (i) => i)],
      skip: (page - 1) * limit,
      limit
    }
  });
  return {
    loading: isPending,
    reload,
    page,
    limit,
    error,
    data: promiseFn ? data : [],
    order,
    onChange
  };
};

export { useRemoteTable };
