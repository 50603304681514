export const PREFIX = 'user';

export const FORM_FIELD = {
  name: { name: 'name', label: 'Họ và tên' },
  email: { name: 'email', label: 'Email' },
  type: { name: 'type', label: 'Loại tài khoản' },
  isVip: { name: 'isVip', label: 'Vip' },
  typeLogin: { name: 'typeLogin', label: 'Loại đăng nhập' },
  avatar: { name: 'avatar', label: 'Hình ảnh' },
  phone: { name: 'phone', label: 'Số điện thoại' },
  gender: { name: 'gender', label: 'Giới tính' },
  nickName: { name: 'nickName', label: 'Biệt danh' },
  address: { name: 'address', label: 'Địa chỉ' },
  password: { name: 'password', label: 'Mật khẩu' },
  isSendPassword: { name: 'isSendPassword', label: 'Gửi mật khẩu vào email' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  isAffiliate: { name: 'isAffiliate', label: 'Kích hoạt Affiliate' },
  birthday: { name: 'birthday', label: 'Ngày sinh nhật' },
  joinDay: { name: 'joinDay', label: 'Ngày tham gia' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' },
  fromToJoinDay: { name: 'fromToJoinDay', label: 'Ngày tham gia' }
};

export const EUserType = {
  Normal: 'Normal',
  Vip: 'Vip'
};

export const SEARCH_DEFAULT = {
  isActive: true
};
