export const PREFIX = 'eVoucher';

export const FORM_FIELD = {
  type: { name: 'type', label: 'Loại' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  userId: { name: 'userId', label: 'Người tạo' },
  productId: { name: 'productId', label: 'Sản phẩm' },
  code: { name: 'code', label: 'Mã voucher' },
  priceType: { name: 'priceType', label: 'Loại giảm giá' },
  description: { name: 'description', label: 'Mô tả' },
  fromDate: { name: 'fromDate', label: 'Ngày hiệu lực' },
  toDate: { name: 'toDate', label: 'Ngày hết hiệu lực' },
  amount: { name: 'amount', label: 'Số lượng' },
  useCount: { name: 'useCount', label: 'Đã dùng' },
  percentReduction: { name: 'percentReduction', label: 'Phần trăm giảm' },
  priceReduction: { name: 'priceReduction', label: 'Số tiền giảm' },
  price: { name: 'price', label: 'Giá' },
  priceLimit: { name: 'priceLimit', label: 'Giới hạn giá tiền' },
  sellPrice: { name: 'sellPrice', label: 'Giá bán' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' },
  fromToDate: { name: 'fromToDate', label: 'Thời gian hiệu lực' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};
