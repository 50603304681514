import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useUpdateAccount } from '../../../helpers/hookAccount';
import GeneralInfo from '../components/generalInfo';
import { fieldFromRecord } from '../utils';
import { APP_MESSAGE } from '../../../constants/messages';
import { FormType } from '../../../constants/form';
import { customToast } from '../../../common/components/Toast';

const MeDetail = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { user: data } = useSelector((state) => state.Auth);

  const { error: errorUpdateAccount, put: updateAccount } = useUpdateAccount();

  useHandleErrorCode(APP_MESSAGE.account.name, [errorUpdateAccount], APP_MESSAGE.account.editError);

  const onUpdate = useCallback(
    (values) => {
      updateAccount(data?.id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.account.editSuccess, type: 'success' });
          history.push(`${ROUTER_URL.ACCOUNT_EDIT}/${data?.id}`);
        }
      });
    },
    [data.id, history, updateAccount]
  );

  const onSave = () => {
    form.validateFields().then(({ name, avatarUrl }) => {
      const body = {
        name,
        avatarUrl
      };
      onUpdate(body);
    });
  };

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.account.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
    >
      <CustomForm
        name="account"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        contentOK="Bạn muốn lưu thông tin cập nhật cho tài khoản?"
        hideCancel
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            formType={FormType.update}
            requiredItems={['name']}
            disabledItem={['email']}
            defaultFileList={data?.avatar ? [data.avatar] : []}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default MeDetail;
