import { Button } from 'antd';
import React from 'react';
import PermissionFeatureAccess from '../PermissionFeatureAccess';
import './style.scss';

const CustomButton = ({ children, onClick, type, className, visible = true, ...buttonProps }) => {
  return (
    <>
      {visible && (
        <PermissionFeatureAccess>
          <Button onClick={onClick} className={`button-container ${className}`} type={type} {...buttonProps}>
            {children}
          </Button>
        </PermissionFeatureAccess>
      )}
    </>
  );
};

export default CustomButton;
