import React from 'react';
import { useUserQuickSearch } from '../../../../helpers/hookUser';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxUserQuickSearch = ({ type, field, ...props }) => {
  const { get: getUser } = useUserQuickSearch(type, field);
  return (
    <SuggestionInput
      promiseFn={(data) => {
        const params = {
          keyword: data.name
        };
        return getUser(params);
      }}
      hideInputLabel
      allowClear
      {...props}
    />
  );
};

export default SelectBoxUserQuickSearch;
