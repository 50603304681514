import { list } from '../api/statistic.js';
import { useGetListTable } from './hookAPI';

const useGetListStatistic = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

export { useGetListStatistic };
