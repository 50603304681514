import { Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const ActiveLabel = ({ isActive }) => {
  return isActive ? <Tag color="success">Hoạt động</Tag> : <Tag color="error">Tạm ngưng</Tag>;
};

ActiveLabel.propTypes = {
  isActive: PropTypes.boolean
};

const MemoizedActiveLabel = React.memo(ActiveLabel);
export default MemoizedActiveLabel;
