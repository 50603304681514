export const REDUX_NAME = 'Notification';

export const INIT_NOTIFICATIONS_ON_LIST = 20;
export const INIT_NOTIFICATIONS_LATEST = 10;
export const STEP_NOTIFICATIONS = 10;

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const POST_NOTIFICATION_MARK_AS_READ_BY_ID = 'POST_NOTIFICATION_MARK_AS_READ_BY_ID';
export const POST_NOTIFICATION_MARK_AS_READ_ALL = 'POST_NOTIFICATION_MARK_AS_READ_ALL';
export const GET_COUNT_UNREAD_NOTI = 'GET_COUNT_UNREAD_NOTI';

export const NOTY_TYPE = {
  HNET: 'HNET',
  HAG: 'HAG'
};
