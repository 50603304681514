/* eslint-disable */
import React from 'react';
import { Prompt } from 'react-router-dom';
import find from 'lodash/find';
import './style.scss';

const BlockNavigation = () => {
  return (
    <Prompt
      when
      message={(nextLocation) => {
        const currentPathname = window.location.pathname;
        const pathsChangeData = ['custom-comparison'];
        const isCreateOrEdit = find(pathsChangeData, (p) => currentPathname.indexOf(p) !== -1);
        return isCreateOrEdit
          ? `Những nội dung bạn đang nhập hiện chưa lưu. Bạn có muốn rời khỏi màn hình hiện tại?`
          : true;
      }}
    />
  );
};

export default BlockNavigation;
