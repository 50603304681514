import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/forums`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/forums/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/forums`, {
    params
  });
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/forums/${id}`, body);
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/forums/change-status/${id}`, body);
};

export const approved = async (id, body) => {
  return await axiosInstance.put(`/forums/approved/${id}`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/forums/${id}`);
};

export const count = async (params) => {
  return await axiosInstance.get(`/forums/count`, { params });
};
