import { axiosInstance } from './common';

export const list = async (params) => {
  return await axiosInstance.get(`/product-reviews`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/product-reviews/${id}`);
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/product-reviews/change-status/${id}`, body);
};
