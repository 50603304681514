import React, { useEffect, useState } from 'react';
import { Image, Layout } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../header';
import LeftMenu from '../leftMenu';
import { IMAGE_APP } from '../../../constants';
import ROUTER_URL from '../../../constants/router';
import { useIsCheckPermissionPath } from '../../../helpers/hookPermission';
import './style.scss';

const { LogoImage } = IMAGE_APP;

const { Content, Sider } = Layout;

const LayoutComponent = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const { push } = useHistory();
  const isMobile = window.matchMedia('(max-width: 767px)').matches;

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile]);

  const toggle = () => {
    setCollapsed((state) => !state);
  };

  const handleOnClickLogo = () => {
    push('/home');
  };

  if (useIsCheckPermissionPath(pathname) === false && pathname !== '/forbidden') {
    push('/forbidden');
  }

  if (
    [
      ROUTER_URL.LOGIN,
      ROUTER_URL.LOGIN_CONFIRM,
      ROUTER_URL.PASSWORD_FORGOT,
      ROUTER_URL.PASSWORD_CHANGE_CODE,
      ROUTER_URL.PASSWORD_RESET
    ].includes(pathname)
  ) {
    return children;
  }

  return (
    <Layout className="min-h-screen">
      {!isMobile && <div className={!collapsed ? 'sider-extend' : 'sider-collapsed'} />}
      {!collapsed && (
        <Sider width={200} trigger={null} collapsed={collapsed} className="sider-container z-20">
          <div className="ant-layout-logo">
            <Image className="ant-layout-logo-image" preview={false} src={LogoImage} onClick={handleOnClickLogo} />
          </div>
          <LeftMenu />
        </Sider>
      )}
      <Layout>
        <Header collapsed={collapsed} toggle={toggle} />
        <Content className="mt-16">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
