import { isEmpty } from 'lodash';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToCreateRecord = (values) => {
  const logo = values[`${PREFIX}_${FORM_FIELD?.logo?.name}`];
  const bankQR = values[`${PREFIX}_${FORM_FIELD?.bankQR?.name}`];
  return {
    logo: !isEmpty(logo) ? logo[0] : null,
    emailSupport: values[`${PREFIX}_${FORM_FIELD?.emailSupport?.name}`] || undefined,
    hotline: values[`${PREFIX}_${FORM_FIELD?.hotline?.name}`] || undefined,
    workingTime: values[`${PREFIX}_${FORM_FIELD?.workingTime?.name}`],
    facebookLink: values[`${PREFIX}_${FORM_FIELD?.facebookLink?.name}`],
    twitterLink: values[`${PREFIX}_${FORM_FIELD?.twitterLink?.name}`],
    googleLink: values[`${PREFIX}_${FORM_FIELD?.googleLink?.name}`],
    hangoutLink: values[`${PREFIX}_${FORM_FIELD?.hangoutLink?.name}`],
    hideProductMobile: values[`${PREFIX}_${FORM_FIELD?.hideProductMobile?.name}`],
    address: values[`${PREFIX}_${FORM_FIELD?.address?.name}`],
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`],
    bankName: values[`${PREFIX}_${FORM_FIELD?.bankName?.name}`],
    bankAccount: values[`${PREFIX}_${FORM_FIELD?.bankAccount?.name}`],
    bankTitle: values[`${PREFIX}_${FORM_FIELD?.bankTitle?.name}`],
    bankDescription: values[`${PREFIX}_${FORM_FIELD?.bankDescription?.name}`],
    productDescription: values[`${PREFIX}_${FORM_FIELD?.productDescription?.name}`],
    bankNote: values[`${PREFIX}_${FORM_FIELD?.bankNote?.name}`],
    bankCode: values[`${PREFIX}_${FORM_FIELD?.bankCode?.name}`],
    bankNumber: values[`${PREFIX}_${FORM_FIELD?.bankNumber?.name}`],
    bankPrefix: values[`${PREFIX}_${FORM_FIELD?.bankPrefix?.name}`],
    bankQR: !isEmpty(bankQR) ? bankQR[0] : null,
    gallery: values[`${PREFIX}_${FORM_FIELD?.gallery?.name}`],
    footerInfo: {
      mst: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.mst?.name}`] || undefined,
      hotline: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.hotline?.name}`] || undefined,
      hotline1: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.hotline1?.name}`] || undefined,
      hotline2: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.hotline2?.name}`] || undefined,
      emailSupport: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.emailSupport?.name}`] || undefined,
      emailSupport1: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.emailSupport1?.name}`] || undefined,
      emailSupport2: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.emailSupport2?.name}`] || undefined,
      address: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.address?.name}`],
      address1: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.address1?.name}`],
      address2: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.address2?.name}`],
      workingTime: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.workingTime?.name}`],
      workingTime1: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.workingTime1?.name}`],
      workingTime2: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.workingTime2?.name}`],
      googleMap1: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.googleMap1?.name}`],
      googleMap2: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.googleMap2?.name}`],
      bankVietcombank: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.bankVietcombank?.name}`],
      bankTechcombank: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.bankTechcombank?.name}`],
      purchaseMethodLink: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.purchaseMethodLink?.name}`],
      guaranteeLink: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.guaranteeLink?.name}`],
      privacyPolicyLink: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.privacyPolicyLink?.name}`],
      paymentAndDeliveryLink: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.paymentAndDeliveryLink?.name}`],
      repairAndMaintenanceLink: values[`${PREFIX}_${FORM_FIELD?.footerInfo?.repairAndMaintenanceLink?.name}`]
    }
  };
};

export const fieldFromRecord = (records) => {
  let logo = null;
  let emailSupport = null;
  let hotline = null;
  let workingTime = null;
  let description = null;
  let facebookLink = null;
  let twitterLink = null;
  let googleLink = null;
  let hangoutLink = null;
  let address = null;
  let bankName = null;
  let bankAccount = null;
  let bankNumber = null;
  let bankPrefix = null;
  let bankTitle = null;
  let bankDescription = null;
  let productDescription = null;
  let bankNote = null;
  let bankCode = null;
  let bankQR = null;
  let footerInfo = null;
  let hideProductMobile = false;
  let gallery = null;

  records.map((item) => {
    if (!logo && item.key === 'logo') logo = item.image;
    if (!emailSupport && item.key === 'emailSupport') emailSupport = item.value;
    if (!hotline && item.key === 'hotline') hotline = item.value;
    if (!workingTime && item.key === 'workingTime') workingTime = item.value;
    if (!description && item.key === 'description') description = item.value;
    if (!facebookLink && item.key === 'facebookLink') facebookLink = item.value;
    if (!twitterLink && item.key === 'twitterLink') twitterLink = item.value;
    if (!googleLink && item.key === 'googleLink') googleLink = item.value;
    if (!hideProductMobile && item.key === 'hideProductMobile')
      hideProductMobile = item.value === 'true' ? true : false;
    if (!hangoutLink && item.key === 'hangoutLink') hangoutLink = item.value;
    if (!address && item.key === 'address') address = item.value;
    if (!bankName && item.key === 'bankName') bankName = item.value;
    if (!bankAccount && item.key === 'bankAccount') bankAccount = item.value;
    if (!bankNumber && item.key === 'bankNumber') bankNumber = item.value;
    if (!bankTitle && item.key === 'bankTitle') bankTitle = item.value;
    if (!bankDescription && item.key === 'bankDescription') bankDescription = item.value;
    if (!productDescription && item.key === 'productDescription') productDescription = item.value;
    if (!bankNote && item.key === 'bankNote') bankNote = item.value;
    if (!bankCode && item.key === 'bankCode') bankCode = item.value;
    if (!bankPrefix && item.key === 'bankPrefix') bankPrefix = item.value;
    if (!bankQR && item.key === 'bankQR') bankQR = item.value;
    if (!footerInfo && item.key === 'footerInfo') footerInfo = item.dataJson;
    if (!gallery && item.key === 'gallery') gallery = item.value;
  });

  return {
    [`${PREFIX}_${FORM_FIELD?.logo?.name}`]: logo,
    [`${PREFIX}_${FORM_FIELD?.emailSupport?.name}`]: emailSupport,
    [`${PREFIX}_${FORM_FIELD?.hotline?.name}`]: hotline,
    [`${PREFIX}_${FORM_FIELD?.workingTime?.name}`]: workingTime,
    [`${PREFIX}_${FORM_FIELD?.facebookLink?.name}`]: facebookLink,
    [`${PREFIX}_${FORM_FIELD?.twitterLink?.name}`]: twitterLink,
    [`${PREFIX}_${FORM_FIELD?.googleLink?.name}`]: googleLink,
    [`${PREFIX}_${FORM_FIELD?.hideProductMobile?.name}`]: hideProductMobile,
    [`${PREFIX}_${FORM_FIELD?.hangoutLink?.name}`]: hangoutLink,
    [`${PREFIX}_${FORM_FIELD?.address?.name}`]: address,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: description,
    [`${PREFIX}_${FORM_FIELD?.bankName?.name}`]: bankName,
    [`${PREFIX}_${FORM_FIELD?.bankAccount?.name}`]: bankAccount,
    [`${PREFIX}_${FORM_FIELD?.bankNumber?.name}`]: bankNumber,
    [`${PREFIX}_${FORM_FIELD?.bankNote?.name}`]: bankNote,
    [`${PREFIX}_${FORM_FIELD?.bankCode?.name}`]: bankCode,
    [`${PREFIX}_${FORM_FIELD?.bankPrefix?.name}`]: bankPrefix,
    [`${PREFIX}_${FORM_FIELD?.bankTitle?.name}`]: bankTitle,
    [`${PREFIX}_${FORM_FIELD?.bankDescription?.name}`]: bankDescription,
    [`${PREFIX}_${FORM_FIELD?.bankQR?.name}`]: bankQR,
    [`${PREFIX}_${FORM_FIELD?.productDescription?.name}`]: productDescription,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.mst?.name}`]: footerInfo?.mst,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.googleMap1?.name}`]: footerInfo?.googleMap1,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.googleMap2?.name}`]: footerInfo?.googleMap2,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.bankVietcombank?.name}`]: footerInfo?.bankVietcombank,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.bankTechcombank?.name}`]: footerInfo?.bankTechcombank,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.purchaseMethodLink?.name}`]: footerInfo?.purchaseMethodLink,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.guaranteeLink?.name}`]: footerInfo?.guaranteeLink,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.paymentAndDeliveryLink?.name}`]: footerInfo?.paymentAndDeliveryLink,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.repairAndMaintenanceLink?.name}`]: footerInfo?.repairAndMaintenanceLink,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.privacyPolicyLink?.name}`]: footerInfo?.privacyPolicyLink,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.hotline?.name}`]: footerInfo?.hotline,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.hotline1?.name}`]: footerInfo?.hotline1,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.hotline2?.name}`]: footerInfo?.hotline2,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.emailSupport?.name}`]: footerInfo?.emailSupport,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.emailSupport1?.name}`]: footerInfo?.emailSupport1,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.emailSupport2?.name}`]: footerInfo?.emailSupport2,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.address?.name}`]: footerInfo?.address,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.address1?.name}`]: footerInfo?.address1,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.address2?.name}`]: footerInfo?.address2,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.workingTime?.name}`]: footerInfo?.workingTime,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.workingTime1?.name}`]: footerInfo?.workingTime1,
    [`${PREFIX}_${FORM_FIELD?.footerInfo?.workingTime2?.name}`]: footerInfo?.workingTime2,
    [`${PREFIX}_${FORM_FIELD?.gallery?.name}`]: +gallery
  };
};
