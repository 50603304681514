import { axiosInstance } from './common';

export const analytic = async () => {
  return await axiosInstance.get(`/analytics`);
};

export const analyticListProduct = async (params) => {
  return await axiosInstance.get(`/analytics/list-product`, {
    params
  });
};

export const analyticListProductSales = async (params) => {
  return await axiosInstance.get(`/analytics/list-product-sales`, {
    params
  });
};

export const analyticStatusCart = async (params) => {
  return await axiosInstance.get(`/analytics/status-cart`, {
    params
  });
};
