import { list, update } from '../api/setting.js';
import { useGetListTable, useSendPutAPI } from './hookAPI';

const useGetListSetting = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useUpdateSetting = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

export { useGetListSetting, useUpdateSetting };
