/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import ActiveLabel from '../../../common/components/ActiveLabel';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';

export const getTableColumns = ({ page }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    render: (text, record, index) => <Typography.Text>{(page - 1) * 10 + index + 1}</Typography.Text>
  },
  {
    title: 'Tên sản phẩm',
    render: (text, record) => (
      <a className="ant-typography" target="_blank" href={`/product/edit/${record?.product?.id}`}>
        {record?.product?.title}
      </a>
    )
  },
  {
    title: 'Mã',
    dataIndex: ['title']
  },
  {
    title: 'Mã đơn hàng',
    render: (text, record) => (
      <a className="ant-typography" target="_blank" href={`/cart/detail/${record?.cartId}`}>
        {record?.cartCode || record?.cart?.code}
      </a>
    )
  },
  {
    title: 'Ngày bán',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.cart?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Thứ tự',
    align: 'center',
    dataIndex: 'orderNumber'
  },
  {
    title: 'Tình Trạng',
    align: 'center',
    render: (text, record) => <ActiveLabel isActive={record.isActive} />
  },
  {
    title: 'Người tạo',
    dataIndex: ['createdBy', 'name']
  },
  {
    title: 'Người cập nhật',
    dataIndex: ['updatedBy', 'name']
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  }
];
