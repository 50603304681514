import { Modal } from 'antd';
import { getMsgFromErrorCode } from '../../../utils/getErrors';

export const errorModal = ({ content, okText, cancelText, onOk = () => {} }) => {
  Modal.error({
    title: 'Lỗi',
    content,
    okText,
    cancelText,
    onOk() {
      onOk();
    }
  });
};

export const errorModalByErrorCode = (errorCode) => {
  const msg = getMsgFromErrorCode(errorCode);
  errorModal({ content: msg });
};
