import React from 'react';
import { useProductTitleQuickSearch } from '../../../../helpers/hookProductTitle';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxProductTitleQuickSearch = ({ exceptId, ...props }) => {
  const { get: getProductTitleQuickSearch } = useProductTitleQuickSearch(exceptId);

  return <SuggestionInput promiseFn={getProductTitleQuickSearch} hideInputLabel allowClear modeLikeSearch {...props} />;
};

export default SelectBoxProductTitleQuickSearch;
