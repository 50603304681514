import { Col, Row, Tabs, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetListStatistic } from '../../helpers/hookStatistic';
import CountComponent from './components/Count';
import ProductCountComponent from './components/ProductCount';
import CartCountComponent from './components/CartCount';
import FeedbackCountComponent from './components/FeedbackCount';
import TableComponent from './components/Table';
import { columns, productColumns, cartColumns } from './column';
import './style.scss';
import CardForm from '../../common/components/CardForm';
import { useGetAnalytic } from '../../helpers/hookAnalytic';
import CardCountComponent from './components/CardCount';
import EImage from '../../common/components/EImage';
import { Link } from 'react-router-dom';
import ROUTER_URL from '../../constants/router';
import FormItem from '../../common/components/FormItem';
import { toFormatCurrencyVND } from '../../utils/numberFormat';
import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { dateOptions } from '../../constants/data';
import { getDateByType } from '../../utils';
import TableAnalyticComponent from './components/TableAnalytic';

const Home = () => {
  const [data, setData] = useState(null);
  const [dateType, setDateType] = useState('today');
  const [dataAnalytic, setDataAnalytic] = useState(null);
  const [dataListProduct, setDataListProduct] = useState(null);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('totalSales');
  const [sortType, setSortType] = useState('desc');
  const [tab, setTab] = useState(1);

  const { getStatusCart, getListProductSales } = useGetAnalytic();

  const { get: getListStatistic } = useGetListStatistic();

  const onGetListStatistic = useCallback(() => {
    getListStatistic({}).then((response) => {
      setData(response);
    });
  }, [getListStatistic]);

  const onGetListProduct = useCallback(() => {
    getListProductSales({
      fromDate: fromDate ? moment(fromDate).format(DATE_FORMAT_FROM_SERVER) : undefined,
      toDate: toDate ? moment(toDate).format(DATE_FORMAT_FROM_SERVER) : undefined,
      limit: pageSize,
      skip: (page - 1) * pageSize,
      order: sortColumn ? sortType : null,
      orderField: sortColumn ? sortColumn : null
    }).then((response) => {
      setDataListProduct(response.data.data);
    });
  }, [getListProductSales, fromDate, toDate, page, pageSize, sortColumn, sortType]);

  const onGetStatusCart = useCallback(() => {
    getStatusCart({
      fromDate: fromDate ? moment(fromDate).format(DATE_FORMAT_FROM_SERVER) : undefined,
      toDate: toDate ? moment(toDate).format(DATE_FORMAT_FROM_SERVER) : undefined
    }).then((response) => {
      setDataAnalytic(response);
    });
  }, [getStatusCart, fromDate, toDate]);

  useEffect(() => {
    document.title = 'Trang chủ';

    onGetListStatistic();

    onGetStatusCart();
    onGetListProduct();
  }, [onGetListStatistic, onGetListProduct, onGetStatusCart]);

  const columnsListAnalyticProduct = [
    {
      title: 'STT',
      align: 'center',
      width: '50px',
      render: function STT(text, record, index) {
        return <Typography.Text>{(page - 1) * 10 + index + 1}</Typography.Text>;
      }
    },
    {
      title: 'Ảnh đại diện',
      dataIndex: 'thumbnail',
      align: 'center',
      fixed: 'left',
      render: function Avatar(text, record) {
        return <EImage imageUrl={record?.thumbnail?.url} />;
      }
    },
    {
      title: 'Mã sản phẩm',
      align: 'center',
      dataIndex: 'code',
      render: function Code(text, record) {
        return (
          <Link to={`${ROUTER_URL.PRODUCT_EDIT}/${record?.id}`} target="_blank">
            {record.code}
          </Link>
        );
      }
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: sortColumn === 'totalAmount' ? 'Số lượng' : 'Doanh số (đ)',
      dataIndex: sortColumn === 'totalAmount' ? 'totalAmount' : 'totalSales',
      align: sortColumn !== 'totalAmount' ? 'right' : 'center',
      key: sortColumn === 'totalAmount' ? 'totalAmount' : 'totalSales',
      sorter: true,
      defaultSortOrder: 'descend',
      render: function TotalAmount(text, record) {
        // sortColumn === 'totalSales' ? <div>{toFormatCurrencyVND(e)}</div> : <div>{e}</div>
        if (sortColumn === 'totalSales') {
          return <div>{toFormatCurrencyVND(record.totalSales)}</div>;
        }
        return <div>{record.totalAmount}</div>;
      }
    }
  ];

  const tagItems = [
    {
      key: 1,
      label: `Doanh số`,
      children: null
    },
    {
      key: 2,
      label: `Số lượng`,
      children: null
    }
  ];

  const onChangeFilter = (val) => {
    setFromDate(val ? moment(val[0]).format(DATE_FORMAT_FROM_SERVER) : undefined);
    setToDate(val ? moment(val[1]).format(DATE_FORMAT_FROM_SERVER) : undefined);
  };
  const onChangeDateFilter = (val) => {
    if (val !== 'custom') {
      const { formDate, toDate } = getDateByType(val);
      setFromDate(formDate);
      setToDate(toDate);
    }
    setDateType(val);
  };

  return (
    <div className="home-container">
      <Row gutter={16}>
        <CartCountComponent title="Đơn hàng" count={data?.cartCount} colorClass="color-3" />
        <FeedbackCountComponent title="Góp ý" count={data?.feedbackCount} colorClass="color-1" />
        <ProductCountComponent title="Sản phẩm" count={data?.productCount} colorClass="color-2" />
        <CountComponent title="Tổng tài khoản " count={data?.userCount} colorClass="color-4" />
      </Row>
      <Row gutter={16} className="mt-10">
        <Col span={24}>
          <TableComponent
            title={`Đơn hàng mới (${data?.carts?.length || 0})`}
            color="#d46b08"
            columns={cartColumns}
            dataSource={data?.carts}
          />
        </Col>
        <Col span={24}>
          <TableComponent title="Khách hàng mới" color="#d46b08" columns={columns} dataSource={data?.users} />
        </Col>
        <TableComponent title="Sản phẩm mới" color="#faad14" columns={productColumns} dataSource={data?.products} />
      </Row>

      <CardForm title={'Thống kê sản phẩm'} style={{ width: '100%' }} headStyle={{ color: '#faad14' }}>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              span={24}
              name={'date'}
              type="select"
              label={'Thời gian'}
              onChange={onChangeDateFilter}
              defaultValue="today"
              options={dateOptions}
              allowClear
            />
          </Col>
          {dateType === 'custom' && (
            <Col span={12}>
              <FormItem span={24} name={'fromToDate'} type="dateRange" label={''} onChange={onChangeFilter} />
            </Col>
          )}
        </Row>
        <Row gutter={16} className="mt-10">
          <CardCountComponent title="Tổng đơn hàng" count={dataAnalytic?.order} span={4} />
          <CardCountComponent
            title="Chờ xác nhận"
            count={dataAnalytic?.watingConfirm}
            span={4}
            colorClass={'color-2'}
          />
          <CardCountComponent
            title="Chờ lấy hàng"
            count={dataAnalytic?.witingReceive}
            span={4}
            colorClass={'color-4'}
          />
          <CardCountComponent title="Đang giao" count={dataAnalytic?.delivering} span={4} colorClass={'color-3'} />
          <CardCountComponent title="Đã giao" count={dataAnalytic?.delivered} span={4} colorClass={'color-success'} />
          <CardCountComponent title="Đơn huỷ" count={dataAnalytic?.canceled} span={4} colorClass={'color-danger'} />
          <CardCountComponent
            title="Doanh số (đ)"
            count={toFormatCurrencyVND(dataAnalytic?.sales)}
            span={4}
            colorClass={'color-2 '}
          />
          <CardCountComponent
            title="Tiền giảm giá (đ)"
            count={toFormatCurrencyVND(dataAnalytic?.voucher)}
            span={4}
            colorClass={'color-1'}
          />
          <CardCountComponent
            title="Tiền ship (đ)"
            count={toFormatCurrencyVND(dataAnalytic?.ship)}
            span={4}
            colorClass={'color-3'}
          />
          <CardCountComponent
            title="Lợi nhuận (đ)"
            count={toFormatCurrencyVND(dataAnalytic?.profit)}
            span={4}
            colorClass={'color-success'}
          />
          <CardCountComponent
            title="Doanh số TB (đ)"
            count={toFormatCurrencyVND(dataAnalytic?.avgSales)}
            span={4}
            colorClass={'color-5'}
          />
        </Row>
        <Row className="mt-10">
          <CardForm
            title={'Danh sách sản phẩm bán chạy nhất'}
            style={{ width: '100%' }}
            headStyle={{ color: '#faad14' }}
          >
            <Tabs
              defaultActiveKey=""
              items={tagItems}
              onChange={(e) => {
                setTab(e);
                switch (tab) {
                  case 1:
                    setSortColumn('totalAmount');
                    break;
                  case 2:
                    setSortColumn('totalSales');
                    break;
                  default:
                    break;
                }
              }}
            />
            <TableAnalyticComponent
              columns={columnsListAnalyticProduct}
              pageSize={pageSize}
              total={dataListProduct?.total}
              page={page}
              dataSource={dataListProduct?.items}
              onChangeFromToDate={onChangeFilter}
              onChange={(pagination, filters, sorter) => {
                if (sorter.order === 'ascend') {
                  setSortColumn(sorter.field);
                  setSortType('asc');
                } else {
                  setSortColumn(sorter.field);
                  setSortType('desc');
                }

                setPage(pagination.current);
                setPageSize(pagination.pageSize);
              }}
            />
          </CardForm>
        </Row>
      </CardForm>
    </div>
  );
};

export default Home;
