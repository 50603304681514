import React from 'react';
import { statusOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxStatus = ({ prefix = '', name = 'isActive', label = 'Tình trạng', ...props }) => {
  return (
    <FormItem prefix={prefix} name={name} label={label} type="select" options={statusOptions} allowClear {...props} />
  );
};

SelectBoxStatus.propTypes = propTypes;

export default SelectBoxStatus;
