export const BANK_CODE = {
  ICB: 'ICB',
  VCB: 'VCB',
  BIDV: 'BIDV',
  VBA: 'VBA',
  OCB: 'OCB',
  MB: 'MB',
  TCB: 'TCB',
  ACB: 'ACB',
  VPB: 'VPB',
  TPB: 'TPB',
  STB: 'STB',
  HDB: 'HDB',
  VCCB: 'VCCB',
  SCB: 'SCB',
  VIB: 'VIB',
  SHB: 'SHB',
  EIB: 'EIB',
  MSB: 'MSB',
  CAKE: 'CAKE',
  Ubank: 'Ubank',
  TIMO: 'TIMO',
  VTLMONEY: 'VTLMONEY',
  VNPTMONEY: 'VNPTMONEY',
  SGICB: 'SGICB',
  BAB: 'BAB',
  PVCB: 'PVCB',
  Oceanbank: 'Oceanbank',
  NCB: 'NCB',
  SHBVN: 'SHBVN',
  ABB: 'ABB',
  VAB: 'VAB',
  NAB: 'NAB',
  PGB: 'PGB',
  VIETBANK: 'VIETBANK',
  BVB: 'BVB',
  SEAB: 'SEAB',
  COOPBANK: 'COOPBANK',
  LPB: 'LPB',
  KLB: 'KLB',
  KBank: 'KBank',
  SCVN: 'SCVN',
  PBVN: 'PBVN',
  IVB: 'IVB',
  'IBK - HCM': 'IBK - HCM',
  UOB: 'UOB',
  'IBK - HN': 'IBK - HN',
  'NHB HN': 'NHB HN',
  HLBVN: 'HLBVN',
  GPB: 'GPB',
  DOB: 'DOB',
  VRB: 'VRB',
  WVN: 'WVN',
  KBHN: 'KBHN',
  KBHCM: 'KBHCM',
  DBS: 'DBS',
  CIMB: 'CIMB',
  CBB: 'CBB',
  HSBC: 'HSBC'
};
