import { useGetListTable, useGetDetailById, useSendPutAPI, useSendAPIById, useSendTagAPI } from './hookAPI';
import { create, list, detail, update, deleteById } from '../api/tag';

const useGetListTag = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateTag = () => {
  const { loading, action, error, setError } = useSendTagAPI(create);
  return {
    loading,
    tag: action,
    error,
    setError
  };
};

const useDetailTag = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateTag = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteTag = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

export { useGetListTag, useDetailTag, useUpdateTag, useDeleteTag, useCreateTag };
