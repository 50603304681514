import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';
import { isEmpty } from 'lodash';
import moment from 'moment';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    title: payload[`${PREFIX}_${FORM_FIELD?.title?.name}`] || undefined,
    description: payload[`${PREFIX}_${FORM_FIELD?.description?.name}`] || undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`] || undefined,
    image: payload[`${PREFIX}_${FORM_FIELD?.image?.name}`] || undefined,
    isShowHome: payload[`${PREFIX}_${FORM_FIELD?.isShowHome?.name}`] || undefined,
    orderNumber: payload[`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`] || undefined,
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`] || undefined,
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`] || undefined,
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined
  };
};

export const mapFormDataToCreateRecord = (values) => {
  let image = values[`${PREFIX}_${FORM_FIELD?.image?.name}`] || null;

  return {
    title: values[`${PREFIX}_${FORM_FIELD?.title?.name}`],
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`] || null,
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    image: !isEmpty(image) ? image[0] : undefined,
    orderNumber: +values[`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`] || 100,
    isShowHome: values[`${PREFIX}_${FORM_FIELD?.isShowHome?.name}`]
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.title?.name}`]: record?.title,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: record?.description,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive,
    [`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`]: record?.orderNumber || 100,
    [`${PREFIX}_${FORM_FIELD?.image?.name}`]: record?.image,
    [`${PREFIX}_${FORM_FIELD?.isShowHome?.name}`]: record?.isShowHome
  };
};
