import React from 'react';
import SuggestionInput from '../../SuggestionInput';
import { usePostQuickSearch } from '../../../../helpers/hookPost';
import { Col, Row } from 'antd';

const SelectBoxPageQuickSearch = ({ type, ...props }) => {
  const { get: getPosts } = usePostQuickSearch(type);

  return (
    <Row gutter={24} align={'middle'}>
      <Col span={20}>
        <SuggestionInput promiseFn={getPosts} hideInputLabel allowClear {...props} />
      </Col>
      <Col span={4}>
        <a href={`/post/edit/${props.value}`} target="_blank">
          Sửa
        </a>
      </Col>
    </Row>
  );
};

export default SelectBoxPageQuickSearch;
