export default {
  rootApi: process.env.REACT_APP_API_ENDPOINT,
  namespaceApi: process.env.REACT_APP_API_NAMESPACE,
  nodeEnv: process.env.NODE_ENV,
  socketApi: process.env.REACT_APP_API_SOCKET,
  uiUrl: process.env.REACT_APP_UI_URL,
  onOffFeatureKeys: process.env.REACT_APP_ON_OFF_FEATURE_KEYS,
  minSuggestionInputSearchLength: 3,
  maxInt: 10000,
  maxLengthShortInput: 1024,
  maxLengthLongInput: 102400,
  uploadMaxFile: 20,
  uploadMaxTotalSize: 80 * 1024 * 1024, // 80MB
  uploadMaxFileSize: 10 * 1024 * 1024, // 10MB
  uploadMaxVideoSize: 10000 * 1024 * 1024, // 1000MB
  oneBil: 1000000000,
  oneMil: 1000000,
  oneHundred: 100,
  minLengthPassword: 6,
  constants: {
    errorCode: {
      EntityNotFound: 'EntityNotFound'
    }
  },
  whitelist401: {
    endpoint: {
      login: '/auth/login',
      me: '/accounts/me'
    },
    page: {
      forgot: '/password/forgot'
    }
  }
};
