import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import './App.scss';
import GlobalLoading from './common/globalLoading/GlobalLoading';
import configureStore from './configureStore';
import history from './history';
import Routes from './routers';
import Layout from './common/general/layout';
import BlockNavigation from './common/components/BlockNavigation';
import GlobalToast from './common/globalToast';

const store = configureStore;
/**
 * This App Page
 * @module App
 * @category App
 */
const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div className="App">
          <GlobalToast>
            <GlobalLoading />
            <Layout>
              <BlockNavigation />
              <Routes />
            </Layout>
          </GlobalToast>
        </div>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
