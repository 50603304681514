export const PREFIX = 'cart';

export const EPriceType = {
  Default: 'Default',
  BH1: 'BH1',
  BH2: 'BH2',
  BH3: 'BH3'
};

export const ECartStatus = {
  WaitingConfirm: 'WaitingConfirm',
  WaitingReceive: 'WaitingReceive',
  Delivering: 'Delivering',
  Delivered: 'Delivered',
  Canceled: 'Canceled'
};

export const FORM_FIELD = {
  status: { name: 'status', label: 'Trạng thái đơn hàng' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  description: { name: 'description', label: 'Ghi chú đơn hàng' },
  isNewUser: { name: 'isNewUser', label: 'Khách hàng mới' },
  userId: { name: 'userId', label: 'Khách hàng' },
  password: { name: 'password', label: 'Mật khẩu' },
  userName: { name: 'userName', label: 'Tên khách hàng' },
  userEmail: { name: 'userEmail', label: 'Email' },
  userPhone: { name: 'userPhone', label: 'Số điện thoại' },
  deliveryAddress: { name: 'deliveryAddress', label: 'Địa chỉ giao hàng' },
  noteForUser: { name: 'noteForUser', label: 'Ghi chú về khách hàng' },
  totalAmount: { name: 'totalAmount', label: 'Tổng số lượng' },
  totalPrice: { name: 'totalPrice', label: 'Tổng tiền hàng' },
  shipPrice: { name: 'shipPrice', label: 'Phí vận chuyển' },
  voucherPrice: { name: 'voucherPrice', label: 'Giảm giá' },
  finalPrice: { name: 'finalPrice', label: 'Phải thu' },
  importPrice: { name: 'importPrice', label: 'Giá nhập' },
  profitPrice: { name: 'profitPrice', label: 'Lợi nhuận' },
  code: { name: 'code', label: 'Mã đơn hàng' },
  paymentDate: { name: 'paymentDate', label: 'Ngày thanh toán' },
  voucherCode: { name: 'voucherCode', label: 'EVoucher' },
  createdAt: { name: 'createdAt', label: 'Ngày mua hàng' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToPaymentDate: { name: 'fromToPaymentDate', label: 'Ngày thanh toán' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' },
  priceFrom: { name: 'fromPrice', label: 'Giá từ' },
  priceTo: { name: 'toPrice', label: 'Giá tối đa' },
  shipPriceFrom: { name: 'shipPriceFrom', label: 'Giá vận chuyển từ' },
  shipPriceTo: { name: 'shipPriceTo', label: 'Giá vận chuyển tối đa' },
  fromToPriceVoucher: { name: 'fromToPriceVoucher', label: 'Giá giảm' },
  fromToPriceShip: { name: 'fromToPriceShip', label: 'Giá vận chuyển' },
  voucherPriceFrom: { name: 'fromToPriceVoucher', label: 'Giá giảm từ' },
  voucherPriceTo: { name: 'fromToPriceShip', label: 'Giá giảm tối đa' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};
