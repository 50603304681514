export const REDUX_NAME = 'Auth';

export const LOGIN_PENDING = `${REDUX_NAME}/LOGIN_PENDING`;
export const LOGIN_SUCCESS = `${REDUX_NAME}/LOGIN_SUCCESS`;
export const LOGIN_FAILED = `${REDUX_NAME}/LOGIN_FAILED`;
export const LOGIN_CONFIRM_PENDING = `${REDUX_NAME}/LOGIN_CONFIRM_PENDING`;

export const LOGOUT_PENDING = `${REDUX_NAME}/LOGOUT_PENDING`;
export const LOGOUT_SUCCESS = `${REDUX_NAME}/LOGOUT_SUCCESS`;
export const LOGOUT_FAILED = `${REDUX_NAME}/LOGOUT_FAILED`;

export const SEND_PASSWORD_RESET_EMAIL_PENDING = `${REDUX_NAME}/SEND_PASSWORD_RESET_EMAIL_PENDING`;
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS = `${REDUX_NAME}/SEND_PASSWORD_RESET_EMAIL_SUCCESS`;
export const SEND_PASSWORD_RESET_EMAIL_FAILED = `${REDUX_NAME}/SEND_PASSWORD_RESET_EMAIL_FAILED`;

export const CHANGE_PASSWORD = `${REDUX_NAME}/CHANGE_PASSWORD`;
export const CHANGE_PASSWORD_SUCCESS = `${REDUX_NAME}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAILED = `${REDUX_NAME}/CHANGE_PASSWORD_FAILED`;

export const CHANGE_PASSWORD_WITH_CODE = `${REDUX_NAME}/CHANGE_PASSWORD_WITH_CODE`;
export const CHANGE_PASSWORD_WITH_CODE_SUCCESS = `${REDUX_NAME}/CHANGE_PASSWORD_WITH_CODE_SUCCESS`;
export const CHANGE_PASSWORD_WITH_CODE_FAILED = `${REDUX_NAME}/CHANGE_PASSWORD_WITH_CODE_FAILED`;

export const GET_CURRENT_USER_PENDING = `${REDUX_NAME}/GET_CURRENT_USER_PENDING`;
export const GET_CURRENT_USER_SUCCESS = `${REDUX_NAME}/GET_CURRENT_USER_SUCCESS`;
export const GET_CURRENT_USER_FAILED = `${REDUX_NAME}/GET_CURRENT_USER_FAILED`;

export const STORE_INFO_APP = `${REDUX_NAME}/STORE_INFO_APP`;
