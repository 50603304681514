import { Form } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateCategory } from '../../../helpers/hookCategory';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [type, setType] = useState('Product');

  const { error: errorCreateCategory, post: createCategory } = useCreateCategory();

  useHandleErrorCode(APP_MESSAGE.category.name, [errorCreateCategory], APP_MESSAGE.category.createError);

  const onCreate = useCallback(
    (values) => {
      createCategory({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.category.createSuccess, type: 'success' });
          history.push(`${ROUTER_URL.CATEGORY_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createCategory]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.CATEGORY_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  const handleChangeValues = (values) => {
    const key = Object.keys(values);
    if (key[0] === 'category_type') {
      setType(values.category_type);
    }
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.category.name}`} footerCreatorVisible>
      <CustomForm
        name="category"
        module="Category"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.category.createConfirm}
        contentCancel={APP_MESSAGE.category.createCancel}
        onCancel={onCancel}
        form={form}
        onValuesChange={handleChangeValues}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.type.name,
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
              FORM_FIELD.orderNumber.name
            ]}
            type={type}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
