const statusOptions = [
  {
    label: 'Hoạt động',
    value: true
  },
  {
    label: 'Tạm ngưng',
    value: false
  }
];

const categoryTypeOptions = [
  {
    label: 'Sản phẩm',
    value: 'Product'
  },
  {
    label: 'Bài đăng',
    value: 'Post'
  },
  {
    label: 'Diễn đàn',
    value: 'Forum'
  },
  {
    label: 'Trang',
    value: 'Page'
  },
  {
    label: 'Thư viện ảnh',
    value: 'Gallery'
  }
];

const productTypeOptions = [
  {
    label: 'Mới',
    value: 'New'
  },
  {
    label: 'Đã sử dụng',
    value: 'Used'
  }
];

const eVoucherTypeOptions = [
  {
    label: 'Người dùng mới',
    value: 'New'
  },
  {
    label: 'Thông thường',
    value: 'Common'
  }
];

const eVoucherPriceTypeOptions = [
  {
    label: 'Phần trăm',
    value: 'Percent'
  },
  {
    label: 'Tiền mặt',
    value: 'Money'
  }
];

const contentTypeOptions = [
  {
    label: 'Trang chủ / Banner',
    value: 'Home_Banner'
  },
  {
    label: 'Trang chủ / Dịch vụ',
    value: 'Home_Service'
  },
  {
    label: 'Trang chủ / Vận chuyển',
    value: 'Home_Sipping'
  },
  {
    label: 'Trang chủ / Về chúng tôi',
    value: 'Home_AboutUs'
  },
  {
    label: 'Trang chủ / Đối tác',
    value: 'Home_OurPartner'
  }
];

const cartStatusOptions = [
  {
    label: 'Chờ xác nhận',
    value: 'WaitingConfirm',
    color: 'blue'
  },
  {
    label: 'Chờ lấy hàng',
    value: 'WaitingReceive',
    color: 'yellow'
  },
  {
    label: 'Đang giao',
    value: 'Delivering',
    color: 'orange'
  },
  {
    label: 'Đã giao',
    value: 'Delivered',
    color: 'green'
  },
  {
    label: 'Đã hủy',
    value: 'Canceled',
    color: 'gray'
  }
];

const feedbackStatusOptions = [
  {
    label: 'Mới',
    value: 'New',
    color: 'blue'
  },
  {
    label: 'Chờ duyệt',
    value: 'Pending',
    color: 'yellow'
  },
  {
    label: 'Tiếp nhận',
    value: 'Processing',
    color: 'orange'
  },
  {
    label: 'Từ chối',
    value: 'Reject',
    color: 'red'
  },
  {
    label: 'Hoàn thành',
    value: 'Success',
    color: 'green'
  },
  {
    label: 'Đã hủy',
    value: 'Canceled',
    color: 'gray'
  }
];

const productStatusOptions = [
  {
    label: 'Mới tạo',
    value: 'New',
    color: 'blue'
  },
  {
    label: 'Đang cập nhật',
    value: 'Updating',
    color: 'blue'
  },
  {
    label: 'Chờ duyệt',
    value: 'Pending',
    color: 'yellow'
  },
  {
    label: 'Từ chối',
    value: 'Reject',
    color: 'red'
  },
  {
    label: 'Chờ phát hành',
    value: 'Success',
    color: 'orange'
  },
  {
    label: 'Đã phát hành',
    value: 'Published',
    color: 'green'
  }
];

const reviewStatusOptions = [
  {
    label: 'Mới tạo',
    value: 'New',
    color: 'blue'
  },
  {
    label: 'Từ chối',
    value: 'Reject',
    color: 'red'
  },
  {
    label: 'Phát hành',
    value: 'Published',
    color: 'green'
  }
];

const commentReportStatusOptions = [
  {
    label: 'Mới tạo',
    value: 'New',
    color: 'blue'
  },
  {
    label: 'Từ chối',
    value: 'Reject',
    color: 'red'
  },
  {
    label: 'Hủy bình luận',
    value: 'Canceled',
    color: 'gray'
  }
];

const typeAccountOptions = [
  {
    label: 'Nhân viên',
    value: 'Employee'
  }
];

const typeUserOptions = [
  {
    label: 'Thường',
    value: 'Normal'
  },
  {
    label: 'Vip',
    value: 'Vip'
  }
];

const typeLoginOptions = [
  {
    label: 'Email',
    value: 'Email'
  },
  {
    label: 'Facebook',
    value: 'Facebook'
  },
  {
    label: 'Gmail',
    value: 'Gmail'
  }
];

const genderOptions = [
  {
    label: 'Nam',
    value: 'Male'
  },
  {
    label: 'Nữ',
    value: 'Female'
  },
  {
    label: 'Khác',
    value: 'Other'
  }
];

const availableOptions = [
  {
    label: 'Online',
    value: 'Online'
  },
  {
    label: 'Offline',
    value: 'Offline'
  }
];

const shareStatusOptions = [
  {
    label: 'Riêng tư',
    value: 'private'
  },
  {
    label: 'Công khai',
    value: 'public'
  }
];

const shareTypeOptions = [
  {
    label: 'Cá nhân',
    value: 'person'
  },
  {
    label: 'Nhóm',
    value: 'group'
  }
];

const yesOrNoOptions = [
  {
    label: 'Có',
    value: 'true'
  },
  {
    label: 'Không',
    value: 'false'
  }
];

const dateOptions = [
  { label: 'Hôm nay', value: 'today' },
  { label: 'Hôm qua', value: 'preDay' },
  { label: 'Tuần này', value: 'week' },
  { label: 'Tuần trước', value: 'preWeek' },
  { label: 'Tháng này', value: 'month' },
  { label: 'Tháng trước', value: 'preMonth' },
  { label: 'Năm nay', value: 'year' },
  { label: 'Năm trước', value: 'preYear' },
  { label: 'Tùy chọn', value: 'custom' }
];

const userWithDrawalStatusOptions = [
  {
    label: 'Mới',
    value: 'New',
    color: 'blue'
  },
  {
    label: 'Chờ duyệt',
    value: 'Processing',
    color: 'yellow'
  },
  {
    label: 'Từ chối',
    value: 'Reject',
    color: 'red'
  },
  {
    label: 'Hoàn thành',
    value: 'Success',
    color: 'green'
  },
  {
    label: 'Đã hủy',
    value: 'Canceled',
    color: 'orange'
  }
];

const postStatusOptions = [
  {
    label: 'Mới tạo',
    value: 'New',
    color: 'blue'
  },
  {
    label: 'Đang cập nhật',
    value: 'Updating',
    color: 'blue'
  },
  {
    label: 'Chờ duyệt',
    value: 'Pending',
    color: 'yellow'
  },
  {
    label: 'Tiếp nhận',
    value: 'Processing',
    color: 'yellow'
  },
  {
    label: 'Từ chối',
    value: 'Reject',
    color: 'red'
  },
  {
    label: 'Chờ phát hành',
    value: 'Success',
    color: 'orange'
  },
  {
    label: 'Đã phát hành',
    value: 'Published',
    color: 'green'
  }
];

const forumStatusOptions = [
  {
    label: 'Mới tạo',
    value: 'New',
    color: 'blue'
  },
  {
    label: 'Đang cập nhật',
    value: 'Updating',
    color: 'blue'
  },
  {
    label: 'Chờ duyệt',
    value: 'Pending',
    color: 'yellow'
  },
  {
    label: 'Tiếp nhận',
    value: 'Processing',
    color: 'yellow'
  },
  {
    label: 'Từ chối',
    value: 'Reject',
    color: 'red'
  },
  {
    label: 'Chờ phát hành',
    value: 'Success',
    color: 'orange'
  },
  {
    label: 'Đã phát hành',
    value: 'Published',
    color: 'green'
  }
];

export {
  statusOptions,
  typeAccountOptions,
  genderOptions,
  availableOptions,
  shareStatusOptions,
  shareTypeOptions,
  yesOrNoOptions,
  typeUserOptions,
  typeLoginOptions,
  cartStatusOptions,
  feedbackStatusOptions,
  contentTypeOptions,
  userWithDrawalStatusOptions,
  productStatusOptions,
  categoryTypeOptions,
  postStatusOptions,
  reviewStatusOptions,
  eVoucherTypeOptions,
  eVoucherPriceTypeOptions,
  productTypeOptions,
  forumStatusOptions,
  commentReportStatusOptions,
  dateOptions
};
