import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/branches`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/branches/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/branches/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/branches`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/branches/${id}`);
};
