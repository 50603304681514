import { Row, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import CustomButton from '../../../common/components/Button';
import { EPostStatus } from '../constants';
import { useSelector } from 'react-redux';
import ROUTER_URL from '../../../constants/router';
import { customToast } from '../../../common/components/Toast';
import { confirmModal } from '../../../common/components/Modal';
import { useDeleteTag } from '../../../helpers/hookTag';

const StatusButton = ({ id, status = 'New', createdById = -1 }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.Auth);
  const { delete: deleteTag } = useDeleteTag();

  const onDelete = async () => {
    confirmModal({
      content: 'Thực hiện thao tác này sẽ xóa dữ liệu khỏi hệ thống. Xác nhận xóa?',
      onOk: async () => {
        const resData = await deleteTag(id);
        if (resData?.success) {
          customToast({ content: 'Xác nhận xóa Bài đăng thành công', type: 'success' });
          history.push(`${ROUTER_URL.TAG_FIND}`);
        } else {
          customToast({ content: 'Xác nhận xóa Bài đăng thất bại', type: 'error' });
        }
      }
    });
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Row justify="end">
        <Space wrap size="large">
          <CustomButton
            type="danger"
            className="button-container button-delete"
            onClick={onDelete}
            visible={
              (user?.type === 'Admin' || user.id === createdById) &&
              [EPostStatus.New, EPostStatus.Updating, EPostStatus.Reject].includes(status)
            }
          >
            Xóa
          </CustomButton>
        </Space>
      </Row>
    </div>
  );
};

export default StatusButton;
