import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/contents`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/contents/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/contents/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/contents`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/contents/${id}`);
};
