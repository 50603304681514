import React from 'react';
import { reviewStatusOptions } from '../../../../constants/data';
import Select from '../../Select';

const propTypes = {};

const SelectBoxReviewNoLabelStatus = ({ name = 'status', value, ...props }) => {
  return <Select value={value} name={name} options={reviewStatusOptions} {...props} allowClear />;
};

SelectBoxReviewNoLabelStatus.propTypes = propTypes;

export default SelectBoxReviewNoLabelStatus;
