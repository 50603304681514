import { Col } from 'antd';
import React from 'react';
import CardForm from '../../../common/components/CardForm';
import { Pie } from '@ant-design/plots';
import { toFormatCurrencyVND } from '../../../utils/numberFormat';

const PieChartComponent = ({ title, totalPriceInventory, totalPriceSold }) => {
  const data = [
    {
      type: 'Tổng tiền tồn kho',
      value: totalPriceInventory
    },
    {
      type: 'Tổng tiền đã bán',
      value: totalPriceSold
    }
  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}'
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    tooltip: {
      formatter: (datum) => {
        return { name: datum.type, value: `${toFormatCurrencyVND(datum.value)} đ` };
      }
    }
  };

  return (
    <Col span={24}>
      <CardForm title={title} className="p-card">
        <Pie {...config} />
        <div className="text-center">
          Tổng tiền tồn kho: <b>{toFormatCurrencyVND(totalPriceInventory)} VNĐ</b>
          <br />
          Tổng tiền đã bán: <b>{toFormatCurrencyVND(totalPriceSold)} VNĐ</b>
          <br />
          Tổng tiền: <b>{toFormatCurrencyVND(totalPriceInventory + totalPriceSold)} VNĐ</b>
        </div>
      </CardForm>
    </Col>
  );
};

export default PieChartComponent;
