import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import FormItem from '../../../../common/components/FormItem';
import { PREFIX, FORM_FIELD } from '../../constants';
import SelectBoxReviewStatus from '../../../../common/components/SelectBox/SelectBoxReviewStatus';
import SelectBoxUserQuickSearch from '../../../../common/components/SelectBox/SelectBoxUser';

const ListSearch = () => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.content.name} label={FORM_FIELD.content.label} />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.userId.name}
            label={FORM_FIELD.userId.label}
            component={<SelectBoxUserQuickSearch prefix={PREFIX} />}
          />
        </Col>
        <Col span={12}>
          <SelectBoxReviewStatus prefix={PREFIX} />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToCreatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToCreatedAt.label}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToUpdatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToUpdatedAt.label}
          />
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {
  listAvailable: PropTypes.array
};

export default ListSearch;
