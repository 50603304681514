import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import FormItem from '../../../../common/components/FormItem';
import { PREFIX, FORM_FIELD } from '../../constants';
import SelectBoxAccountQuickSearch from '../../../../common/components/SelectBox/SelectBoxAccount';
import { EAccountType } from '../../../Account/constants';
// import SelectBoxPostStatus from '../../../../common/components/SelectBox/SelectBoxProductStatus';
import SelectBoxCategoryQuickSearch from '../../../../common/components/SelectBox/SelectBoxCategory';

const ListSearch = () => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.title.name} label={FORM_FIELD.title.label} />
          <FormItem prefix={PREFIX} name={FORM_FIELD.description.name} label={FORM_FIELD.description.label} />
          {/* <SelectBoxPostStatus prefix={PREFIX} /> */}
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.categoryId.name}
            label={FORM_FIELD.categoryId.label}
            component={<SelectBoxCategoryQuickSearch allowClear showSearch hideInputLabel type={'Forum'} />}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.accountId.name}
            label={FORM_FIELD.accountId.label}
            component={<SelectBoxAccountQuickSearch prefix={PREFIX} type={EAccountType.Vip} />}
          />
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.createdById.name}
            label={FORM_FIELD.createdById.label}
            component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.updatedById.name}
            label={FORM_FIELD.updatedById.label}
            component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToCreatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToCreatedAt.label}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToUpdatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToUpdatedAt.label}
          />
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {
  listAvailable: PropTypes.array
};

export default ListSearch;
