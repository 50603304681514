import { Table } from 'antd';
import React from 'react';
import './style.scss';
import { useAddEventListenerCopy } from '../../../helpers/common.hooks';

const RemoteTable = ({ columns, data, total, limit, page, loading, onChange, ...tableProps }) => {
  useAddEventListenerCopy();
  return (
    <div className="remote-table-container">
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={onChange}
        pagination={{
          current: page,
          pageSize: limit,
          showSizeChanger: true,
          showQuickJumper: true,
          total,
          showTotal: (tt, range) => `${range[0]}-${range[1]} of ${tt} kết quả`
        }}
        {...tableProps}
      />
    </div>
  );
};

export default RemoteTable;
