import { Col, Row, Select, Typography } from 'antd';
import { map, isEmpty, find, toString } from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import { useAsync } from 'react-async';
import PropTypes from 'prop-types';
import './style.scss';

const { Option } = Select;

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  promiseFn: PropTypes.any,
  hideInputLabel: PropTypes.bool,
  modeShowSearch: PropTypes.bool,
  invalidOptionText: PropTypes.string,
  setOptionSelected: PropTypes.func
};

const SuggestionInput = ({
  label,
  value,
  onChange,
  onChangeSelectObject,
  placeholder,
  promiseFn,
  hideInputLabel,
  modeLikeSearch = false,
  formatFn = (list) => list,
  invalidOptionText,
  setOptionSelected,
  ...inputProps
}) => {
  const [currentText, setText] = useState('');
  const [valueSelected, setValueSelected] = useState('');

  const { data: options, isPending } = useAsync({
    promiseFn,
    name: modeLikeSearch && !isEmpty(currentText) ? `%${currentText?.normalize()}%` : currentText?.normalize(),
    watch: currentText
  });

  useEffect(() => {
    if (!valueSelected || !value) {
      setValueSelected(value);
      setOptionSelected && setOptionSelected();
    }
    if (!isPending && options && value) {
      const optionSelected = find(options, (item) => item.value === value);
      if (!optionSelected && invalidOptionText) {
        setValueSelected(invalidOptionText);
        setOptionSelected && setOptionSelected(invalidOptionText);
      } else {
        setValueSelected(value);
        setOptionSelected && setOptionSelected(optionSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidOptionText, isPending, value, toString(options)]);

  const onChangeSearch = useCallback((newValue) => {
    setText(newValue);
  }, []);
  const onChangeSelect = useCallback(
    (value) => {
      onChange && onChange(value);
      onChangeSelectObject && onChangeSelectObject(options.find((item) => item.value === value));
    },
    [onChange, onChangeSelectObject, options]
  );

  return (
    <div className="sugestion-input-container">
      <Row gutter={8} align="middle" justify="start">
        {!hideInputLabel && (
          <Col className="sugestion-input-label-block">
            <Typography.Text className="sugestion-input-label">{label}</Typography.Text>
          </Col>
        )}
        <Col span={!hideInputLabel ? 16 : 24}>
          <Select
            value={valueSelected}
            onChange={onChangeSelect}
            onSearch={onChangeSearch}
            placeholder={placeholder}
            size="middle"
            className="select-value"
            showSearch
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            {...inputProps}
          >
            {/* {isPending ? (
              <Option>
                <Spin size="default" className="w-full" />
              </Option>
            ) : (
              map(formatFn(options), (item, index) => (
                <Option key={`selectbox-key-${index}`} value={item.value}>
                  {item.label}
                </Option>
              ))
            )} */}
            {map(formatFn(options), (item, index) => (
              <Option key={`selectbox-key-${index}`} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

SuggestionInput.propTypes = propTypes;

export default SuggestionInput;
