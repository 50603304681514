import { Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import { PREFIX, FORM_FIELD } from '../../constants';
import SelectBoxPageQuickSearch from '../../../../common/components/SelectBox/SelectBoxPost';
import { ECategoryType } from '../../../Category/constants';

const GalleryInfo = ({ disabled, requiredItems = [], disabledItems = [] }) => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.gallery.name}
          label={FORM_FIELD.gallery.label}
          required={requiredItems.includes(FORM_FIELD.gallery.name)}
          disabled={disabledItems.includes(FORM_FIELD.gallery.name) || disabled}
          component={
            <SelectBoxPageQuickSearch
              type={ECategoryType.gallery}
              prefix={PREFIX}
              name={FORM_FIELD.gallery.name}
              required={requiredItems.includes(FORM_FIELD.gallery.name)}
              disabled={disabledItems.includes(FORM_FIELD.gallery.name) || disabled}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default GalleryInfo;
