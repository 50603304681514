/* eslint-disable react/display-name */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import React from 'react';
import './style.scss';
import { useIsCheckFeature } from '../../../helpers/hookPermission';

const ActionButton = ({ record, onDelete, module = null }) => {
  const isDeleted = useIsCheckFeature(module, 'Delete');

  return (
    <>
      <Row justify="center" gutter={24}>
        <Col>
          {/* {onEdit && (
            <Tooltip title="Cập nhật">
              <Button
                className="mr-2 edit-btn"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  onEdit(id);
                }}
              />
            </Tooltip>
          )} */}

          {isDeleted && onDelete && (
            <Tooltip title="Xóa">
              <Button
                danger
                shape="circle"
                icon={<DeleteOutlined danger />}
                onClick={() => {
                  onDelete(record);
                }}
              />
            </Tooltip>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ActionButton;
