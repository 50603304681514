import React from 'react';
import { useAccountQuickSearch } from '../../../../helpers/hookAccount';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxAccountQuickSearch = ({ type, ...props }) => {
  const { get: getAccount } = useAccountQuickSearch(type);

  return <SuggestionInput promiseFn={getAccount} hideInputLabel allowClear {...props} />;
};

export default SelectBoxAccountQuickSearch;
