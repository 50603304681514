import { list } from '../api/notification';
import { useGetListTable } from './hookAPI';

const useGetListNotification = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

export { useGetListNotification };
