import React from 'react';
import { categoryTypeOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxCategoryType = ({ prefix = '', name = 'type', label = 'Loại', ...props }) => {
  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="select"
      options={categoryTypeOptions}
      allowClear
      {...props}
    />
  );
};

SelectBoxCategoryType.propTypes = propTypes;

export default SelectBoxCategoryType;
