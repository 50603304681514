import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import * as CONST from './constants';

export const name = CONST.REDUX_NAME;

const initialState = freeze({
  user: null,
  loadingProfile: false,
  loggingIn: false,
  loggingOut: false,
  loginErrors: null,
  logoutErrors: null,
  /** infoApp: { version: string } */
  infoApp: null
});

/**
 * @name AuthReducer
 * @type {Function}
 * @description This is AuthReducer handleActions
 * @param {bool} AuthReducer
 * @memberof module:Modules
 */
export default handleActions(
  {
    [actions.loginSuccess]: (state) => {
      return freeze({
        ...state,
        loggingIn: true,
        loggingOut: false,
        loginErrors: null
      });
    },
    [actions.loginFailed]: (state, action) => {
      return freeze({
        ...state,
        loggingIn: false,
        loggingOut: false,
        loginErrors: action.payload
      });
    },
    [actions.logoutSuccess]: (state) => {
      return freeze({
        ...state,
        loggingIn: false,
        loggingOut: true,
        user: null
      });
    },
    [actions.logoutFailed]: (state, action) => {
      return freeze({
        ...state,
        logoutErrors: action.payload
      });
    },
    [actions.getCurrentUserSuccess]: (state, action) => {
      return freeze({
        ...state,
        user: action.payload
      });
    },
    [actions.storeInfoApp]: (state, action) => {
      return freeze({
        ...state,
        infoApp: action.payload
      });
    }
  },
  initialState
);
