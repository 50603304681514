import { list } from '../api/serialNumber';
import { useGetListTable } from './hookAPI';

const useGetListSerialNumber = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

export { useGetListSerialNumber };
