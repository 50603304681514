/* eslint-disable */
import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLocalStorage, localStorageKey, setLocalToken } from './localStorage';
import ROUTER_URL from '../constants/router';

const ProtectedRoute = ({ children, path, exact, forAdmin, ...props }) => {
  const history = useHistory();
  const { user: userStore } = useSelector((state) => state.Auth);

  if (forAdmin && userStore?.type !== 'Admin' && !getLocalStorage(localStorageKey.token)) return null;

  return (
    userStore && (
      <Route path={path} exact={exact} {...props}>
        {children}
      </Route>
    )
  );
};

export default ProtectedRoute;
