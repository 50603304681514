import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';

const Container = styled(Card)`
  width: 100%;
  margin: 10px 0px;
  border-radius: 10px;
`;

const CardForm = ({ children, title, className }) => {
  return (
    <Container title={title} className={className}>
      {children}
    </Container>
  );
};

export default CardForm;
