/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import ActionButton from '../../../common/components/ActionButton';
import EImage from '../../../common/components/EImage';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';
import PostStatus from '../../../common/components/PostStatus';

export const getTableColumns = ({ onEdit, onDelete, page, limit }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    fixed: 'left',
    render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
  },
  {
    title: 'Ảnh đại diện',
    dataIndex: 'thumbnail',
    align: 'center',
    fixed: 'left',
    render: (text, record) => <EImage imageUrl={record?.thumbnail?.url} />
  },
  {
    title: 'Tiêu đề',
    fixed: 'left',
    width: '300px',
    render: (text, record) => <Typography.Link onClick={() => onEdit(record.id)}>{record.title}</Typography.Link>
  },
  {
    title: 'Danh mục',
    dataIndex: ['category', 'title']
  },
  {
    title: 'Người dùng đăng',
    dataIndex: ['user', 'name']
  },
  {
    title: 'Nhân viên đăng',
    dataIndex: ['account', 'name']
  },
  // {
  //   title: 'Mô tả',
  //   width: '20%',
  //   dataIndex: 'description'
  // },
  {
    title: 'Thứ tự',
    align: 'center',
    dataIndex: 'orderNumber'
  },
  {
    title: 'Trạng thái',
    align: 'center',
    render: (text, record) => <PostStatus status={record.status} />
  },
  {
    title: 'Người tạo',
    dataIndex: ['createdBy', 'name']
  },
  {
    title: 'Người cập nhật',
    dataIndex: ['updatedBy', 'name']
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Hành động',
    dataIndex: undefined,
    fixed: 'right',
    width: '80px',
    render: (text, record) => {
      return <ActionButton id={record.id} record={record} onEdit={onEdit} onDelete={onDelete} module={'Forum'} />;
    }
  }
];
