import { map, uniqBy } from 'lodash';
import { useCallback } from 'react';
import { useRef } from 'react';
import { create, detail, list, update, deleteById, changeStatus, count } from '../api/product';
import { useGetDetailById, useGetListTable, useSendPostAPI, useSendPutAPI, useSendAPIById } from './hookAPI';

const useGetListProduct = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateProduct = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailProduct = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateProduct = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteProduct = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const useProductQuickSearch = () => {
  const latestAbortToken = useRef(null);
  const get = useCallback(({ name }) => {
    return new Promise((resolve, reject) => {
      if (latestAbortToken.current) {
        clearTimeout(latestAbortToken.current);
      }
      const cancelToken = setTimeout(() => {
        list(
          {
            title: name || undefined,
            isActive: true
          },
          {}
        )
          .then(({ data }) => {
            return resolve(
              uniqBy(
                map(data?.data?.items, (item) => ({
                  label: item.title,
                  value: item.id
                })),
                (item) => item.value
              )
            );
          })
          .catch((error) => {
            return reject(error);
          });
      }, 1000);
      latestAbortToken.current = cancelToken;
    });
  }, []);
  return {
    get
  };
};

const usePutProductChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useGetCountProduct = () => {
  const { get } = useGetListTable(count);
  return {
    get
  };
};

export {
  useGetListProduct,
  useCreateProduct,
  useDetailProduct,
  useUpdateProduct,
  useDeleteProduct,
  useProductQuickSearch,
  usePutProductChangeStatus,
  useGetCountProduct
};
