export const EPostStatus = {
  New: 'New',
  Updating: 'Updating',
  Pending: 'Pending',
  Success: 'Success',
  Reject: 'Reject',
  Published: 'Published'
};

export const PREFIX = 'post';

export const FORM_FIELD = {
  title: { name: 'title', label: 'Tiêu đề' },
  description: { name: 'description', label: 'Mô tả' },
  status: { name: 'status', label: 'Trạng thái' },
  content: { name: 'content', label: 'Nội dung' },
  source: { name: 'source', label: 'Nguồn' },
  accountId: { name: 'accountId', label: 'Người đăng' },
  tags: { name: 'tags', label: 'Gắn thẻ' },
  images: { name: 'images', label: 'Hình ảnh' },
  thumbnail: { name: 'thumbnail', label: 'Ảnh đại diện' },
  cover: { name: 'cover', label: 'Ảnh cover' },
  categoryId: { name: 'categoryId', label: 'Danh mục' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  isHot: { name: 'isHot', label: 'Hot' },
  reasonForRefusal: { name: 'reasonForRefusal', label: 'Lý do từ chối' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};
