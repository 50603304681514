/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import CartStatus from '../../../common/components/CartStatus';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';
import { toFormatCurrencyVND } from '../../../utils/numberFormat';

export const getTableColumns = ({ page, order = [], limit }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
  },
  {
    title: 'Mã đơn hàng',
    sorter: true,
    sortOrder: order[1],
    columnIndex: 1,
    // render: (text, record) => <Typography.Link onClick={() => onEdit(record.id)}>{record.code}</Typography.Link>
    render: (text, record) => (
      <a className="ant-typography" href={`/cart/edit/${record?.id}`}>
        {record?.code}
      </a>
    )
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    sorter: true,
    sortOrder: order[2],
    columnIndex: 2,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Khách hàng',
    dataIndex: ['user', 'name'],
    render: (text, record) => <Typography.Text>{record?.userName || record?.user?.name}</Typography.Text>
  },
  {
    title: 'Giá (đ)',
    align: 'right',
    sorter: true,
    sortOrder: order[4],
    columnIndex: 4,
    render: (text, record) => toFormatCurrencyVND(record.price)
  },
  {
    title: 'Giảm giá (đ)',
    align: 'right',
    sorter: true,
    sortOrder: order[5],
    columnIndex: 5,
    render: (text, record) => toFormatCurrencyVND(record.voucherPrice)
  },
  {
    title: 'Tiền vận chuyển (đ)',
    align: 'right',
    sorter: true,
    sortOrder: order[6],
    columnIndex: 6,
    render: (text, record) => toFormatCurrencyVND(record.shipPrice)
  },
  {
    title: 'Thành tiền (đ)',
    align: 'right',
    sorter: true,
    sortOrder: order[7],
    columnIndex: 7,
    render: (text, record) => toFormatCurrencyVND(record.finalPrice)
  },
  {
    title: 'Trạng thái',
    align: 'center',
    sorter: true,
    sortOrder: order[8],
    columnIndex: 8,
    render: (text, record) => <CartStatus status={record?.status} />
  },
  // {
  //   title: 'Ngày thanh toán',
  //   align: 'center',
  //   sorter: true,
  //   sortOrder: order[8],
  //   columnIndex: 8,
  //   render: (text, record) => (
  //     <Typography.Text>{displayDateFromString(record?.paymentDate, DATE_TIME_FORMAT)}</Typography.Text>
  //   )
  // },
  {
    title: 'Người tạo',
    dataIndex: ['createdBy', 'name']
  }
];
