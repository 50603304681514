export const PREFIX = 'account';

export const FORM_FIELD = {
  name: { name: 'name', label: 'Họ và tên' },
  email: { name: 'email', label: 'Email' },
  type: { name: 'type', label: 'Loại tài khoản' },
  phone: { name: 'phone', label: 'Số điện thoại' },
  avatar: { name: 'avatar', label: 'Hình ảnh' },
  roleName: { name: 'roleName', label: 'Chức danh' },
  roleId: { name: 'roleId', label: 'Vai trò' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  birthday: { name: 'birthday', label: 'Ngày sinh nhật' },
  joinDay: { name: 'joinDay', label: 'Ngày tham gia' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' },
  fromToJoinDay: { name: 'fromToJoinDay', label: 'Ngày tham gia' }
};

export const EAccountType = {
  Admin: 'Admin',
  Employee: 'Employee'
};

export const SEARCH_DEFAULT = {
  isActive: true
};
