import React from 'react';
import { eVoucherPriceTypeOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxEVoucherPriceType = ({ prefix = '', name = 'priceType', label = 'Loại giảm', ...props }) => {
  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="select"
      options={eVoucherPriceTypeOptions}
      allowClear
      {...props}
    />
  );
};

SelectBoxEVoucherPriceType.propTypes = propTypes;

export default SelectBoxEVoucherPriceType;
