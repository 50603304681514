import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/categories`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/categories/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/categories/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/categories`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/categories/${id}`);
};
