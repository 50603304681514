import { Form } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { customToast } from '../../../common/components/Toast';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import {
  useDeleteCommentReport,
  useGetListCommentReport,
  usePutCommentReportChangeStatus
} from '../../../helpers/hookCommentReport';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import ListSearch from '../components/listSearch';
import { SEARCH_DEFAULT } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';
import { confirmModal } from '../../../common/components/Modal';
import { setLocalStorage } from '../../../utils/localStorage';

const Find = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const { get: getListCommentReport } = useGetListCommentReport();
  const { error: errorDelete, delete: deleteCommentReport } = useDeleteCommentReport();
  const { put: putChangeStatusCommentReport } = usePutCommentReportChangeStatus();

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  const { loading, reload, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, null],
    promiseFn: getListCommentReport
  });

  useHandleErrorCode(APP_MESSAGE.commentReport.name, [errorList], APP_MESSAGE.commentReport.missData);
  useHandleErrorCode(APP_MESSAGE.commentReport.name, [errorDelete], APP_MESSAGE.commentReport.deleteError);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onDelete = useCallback(
    (record) => {
      confirmModal({
        title: getMessageFromName(APP_MESSAGE.commentReport.deleteConfirm, record?.title),
        onOk: async () => {
          const resData = await deleteCommentReport(record.id);
          if (resData?.success) {
            customToast({
              content: getMessageFromName(APP_MESSAGE.commentReport.deleteSuccess, record?.title),
              type: 'success'
            });
            reload();
          }
        }
      });
    },
    [deleteCommentReport, reload]
  );

  const onFinish = useCallback(
    (values) => {
      setSearchParams(mapFormDataToSearchRecord(values));
    },
    [setSearchParams]
  );

  const onChangeStatus = useCallback(
    (record, values) => {
      setLocalStorage('type-enter', 'modal');
      confirmModal({
        title: 'Xác nhận thay đổi trạng thái',
        onOk: async () => {
          setLocalStorage('type-enter', '');
          const resData = await putChangeStatusCommentReport(record.id, values);
          if (resData?.success) {
            customToast({
              content: 'Xác nhận thay đổi trạng thái thành công',
              type: 'success'
            });
            reload();
          } else {
            customToast({ content: 'Xác nhận thay đổi trạng thái thất bại', type: 'error' });
          }
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [putChangeStatusCommentReport, reload]
  );

  const onChangeShowHome = useCallback(
    (record, values) => {
      confirmModal({
        title: 'Xác nhận thay đổi hiển thị trang chủ',
        onOk: async () => {
          const resData = await putChangeStatusCommentReport(record.id, values);
          if (resData?.success) {
            customToast({
              content: 'Xác nhận thay đổi hiển thị trang chủ thành công',
              type: 'success'
            });
            reload();
          } else {
            customToast({ content: 'Xác nhận thay đổi hiển thị trang chủ thất bại', type: 'error' });
          }
        }
      });
    },
    [putChangeStatusCommentReport, reload]
  );

  const columns = useMemo(() => getTableColumns({ page, order, onDelete, onChangeStatus, onChangeShowHome }), [
    order,
    page,
    onDelete,
    onChangeStatus,
    onChangeShowHome
  ]);

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.commentReport.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <RemoteTable
          columns={columns}
          data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
          loading={loading}
          page={page}
          limit={limit}
          total={data?.total ?? 0}
          onChange={onChange(() => {})}
          rowKey="id"
          scroll={{
            x: 1500
          }}
        />
      </CardForm>
    </PageContent>
  );
};

export default Find;
