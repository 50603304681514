import { Input } from 'antd';
import { Decimal } from 'decimal.js';
import { round } from 'lodash';
import numeral from 'numeral';
import React, { useCallback, useEffect, useState } from 'react';
import { DECIMAL_NUMBER_FORMAT } from '../../../constants';
import config from '../../../constants/config';
import { isValidCalculateNumber } from '../../../utils/formValidators';
import { isValidNumber } from '../../../utils/numberFormat';

const CurrencyBilInput = ({ value, onChange, showUnit, ...inputProps }) => {
  const [currentValue, setCurrentValue] = useState('');
  const getDisplayCurrentValue = useCallback(
    (valueFormat) =>
      isValidCalculateNumber(valueFormat)
        ? numeral(new Decimal(valueFormat).div(config.oneBil).toNumber()).format(DECIMAL_NUMBER_FORMAT)
        : undefined,
    []
  );
  useEffect(() => {
    if (isValidCalculateNumber(value)) {
      setCurrentValue(getDisplayCurrentValue(value));
    } else {
      setCurrentValue();
    }
  }, [getDisplayCurrentValue, value, currentValue]);
  const onChangeFormatted = useCallback(() => {
    if (isValidNumber(currentValue)) {
      const number = numeral(currentValue);
      const billValue = round(number._value * config.oneBil);
      onChange(billValue);
      if (billValue === value) {
        setCurrentValue(getDisplayCurrentValue(billValue));
      }
    } else {
      onChange();
    }
  }, [currentValue, onChange, value, getDisplayCurrentValue]);

  const handleValueChange = (e) => {
    setCurrentValue(e.target.value);
    const number = numeral(e.target.value);
    onChange(number._value ? round(number._value) : 0);
  };

  return (
    <Input
      value={currentValue}
      onChange={handleValueChange}
      onBlur={onChangeFormatted}
      type="text"
      addonAfter={showUnit ? 'tỷ đồng' : undefined}
      lang="vi"
      {...inputProps}
    />
  );
};

export default CurrencyBilInput;
