import React from 'react';
import { yesOrNoOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxShowHomeStatus = ({ prefix = '', name = 'isShowHome', label = 'Hiển thị trang chủ', ...props }) => {
  return (
    <FormItem prefix={prefix} name={name} label={label} type="select" options={yesOrNoOptions} allowClear {...props} />
  );
};

SelectBoxShowHomeStatus.propTypes = propTypes;

export default SelectBoxShowHomeStatus;
