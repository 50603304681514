import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateContent } from '../../../helpers/hookContent.js';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { error: errorCreateContent, post: createContent } = useCreateContent();

  useHandleErrorCode(APP_MESSAGE.content.name, [errorCreateContent], APP_MESSAGE.content.createError);

  const onCreate = useCallback(
    (values) => {
      createContent({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.content.createSuccess, type: 'success' });
          history.push(`${ROUTER_URL.CONTENT_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createContent]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.Content_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.content.name}`} footerCreatorVisible>
      <CustomForm
        name="Content"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.content.createConfirm}
        contentCancel={APP_MESSAGE.content.createCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.type.name,
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
              FORM_FIELD.orderNumber.name
            ]}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
