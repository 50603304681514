import { axiosInstance } from './common';

export const login = async (body) => {
  return await axiosInstance.post(`/auth/login`, body);
};

export const loginConfirm = async (body) => {
  return await axiosInstance.post(`/auth/confirm-login`, body);
};

export const logout = async () => {
  return await axiosInstance.post(`/auth/logout`);
};

export const resetPassword = async (body) => {
  return await axiosInstance.put(`/auth/reset-password`, body);
};

export const forgotPassword = async (body) => {
  return await axiosInstance.put(`/auth/forgot-password`, body);
};

export const changePassword = async (body) => {
  return await axiosInstance.put(`/auth/change-password`, body);
};

export const detailByCode = async (params) => {
  return await axiosInstance.get(`/auth/reset-password`, { params });
};
