import React from 'react';
import { commentReportStatusOptions } from '../../../../constants/data';
import Select from '../../Select';

const propTypes = {};

const SelectBoxCommentReportNoLabelStatus = ({ name = 'status', value, ...props }) => {
  return <Select value={value} name={name} options={commentReportStatusOptions} {...props} allowClear />;
};

SelectBoxCommentReportNoLabelStatus.propTypes = propTypes;

export default SelectBoxCommentReportNoLabelStatus;
