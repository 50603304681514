import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import globalLoadingReducer, { name as nameOfglobalLoading } from './common/globalLoading';
import AuthReducer, { name as nameOfAuth } from './modules/Auth';
import NotificationsReducer, { name as nameOfNotifications } from './modules/Notifications';

/**
 * @name createRootReducer
 * @description Root reducer management
 * @module Reducers
 * @memberof module:Reducers
 */

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    [nameOfglobalLoading]: globalLoadingReducer,
    [nameOfAuth]: AuthReducer,
    [nameOfNotifications]: NotificationsReducer
  });

export default createRootReducer;
