import { Form, Modal } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDeleteGroupValue, useGetListGroupValue } from '../../../helpers/hookGroupValue';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import { getGroupValueTableColumns } from './columns';
import ListSearch from '../components/listSearch';
import { mapFormDataToSearchRecord } from '../utils';
import { customToast } from '../../../common/components/Toast';
import { setLocalStorage } from '../../../utils/localStorage';

const Find = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [optionSelectedQuickSearch, setOptionSelectedQuickSearch] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const { error: errorList, get: getListGroupValue } = useGetListGroupValue();
  const { error: errorDelete, delete: deleteGroupValue } = useDeleteGroupValue();

  useHandleErrorCode(APP_MESSAGE.category.name, [errorList], APP_MESSAGE.category.missData);
  useHandleErrorCode(APP_MESSAGE.category.name, [errorDelete], APP_MESSAGE.category.deleteError);

  const { loading, reload, data, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, 'descend', false, false, false],
    columnSchema: [null, null, null, null, null, null, 'updatedAt', null, null, null],
    promiseFn: getListGroupValue
  });

  useEffect(() => {
    form.setFieldsValue({ parentName: optionSelectedQuickSearch?.name });
  }, [form, optionSelectedQuickSearch]);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onEdit = useCallback(
    (id) => {
      history.push(`${ROUTER_URL.GROUP_VALUE_EDIT}/${id}`);
    },
    [history]
  );

  const onDelete = useCallback(
    (record) => {
      setLocalStorage('type-enter', 'modal');
      Modal.confirm({
        title: getMessageFromName(APP_MESSAGE.category.deleteConfirm, record.title),
        onOk: async () => {
          setLocalStorage('type-enter', '');
          deleteGroupValue(record.id).then((response) => {
            if (response?.success) {
              customToast({ content: APP_MESSAGE.category.deleteSuccess, type: 'success' });
              reload();
            }
          });
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [deleteGroupValue, reload]
  );

  const onFinish = useCallback(
    (values) => {
      setSearchParams((pre) => ({
        ...pre,
        ...mapFormDataToSearchRecord(values)
      }));
    },
    [setSearchParams]
  );

  const handleOnSelectedInputCodeQuickSearch = (text, option) => {
    if (option?.value) {
      form.setFieldsValue({ code: option.label });
      form.setFieldsValue({ name: option?.name });
    }
  };

  const columns = useMemo(() => getGroupValueTableColumns({ page, order, onEdit, onDelete, limit }), [
    page,
    order,
    onEdit,
    onDelete,
    limit
  ]);

  return (
    <PageContent title="Nhóm giá trị">
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch
            onSelectedInputCodeQuickSearch={handleOnSelectedInputCodeQuickSearch}
            setOptionSelectedQuickSearch={setOptionSelectedQuickSearch}
          />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <RemoteTable
          columns={columns}
          data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
          loading={loading}
          page={page}
          limit={limit}
          total={data?.total ?? 0}
          onChange={onChange(() => {})}
          rowKey="id"
        />
      </CardForm>
    </PageContent>
  );
};

export default Find;
