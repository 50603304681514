import { map, uniqBy } from 'lodash';
import { useCallback, useRef } from 'react';
import { detail, list, search, create, update, changeStatus, deleteById, count, approved } from '../api/cart';
import { useGetDetailById, useGetListTable, useSendPostAPI, useSendPutAPI, useSendAPIById } from './hookAPI';

const useGetListCart = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateCart = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useGetDetailCart = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateCart = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteCart = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const useCartQuickSearch = () => {
  const latestAbortToken = useRef(null);
  const get = useCallback(({ name }) => {
    return new Promise((resolve, reject) => {
      if (latestAbortToken.current) {
        clearTimeout(latestAbortToken.current);
      }
      const cancelToken = setTimeout(() => {
        search(
          {
            code: name || undefined,
            isActive: true
          },
          {}
        )
          .then(({ data }) => {
            return resolve(
              uniqBy(
                map(data?.data?.items, (item) => ({
                  label: item.code,
                  value: item.code
                })),
                (item) => item.value
              )
            );
          })
          .catch((error) => {
            return reject(error);
          });
      }, 1000);
      latestAbortToken.current = cancelToken;
    });
  }, []);
  return {
    get
  };
};

const usePutCartChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const usePutCartApprovedStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(approved);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useGetCountCart = () => {
  const { get } = useGetListTable(count);
  return {
    get
  };
};

export {
  useCreateCart,
  useGetListCart,
  useGetDetailCart,
  useUpdateCart,
  useDeleteCart,
  useCartQuickSearch,
  usePutCartChangeStatus,
  useGetCountCart,
  usePutCartApprovedStatus
};
