import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Switch } from 'antd';
import FormItem from '../../../../common/components/FormItem';
import { PREFIX, FORM_FIELD } from '../../constants';
import SelectBoxAccountQuickSearch from '../../../../common/components/SelectBox/SelectBoxAccount';
import SelectBoxCategoryQuickSearch from '../../../../common/components/SelectBox/SelectBoxCategory';
import SelectBoxBranchQuickSearch from '../../../../common/components/SelectBox/SelectBoxBranch';

const ListSearch = () => {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const onSwitchFilter = useCallback((checked) => {
    setIsAdvanced(checked);
  }, []);
  return (
    <>
      <Switch className="mb-5" checkedChildren="Nâng cao" unCheckedChildren="Nâng cao" onChange={onSwitchFilter} />
      <Row gutter={24}>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.code.name} label={FORM_FIELD.code.label} />
          <FormItem prefix={PREFIX} name={FORM_FIELD.title.name} label={FORM_FIELD.title.label} />
          {isAdvanced && (
            <>
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.createdById.name}
                label={FORM_FIELD.createdById.label}
                component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.updatedById.name}
                label={FORM_FIELD.updatedById.label}
                component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.isHot.name}
                label={FORM_FIELD.isHot.label}
                type="select"
                defaultValue={null}
                options={[
                  {
                    label: 'Tất cả',
                    value: null
                  },
                  {
                    label: 'Hot',
                    value: true
                  },
                  {
                    label: 'Không hot',
                    value: false
                  }
                ]}
              />
            </>
          )}
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.categoryId.name}
            label={FORM_FIELD.categoryId.label}
            component={<SelectBoxCategoryQuickSearch allowClear showSearch hideInputLabel />}
          />
          {isAdvanced && (
            <>
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.branchId.name}
                label={FORM_FIELD.branchId.label}
                component={<SelectBoxBranchQuickSearch allowClear showSearch hideInputLabel />}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.fromToCreatedAt.name}
                type="dateRange"
                label={FORM_FIELD.fromToCreatedAt.label}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.fromToUpdatedAt.name}
                type="dateRange"
                label={FORM_FIELD.fromToUpdatedAt.label}
              />
              <FormItem
                prefix={PREFIX}
                name={FORM_FIELD.isRequiredVip.name}
                label={FORM_FIELD.isRequiredVip.label}
                type="select"
                defaultValue={null}
                options={[
                  {
                    label: 'Tất cả',
                    value: null
                  },
                  {
                    label: 'VIP',
                    value: true
                  },
                  {
                    label: 'Không VIP',
                    value: false
                  }
                ]}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {
  listAvailable: PropTypes.array
};

export default ListSearch;
