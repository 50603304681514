import { useMemo } from 'react';
import { useAsync } from 'react-async';
import { getMasterValues } from '../api/masterValue';
import config from '../constants/config';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useGetListTable, useSendPostAPI, useGetDetailById, useSendPutAPI, useSendAPIById } from './hookAPI';
import { dumpPromise } from '../utils';
import { create, detail, update, deleteById } from '../api/masterValue';

const useGetListValue = () => {
  const { get } = useGetListTable(getMasterValues);
  return {
    get
  };
};

const useGetListValueOptionByGroup = (groupCode) => {
  const memoDumpPromise = useMemo(() => dumpPromise([]), []);
  const { isPending, data, error } = useAsync({
    promiseFn: groupCode ? getMasterValues : memoDumpPromise,
    groupCode,
    limit: config.maxInt,
    watch: groupCode
  });
  return {
    loading: isPending,
    data: map(
      filter(data?.data?.data?.items ?? [], (item) => item.groupValue.code === groupCode),
      (item) => ({
        label: item.valueCode,
        value: item.id,
        name: item.valueName
      })
    ),
    error
  };
};

const useCreateMasterValue = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailMasterValue = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateMasterValue = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteMasterValue = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

export {
  useGetListValue,
  useGetListValueOptionByGroup,
  useCreateMasterValue,
  useDetailMasterValue,
  useUpdateMasterValue,
  useDeleteMasterValue
};
