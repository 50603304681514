export const EPostStatus = {
  New: 'New',
  Updating: 'Updating',
  Pending: 'Pending',
  Success: 'Success',
  Reject: 'Reject',
  Published: 'Published'
};

export const PREFIX = 'tag';

export const FORM_FIELD = {
  title: { name: 'title', label: 'Tiêu đề' },
  description: { name: 'description', label: 'Mô tả' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  image: { name: 'image', label: 'Hình ảnh' },
  isShowHome: { name: 'isShowHome', label: 'Hiển thị trang chủ' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};
