export default {
  LOGIN: '/login',
  LOGIN_CONFIRM: '/login-confirm',
  CONFIG: '/config',
  // --- //
  PASSWORD: '/password',
  PASSWORD_FORGOT: '/password/forgot',
  PASSWORD_RESET: '/password/reset',
  PASSWORD_CHANGE: '/password/change',
  NOT_FOUND: '/404',
  HOME: '/home',
  // --- //
  ME: '/me',
  ME_DETAIL: '/me/detail',
  // --- //
  GROUP_VALUE: '/group-value',
  GROUP_VALUE_FIND: '/group-value/find',
  GROUP_VALUE_CREATE: '/group-value/create',
  GROUP_VALUE_EDIT: '/group-value/edit',
  // --- //
  MASTER_VALUE: '/master-value',
  MASTER_VALUE_FIND: '/master-value/find',
  MASTER_VALUE_CREATE: '/master-value/create',
  MASTER_VALUE_EDIT: '/master-value/edit',
  // --- //
  ACCOUNT: '/account',
  ACCOUNT_FIND: '/account/find',
  ACCOUNT_CREATE: '/account/create',
  ACCOUNT_EDIT: '/account/edit',
  // --- //
  CATEGORY: '/category',
  CATEGORY_FIND: '/category/find',
  CATEGORY_CREATE: '/category/create',
  CATEGORY_EDIT: '/category/edit',
  // --- //
  BRANCH: '/branch',
  BRANCH_FIND: '/branch/find',
  BRANCH_CREATE: '/branch/create',
  BRANCH_EDIT: '/branch/edit',
  // --- //
  CONTENT: '/content',
  CONTENT_FIND: '/content/find',
  CONTENT_CREATE: '/content/create',
  CONTENT_EDIT: '/content/edit',
  // --- //
  USER: '/user',
  USER_FIND: '/user/find',
  USER_CREATE: '/user/create',
  USER_EDIT: '/user/edit',

  // --- //
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_ALL: '/notifications/all',
  // --- //
  NOTIFICATION: '/notification',
  NOTIFICATION_FIND: '/notification/find',
  // --- //
  FEEDBACK: '/feedback',
  FEEDBACK_FIND: '/feedback/find',
  FEEDBACK_CREATE: '/feedback/create',
  FEEDBACK_EDIT: '/feedback/edit',
  // --- //
  PRODUCT: '/product',
  PRODUCT_FIND: '/product/find',
  PRODUCT_CREATE: '/product/create',
  PRODUCT_EDIT: '/product/edit',
  // --- //
  PRODUCT_SERIAL_NUMBER: '/serial-number',
  PRODUCT_SERIAL_NUMBER_FIND: '/serial-number/find',
  // --- //
  PRODUCT_TITLE: '/product-title',
  PRODUCT_TITLE_FIND: '/product-title/find',
  PRODUCT_TITLE_CREATE: '/product-title/create',
  PRODUCT_TITLE_EDIT: '/product-title/edit',
  // --- //
  CART: '/cart',
  CART_FIND: '/cart/find',
  CART_CREATE: '/cart/create',
  CART_EDIT: '/cart/edit',
  CART_DETAIL: '/cart/detail',
  // --- //
  E_VOUCHER: '/e-voucher',
  E_VOUCHER_FIND: '/e-voucher/find',
  E_VOUCHER_CREATE: '/e-voucher/create',
  E_VOUCHER_EDIT: '/e-voucher/edit',
  // --- //
  USER_WITH_DRAWAL: '/user-withdrawals',
  USER_WITH_DRAWAL_FIND: '/user-withdrawals/find',
  USER_WITH_DRAWAL_CREATE: '/user-withdrawals/create',
  USER_WITH_DRAWAL_EDIT: '/user-withdrawals/edit',
  // --- //
  PRODUCT_REVIEW: '/product-review',
  PRODUCT_REVIEW_FIND: '/product-review/find',
  // --- //
  PRODUCT_COMMENT: '/product-comment',
  PRODUCT_COMMENT_FIND: '/product-comment/find',
  // --- //
  POST_COMMENT: '/post-comment',
  POST_COMMENT_FIND: '/post-comment/find',
  // --- //
  REGISTER_EXPERT: '/register-expert',
  REGISTER_EXPERT_FIND: '/register-expert/find',
  // --- //
  REGISTER_RECEIPT_EMAIL: '/register-receipt-email',
  REGISTER_RECEIPT_EMAIL_FIND: '/register-receipt-email/find',
  // --- //
  POST: '/post',
  POST_FIND: '/post/find',
  POST_CREATE: '/post/create',
  POST_EDIT: '/post/edit',
  // --- //
  TAG: '/tag',
  TAG_FIND: '/tag/find',
  TAG_CREATE: '/tag/create',
  TAG_QUICK_CREATE: '/tag/quick-create',
  TAG_EDIT: '/tag/edit',
  // --- //
  PUBLIC: '/public',
  PUBLIC_FILES: '/public/files',
  // --- //
  ANALYTICS: '/analytics',
  ANALYTICS_GENERAL: '/analytics/general',
  ANALYTICS_SALES: '/analytics/sales',
  // --- //
  FORUM: '/forum',
  FORUM_FIND: '/forum/find',
  FORUM_CREATE: '/forum/create',
  FORUM_EDIT: '/forum/edit',
  // --- //
  FORUM_COMMENT: '/forum-comment',
  FORUM_COMMENT_FIND: '/forum-comment/find',
  // --- //
  COMMENT_REPORT: '/comment-report',
  COMMENT_REPORT_FIND: '/comment-report/find',
  // --- //
  FORBIDDEN: '/forbidden'
};
