import { last, isEqual, replace } from 'lodash';
import { useMemo, useEffect } from 'react';
// import { Modal } from 'antd';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

const useRecordRouteId = (pattern = /\w+/) => {
  return useMemo(() => {
    const { pathname } = window.location;
    const id = last(pathname.split('/'));
    if (pattern.test(id)) {
      return '';
    }
    return id;
  }, [pattern]);
};

const useIsEditMode = (id, pathEdit) => {
  return useMemo(() => {
    const { pathname } = window.location;
    return id ? isEqual(replace(pathEdit, ':id', id), pathname) : false;
  }, [id, pathEdit]);
};

const useAddEventListenerEnter = (fn, type) => {
  useEffect(() => {
    setLocalStorage('type-enter', type);
    const isSearchModal = getLocalStorage('type-modal-search');

    const keyDownHandlerCreateOrUpdate = (event) => {
      if (isSearchModal === 'true') return;

      const currentType = getLocalStorage('type-enter');
      if (currentType === 'modal' && type !== currentType) {
        return;
      }

      if (currentType === 'textarea' && type !== currentType) {
        return;
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        fn();
        // Modal.destroyAll();
        document.removeEventListener('keydown', keyDownHandlerCreateOrUpdate);
      }
    };

    document.addEventListener('keydown', keyDownHandlerCreateOrUpdate);

    return () => {
      document.removeEventListener('keydown', keyDownHandlerCreateOrUpdate);
    };
  }, [fn, type]);
};

const useAddEventListenerCopy = () => {
  useEffect(() => {
    document.addEventListener('copy', async function (e) {
      const text = await navigator.clipboard.readText();
      navigator.clipboard.writeText(text.trim());
      e.preventDefault(); // default behaviour is to copy any selected text
    });
  }, []);
};

export { useRecordRouteId, useIsEditMode, useAddEventListenerEnter, useAddEventListenerCopy };
