import React, { useCallback, useEffect } from 'react';
import { Layout, Avatar, Row, Menu, Dropdown, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CaretDownOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { logoutPending, getCurrentUserPending } from '../../../modules/Auth/actions';
import ROUTER_URL from '../../../constants/router';
import { errorModal } from '../../components/Modal';
// import Notification from '../../general/notifications';
import { getLocalToken } from '../../../utils/localStorage';

const { Header } = Layout;

const HeaderComponent = ({ collapsed, toggle }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const handleLogout = useCallback(() => dispatch(logoutPending()), [dispatch]);
  const { user, infoApp } = useSelector((state) => state.Auth);

  useEffect(() => {
    !getLocalToken() && push(`${ROUTER_URL.LOGIN}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getLocalToken()) {
      dispatch(getCurrentUserPending());
    }
  }, [dispatch]);

  const handleProfile = () => {
    if (!user) {
      errorModal({ content: 'Không tồn tại thông tin cá nhân' });
      return;
    }
    push(`${ROUTER_URL.ME_DETAIL}`);
  };

  const handleChangePassword = () => {
    push(`${ROUTER_URL.PASSWORD_CHANGE}`);
  };

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key="0" onClick={handleProfile}>
          Thông tin cá nhân
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1" onClick={handleChangePassword}>
          Thay đổi mật khẩu
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={handleLogout}>
          Đăng xuất
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Header className="bg-white shadow-md z-10 fixed top-0 right-0 w-full">
      <Row justify="space-between" align="middle">
        <div
          style={{
            marginLeft: !collapsed ? '190px' : '',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {collapsed ? (
            <MenuUnfoldOutlined onClick={toggle} style={{ fontSize: '20px', padding: '22px' }} />
          ) : (
            <MenuFoldOutlined onClick={toggle} style={{ fontSize: '20px', padding: '22px' }} />
          )}
        </div>
        <Space direction="horizontal space-between">
          <Typography.Text className="ml-3 mr-3">v{infoApp?.version}</Typography.Text>
          <Dropdown overlay={menu} trigger={['hover']}>
            <Space direction="horizontal">
              <Avatar className="leading-7" size={40} icon={<UserOutlined />} src={user?.avatarUrlPublic} />
              <div className="bold">{user ? user.name : 'no name'}</div>
              <CaretDownOutlined />
            </Space>
          </Dropdown>
        </Space>
      </Row>
    </Header>
  );
};

export default HeaderComponent;
