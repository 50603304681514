import React from 'react';
import { DATE_FORMAT, IMAGE_APP } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';
import { toFormatCurrencyVND } from '../../../utils/numberFormat';

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        padding: '2rem 3rem'
      }}
    >
      <table
        style={{
          width: '100%'
        }}
      >
        <tbody>
          <tr>
            <td>
              <img
                src={IMAGE_APP.LogoFinalImage}
                alt=""
                style={{
                  height: '110px',
                  maxHeight: '110px',
                  minHeight: '110px'
                }}
                className="CToWUd"
                data-bit="iit"
              />
            </td>
            <td>
              <div
                style={{
                  fontSize: '11px',
                  lineHeight: '26px',
                  fontWeight: '700',
                  textAlign: 'right'
                }}
              >
                <span className="il">Công Ty TNHH Bảo Râu AZ247</span>
              </div>
              <div
                style={{
                  fontSize: '11px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  textAlign: 'right'
                }}
              >
                <span className="il">39/6C Nguyễn Trọng Tuyển, P15</span>
              </div>
              <div
                style={{
                  fontSize: '11px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  textAlign: 'right'
                }}
              >
                <span className="il">Phú Nhuận, TPHCM</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        style={{
          width: '100%'
        }}
      >
        <tbody>
          <tr>
            <td
              height="35"
              style={{
                fontSize: '19px',
                lineHeight: '26px',
                fontWeight: '700',
                textAlign: 'center'
              }}
            >
              BIÊN BẢN BÀN GIAO THIẾT BỊ
            </td>
          </tr>
        </tbody>
      </table>

      <table
        style={{
          width: '100%'
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'center'
              }}
            >
              Ngày: {displayDateFromString(new Date().toDateString(), DATE_FORMAT)}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'center'
              }}
            >
              Số : {props?.data?.code}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        style={{
          width: '100%'
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'left'
              }}
            >
              Kính gửi:
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              <span style={{ width: '100px', display: 'inline-block' }}>Khách hàng:</span>
              {props?.data?.userName || props?.data?.user?.name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              <span style={{ width: '100px', display: 'inline-block' }}>Địa chỉ:</span>
              {props?.data?.deliveryAddress}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              <span style={{ width: '100px', display: 'inline-block' }}>Số điện thoại:</span>
              {props?.data?.userPhone || props?.data?.user?.phone}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              <span style={{ width: '100px', display: 'inline-block' }}>Email:</span>
              {props?.data?.userEmail || props?.data?.user?.email}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        style={{
          width: '100%',
          marginTop: '20px'
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                fontSize: '12px',
                fontWeight: '700',
                textAlign: 'left'
              }}
            >
              Danh mục thiết bị bàn giao bao gồm:
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              {props?.data?.content}
            </td>
          </tr>
        </tbody>
      </table>

      <table
        style={{
          width: '100%',
          marginTop: '20px'
        }}
        cellSpacing="0"
        cellPadding="0"
      >
        <thead>
          <tr>
            <th
              style={{
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'center',
                borderBottom: '2px solid #000',
                borderTop: '2px solid #000',
                padding: '5px'
              }}
            >
              Sản phẩm
            </th>
            <th
              style={{
                minWidth: '70px',
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'center',
                borderBottom: '2px solid #000',
                borderTop: '2px solid #000',
                padding: '5px'
              }}
            >
              Số lượng
            </th>
            <th
              style={{
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'center',
                borderBottom: '2px solid #000',
                borderTop: '2px solid #000',
                padding: '5px'
              }}
            >
              SERIAL
            </th>
            <th
              style={{
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'center',
                borderBottom: '2px solid #000',
                borderTop: '2px solid #000',
                padding: '5px'
              }}
            >
              Giá (đ)
            </th>
            <th
              style={{
                minWidth: '100px',
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'center',
                borderTop: '2px solid #000',
                borderBottom: '2px solid #000'
              }}
            >
              Thành tiền (đ)
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '11px', fontWeight: '400', textAlign: 'center' }}>
          {props?.data?.cartDetails?.map((item, index) => (
            <tr key={index}>
              <td style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'left' }}>
                {item?.product?.title}
              </td>
              <td style={{ borderBottom: '1px solid #000', padding: '5px' }}>{item?.amount}</td>
              <td style={{ borderBottom: '1px solid #000', padding: '5px' }}>
                {item.cartDetailSerials?.map((item) => item.productSerialNumber?.title).join(', ')}
              </td>
              <td style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'right' }}>
                {toFormatCurrencyVND(item?.price)}
              </td>
              <td style={{ borderBottom: '1px solid #000', padding: '5px', textAlign: 'right' }}>
                {toFormatCurrencyVND(+item.amount * item.price)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table
        style={{
          width: '100%',
          marginTop: '10px'
        }}
      >
        <thead>
          <tr>{''}</tr>
          <tr>{''}</tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'right',
                padding: '5px'
              }}
            >
              Tổng cộng:{' '}
              <span style={{ fontWeight: 700, minWidth: '200px', display: 'inline-block' }}>
                {toFormatCurrencyVND(props?.data?.price)} đ
              </span>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'right',
                padding: '5px'
              }}
            >
              Giảm giá:{' '}
              <span style={{ fontWeight: 700, minWidth: '200px', display: 'inline-block' }}>
                {toFormatCurrencyVND(props?.data?.voucherPrice)} đ
              </span>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'right',
                padding: '5px'
              }}
            >
              Phí vận chuyển:{' '}
              <span style={{ fontWeight: 700, minWidth: '200px', display: 'inline-block' }}>
                {toFormatCurrencyVND(props?.data?.shipPrice)} đ
              </span>
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'right',
                padding: '5px'
              }}
            >
              Thu thực tế:{' '}
              <span style={{ fontWeight: 700, minWidth: '200px', display: 'inline-block' }}>
                {toFormatCurrencyVND(props?.data?.finalPrice)} đ
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      {/* ghi chú */}
      <table style={{ width: '100%', marginTop: '20px' }}>
        <tbody>
          <tr>
            <td
              style={{
                fontSize: '12px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              Ghi chú:
              <p style={{ whiteSpace: 'pre-line', paddingLeft: '10px' }}>{props?.data?.description}</p>
            </td>
          </tr>
        </tbody>
      </table>
      {/* Lưu ý chung */}
      <table style={{ width: '100%', marginTop: '20px' }}>
        <tbody>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'left'
              }}
            >
              Lưu ý chung:
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              - Máy được cài win hệ điều hành Window + trình điều khiển phần cứng (driver), còn sofware sử dụng khách tự
              cài.
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              - Nếu máy bị lỗi phần mềm, thì bên mua tự làm hoặc mang tới AZPC để hộ trợ miễn phí.
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              - Nếu máy bị lỗi phần cứng, thì bên mua mang máy tới AZPC chờ xử lí, mang về ngay.
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              - Nếu máy bị lỗi phần cứng, thì bên mua mang máy tới AZPC chờ xử lí, mang về ngay.
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'left'
              }}
            >
              - Cần hỗ trợ gì khác, vui lòng alo trực tiếp 0977642828 - AZPC.
            </td>
          </tr>
        </tbody>
      </table>
      {/* kí tên */}
      <table style={{ width: '100%', marginTop: '10px' }}>
        <thead>
          <tr>
            <th
              style={{
                fontSize: '11px',
                textAlign: 'center'
              }}
            >
              Đai diện bên khách hàng
            </th>
            <th
              style={{
                fontSize: '11px',
                textAlign: 'center'
              }}
            >
              Đai diện CTY BRAZ247
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '700',
                textAlign: 'center'
              }}
            >
              {''}
            </td>
            <td
              style={{
                fontSize: '11px',
                fontWeight: '400',
                textAlign: 'center',
                paddingTop: '150px'
              }}
            >
              Nguyễn Linh Bảo
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
ComponentToPrint.displayName = 'ComponentToPrint';
export default ComponentToPrint;
