import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as CONST from './constants';
import * as actions from './actions';

export const name = CONST.REDUX_NAME;

const initialState = freeze({
  notifications: {
    items: [],
    total: 0
  },
  countUnread: 0
});

export default handleActions(
  {
    [actions.getNotificationsSuccess]: (state, action) => {
      return freeze({
        ...state,
        notifications: action.payload
      });
    },
    [actions.updateCountUnreadNoti]: (state, action) => {
      return freeze({
        ...state,
        countUnread: action.payload
      });
    }
  },
  initialState
);
