import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    status: payload[`${PREFIX}_${FORM_FIELD?.status?.name}`],
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`],
    userId: payload[`${PREFIX}_${FORM_FIELD?.userId?.name}`] || undefined,
    isShowHome: payload[`${PREFIX}_${FORM_FIELD?.isShowHome?.name}`] || undefined,
    content: payload[`${PREFIX}_${FORM_FIELD?.content?.name}`] || undefined
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.userId?.name}`]: record?.userId,
    [`${PREFIX}_${FORM_FIELD?.content?.name}`]: record?.content,
    [`${PREFIX}_${FORM_FIELD?.status?.name}`]: record?.status
  };
};
