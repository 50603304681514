import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import FunctionSelect from '../../../../common/components/MasterData/Function';
import SelectBoxFeedbackStatus from '../../../../common/components/SelectBox/SelectBoxFeedbackStatus';
import { FORM_FIELD, PREFIX } from '../../constants';

const ListSearch = () => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <FunctionSelect prefix={PREFIX} label={FORM_FIELD.mvFunctionId.label} name={FORM_FIELD.mvFunctionId.name} />
          <FormItem prefix={PREFIX} name={FORM_FIELD.name.name} label={FORM_FIELD.name.label} />
          <FormItem prefix={PREFIX} name={FORM_FIELD.email.name} label={FORM_FIELD.email.label} />
          <FormItem prefix={PREFIX} name={FORM_FIELD.description.name} label={FORM_FIELD.description.label} />
        </Col>
        <Col span={12}>
          <SelectBoxFeedbackStatus prefix={PREFIX} />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToCreatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToCreatedAt.label}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToUpdatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToUpdatedAt.label}
          />
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {
  listAvailable: PropTypes.array
};

export default ListSearch;
