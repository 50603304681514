import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Space, Tag, Tooltip, AutoComplete } from 'antd';
import { useGetListTag } from '../../../helpers/hookTag';

const Tags = ({ getTags, defaultTags = [] }) => {
  const [tags, setTags] = useState(defaultTags);
  const [inputVisible, setInputVisible] = useState(false);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const { get: getListTags } = useGetListTag();

  const [options, setOptions] = useState(defaultTags.map((tag) => ({ value: tag })));

  const getPanelValue = (searchText) => (!searchText ? [] : [{ value: searchText }]);

  useEffect(() => {
    setTags(defaultTags);
  }, [defaultTags]);

  useEffect(() => {
    getTags && getTags(tags);
  }, [tags, getTags]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, []);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputConfirm = (inputValue) => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
  };

  const handleEditInputConfirm = (inputValue) => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
  };

  const handleSearchTag = async (text) => {
    const response = await getListTags({
      searchOption: {
        title: text
      }
    });
    if (response.total > 0) {
      setOptions(response?.items?.map((item) => ({ value: item.title })));
      return;
    }
    setOptions(getPanelValue(text));
  };

  const tagInputStyle = {
    width: 100
  };

  const tagPlusStyle = {
    borderStyle: 'dashed',
    display: 'flex',
    alignItems: 'center'
  };

  return (
    <Space size={[0, 8]} wrap>
      <Space size={[0, 8]} wrap>
        {tags?.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <AutoComplete
                // options={options}
                ref={editInputRef}
                key={tag}
                size="small"
                style={tagInputStyle}
                value={editInputValue}
                onSelect={handleInputConfirm}
              />
            );
          }
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable={index >= 0}
              style={{ userSelect: 'none', display: 'flex', alignItems: 'center' }}
              onClose={() => handleClose(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    setEditInputIndex(index);
                    setEditInputValue(tag);
                    e.preventDefault();
                  }
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      </Space>
      {inputVisible ? (
        <AutoComplete
          options={options}
          size="small"
          style={tagInputStyle}
          onSelect={handleEditInputConfirm}
          onSearch={(text) => handleSearchTag(text)}
        />
      ) : (
        <Tag style={tagPlusStyle} onClick={showInput}>
          <PlusOutlined /> Thẻ mới
        </Tag>
      )}
    </Space>
  );
};

export default Tags;
