import { Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import SelectBoxGroupValueAutoComplete from '../../../../common/components/SelectBox/SelectBoxGroupValueAutoComplete';
import SelectBoxGroupValueQuickSearch from '../../../../common/components/SelectBox/SelectBoxGroupValueQuickSearch';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import { FORM_FIELD, PREFIX } from '../../constants';
import SelectBoxAccountQuickSearch from '../../../../common/components/SelectBox/SelectBoxAccount';

const ListSearch = ({ onSelectedInputCodeQuickSearch, setOptionSelectedQuickSearch }) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.code.name}
            label="Mã nhóm giá trị"
            component={
              <SelectBoxGroupValueAutoComplete onSelect={onSelectedInputCodeQuickSearch} capitalize hideInputLabel />
            }
          />
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.parentId.name}
            label="Mã nhóm giá trị cha"
            component={
              <SelectBoxGroupValueQuickSearch
                allowClear
                showSearch
                setOptionSelected={setOptionSelectedQuickSearch}
                hideInputLabel
              />
            }
          />
        </Col>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.name.name} label="Nhóm giá trị" />
          <FormItem prefix={PREFIX} name={FORM_FIELD.parentName.name} label="Nhóm giá trị cha" disabled />
          <SelectBoxStatus prefix={PREFIX} name={FORM_FIELD.isActive.name} label="Trạng thái" allowClear />
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.createdById.name}
            label={FORM_FIELD.createdById.label}
            component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.updatedById.name}
            label={FORM_FIELD.updatedById.label}
            component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToCreatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToCreatedAt.label}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToUpdatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToUpdatedAt.label}
          />
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {};

export default ListSearch;
