/* eslint-disable react/display-name */
import { Col, Row, Tabs, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CardForm from '../../../common/components/CardForm';
import EImage from '../../../common/components/EImage';
import FormItem from '../../../common/components/FormItem';
import { DATE_FORMAT_FROM_SERVER } from '../../../constants';
import ROUTER_URL from '../../../constants/router';
import { useGetAnalytic } from '../../../helpers/hookAnalytic';
import { toFormatCurrencyVND } from '../../../utils/numberFormat';
import CardCountComponent from '../components/CardCount';
import TableComponent from '../components/Table';
import '../style.scss';

const AnalyticSales = () => {
  const [data, setData] = useState(null);
  const [dataListProduct, setDataListProduct] = useState(null);
  const { getStatusCart, getListProductSales } = useGetAnalytic();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('totalSales');
  const [sortType, setSortType] = useState('desc');
  const [tab, setTab] = useState(1);

  const onGetStatusCart = useCallback(() => {
    getStatusCart({
      fromDate: fromDate,
      toDate: toDate
    }).then((response) => {
      setData(response);
    });
  }, [getStatusCart, fromDate, toDate]);

  const onGetListProduct = useCallback(() => {
    getListProductSales({
      fromDate: fromDate,
      toDate: toDate,
      limit: pageSize,
      skip: (page - 1) * pageSize,
      order: sortColumn ? sortType : null,
      orderField: sortColumn ? sortColumn : null
    }).then((response) => {
      setDataListProduct(response.data.data);
    });
  }, [getListProductSales, fromDate, toDate, page, pageSize, sortColumn, sortType]);

  useEffect(() => {
    onGetStatusCart();
    onGetListProduct();
  }, [onGetStatusCart, onGetListProduct]);

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: '50px',
      render: (text, record, index) => <Typography.Text>{(page - 1) * 10 + index + 1}</Typography.Text>
    },
    {
      title: 'Ảnh đại diện',
      dataIndex: 'thumbnail',
      align: 'center',
      fixed: 'left',
      render: (text, record) => <EImage imageUrl={record?.thumbnail?.url} />
    },
    {
      title: 'Mã sản phẩm',
      align: 'center',
      dataIndex: 'code',
      render: (text, record) => (
        <Link to={`${ROUTER_URL.PRODUCT_EDIT}/${record?.id}`} target="_blank">
          {record.code}
        </Link>
      )
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: sortColumn === 'totalAmount' ? 'Số lượng' : 'Doanh số (đ)',
      dataIndex: sortColumn === 'totalAmount' ? 'totalAmount' : 'totalSales',
      align: sortColumn !== 'totalAmount' ? 'right' : 'center',
      key: sortColumn === 'totalAmount' ? 'totalAmount' : 'totalSales',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (e) => (sortColumn === 'totalSales' ? <div>{toFormatCurrencyVND(e)}</div> : <div>{e}</div>)
    }
  ];

  const tagItems = [
    {
      key: 1,
      label: `Doanh số`,
      children: null
    },
    {
      key: 2,
      label: `Số lượng`,
      children: null
    }
  ];

  const onChangeFilter = (val) => {
    setFromDate(val ? moment(val[0]).format(DATE_FORMAT_FROM_SERVER) : undefined);
    setToDate(val ? moment(val[1]).format(DATE_FORMAT_FROM_SERVER) : undefined);
  };

  return (
    <div className="home-container">
      <Row>
        <CardForm title={'Thông tin tìm kiếm'} style={{ width: '100%' }} headStyle={{ color: '#faad14' }}>
          <Col span={12}>
            <FormItem span={24} name={'fromToDate'} type="dateRange" label={'Thời gian'} onChange={onChangeFilter} />
          </Col>
        </CardForm>
      </Row>
      <Row gutter={16} className="mt-10">
        <CardCountComponent title="Tổng đơn hàng" count={data?.order} span={4} />
        <CardCountComponent title="Chờ xác nhận" count={data?.watingConfirm} span={4} colorClass={'color-2'} />
        <CardCountComponent title="Chờ lấy hàng" count={data?.witingReceive} span={4} colorClass={'color-4'} />
        <CardCountComponent title="Đang giao" count={data?.delivering} span={4} colorClass={'color-3'} />
        <CardCountComponent title="Đã giao" count={data?.delivered} span={4} colorClass={'color-success'} />
        <CardCountComponent title="Đơn huỷ" count={data?.canceled} span={4} colorClass={'color-danger'} />
        <CardCountComponent
          title="Doanh số (đ)"
          count={toFormatCurrencyVND(data?.sales)}
          span={4}
          colorClass={'color-2 '}
        />
        <CardCountComponent
          title="Tiền giảm giá (đ)"
          count={toFormatCurrencyVND(data?.voucher)}
          span={4}
          colorClass={'color-1'}
        />
        <CardCountComponent
          title="Tiền ship (đ)"
          count={toFormatCurrencyVND(data?.ship)}
          span={4}
          colorClass={'color-3'}
        />
        <CardCountComponent
          title="Lợi nhuận (đ)"
          count={toFormatCurrencyVND(data?.profit)}
          span={4}
          colorClass={'color-success'}
        />
        <CardCountComponent
          title="Doanh số TB (đ)"
          count={toFormatCurrencyVND(data?.avgSales)}
          span={4}
          colorClass={'color-5'}
        />
      </Row>
      <Row className="mt-10">
        <CardForm title={'Danh sách sản phẩm bán chạy nhất'} style={{ width: '100%' }} headStyle={{ color: '#faad14' }}>
          <Tabs
            defaultActiveKey=""
            items={tagItems}
            onChange={(e) => {
              setTab(e);
              switch (tab) {
                case 1:
                  setSortColumn('totalAmount');
                  break;
                case 2:
                  setSortColumn('totalSales');
                  break;
                default:
                  break;
              }
            }}
          />
          <TableComponent
            columns={columns}
            pageSize={pageSize}
            total={dataListProduct?.total}
            page={page}
            dataSource={dataListProduct?.items}
            onChangeFromToDate={onChangeFilter}
            onChange={(pagination, filters, sorter) => {
              if (sorter.order === 'ascend') {
                setSortColumn(sorter.field);
                setSortType('asc');
              } else {
                setSortColumn(sorter.field);
                setSortType('desc');
              }

              setPage(pagination.current);
              setPageSize(pagination.pageSize);
            }}
          />
        </CardForm>
      </Row>
    </div>
  );
};

export default AnalyticSales;
