import React, { useEffect } from 'react';
import FormItem from '../FormItem';
import { useGetMasterValues } from './hook';
import GroupCode from '../../../constants/groupCode';

const GuaranteeSelect = ({ prefix, disabled, name, label, form, isDefault, ...props }) => {
  const { data } = useGetMasterValues(GroupCode.Guarantee);

  useEffect(() => {
    if (isDefault && form) {
      const selectId = data?.find((item) => item.label === '1 đổi 1 không quá 4 tiếng tại AZPC')?.value || undefined;
      form.setFieldsValue({ [`${prefix}_${name}`]: selectId });
    }
  }, [data, form, isDefault, name, prefix]);

  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="selectFilter"
      disabled={disabled}
      options={data}
      autoComplete="bypass-autocomplete"
      {...props}
    />
  );
};

export default GuaranteeSelect;
