import { useCallback, useState } from 'react';
import {
  postFile,
  postGeneratePresignedUrl,
  putUploadFileToPresignedUrl,
  getUrlImageToDownload,
  postGeneratePublicPresignedUrl
} from '../api/files';
import { getExtensionByFileName } from '../common/components/UploadVideo/utils';
import { useGetDetailById } from './hookAPI';

const useHandleUploadFileWithPresignedUrl = () => {
  const [loading, setLoading] = useState(false);
  const post = useCallback(async (file, folderUpload = '') => {
    try {
      setLoading(true);
      const ext = getExtensionByFileName(file.name);
      const resGeneratePresignedUrl = await postGeneratePresignedUrl({
        folder: folderUpload,
        mimeType: file.type,
        extension: ext
      });
      if (resGeneratePresignedUrl?.data?.data) {
        const { url, key } = resGeneratePresignedUrl.data.data;
        const resUpload = await putUploadFileToPresignedUrl(url, file);
        if (resUpload) {
          return {
            url,
            key,
            fileName: file.name,
            mimeType: file.type,
            size: file.size
          };
        }
      }
      const err = new Error('Call API error');
      throw err;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    post,
    loading
  };
};

const useHandleUploadFile = () => {
  const [loading, setLoading] = useState(false);
  const post = useCallback(async (file, folder) => {
    try {
      setLoading(true);
      const res = await postFile(file, folder);
      if (res?.data?.data) {
        return {
          url: res?.data?.data.url,
          // urlPublic: res?.data?.data.urlPublic,
          key: res?.data?.data.key,
          fileName: res?.data?.data.fileName,
          mimeType: res?.data?.data.mimeType,
          size: res?.data?.data.size
        };
      }
      const err = new Error('Call API error');
      throw err;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    post,
    loading
  };
};

const useGetUrlImageToDownload = () => {
  const { loading, get, error, setError } = useGetDetailById(getUrlImageToDownload);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useHandleGetPublicPresignedUrl = () => {
  const [loading, setLoading] = useState(false);
  const post = useCallback(async (key) => {
    try {
      setLoading(true);

      const resGeneratePresignedUrl = await postGeneratePublicPresignedUrl({
        key
      });
      if (resGeneratePresignedUrl?.data?.data?.url) {
        return resGeneratePresignedUrl.data.data.url;
      }
      const err = new Error('Call API error');
      throw err;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    post,
    loading
  };
};

export {
  useHandleUploadFileWithPresignedUrl,
  useHandleUploadFile,
  useGetUrlImageToDownload,
  useHandleGetPublicPresignedUrl
};
