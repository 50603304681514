import { Col, Row, Form } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImage from '../../../../common/components/UploadImage';
import { PREFIX } from '../../constants';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
// import SelectBoxTypeUser from '../../../../common/components/SelectBox/SelectBoxTypeUser';
import { FormItemLayoutArea } from '../../../../constants/form';
import SelectBoxGender from '../../../../common/components/SelectBox/SelectBoxGender';
import { FormType } from '../../../../constants/form';

const GeneralInfo = ({
  disabled,
  requiredItems = [],
  disabledItems = [],
  defaultFileList,
  formType = FormType.create
}) => {
  return (
    <Row gutter={24}>
      <Col span={24} lg={12}>
        <FormItem
          prefix={PREFIX}
          name="name"
          label="Họ tên"
          required={requiredItems.includes('name')}
          disabled={disabledItems.includes('name') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name="email"
          label="Email"
          required={requiredItems.includes('email')}
          disabled={disabledItems.includes('email') || disabled}
        />
        {/* <SelectBoxTypeUser
          prefix={PREFIX}
          required={requiredItems.includes('type')}
          disabled={disabledItems.includes('type') || disabled}
        /> */}
        <FormItem
          prefix={PREFIX}
          name="isVip"
          label="Vip"
          type="switch"
          required={requiredItems.includes('type')}
          disabled={disabledItems.includes('type') || disabled}
        />
        <SelectBoxStatus
          prefix={PREFIX}
          required={requiredItems.includes('isActive')}
          disabled={disabledItems.includes('isActive') || disabled}
        />
        <Form.Item {...FormItemLayoutArea} name={`${PREFIX}_avatar`} label={'Hình ảnh'}>
          <UploadImage defaultFileList={defaultFileList} />
        </Form.Item>
      </Col>
      <Col span={24} lg={12}>
        <FormItem
          prefix={PREFIX}
          name="nickName"
          label="Biệt danh"
          required={requiredItems.includes('nickName')}
          disabled={disabledItems.includes('nickName') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name="phone"
          label="Số điện thoại"
          required={requiredItems.includes('phone')}
          disabled={disabledItems.includes('phone') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name="address"
          label="Địa chỉ"
          required={requiredItems.includes('address')}
          disabled={disabledItems.includes('address') || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name="birthday"
          label="Ngày sinh"
          type="date"
          required={requiredItems.includes('birthday')}
          disabled={disabledItems.includes('birthday') || disabled}
        />
        <SelectBoxGender
          prefix={PREFIX}
          required={requiredItems.includes('gender')}
          disabled={disabledItems.includes('gender') || disabled}
        />
        {formType === FormType.create && (
          <>
            <FormItem
              prefix={PREFIX}
              name="password"
              label="Mật khẩu"
              required={requiredItems.includes('password')}
              disabled={disabledItems.includes('password') || disabled}
            />
            <FormItem
              prefix={PREFIX}
              name="isSendPassword"
              label="Gửi mật khẩu vào email"
              type="switch"
              required={requiredItems.includes('isSendPassword')}
              disabled={disabledItems.includes('isSendPassword') || disabled}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default GeneralInfo;
