import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    status: payload[`${PREFIX}_${FORM_FIELD?.status?.name}`],
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`]
  };
};

export const mapFormDataToCreateRecord = (values) => {
  return {
    content: values[`${PREFIX}_${FORM_FIELD?.content?.name}`] || null,
    accountId: values[`${PREFIX}_${FORM_FIELD?.accountId?.name}`],
    productId: values[`${PREFIX}_${FORM_FIELD?.productId?.name}`],
    createdById: values[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: values[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`]
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.accountId?.name}`]: record?.accountId,
    [`${PREFIX}_${FORM_FIELD?.productId?.name}`]: record?.productId,
    [`${PREFIX}_${FORM_FIELD?.content?.name}`]: record?.content,
    [`${PREFIX}_${FORM_FIELD?.createdById?.name}`]: record?.createdById,
    [`${PREFIX}_${FORM_FIELD?.updatedById?.name}`]: record?.updatedById
  };
};
