import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDetailContent, useUpdateContent } from '../../../helpers/hookContent.js';
import GeneralInfo from '../components/generalInfo';
// import StatusButton from '../components/StatusButton';
import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const { error: errorDetailContent, get: detailContent } = useDetailContent();
  const { error: errorUpdateContent, put: updateContent } = useUpdateContent(id);

  useHandleErrorCode(APP_MESSAGE.content.name, [errorDetailContent], APP_MESSAGE.content.detailError);
  useHandleErrorCode(APP_MESSAGE.content.name, [errorUpdateContent], APP_MESSAGE.content.editError);

  const onGetDetail = useCallback(async () => {
    const response = await detailContent(id);
    if (response) {
      setData(response);
    }
  }, [detailContent, id]);

  const onUpdate = useCallback(
    (values) => {
      updateContent(id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.content.editSuccess, type: 'success' });
          history.push(`${ROUTER_URL.CONTENT_EDIT}/${id}`);
        }
      });
    },
    [history, id, updateContent]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.CONTENT_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onUpdate(mapFormDataToCreateRecord(values));
    });
  };

  const onCreate = () => {
    history.push(ROUTER_URL.CONTENT_CREATE);
  };

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.content.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
      onCreate={onCreate}
    >
      <CustomForm
        name="Content"
        module="Content"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.content.editConfirm}
        contentCancel={APP_MESSAGE.content.editCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          {/* <StatusButton id={id} status={data.status} createdById={data.createdById} /> */}
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.type.name,
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
              FORM_FIELD.orderNumber.name
            ]}
            disabledItems={[]}
            defaultFileList={data?.image ? [data?.image] : []}
            defaultMobileFileList={data?.mobileImage ? [data?.mobileImage] : []}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Edit;
