import React from 'react';
import { Modal } from 'antd';

export const previewModal = ({ url }) => {
  Modal.info({
    content: <img src={url} alt="img" />,
    icon: null,
    maskClosable: true,
    closable: true,
    okButtonProps: {
      style: {
        display: 'none'
      }
    }
  });
};
