import { Form, Button, Row, Col } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateCart } from '../../../helpers/hookCart';
import UserInfo from '../components/userInfo';
import ProductInfo from '../components/productInfo';
import PaymentInfo from '../components/paymentInfo';
import FindProduct from '../components/findProduct';
import { FORM_FIELD, PREFIX } from '../constants';
import { fieldFromRecord, mapFormDataToCreateRecord, getPriceByType } from '../utils';
import { FormCreateType } from '../../../constants/form';
import { setLocalStorage } from '../../../utils/localStorage';
import { useLocation } from 'react-router-dom';
import { useDetailUser } from '../../../helpers/hookUser';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userId = searchParams.get('userId');

  const [user, setUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [isNewUser, setIsNewUser] = useState(false);

  const { error: errorCreateCart, post: createCart } = useCreateCart();
  const { error: errorDetailUser, get: detailUser } = useDetailUser();

  useHandleErrorCode(APP_MESSAGE.cart.name, [errorCreateCart], APP_MESSAGE.cart.createError);
  useHandleErrorCode(APP_MESSAGE.user.name, [errorDetailUser], APP_MESSAGE.user.detailError);

  const onGetDetail = useCallback(async () => {
    if (!userId) return;
    const response = await detailUser(userId);
    if (response) {
      setUser(response);
      // form.setFieldsValue({
      //   [`${PREFIX}_${FORM_FIELD?.userId?.name}`]: response?.id,
      //   [`${PREFIX}_${FORM_FIELD?.userEmail?.name}`]: response?.email,
      //   [`${PREFIX}_${FORM_FIELD?.userPhone?.name}`]: response?.phone,
      //   [`${PREFIX}_${FORM_FIELD?.deliveryAddress?.name}`]: response?.address
      // });
    }
  }, [detailUser, userId]);

  useEffect(() => {
    onGetDetail();
  }, [form, userId, onGetDetail]);

  const onCreate = useCallback(
    (values) => {
      createCart({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.cart.createSuccess, type: 'success' });
          history.push(`${ROUTER_URL.CART_DETAIL}/${rs?.id}`);
        }
      });
    },
    [history, createCart]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.CART_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      console.log(values);
      const errors = [];
      products.map((item) => {
        const serialIdsLength = typeof item.serialIds === 'number' ? 1 : item.serialIds?.length;
        if (serialIdsLength !== item.amountInput) {
          errors.push({ name: `serialInput-${item.id}`, errors: ['Số lượng serial chưa đúng'] });
        }
      });

      if (errors.length) {
        form.setFields(errors);
      } else {
        onCreate(mapFormDataToCreateRecord({ ...values, products }));
      }
    });
  };

  const handleChangeValues = (values) => {
    const [key] = Object.keys(values);

    if (key.includes('amountInput')) {
      const [nameField, idValue] = key.split('-');
      setProducts((pre) => {
        return pre.map((item) => ({
          ...item,
          [nameField]: item.id === +idValue ? +values[key] : item.amountInput
        }));
      });
    }

    if (key.includes('isNewUser')) {
      setIsNewUser(!!values[key]);
    }

    if (key.includes('priceInput')) {
      const [nameField, idValue] = key.split('-');
      setProducts((pre) => {
        return pre.map((item) => ({
          ...item,
          [nameField]: item.id === +idValue ? +values[key] : item.priceInput
        }));
      });
    }

    if (key.includes('voucherPrice') || key.includes('shipPrice')) {
      changePrice();
    }
  };

  const handleRemoveProduct = (id) => {
    setProducts((pre) => {
      return pre.filter((item) => item.id !== id);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changePrice = () => {
    const totalAmount = products.reduce((sum, item) => sum + item.amountInput, 0);
    const totalPrice = products.reduce((sum, item) => sum + item.amountInput * item.priceInput, 0);
    const voucherPrice = +form.getFieldValue(`${PREFIX}_voucherPrice`) || 0;
    const shipPrice = +form.getFieldValue(`${PREFIX}_shipPrice`) || 0;
    const finalPrice = totalPrice - voucherPrice + shipPrice;
    products.map((item) => {
      form.setFieldsValue({
        [`serialInput-${item.id}`]: item.serialIds,
        [`amountInput-${item.id}`]: item.amountInput,
        [`priceInput-${item.id}`]: item.priceInput || 0,
        [`${PREFIX}_totalAmount`]: totalAmount,
        [`${PREFIX}_totalPrice`]: totalPrice,
        // [`${PREFIX}_shipPrice`]: voucherPrice,
        // [`${PREFIX}_voucherPrice`]: shipPrice,
        [`${PREFIX}_finalPrice`]: finalPrice
      });
    });
  };

  useEffect(() => {
    changePrice();
  }, [products, form, changePrice]);

  const onChangeSerialNumbers = (record, value) => {
    setProducts((pre) => {
      return pre.map((item) => ({
        ...item,
        serialIds: item.id === record.id ? value : item?.serialIds
      }));
    });
  };

  const onChangeBHPrice = (record, value) => {
    setProducts((pre) => {
      return pre.map((item) => ({
        ...item,
        priceType: item.id === record.id ? value : item?.priceType,
        priceInput: item.id === record.id ? getPriceByType(record, value) : item.priceInput
      }));
    });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.cart.name}`} footerCreatorVisible>
      <CustomForm
        name="cart"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.cart.createConfirm}
        contentCancel={APP_MESSAGE.cart.createCancel}
        onCancel={onCancel}
        form={form}
        onValuesChange={handleChangeValues}
      >
        <Row gutter={24}>
          <Col span={24}>
            <CardForm title="Sản phẩm">
              <Button
                type="default"
                className="mb-4"
                onClick={() => {
                  setVisible(true);
                  setLocalStorage('type-modal-search', true);
                }}
              >
                Thêm sản phẩm
              </Button>
              <ProductInfo
                products={products}
                onDelete={handleRemoveProduct}
                onChangeSerialNumbers={onChangeSerialNumbers}
                onChangeBHPrice={onChangeBHPrice}
              />
            </CardForm>
          </Col>
          <Col span={8}>
            <CardForm title="Thông tin khách hàng">
              <UserInfo
                mode={FormCreateType}
                form={form}
                isNewUser={isNewUser}
                userDefault={user}
                disabledItems={[FORM_FIELD.password.name]}
                requiredItems={[
                  FORM_FIELD.code.name,
                  FORM_FIELD.userId.name,
                  FORM_FIELD.userName.name,
                  FORM_FIELD.userEmail.name,
                  FORM_FIELD.userPhone.name,
                  FORM_FIELD.deliveryAddress.name,
                  isNewUser ? FORM_FIELD.password.name : undefined
                ]}
              />
            </CardForm>
          </Col>
          <Col span={16}>
            <CardForm title="Thanh toán">
              <PaymentInfo />
            </CardForm>
          </Col>
        </Row>
      </CustomForm>
      <FindProduct visible={visible} setVisible={setVisible} products={products} setProducts={setProducts} />
    </PageContent>
  );
};

export default Create;
