import { Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import SelectBoxEVoucherType from '../../../../common/components/SelectBox/SelectBoxEVoucherType';
import SelectBoxEVoucherPriceType from '../../../../common/components/SelectBox/SelectBoxEVoucherPriceType';
import { PREFIX, FORM_FIELD } from '../../constants';
import SelectBoxProductQuickSearch from '../../../../common/components/SelectBox/SelectBoxProduct';
import { DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS, TIME_HOUR_MINUTES } from '../../../../constants';
import { validateVoucherCode, validateVoucherPercent, validateNumber } from '../../../../utils/formValidators';

const GeneralInfo = ({ disabled, requiredItems = [], disabledItems = [], priceType, fromDate, toDate }) => {
  const disabledFromDate = (current) => {
    if (!current) return false;
    return (toDate && current > toDate) || current < new Date();
  };

  const disabledToDate = (current) => {
    if (!current) return false;
    return (fromDate && current < fromDate) || current < new Date();
  };
  return (
    <Row gutter={24}>
      <Col span={12}>
        <SelectBoxEVoucherType
          prefix={PREFIX}
          required={requiredItems.includes(FORM_FIELD.type.name)}
          disabled={disabledItems.includes(FORM_FIELD.type.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.code.name}
          label={FORM_FIELD.code.label}
          required={requiredItems.includes(FORM_FIELD.code.name)}
          disabled={disabledItems.includes(FORM_FIELD.code.name) || disabled}
          {...validateVoucherCode}
        />
        <FormItem
          type="number"
          prefix={PREFIX}
          name={FORM_FIELD.useCount.name}
          label={FORM_FIELD.useCount.label}
          disabled
        />
        <SelectBoxEVoucherPriceType
          prefix={PREFIX}
          required={requiredItems.includes(FORM_FIELD.priceType.name)}
          disabled={disabledItems.includes(FORM_FIELD.priceType.name)}
        />
        {priceType === 'Percent' ? (
          <>
            <FormItem
              type="number"
              prefix={PREFIX}
              name={FORM_FIELD.percentReduction.name}
              label={FORM_FIELD.percentReduction.label}
              required={requiredItems.includes(FORM_FIELD.percentReduction.name)}
              disabled={disabledItems.includes(FORM_FIELD.percentReduction.name) || disabled}
              {...validateVoucherPercent}
            />
          </>
        ) : (
          <FormItem
            showUnit
            prefix={PREFIX}
            name={FORM_FIELD.priceReduction.name}
            label={FORM_FIELD.priceReduction.label}
            required={requiredItems.includes(FORM_FIELD.priceReduction.name)}
            disabled={disabledItems.includes(FORM_FIELD.priceReduction.name) || disabled}
            type="CurrencyVNDInput"
          />
        )}
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.priceLimit.name}
          label={FORM_FIELD.priceLimit.label}
          required={requiredItems.includes(FORM_FIELD.priceLimit.name)}
          disabled={disabledItems.includes(FORM_FIELD.priceLimit.name) || disabled}
          type="CurrencyVNDInput"
        />
        <SelectBoxStatus
          prefix={PREFIX}
          required={requiredItems.includes(FORM_FIELD.isActive.name)}
          disabled={disabledItems.includes(FORM_FIELD.isActive.name)}
        />
      </Col>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.productId.name}
          label={FORM_FIELD.productId.label}
          component={
            <SelectBoxProductQuickSearch
              prefix={PREFIX}
              required={requiredItems.includes(FORM_FIELD.productId.name)}
              disabled={disabledItems.includes(FORM_FIELD.productId.name) || disabled}
            />
          }
        />
        <FormItem
          type="number"
          min={1}
          prefix={PREFIX}
          name={FORM_FIELD.amount.name}
          label={FORM_FIELD.amount.label}
          required={requiredItems.includes(FORM_FIELD.amount.name)}
          disabled={disabledItems.includes(FORM_FIELD.amount.name) || disabled}
          {...validateNumber}
        />
        <FormItem
          prefix={PREFIX}
          name={`${FORM_FIELD.fromDate.name}`}
          label={FORM_FIELD.fromDate.label}
          required={requiredItems.includes(FORM_FIELD.fromDate.name)}
          disabled={disabledItems.includes(FORM_FIELD.fromDate.name) || disabled}
          type="date"
          format={DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS}
          showTime={{
            format: TIME_HOUR_MINUTES
          }}
          disabledDate={disabledFromDate}
        />
        <FormItem
          prefix={PREFIX}
          name={`${FORM_FIELD.toDate.name}`}
          label={FORM_FIELD.toDate.label}
          required={requiredItems.includes(FORM_FIELD.toDate.name)}
          disabled={disabledItems.includes(FORM_FIELD.toDate.name) || disabled}
          type="date"
          format={DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS}
          showTime={{
            format: TIME_HOUR_MINUTES
          }}
          disabledDate={disabledToDate}
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.price.name}
          label={FORM_FIELD.price.label}
          disabled
          type="CurrencyVNDInput"
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          name={FORM_FIELD.sellPrice.name}
          label={FORM_FIELD.sellPrice.label}
          disabled
          type="CurrencyVNDInput"
        />
        {/* <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.userId.name}
          label={FORM_FIELD.userId.label}
          required={requiredItems.includes(FORM_FIELD.userId.name)}
          component={
            <SelectBoxUserQuickSearch
              disabled={disabledItems.includes(FORM_FIELD.userId.name) || disabled}
              prefix={PREFIX}
            />
          }
        /> */}
      </Col>
      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
