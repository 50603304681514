import React, { useEffect, useState } from 'react';
import { Modal, List, Typography } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { findIndex } from 'lodash';

const ModalProcessUpload = ({ filesUploading, fileList, onCancel }) => {
  const [fileListUploading, setFileListUploading] = useState([]);

  useEffect(() => {
    setFileListUploading(filesUploading);
  }, [filesUploading]);

  return (
    <Modal
      className="modal-img"
      title={'Tải hình lên hệ thống'}
      closable={false}
      keyboard={false}
      maskClosable={false}
      visible={fileListUploading.length > 0}
      onCancel={onCancel}
      cancelText="Đóng"
      centered
      okButtonProps={{ style: { display: 'none' } }}
    >
      <List
        size="large"
        dataSource={fileListUploading}
        renderItem={(item) => (
          <List.Item className="text-lg upload-image-list-item">
            <span>
              {item.name}
              <br />
              {item.fail && (
                <Typography.Text type="danger" className="text-sm">
                  {item.fail}
                </Typography.Text>
              )}
            </span>
            {item.fail && <CloseCircleTwoTone twoToneColor="#eb2f96" />}
            {findIndex(fileList, (f) => f.uuid === item.uid) < 0 && !item.fail && <LoadingOutlined />}
            {findIndex(fileList, (f) => f.uuid === item.uid) >= 0 && !item.fail && (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            )}
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ModalProcessUpload;
