import * as CONST from './constants';

export const name = 'globalLoading';

const initialState = {
  showLoading: false
};

/**
 * @name globalLoadingReducer
 * @description This is globalLoading reducer
 * @memberof module:Components
 * @param state
 * @param action
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SHOW_LOADING: {
      return {
        ...state,
        showLoading: true
      };
    }
    case CONST.HIDE_LOADING: {
      return {
        ...state,
        showLoading: false
      };
    }
    default:
      return state;
  }
};

export default reducer;
