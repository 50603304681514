import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import './style.scss';
import { getLocalToken, setLocalStorage } from '../../../utils/localStorage';
import config from '../../../constants/config';
import { folderUpload } from '../UploadFiles/constants';

const classicEditorConfig = {
  toolbar: {
    items: [
      'autoLink',
      'undo',
      'redo',
      '|',
      'heading',
      '|',
      'fontfamily',
      'fontsize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underLine',
      '|',
      'link',
      'uploadImage',
      'blockQuote',
      'insertTable',
      'mediaEmbed',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      'alignment'
    ]
  },
  image: {
    toolbar: [
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
      'imageStyle:alignLeft',
      'imageStyle:alignRight',
      '|',
      'toggleImageCaption',
      'imageTextAlternative'
    ]
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
  extraPlugins: [uploadPlugin]
};

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        const folderName = folderUpload.images;
        const token = getLocalToken();
        const reader = new FileReader();

        loader.file.then((file) => {
          formData.append('file', file);
          formData.append('folder', folderName);
          let headers = new Headers();
          headers.append('Authorization', `Bearer ${token}`);

          fetch(`${config.rootApi}${config.namespaceApi}/files/CKEditor`, {
            method: 'post',
            body: formData,
            headers
          })
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              resolve({
                default: res?.data?.url
              });
            })
            .catch((err) => {
              reject(err);
            });

          reader.readAsDataURL(file);
        });
      });
    }
  };
}

function uploadPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const CustomCKEditor = ({ defaultValue, handleOnBlur, height, ...ckEditorProps }) => {
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    handleOnBlur(editor?.getData());
    editor?.ui.view.editable.element.addEventListener('click', () => {
      setLocalStorage('type-enter', 'textarea');
    });
  });

  return (
    <CKEditor
      data={defaultValue}
      config={classicEditorConfig}
      removePlugins="toolbar"
      editor={DecoupledEditor}
      onBlur={(event, editor) => {
        handleOnBlur(editor.getData());
        setLocalStorage('type-enter', '');
      }}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        console.log('Editor is ready to use!', editor);
        editor.editing.view.change((writer) => {
          writer.setStyle('height', height ?? '100px', editor.editing.view.document.getRoot());
        });

        // Insert the toolbar before the editable area.
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
        setEditor(editor);
      }}
      onError={(error, { willEditorRestart }) => {
        // If the editor is restarted, the toolbar element will be created once again.
        // The `onReady` callback will be called again and the new toolbar will be added.
        // This is why you need to remove the older toolbar.
        if (willEditorRestart) {
          editor.ui.view.toolbar.element.remove();
        }
      }}
      {...ckEditorProps}
    />
  );
};

export default CustomCKEditor;
