import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/feedbacks`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/feedbacks/${id}`);
};

export const functionList = async () => {
  return await axiosInstance.get('/feedbacks/functions');
};

export const list = async (params) => {
  return await axiosInstance.get(`/feedbacks`, {
    params
  });
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/feedbacks/${id}`, body);
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/feedbacks/change-status/${id}`, body);
};

export const approved = async (id, body) => {
  return await axiosInstance.put(`/feedbacks/approved/${id}`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/feedbacks/${id}`);
};
