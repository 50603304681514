import { Modal } from 'antd';

export const successModal = ({ content, onOk = () => {} }) => {
  Modal.success({
    title: 'Thành công',
    content,
    onOk() {
      onOk();
    }
  });
};
