/* eslint-disable react/display-name */
import { Button, Checkbox, Col, Form, Modal, Row, Typography, Select } from 'antd';
import React, { useMemo, useState } from 'react';
import EImage from '../../../../common/components/EImage';
import FormItem from '../../../../common/components/FormItem';
import GroupFindButton from '../../../../common/components/GroupButton/Find';
import RemoteTable from '../../../../common/components/RemoteTable';
import SelectBoxBranchQuickSearch from '../../../../common/components/SelectBox/SelectBoxBranch';
import SelectBoxCategoryQuickSearch from '../../../../common/components/SelectBox/SelectBoxCategory';
import { useGetListProduct } from '../../../../helpers/hookProduct';
import { useRemoteTable } from '../../../../helpers/hookTable';
import { toFormatCurrencyVND } from '../../../../utils/numberFormat';
import { getPriceDefault, getTypeDefault } from '../../utils';
import { Link } from 'react-router-dom';
import ROUTER_URL from '../../../../constants/router';
import { setLocalStorage } from '../../../../utils/localStorage';
import useDeepCompareEffect from 'use-deep-compare-effect';

const FindProduct = ({ visible, setVisible, products, setProducts }) => {
  const [searchForm] = Form.useForm();

  const { get: getListProduct } = useGetListProduct();
  const [searchParams, setSearchParams] = useState({ isActive: true, inventoryAmount: 1 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSerials, setSelectedSerials] = useState({});

  const { loading, data, onChange, page, limit } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, null],
    promiseFn: getListProduct,
    defaultLimit: 10
  });

  const columns = [
    {
      title: 'STT',
      align: 'center',
      render: (text, record, index) => <Typography.Text>{index + 1}</Typography.Text>
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'thumbnail',
      align: 'center',
      render: (text, record) => <EImage imageUrl={record?.thumbnail?.url} />
    },
    {
      title: 'Mã sản phẩm',
      align: 'center',
      dataIndex: 'code',
      render: (text, record) => (
        <Link to={`${ROUTER_URL.PRODUCT_EDIT}/${record?.id}`} target="_blank">
          {record.code}
        </Link>
      )
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'title'
    },
    {
      title: 'Danh mục',
      dataIndex: ['category', 'title']
    },
    {
      title: 'Thương hiệu',
      dataIndex: ['branch', 'title']
    },
    {
      title: 'Tồn kho',
      width: '80px',
      align: 'center',
      dataIndex: 'inventoryAmount'
    },
    {
      title: 'Serial number',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        const serialNumbers = record.serialNumbers?.filter(
          (item) => !item.isUsed || selectedSerials?.[record.id]?.includes(item.id)
        );

        return (
          <Form.Item name={`search-serialInput-${record.id}`} required>
            <Select
              style={{ width: '100%' }}
              disabled={false}
              options={serialNumbers
                ?.sort((a, b) => b.orderNumber - a.orderNumber)
                ?.map((item) => ({
                  label: item.title,
                  value: item.id
                }))}
              mode="multiple"
              onChange={(value) => onChangeSerialNumbers(record, value)}
              allowClear
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Giá (đ)',
      width: '120px',
      align: 'right',
      dataIndex: 'price',
      render: (text, record) => <Typography.Text>{toFormatCurrencyVND(record.price)}</Typography.Text>
    },
    {
      title: 'Giá BH1 (đ)',
      width: '120px',
      align: 'right',
      dataIndex: 'price1',
      render: (text, record) => <Typography.Text>{toFormatCurrencyVND(record.price1)}</Typography.Text>
    },
    {
      title: 'Giá BH2 (đ)',
      width: '120px',
      align: 'right',
      dataIndex: 'price2',
      render: (text, record) => <Typography.Text>{toFormatCurrencyVND(record.price2)}</Typography.Text>
    },
    {
      title: 'Giá BH3 (đ)',
      width: '120px',
      align: 'right',
      dataIndex: 'price3',
      render: (text, record) => <Typography.Text>{toFormatCurrencyVND(record.price3)}</Typography.Text>
    },
    {
      title: 'Hành động',
      width: 70,
      dataIndex: '',
      render: (record) => {
        return (
          <Form.Item name={`checkbox-${record.id}`} valuePropName="checked">
            <Checkbox
              disabled={record.inventoryAmount <= 0}
              className="ml-5"
              onChange={(event) => {
                onSelectRowHandle(event, record);
              }}
            />
          </Form.Item>
        );
      }
    }
  ];

  useDeepCompareEffect(() => {
    setSelectedRows(products);
    if (visible) {
      const checkedFields = {};
      data?.items?.map((item) => {
        const product = products.find((item1) => item1.id === item.id);

        checkedFields[`checkbox-${item.id}`] = product ? true : false;
        checkedFields[`search-serialInput-${item.id}`] = selectedSerials?.[item.id] || product?.serialIds || [];
      });

      searchForm.setFieldsValue(checkedFields);
    }
  }, [data, products, products.length, selectedSerials, searchForm, visible]);

  useDeepCompareEffect(() => {
    const tmpSelectedSerials = {};
    products.map((item) => {
      tmpSelectedSerials[item.id] = item.serialIds;
    });
    setSelectedSerials(tmpSelectedSerials);
  }, [products]);

  const onChangeSerialNumbers = (record, value) => {
    setSelectedSerials((pre) => ({
      ...pre,
      [record.id]: value
    }));
  };

  const onSelectRowHandle = (event, record) => {
    if (event?.target?.checked) {
      selectedRows.push(record);
      setSelectedRows(selectedRows);
      return;
    }
    setSelectedRows(selectedRows.filter((item) => item.id !== record.id));
  };

  const onClosePopupHandle = () => {
    setVisible(false);
    setLocalStorage('type-modal-search', false);
  };
  const onSearchHandle = () => {
    const formValue = searchForm.getFieldsValue();
    setSearchParams((pre) => ({
      ...pre,
      code: formValue.code,
      title: formValue.title,
      serialCode: formValue.serialCode,
      categoryId: formValue.categoryId,
      branchId: formValue.branchId
    }));
  };

  const onClearHandle = () => {
    searchForm.resetFields();
    setSearchParams(null);
  };

  const onAcceptSubmit = () => {
    setProducts(
      selectedRows.map((item) => {
        const serialIds = selectedSerials?.[item.id] || [];
        return {
          id: item.id,
          thumbnail: item.thumbnail,
          code: item.code,
          title: item.title,
          priceType: item.priceType || getTypeDefault(item),
          serialIds,
          inventoryAmount: item.inventoryAmount,
          serialNumbers: item.serialNumbers,
          amount: item.amount || 1,
          amountInput: item.amountInput || serialIds?.length || 1,
          priceInput: item.priceInput || getPriceDefault(item),
          price: item.price,
          price1: item.price1,
          price2: item.price2,
          price3: item.price3
        };
      })
    );
    setVisible(false);
  };
  return (
    <Modal
      visible={visible}
      className="popup-find-many-ksdg-for-letter"
      width="90%"
      title="Tìm sản phẩm"
      onCancel={onClosePopupHandle}
      footer={null}
    >
      <Form form={searchForm}>
        <div className="condition-wrapper">
          <Row gutter={24}>
            <Col span={12}>
              <FormItem prefix={''} name={'code'} label={'Mã sản phẩm'} />
              <FormItem prefix={''} name={'title'} label={'Tên sản phẩm'} />
              <FormItem prefix={''} name={'serialCode'} label={'Mã serial number'} />
            </Col>
            <Col span={12}>
              <FormItem
                prefix={''}
                name={'categoryId'}
                label={'Danh mục'}
                component={<SelectBoxCategoryQuickSearch allowClear showSearch hideInputLabel />}
              />
              <FormItem
                prefix={''}
                name={'branchId'}
                label={'Thương hiệu'}
                component={<SelectBoxBranchQuickSearch allowClear showSearch hideInputLabel />}
              />
            </Col>
          </Row>
        </div>
        <Row gutter={8} justify="center" className="mb-4">
          <GroupFindButton onOk={onSearchHandle} onClean={onClearHandle} />
        </Row>

        <Row gutter={8} justify="end">
          <Button type="primary" className="ml-b-2 button-container mb-2" onClick={onAcceptSubmit}>
            Đồng ý
          </Button>
        </Row>

        <RemoteTable
          columns={columns}
          data={(data?.items || []).map((item) => ({ key: item.id, ...item }))}
          loading={loading}
          page={page}
          limit={limit}
          total={data?.total ?? 0}
          onChange={onChange(() => {})}
          rowKey="noteId"
        />
      </Form>
      <Row gutter={8} justify="center">
        <Button type="primary" className="ml-b-2 button-container" onClick={onAcceptSubmit}>
          Đồng ý
        </Button>
      </Row>
    </Modal>
  );
};

export default FindProduct;
