export const PREFIX = 'notification';

export const FORM_FIELD = {
  content: { name: 'content', label: 'Nội dung' },
  productId: { name: 'productId', label: 'Sản phẩm' },
  accountId: { name: 'accountId', label: 'Người nhận' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {};
