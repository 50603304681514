import { map, uniqBy } from 'lodash';
import { useCallback } from 'react';
import { useRef } from 'react';
import { create, detail, list, update, deleteById, changeStatus } from '../api/productTitle';
import { useGetDetailById, useGetListTable, useSendPutAPI, useSendAPIById, useSendProductTitleAPI } from './hookAPI';

const useGetListProductTitle = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateProductTitle = () => {
  const { loading, action, error, setError } = useSendProductTitleAPI(create);
  return {
    loading,
    productTitle: action,
    error,
    setError
  };
};

const useDetailProductTitle = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateProductTitle = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteProductTitle = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const useProductTitleQuickSearch = () => {
  const latestAbortToken = useRef(null);
  const get = useCallback(({ name }) => {
    return new Promise((resolve, reject) => {
      if (latestAbortToken.current) {
        clearTimeout(latestAbortToken.current);
      }
      const cancelToken = setTimeout(() => {
        list(
          {
            title: name || undefined,
            isActive: true
          },
          {}
        )
          .then(({ data }) => {
            return resolve(
              uniqBy(
                map(data?.data?.items, (item) => ({
                  label: item.title,
                  value: item.id
                })),
                (item) => item.value
              )
            );
          })
          .catch((error) => {
            return reject(error);
          });
      }, 1000);
      latestAbortToken.current = cancelToken;
    });
  }, []);
  return {
    get
  };
};

const usePutProductTitleChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

export {
  useGetListProductTitle,
  useCreateProductTitle,
  useDetailProductTitle,
  useUpdateProductTitle,
  useDeleteProductTitle,
  useProductTitleQuickSearch,
  usePutProductTitleChangeStatus
};
