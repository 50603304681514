import { Col, Form, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import UploadImage from '../../../../common/components/UploadImage';
import { FormItemLayoutArea } from '../../../../constants/form';
import { FORM_FIELD, PREFIX } from '../../constants';

const GeneralInfo = ({ disabled, requiredItems = [], disabledItems = [], defaultFileList }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.title.name}
          label={FORM_FIELD.title.label}
          required={requiredItems.includes(FORM_FIELD.title.name)}
          disabled={disabledItems.includes(FORM_FIELD.title.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.website.name}
          label={FORM_FIELD.website.label}
          required={requiredItems.includes(FORM_FIELD.website.name)}
          disabled={disabledItems.includes(FORM_FIELD.website.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
        <SelectBoxStatus prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.isActive.name)} />
        <Form.Item {...FormItemLayoutArea} name={`${PREFIX}_${FORM_FIELD.logo.name}`} label={FORM_FIELD.logo.label}>
          <UploadImage defaultFileList={defaultFileList} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default GeneralInfo;
