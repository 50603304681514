import React, { useMemo } from 'react';
import { Form, Input, Slider, Switch } from 'antd';
import Select from '../Select';
import DatePicker from '../DatePicker';
import DateRangePicker from '../DateRangePicker';
import CurrencyBilInput from '../CurrencyBilInput';
import CurrencyVNDInput from '../CurrencyVNDInput';
import { setLocalStorage } from '../../../utils/localStorage';

const { TextArea } = Input;

const CustomFormItem = ({
  prefix = 'name',
  name,
  required,
  label,
  type,
  disabled,
  options,
  component,
  allowClear = true,
  labelCol = { span: 6 },
  wrapperCol = { span: 18 },
  labelAlign = 'left',
  rules,
  ...componentProps
}) => {
  const item = useMemo(() => {
    if (type === 'select') {
      return <Select disabled={disabled} options={options} {...componentProps} allowClear />;
    }

    if (type === 'selectFilter') {
      return (
        <Select
          {...componentProps}
          showSearch
          allowClear={allowClear}
          disabled={disabled}
          options={options}
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      );
    }

    if (type === 'date') {
      return <DatePicker disabled={disabled} {...componentProps} />;
    }

    if (type === 'dateRange') {
      return <DateRangePicker disabled={disabled} {...componentProps} placeholder={['Từ ngày', 'Đến ngày']} />;
    }

    if (type === 'text') {
      return <Input disabled bordered={false} {...componentProps} />;
    }

    if (type === 'textArea') {
      return (
        <TextArea
          disabled={disabled}
          {...componentProps}
          onClick={() => {
            setLocalStorage('type-enter', 'textarea');
          }}
          onBlur={() => {
            setLocalStorage('type-enter', '');
          }}
        />
      );
    }

    if (type === 'CurrencyBilInput') {
      return <CurrencyBilInput disabled={disabled} {...componentProps} />;
    }

    if (type === 'CurrencyVNDInput') {
      return <CurrencyVNDInput disabled={disabled} {...componentProps} />;
    }

    if (type === 'password') {
      return <Input.Password type="password" disabled={disabled} {...componentProps} />;
    }

    if (type === 'numberRange') {
      return <Slider range disabled={disabled} {...componentProps} />;
    }

    return <Input type={type} disabled={disabled} {...componentProps} />;
  }, [disabled, options, type, componentProps, allowClear]);

  const eRules = rules ? [...rules] : [];

  if (component) {
    return (
      <Form.Item
        name={prefix ? `${prefix}_${name}` : name}
        label={label}
        rules={[...eRules, { required, message: 'Field bắt buộc, vui lòng bổ sung' }]}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        labelAlign={labelAlign}
        {...componentProps}
      >
        {component}
      </Form.Item>
    );
  }

  if (type === 'switch') {
    return (
      <Form.Item
        valuePropName="checked"
        name={prefix ? `${prefix}_${name}` : name}
        label={label}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        labelAlign={labelAlign}
        {...componentProps}
      >
        <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={disabled} />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      name={prefix ? `${prefix}_${name}` : name}
      label={label}
      rules={[...eRules, { required, message: 'Field bắt buộc, vui lòng bổ sung' }]}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      labelAlign={labelAlign}
    >
      {item}
    </Form.Item>
  );
};

export default CustomFormItem;
