/* eslint-disable react/display-name */
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { Row, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EImage from '../../common/components/EImage';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import ROUTER_URL from '../../constants/router';
import { useGetAnalytic } from '../../helpers/hookAnalytic';
import CardCountComponent from './components/CardCount';
import PieChartComponent from './components/PieChart';
import TableComponent from './components/Table';
import FormItem from '../../common/components/FormItem';
import CardForm from '../../common/components/CardForm';
import './style.scss';

const Analytic = () => {
  const [data, setData] = useState(null);
  const [dataListProduct, setDataListProduct] = useState(null);
  const { getAnalytic: getAnalytic, getListProduct: getListProduct } = useGetAnalytic();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('totalAmountSold');
  const [sortType, setSortType] = useState('desc');

  const onGetListStatistic = useCallback(() => {
    getAnalytic({}).then((response) => {
      setData(response);
    });
  }, [getAnalytic]);

  const onGetListProduct = useCallback(() => {
    getListProduct({
      fromDate: fromDate,
      toDate: toDate,
      limit: pageSize,
      skip: (page - 1) * pageSize,
      order: sortColumn ? sortType : null,
      orderField: sortColumn ? sortColumn : null
    }).then((response) => {
      setDataListProduct(response.data.data);
    });
  }, [getListProduct, fromDate, toDate, page, pageSize, sortColumn, sortType]);

  useEffect(() => {
    document.title = 'Thống kê sản phẩm';
    onGetListStatistic();
    onGetListProduct();
  }, [onGetListStatistic, onGetListProduct]);

  const columns = [
    {
      title: 'STT',
      align: 'center',
      render: (text, record, index) => <Typography.Text>{(page - 1) * 10 + index + 1}</Typography.Text>
    },
    {
      title: 'Ảnh đại diện',
      dataIndex: 'thumbnail',
      align: 'center',
      fixed: 'left',
      render: (text, record) => <EImage imageUrl={record?.thumbnail?.url} />
    },
    {
      title: 'Mã sản phẩm',
      align: 'center',
      dataIndex: 'code',
      render: (text, record) => (
        <Link to={`${ROUTER_URL.PRODUCT_EDIT}/${record?.id}`} target="_blank">
          {record.code}
        </Link>
      )
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Số lượng bán',
      dataIndex: 'totalAmountSold',
      key: 'totalAmountSold',
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend'
    }
  ];

  const iconTotalOrderOfTheMonth = () => {
    if (data?.totalOrderOfTheMonth > data?.totalOrderLastMonth) {
      return <CaretUpFilled />;
    } else if (data?.totalOrderOfTheMonth < data?.totalOrderLastMonth) {
      return <CaretDownFilled />;
    }
    return '';
  };

  return (
    <div className="home-container">
      <Row gutter={24}>
        <CardCountComponent title="Số SP tồn kho" count={data?.totalProductInventory} span={12} sm={8} md={8} />

        <CardCountComponent
          title="Số đơn hàng trong tháng"
          count={
            <>
              {data?.totalOrderOfTheMonth}
              {iconTotalOrderOfTheMonth()}
            </>
          }
          titleCount={'Số đơn hàng trong tháng'}
          colorClass={data?.totalOrderOfTheMonth > data?.totalOrderLastMonth ? 'color-success' : 'color-danger'}
          span={12}
          sm={8}
          md={8}
        >
          <p
            style={{
              fontSize: '14px',
              color: '#999'
            }}
          >
            Tháng trước {data?.totalOrderLastMonth}
          </p>
        </CardCountComponent>
      </Row>
      <Row gutter={24}>
        <PieChartComponent
          title="Thống kê tiền tồn kho và tiền đã bán"
          totalPriceInventory={data?.totalPriceInventory}
          totalPriceSold={data?.totalPriceSold}
          count={data?.totalOrderOfTheMonth}
          colorClass="color-2"
        />
      </Row>
      <Row gutter={16} className="mt-10">
        <CardForm title={'Danh sách sản phẩm bán chạy nhất'} style={{ width: '100%' }} headStyle={{ color: '#faad14' }}>
          <FormItem
            name={'fromToDate'}
            type="dateRange"
            label={'Xem theo thời gian'}
            onChange={(val) => {
              setFromDate(moment(val[0]).format(DATE_FORMAT_FROM_SERVER));
              setToDate(moment(val[1]).format(DATE_FORMAT_FROM_SERVER));
            }}
          />
          <TableComponent
            columns={columns}
            pageSize={pageSize}
            total={dataListProduct?.total}
            page={page}
            dataSource={dataListProduct?.items}
            onChange={(pageination, filters, sorter) => {
              if (!sorter.order) {
                setSortColumn(undefined);
                setSortType(undefined);
              } else if (sorter.order === 'ascend') {
                setSortColumn(sorter.field);
                setSortType('asc');
              } else {
                setSortColumn(sorter.field);
                setSortType('desc');
              }

              setPage(pageination.current);
              setPageSize(pageination.pageSize);
            }}
          />
        </CardForm>
      </Row>
    </div>
  );
};

export default Analytic;
