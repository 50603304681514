import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { FormUpdateType } from '../../../constants/form';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDetailProduct, useUpdateProduct } from '../../../helpers/hookProduct';
import AmountInfo from '../components/amountInfo';
import GeneralInfo from '../components/generalInfo';
import SerialNumberInfo from '../components/serialNumberInfo';
import FindCart from '../components/findCart';
import { FORM_FIELD, PREFIX } from '../constants';
import { fieldFromRecord, mapFormDataToCreateRecord } from '../utils';
import config from '../../../constants/config';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [cart, setCart] = useState(null);

  const { error: errorDetailProduct, get: getDetailProduct } = useDetailProduct();
  const { error: errorUpdateProduct, put: updateProduct } = useUpdateProduct(id);

  useHandleErrorCode(APP_MESSAGE.product.name, [errorDetailProduct], APP_MESSAGE.product.detailError);
  useHandleErrorCode(APP_MESSAGE.product.name, [errorUpdateProduct], APP_MESSAGE.product.editError);

  const onGetDetail = useCallback(async () => {
    const response = await getDetailProduct(id);
    if (response) {
      setData(response);
      setSerialNumbers(response.serialNumbers.sort((a, b) => (a.orderNumber < b.orderNumber ? -1 : 1)));
    }
  }, [getDetailProduct, id]);

  const onUpdate = useCallback(
    (values) => {
      updateProduct(id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.product.editSuccess, type: 'success' });
          onGetDetail();
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, updateProduct, onGetDetail]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.PRODUCT_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onUpdate(mapFormDataToCreateRecord(values, serialNumbers));
    });
  };

  const onCreate = () => {
    history.push(ROUTER_URL.PRODUCT_CREATE);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(fieldFromRecord(data));
    }
  }, [data, form]);

  if (!data) return null;

  const onHandleResetSerial = () => {
    form.setFieldsValue({ [`${PREFIX}_${FORM_FIELD?.inventoryAmount?.name}`]: data.inventoryAmount });
    onHandleUpdateSerial();
  };

  const onHandleUpdateSerial = () => {
    const inventoryAmountNew = form.getFieldValue(`${PREFIX}_${FORM_FIELD?.inventoryAmount?.name}`);
    if (inventoryAmountNew < 0) {
      form.setFields([
        {
          name: `${PREFIX}_${FORM_FIELD?.inventoryAmount?.name}`,
          errors: ['Vui lòng nhập số dương']
        }
      ]);
      return;
    }

    form.setFieldsValue({ [`${PREFIX}_${FORM_FIELD?.amount?.name}`]: +inventoryAmountNew + +data.soldAmount });
    let tmpSerialNumber = [...data.serialNumbers];
    const lastOrderNumber = tmpSerialNumber[tmpSerialNumber.length - 1]?.orderNumber ?? 0;
    const amount = +inventoryAmountNew - (+data.inventoryAmount || 0);

    if (amount > 0) {
      for (let i = 1; i <= amount; i++) {
        const orderNumber = lastOrderNumber + i;
        tmpSerialNumber.push({
          isUsed: false,
          orderNumber,
          title: `${data.code}_${orderNumber}`
        });
        form.setFieldsValue({
          [`${PREFIX}_${FORM_FIELD?.serialNumber?.name}-${orderNumber}`]: `${data.code}_${orderNumber}`
        });
      }
    }

    if (amount < 0) {
      const tmpSerials = [];
      let indexRemove = 0;

      for (const item of tmpSerialNumber) {
        if (!item.isUsed && indexRemove < Math.abs(amount)) {
          indexRemove++;
          continue;
        } else {
          tmpSerials.push(item);
        }
      }

      tmpSerialNumber = tmpSerials;
    }
    setSerialNumbers(tmpSerialNumber.sort((a, b) => (a.orderNumber < b.orderNumber ? -1 : 1)));
  };

  const onChangeProductTitleId = (value) => {
    value?.label &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD?.title?.name}`]: value.label
      });
  };

  const onSelectCart = (cart) => {
    setCart(cart);
    setVisible(true);
  };

  return (
    <PageContent
      module="Product"
      title={`Cập nhật ${APP_MESSAGE.product.name}`}
      footerCreatorVisible
      createdBy={data?.user}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
      onCreate={onCreate}
    >
      <CustomForm
        name="product"
        module="Product"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.product.editConfirm}
        contentCancel={APP_MESSAGE.product.editCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm
          title={
            <>
              <h4>{`Mã sản phẩm # ${data?.code}`}</h4>
              <a className="ant-typography" href={`${config.uiUrl}/items/${data.code}`} target="_blank">
                Xem trên website
              </a>
            </>
          }
        >
          <GeneralInfo
            form={form}
            onChangeProductTitleId={onChangeProductTitleId}
            status={data?.status}
            mode={FormUpdateType}
            requiredItems={[
              FORM_FIELD.categoryId.name,
              FORM_FIELD.branchId.name,
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
              FORM_FIELD.type.name,
              FORM_FIELD.orderNumber.name,
              FORM_FIELD.amount.name,
              FORM_FIELD.deliveryId.name,
              FORM_FIELD.guaranteeId.name,
              FORM_FIELD.warrantyPeriodId.name,
              FORM_FIELD.importPrice.name
              // FORM_FIELD.price.name,
              // FORM_FIELD.price1.name,
              // FORM_FIELD.price2.name,
              // FORM_FIELD.price3.name
            ]}
            defaultImages={data?.images || []}
            defaultThumbnail={data.thumbnail}
            defaultContent={data?.content}
            defaultDescription={data?.description}
            defaultNoteForAdmin={data?.noteForAdmin}
          />
        </CardForm>
        <CardForm title="Quản lý tồn kho">
          <AmountInfo
            amount={data?.inventoryAmount || 0}
            disabledItems={[FORM_FIELD.amount.name, FORM_FIELD.soldAmount.name]}
            onHandleUpdateSerial={onHandleUpdateSerial}
            onHandleResetSerial={onHandleResetSerial}
          />
        </CardForm>
        <CardForm title="Serial number">
          <SerialNumberInfo serialNumbers={serialNumbers} onSelectCart={onSelectCart} />
        </CardForm>
      </CustomForm>
      <FindCart visible={visible} setVisible={setVisible} cart={cart} />
    </PageContent>
  );
};

export default Edit;
