import { Col, Form, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImages from '../../../../common/components/UploadImages';
import { FormItemLayoutArea } from '../../../../constants/form';
import { FORM_FIELD, PREFIX } from '../../constants';
import FeedbackStatus from '../../../../common/components/FeedbackStatus';
import FunctionSelect from '../../../../common/components/MasterData/Function';
import { FeedbackStatus as EFeedbackStatus } from '../../constants';

const GeneralInfo = ({ disabled, requiredItems = [], disabledItems = [], defaultFileList = [], status = 'New' }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <FunctionSelect
          prefix={PREFIX}
          label={FORM_FIELD.mvFunctionId.label}
          name={FORM_FIELD.mvFunctionId.name}
          required={requiredItems.includes(FORM_FIELD.mvFunctionId.name)}
          disabled={disabledItems.includes(FORM_FIELD.mvFunctionId.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.name.name}
          label={FORM_FIELD.name.label}
          required={requiredItems.includes(FORM_FIELD.name.name)}
          disabled={disabledItems.includes(FORM_FIELD.name.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.email.name}
          label={FORM_FIELD.email.label}
          required={requiredItems.includes(FORM_FIELD.email.name)}
          disabled={disabledItems.includes(FORM_FIELD.email.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.status.name}
          label={FORM_FIELD.status.label}
          component={<FeedbackStatus status={status} />}
        />
        <FormItem
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
        {status === EFeedbackStatus.Reject && (
          <FormItem
            prefix={PREFIX}
            type="textArea"
            rows={4}
            name={FORM_FIELD.reasonForRefusal.name}
            label={FORM_FIELD.reasonForRefusal.label}
            required={requiredItems.includes(FORM_FIELD.reasonForRefusal.name)}
            disabled={disabledItems.includes(FORM_FIELD.reasonForRefusal.name) || disabled}
          />
        )}
        <Form.Item
          {...FormItemLayoutArea}
          name={`${PREFIX}_${FORM_FIELD.attachments.name}`}
          label={FORM_FIELD.attachments.label}
          required={requiredItems.includes(FORM_FIELD.attachments.name)}
          disabled={disabledItems.includes(FORM_FIELD.attachments.name) || disabled}
        >
          <UploadImages defaultFileList={defaultFileList} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default GeneralInfo;
