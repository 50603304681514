import React from 'react';
import { useGroupValueCodeQuickSearch } from '../../../../helpers/hookGroupValue';
import SuggestionInput from '../../SuggestionInput';
import PropTypes from 'prop-types';

const propTypes = {
  setOptionSelected: PropTypes.func
};

const SelectBoxGroupValueQuickSearch = ({ setOptionSelected, ...props }) => {
  const { get: getCodeQuickSearch } = useGroupValueCodeQuickSearch();

  return <SuggestionInput promiseFn={getCodeQuickSearch} setOptionSelected={setOptionSelected} {...props} />;
};

SelectBoxGroupValueQuickSearch.propTypes = propTypes;

export default SelectBoxGroupValueQuickSearch;
