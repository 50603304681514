import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`],
    mvFunctionId: payload[`${PREFIX}_${FORM_FIELD?.mvFunctionId?.name}`] || undefined,
    status: payload[`${PREFIX}_${FORM_FIELD?.status?.name}`] || undefined,
    name: payload[`${PREFIX}_${FORM_FIELD?.name?.name}`] || undefined,
    email: payload[`${PREFIX}_${FORM_FIELD?.email?.name}`] || undefined,
    description: payload[`${PREFIX}_${FORM_FIELD?.description?.name}`] || undefined
  };
};

export const mapFormDataToCreateRecord = (values) => {
  return {
    mvFunctionId: values[`${PREFIX}_${FORM_FIELD?.mvFunctionId?.name}`] || null,
    name: values[`${PREFIX}_${FORM_FIELD?.name?.name}`] || null,
    email: values[`${PREFIX}_${FORM_FIELD?.email?.name}`] || null,
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`] || null,
    attachments: values[`${PREFIX}_${FORM_FIELD?.attachments?.name}`] || null
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.mvFunctionId?.name}`]: record?.mvFunctionId,
    [`${PREFIX}_${FORM_FIELD?.name?.name}`]: record?.name,
    [`${PREFIX}_${FORM_FIELD?.email?.name}`]: record?.email,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: record?.description,
    [`${PREFIX}_${FORM_FIELD?.reasonForRefusal?.name}`]: record?.reasonForRefusal,
    [`${PREFIX}_${FORM_FIELD?.attachments?.name}`]: record?.attachments
  };
};
