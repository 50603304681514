import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { confirmModal, previewModal } from '../Modal';
import Config from '../../../constants/config';
import {
  useHandleGetPublicPresignedUrl,
  useHandleUploadFile,
  useHandleUploadFileWithPresignedUrl
} from '../../../helpers/hookFile';
import './style.scss';
import { customToast } from '../Toast';
import { folderUpload } from '../UploadFiles/constants';

const UploadImage = ({
  onChange,
  disabled,
  isUploadWithPresignedUrl = false,
  defaultText = '',
  listType = 'picture-card',
  defaultFileList = [],
  onDelete
}) => {
  const [fileList, setFileList] = useState(defaultFileList ? defaultFileList.map((f) => ({ ...f, uid: f.key })) : []);
  const [fileListCustom, setFileListCustom] = useState(fileList);
  const [loading, setLoading] = useState(false);

  const { post: postFile } = useHandleUploadFile();
  const { post: postFileWithPresignedUrl } = useHandleUploadFileWithPresignedUrl();
  const { post: postFilePublicWithPresignedUrl } = useHandleGetPublicPresignedUrl();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{defaultText}</div>
    </div>
  );

  useEffect(() => {
    const files = fileList.map((f) => {
      const { key, mimeType, url, fileName, size } = f;
      return { key, mimeType, url, fileName, size };
    });
    onChange(files);
    setFileListCustom([...fileList].map((x) => ({ ...x, url: x.urlPublic || x.url })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const beforeUpload = (file) => {
    if (file.size > Config.uploadMaxFileSize) {
      customToast({ content: 'Vượt dung lượng cho phép. Hình ảnh có dung lượng tối đa là 10MB.', type: 'error' });
      return false;
    }
    return true;
  };

  const customRequest = async ({ file }) => {
    setLoading(true);

    try {
      let data = null;
      if (isUploadWithPresignedUrl) {
        data = await postFileWithPresignedUrl(file, folderUpload.privateImages);

        if (data.key) {
          const urlPublic = await postFilePublicWithPresignedUrl(data.key);
          if (urlPublic) {
            data.url = urlPublic;
          }
        }
      } else {
        data = await postFile(file, folderUpload.images);
      }

      setFileList((files) => [...files, { ...data, uuid: file.uid }]);
      if (onChange) {
        onChange([
          {
            url: data.url,
            key: data.key,
            mimeType: data.mimeType,
            size: data.size,
            fileName: data.fileName
          }
        ]);
      }
    } catch (error) {
      customToast({ content: 'Lỗi trong quá trình tải lên', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onRemove = (file) => {
    confirmModal({
      content: `Bạn muốn xóa file ${file.fileName}`,
      onOk: () => {
        setFileList((files) => files.filter((f) => f.key !== file.key));
        onDelete && onDelete(file);
      }
    });
  };
  const handleChange = () => {
    console.log(1);
  };
  const onPreview = (file) => {
    previewModal({ url: file.url });
  };

  return (
    <Upload
      fileList={fileListCustom}
      listType={listType}
      onRemove={onRemove}
      onChange={handleChange}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
      accept="image/*"
      disabled={disabled}
      onPreview={onPreview}
    >
      {fileListCustom.length >= 1 ? null : uploadButton}
    </Upload>
  );
};

export default UploadImage;
