import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { flatMap } from 'lodash';
// import { DEFAULT_MENU_OPTIONS } from './constants';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  TeamOutlined,
  FileTextOutlined,
  TagsOutlined,
  FolderAddOutlined,
  AuditOutlined,
  BookOutlined,
  BarChartOutlined,
  PictureOutlined,
  FileDoneOutlined,
  ShopOutlined,
  NotificationOutlined
} from '@ant-design/icons';

const { SubMenu } = Menu;

const LeftMenu = () => {
  const { user } = useSelector((store) => store.Auth);
  const { pathname } = useLocation();
  const [menusObj, setMenusObj] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const mappingMenus = (mapMenus = []) => {
    const ICONS_SIDE_BAR = {
      Dashboard: <HomeOutlined />,
      'Nhân viên': <UserOutlined />,
      'Dữ liệu chung': <FolderAddOutlined />,
      'Khách hàng': <TeamOutlined />,
      'Danh mục': <TagsOutlined />,
      'Sản phẩm': <PictureOutlined />,
      'Cấu hình chung': <SettingOutlined />,
      'Đơn hàng': <ShopOutlined />,
      'Góp ý': <AuditOutlined />,
      'E-Voucher': <BookOutlined />,
      'Nội dung': <FileTextOutlined />,
      Blogs: <FileTextOutlined />,
      'Q&A': <FileDoneOutlined />,
      Tag: <TagsOutlined />,
      'Thông báo': <NotificationOutlined />,
      'Đăng ký tin': <FileTextOutlined />,
      'Hệ thống': <SettingOutlined />,
      'Thống kê': <BarChartOutlined />
    };

    const getChildrenMenus = (children = []) => {
      return children.map((child) => {
        return {
          url: child.path,
          label: child.name,
          type: child.children.length ? 'sub-menu' : 'menu',
          key: `${child.id}-${child.name}`,
          children: child.children.length ? getChildrenMenus(child.children) : []
        };
      });
    };

    const tempMenus = mapMenus.map((item) => {
      const menu = {
        url: item.path,
        label: item.name,
        type: item.children.length ? 'sub-menu' : 'menu',
        key: `${item.id}-${item.name}`,
        icon: ICONS_SIDE_BAR[item.name] ? ICONS_SIDE_BAR[item.name] : null,
        children: item.children.length ? getChildrenMenus(item.children) : []
      };

      return menu;
    });

    return tempMenus;
  };

  useEffect(() => {
    const openKeysT = [];
    const selectedKeysT = [];

    const findKeys = (arr) => {
      let flag = false;
      arr.forEach((obj) => {
        if (obj.type === 'menu' && obj.url === pathname) {
          selectedKeysT.push(obj.key);
          flag = true;
        } else if (obj.type === 'sub-menu') {
          const haveChildren = findKeys(obj.children);
          if (haveChildren) {
            openKeysT.push(obj.key);
            flag = true;
          }
        }
      });
      return flag;
    };

    const menus = mappingMenus(user?.menus || []);

    findKeys(menus);

    setMenusObj(menus);
    setOpenKeys(openKeysT);
    setSelectedKeys(selectedKeysT);
  }, [pathname, user]); // eslint-disable-line

  if (!user) {
    return null;
  }

  const renderMenu = (arr = []) => {
    return arr.map((obj) => {
      if (obj.type === 'menu') {
        return (
          <Menu.Item key={obj.key} icon={obj.icon}>
            <Link to={`${obj.url}`}>{obj.label}</Link>
          </Menu.Item>
        );
      }
      return (
        <SubMenu key={obj.key} title={obj.label} icon={obj.icon}>
          {renderMenu(obj.children)}
        </SubMenu>
      );
    });
  };

  return (
    <Menu
      key={flatMap([openKeys, selectedKeys]).join('-')}
      mode="inline"
      // theme="dark"
      defaultOpenKeys={openKeys}
      defaultSelectedKeys={selectedKeys}
    >
      {renderMenu(menusObj)}
    </Menu>
  );
};

export default LeftMenu;
