import { Col, Tooltip } from 'antd';
import React from 'react';
import CardForm from '../../../common/components/CardForm';

const CardCountComponent = ({ title, count, titleCount, colorClass, children, ...props }) => {
  return (
    <Col {...props}>
      <CardForm title={title} className="p-card">
        <p className={colorClass}>
          <Tooltip placement="bottomLeft" title={titleCount}>
            <span
              style={{
                fontSize: '20px'
              }}
            >
              {count}
            </span>
          </Tooltip>
        </p>
        <p>{children}</p>
      </CardForm>
    </Col>
  );
};

export default CardCountComponent;
