import React from 'react';
import { genderOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxGender = ({ prefix = '', name = 'gender', label = 'Giới tính', ...props }) => {
  return (
    <FormItem prefix={prefix} name={name} label={label} type="select" options={genderOptions} allowClear {...props} />
  );
};

SelectBoxGender.propTypes = propTypes;

export default SelectBoxGender;
