import React from 'react';
import { Row } from 'antd';
import Button from '../../Button';
import { useAddEventListenerEnter } from '../../../../helpers/common.hooks';

const GroupFindButton = ({ onOk, onClean }) => {
  const handleFind = () => {
    onOk && onOk();
  };

  const handleClean = () => {
    onClean();
  };

  useAddEventListenerEnter(onOk, 'form-find');

  return (
    <Row gutter={8} justify="center">
      <Button className="mr-b-2" onClick={handleClean}>
        Xóa
      </Button>
      <Button type="primary" className="ml-b-2" onClick={handleFind} htmlType="submit">
        Tìm
      </Button>
    </Row>
  );
};

export default GroupFindButton;
