import { Form, Input, Modal, Row, Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import CustomButton from '../../../common/components/Button';
import { ECartStatus } from '../constants';
import { useSelector } from 'react-redux';
import ROUTER_URL from '../../../constants/router';
import { customToast } from '../../../common/components/Toast';
import { confirmModal } from '../../../common/components/Modal';
import { useDeleteCart, usePutCartApprovedStatus } from '../../../helpers/hookCart';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';
import { useIsCheckFeature } from '../../../helpers/hookPermission';

const StatusButton = ({ id, status, onGetDetail, data }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { user } = useSelector((state) => state.Auth);
  const [disableButtonSend, setDisableButtonSend] = useState(true);
  const [visibleModalReject, setVisibleModalReject] = useState(false);
  // const { put: putChangeStatusCart } = usePutCartChangeStatus();
  const { put: putApprovedStatusCart } = usePutCartApprovedStatus();
  const { delete: deleteCart } = useDeleteCart();

  const isApproved = useIsCheckFeature('Cart', 'Approve');

  const componentRef = useRef();
  const onPrintCart = useReactToPrint({
    content: () => componentRef.current
  });

  // const handleChangeStatus = async (willUpdateStatus) => {
  //   const { reason } = form.getFieldsValue(['reason']);
  //   return await putChangeStatusCart(id, { status: willUpdateStatus, reasonForRefusal: reason || '' });
  // };

  const handleApprovedStatus = async (willUpdateStatus) => {
    const { reason } = form.getFieldsValue(['reason']);
    return await putApprovedStatusCart(id, { status: willUpdateStatus, reasonForRefusal: reason || '' });
  };

  const onWaitingConfirm = async () => {
    confirmModal({
      content: 'Xác nhận Chờ xử lý đơn hàng',
      onOk: async () => {
        const resData = await handleApprovedStatus(ECartStatus.WaitingConfirm);
        if (resData?.success) {
          onGetDetail();
          customToast({ content: 'Xác nhận Chờ xử lý đơn hàng thành công', type: 'success' });
        } else {
          customToast({ content: 'Xác nhận Chờ xử lý đơn hàng thất bại', type: 'error' });
        }
      }
    });
  };

  const onWaitingReceive = async () => {
    confirmModal({
      content: 'Xác nhận Chờ lấy hàng',
      onOk: async () => {
        const resData = await handleApprovedStatus(ECartStatus.WaitingReceive);
        if (resData?.success) {
          onGetDetail();
          customToast({ content: 'Xác nhận Chờ lấy hàng thành công', type: 'success' });
        } else {
          customToast({ content: 'Xác nhận Chờ lấy hàng thất bại', type: 'error' });
        }
      }
    });
  };

  const onDelete = async () => {
    confirmModal({
      content: 'Thực hiện thao tác này sẽ xóa dữ liệu khỏi hệ thống. Xác nhận xóa?',
      onOk: async () => {
        const resData = await deleteCart(id);
        if (resData?.success) {
          customToast({ content: 'Xác nhận xóa Đơn hàng thành công', type: 'success' });
          history.push(`${ROUTER_URL.CART_FIND}`);
        } else {
          customToast({ content: 'Xác nhận xóa Đơn hàng thất bại', type: 'error' });
        }
      }
    });
  };

  const onValuesChange = (changeValues) => {
    const { reason } = changeValues;
    setDisableButtonSend(isEmpty(reason));
  };

  const onReject = async () => {
    const { success } = await handleApprovedStatus(ECartStatus.Canceled);
    if (success) {
      onRejectModalClose();
      onGetDetail();
      customToast({ content: 'Xác nhận hủy Đơn hàng thành công', type: 'success' });
      return;
    }
    customToast({ content: 'Xác nhận hủy Đơn hàng thất bại', type: 'error' });
  };

  const onRejectModalOpen = () => {
    setVisibleModalReject(true);
  };

  const onRejectModalClose = () => {
    setVisibleModalReject(false);
  };

  const onViewCart = () => {
    history.push(`${ROUTER_URL.CART_DETAIL}/${id}`);
  };

  const onDelivering = async () => {
    confirmModal({
      content: 'Xác nhận Đang giao sản phẩm?',
      onOk: async () => {
        const { success } = await handleApprovedStatus(ECartStatus.Delivering);
        if (success) {
          onGetDetail();
          customToast({ content: 'Xác nhận Đang giao sản phẩm thành công', type: 'success' });
          return;
        }
        customToast({ content: 'Xác nhận Đang giao sản phẩm thất bại', type: 'error' });
      }
    });
  };

  const onDelivered = async () => {
    confirmModal({
      content: 'Xác nhận Đã giao sản phẩm?',
      onOk: async () => {
        const { success } = await handleApprovedStatus(ECartStatus.Delivered);
        if (success) {
          onGetDetail();
          customToast({ content: 'Xác nhận Đã giao sản phẩm thành công', type: 'success' });
          return;
        }
        customToast({ content: 'Xác nhận Đã giao sản phẩm thất bại', type: 'error' });
      }
    });
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Modal
        visible={visibleModalReject}
        onOk={onReject}
        okButtonProps={{
          disabled: disableButtonSend
        }}
        onCancel={onRejectModalClose}
        title="Xác nhận hủy?"
      >
        <div>
          <Form form={form} onValuesChange={onValuesChange}>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              labelAlign="left"
              name="reason"
              label="Lý do"
              required
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Row justify="end">
        <Space wrap size="large">
          <CustomButton
            type="danger"
            className="button-container button-delete"
            onClick={onDelete}
            // visible={
            //   (user?.type === 'Admin' || user.id === createdById) && [ECartStatus.WaitingConfirm].includes(status)
            // }
            visible={false}
          >
            Xóa
          </CustomButton>

          <CustomButton
            type="primary"
            className="button-container button-rejected"
            onClick={onRejectModalOpen}
            visible={(user?.type === 'Admin' || isApproved) && ![ECartStatus.Canceled].includes(status)}
          >
            Hủy
          </CustomButton>

          <CustomButton
            type="primary"
            className="button-container button-drafting"
            onClick={onWaitingConfirm}
            visible={(user?.type === 'Admin' || isApproved) && ![ECartStatus.WaitingConfirm].includes(status)}
          >
            Chờ xác nhận
          </CustomButton>

          <CustomButton
            type="primary"
            className="button-container button-pending"
            onClick={onWaitingReceive}
            visible={(user?.type === 'Admin' || isApproved) && ![ECartStatus.WaitingReceive].includes(status)}
          >
            Chờ lấy hàng
          </CustomButton>

          <CustomButton
            type="primary"
            className="button-container button-processed"
            onClick={onDelivering}
            visible={(user?.type === 'Admin' || isApproved) && ![ECartStatus.Delivering].includes(status)}
          >
            Đang giao
          </CustomButton>

          <CustomButton
            type="primary"
            className="button-container button-finished"
            onClick={onDelivered}
            visible={(user?.type === 'Admin' || isApproved) && ![ECartStatus.Delivered].includes(status)}
          >
            Đã giao
          </CustomButton>

          <CustomButton
            type="primary"
            className="button-container"
            onClick={onPrintCart}
            // visible={(user?.type === 'Admin' || isApproved) && ![ECartStatus.Delivered].includes(status)}
          >
            In đơn hàng
          </CustomButton>
          <CustomButton
            className="button-container"
            onClick={onViewCart}
            // visible={(user?.type === 'Admin' || isApproved) && ![ECartStatus.Delivered].includes(status)}
          >
            Xem chi tiết đơn hàng
          </CustomButton>
        </Space>
      </Row>
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} data={data} />
      </div>
    </div>
  );
};

export default StatusButton;
