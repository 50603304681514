import { isEmpty } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { makeKey } from '../../utils/index';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    name: payload[`${PREFIX}_${FORM_FIELD?.name?.name}`] || undefined,
    email: payload[`${PREFIX}_${FORM_FIELD?.email?.name}`] || undefined,
    type: payload[`${PREFIX}_${FORM_FIELD?.type?.name}`] || undefined,
    typeLogin: payload[`${PREFIX}_${FORM_FIELD?.typeLogin?.name}`] || undefined,
    phone: payload[`${PREFIX}_${FORM_FIELD?.phone?.name}`] || undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`]
  };
};

export const mapFormDataToCreateRecord = (values) => {
  const avatar = values[`${PREFIX}_${FORM_FIELD?.avatar?.name}`];
  return {
    name: values[`${PREFIX}_${FORM_FIELD?.name?.name}`],
    email: values[`${PREFIX}_${FORM_FIELD?.email?.name}`],
    type: values[`${PREFIX}_${FORM_FIELD?.isVip?.name}`] ? 'Vip' : 'Normal',
    nickName: values[`${PREFIX}_${FORM_FIELD?.nickName?.name}`],
    phone: values[`${PREFIX}_${FORM_FIELD?.phone?.name}`],
    address: values[`${PREFIX}_${FORM_FIELD?.address?.name}`],
    birthday: values[`${PREFIX}_${FORM_FIELD?.birthday?.name}`]
      ? moment(values[`${PREFIX}_${FORM_FIELD?.birthday?.name}`]).format(DATE_FORMAT_FROM_SERVER)
      : null,
    gender: values[`${PREFIX}_${FORM_FIELD?.gender?.name}`],
    password: values[`${PREFIX}_${FORM_FIELD?.password?.name}`],
    isSendPassword: values[`${PREFIX}_${FORM_FIELD?.isSendPassword?.name}`],
    isAffiliate: values[`${PREFIX}_${FORM_FIELD?.isAffiliate?.name}`],
    avatar: !isEmpty(avatar) ? avatar[0] : null,
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`]
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.name?.name}`]: record?.name,
    [`${PREFIX}_${FORM_FIELD?.nickName?.name}`]: record?.nickName,
    [`${PREFIX}_${FORM_FIELD?.email?.name}`]: record?.email,
    [`${PREFIX}_${FORM_FIELD?.type?.name}`]: record?.type,
    [`${PREFIX}_${FORM_FIELD?.isVip?.name}`]: record?.type === 'Vip',
    [`${PREFIX}_${FORM_FIELD?.phone?.name}`]: record?.phone,
    [`${PREFIX}_${FORM_FIELD?.address?.name}`]: record?.address,
    [`${PREFIX}_${FORM_FIELD?.avatar?.name}`]: record?.avatar,
    [`${PREFIX}_${FORM_FIELD?.gender?.name}`]: record?.gender,
    [`${PREFIX}_${FORM_FIELD?.birthday?.name}`]: record?.birthday ? moment(record?.birthday) : null,
    [`${PREFIX}_${FORM_FIELD?.password?.name}`]: record?.password || makeKey(10),
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: record?.description,
    [`${PREFIX}_${FORM_FIELD?.roleName?.name}`]: record?.roleName,
    [`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`]: record?.orderNumber,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive,
    [`${PREFIX}_${FORM_FIELD?.isAffiliate?.name}`]: record?.isAffiliate
  };
};
