/* eslint-disable */
import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import StatusButton from '../components/StatusButton';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { fieldFromRecord, mapFormDataToCreateRecord } from '../utils';
import { useDetailTag, useUpdateTag } from '../../../helpers/hookTag';
import { customToast } from '../../../common/components/Toast';
import { isEmpty } from 'lodash';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const { error: errorDetailTag, get: detailTag } = useDetailTag();
  const { error: errorUpdateTag, put: updateTag } = useUpdateTag(id);

  useHandleErrorCode(APP_MESSAGE.tag.name, [errorDetailTag], APP_MESSAGE.tag.detailError);
  useHandleErrorCode(APP_MESSAGE.tag.name, [errorUpdateTag], APP_MESSAGE.tag.editError);

  const onGetDetail = useCallback(async () => {
    const response = await detailTag(id);
    if (response) {
      setData(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailTag, form, id]);

  const onUpdate = useCallback(
    (values) => {
      updateTag(id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.tag.editSuccess, type: 'success' });
          onGetDetail();
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, updateTag, onGetDetail]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.TAG_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = () => {
    form.validateFields().then((values) => {
      onUpdate(mapFormDataToCreateRecord(values));
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(fieldFromRecord(data));
    }
  }, [data, form]);

  const onCreate = () => {
    history.push(ROUTER_URL.TAG_CREATE);
  };

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.tag.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
      onCreate={onCreate}
    >
      <CustomForm
        name="tag"
        module="Tag"
        onSubmit={onSave}
        contentOK={APP_MESSAGE.tag.editConfirm}
        contentCancel={APP_MESSAGE.tag.editCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <StatusButton id={id} status={data.status} createdById={data.createdById} onGetDetail={onGetDetail} />
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
            ]}
            defaultImage={!isEmpty(data?.image) ? [data.image] : []}
            status={data.status}
            form={form}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Edit;
