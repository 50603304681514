/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import ActionButton from '../../../common/components/ActionButton';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';

export const getGroupValueTableColumns = ({ onEdit, onDelete, page, limit }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
  },
  {
    title: 'Mã nhóm giá trị',
    columnIndex: 1,
    dataIndex: ['code']
  },
  {
    title: 'Tên nhóm giá trị',
    columnIndex: 2,
    dataIndex: ['name']
  },
  {
    title: 'Mã nhóm giá trị cha',
    columnIndex: 3,
    dataIndex: ['parent', 'code']
  },
  {
    title: 'Nhóm giá trị cha',
    columnIndex: 4,
    dataIndex: ['parent', 'name']
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    columnIndex: 5,
    render: (text, record) => displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 6,
    render: (text, record) => displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)
  },
  {
    title: 'Người tạo',
    dataIndex: ['createdBy', 'name']
  },
  {
    title: 'Người cập nhật',
    dataIndex: ['updatedBy', 'name']
  },
  {
    title: 'Hành động',
    dataIndex: undefined,
    width: '80px',
    render: (text, record) => {
      return <ActionButton id={record.id} record={record} onEdit={onEdit} onDelete={onDelete} module={'GroupValue'} />;
    }
  }
];
