import { Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateProduct } from '../../../helpers/hookProduct';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD, PREFIX } from '../constants';
import { fieldFromRecord, mapFormDataToCreateRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { error: errorCreateProduct, post: createProduct } = useCreateProduct();

  useHandleErrorCode(APP_MESSAGE.product.name, [errorCreateProduct], APP_MESSAGE.product.createError);

  const onCreate = useCallback(
    (values) => {
      createProduct({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.product.createSuccess, type: 'success' });
          history.push(`${ROUTER_URL.PRODUCT_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createProduct]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.PRODUCT_FIND);
  }, [history]);

  useEffect(() => {
    form.setFieldsValue({
      [`${PREFIX}_${FORM_FIELD?.isRequiredVip?.name}`]: true,
      [`${PREFIX}_${FORM_FIELD?.isViewMobile?.name}`]: true
    });
  }, []);

  const onSave = () => {
    form.validateFields().then((values) => {
      if (values[`${PREFIX}_${FORM_FIELD?.amount?.name}`] < 0) {
        form.setFields([
          {
            name: `${PREFIX}_${FORM_FIELD?.amount?.name}`,
            errors: ['Vui lòng nhập số dương']
          }
        ]);
        return;
      }
      onCreate(mapFormDataToCreateRecord({ ...values }));
    });
  };

  const onChangeProductTitleId = (value) => {
    value?.label &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD?.title?.name}`]: value.label
      });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.product.name}`} footerCreatorVisible module="Product">
      <CustomForm
        name="product"
        module="Product"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.product.createConfirm}
        contentCancel={APP_MESSAGE.product.createCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            form={form}
            onChangeProductTitleId={onChangeProductTitleId}
            requiredItems={[
              FORM_FIELD.categoryId.name,
              FORM_FIELD.branchId.name,
              FORM_FIELD.title.name,
              FORM_FIELD.isActive.name,
              FORM_FIELD.type.name,
              FORM_FIELD.orderNumber.name,
              FORM_FIELD.amount.name,
              FORM_FIELD.deliveryId.name,
              FORM_FIELD.guaranteeId.name,
              FORM_FIELD.warrantyPeriodId.name,
              FORM_FIELD.importPrice.name
              // FORM_FIELD.price.name,
              // FORM_FIELD.price1.name,
              // FORM_FIELD.price2.name,
              // FORM_FIELD.price3.name
            ]}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
