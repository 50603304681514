import { Button, Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import { FORM_FIELD, PREFIX } from '../../constants';

const AmountInfo = ({
  requiredItems = [],
  disabledItems = [],
  disabled,
  onHandleUpdateSerial,
  onHandleResetSerial
}) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.amount.name}
          label={FORM_FIELD.amount.label}
          required={requiredItems.includes(FORM_FIELD.amount.name)}
          disabled={disabledItems.includes(FORM_FIELD.amount.name) || disabled}
        />
      </Col>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.inventoryAmount.name}
          label={FORM_FIELD.inventoryAmount.label}
          type={'number'}
          min={0}
          required={requiredItems.includes(FORM_FIELD.inventoryAmount.name)}
          disabled={disabledItems.includes(FORM_FIELD.inventoryAmount.name) || disabled}
        />
      </Col>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.soldAmount.name}
          label={FORM_FIELD.soldAmount.label}
          required={requiredItems.includes(FORM_FIELD.soldAmount.name)}
          disabled={disabledItems.includes(FORM_FIELD.soldAmount.name) || disabled}
        />
      </Col>
      <Col span={8} offset={3} style={{ paddingLeft: '6px' }}>
        <Button type="default" onClick={() => onHandleUpdateSerial()} className="edit-btn">
          Cập nhật Serial number
        </Button>
        <Button type="default" onClick={() => onHandleResetSerial()} style={{ marginLeft: '20px' }} className="add-btn">
          Reset
        </Button>
      </Col>
    </Row>
  );
};

export default AmountInfo;
