import { isEmpty } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdateAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdateAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdateAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdateAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    joinDayFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToJoinDay?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToJoinDay?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    joinDayTo: payload[`${PREFIX}_${FORM_FIELD?.fromToJoinDay?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToJoinDay?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    name: payload[`${PREFIX}_${FORM_FIELD?.name?.name}`] || undefined,
    email: payload[`${PREFIX}_${FORM_FIELD?.email?.name}`] || undefined,
    type: payload[`${PREFIX}_${FORM_FIELD?.type?.name}`] || undefined,
    phone: payload[`${PREFIX}_${FORM_FIELD?.phone?.name}`] || undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`]
  };
};

export const mapFormDataToCreateRecord = (values) => {
  const avatar = values[`${PREFIX}_${FORM_FIELD?.avatar?.name}`];
  return {
    name: values[`${PREFIX}_${FORM_FIELD?.name?.name}`] || null,
    email: values[`${PREFIX}_${FORM_FIELD?.email?.name}`] || null,
    type: values[`${PREFIX}_${FORM_FIELD?.type?.name}`] || null,
    phone: values[`${PREFIX}_${FORM_FIELD?.phone?.name}`] || null,
    birthday: values[`${PREFIX}_${FORM_FIELD?.birthday?.name}`]
      ? moment(values[`${PREFIX}_${FORM_FIELD?.birthday?.name}`]).format(DATE_FORMAT_FROM_SERVER)
      : null,
    joinDay: values[`${PREFIX}_${FORM_FIELD?.joinDay?.name}`]
      ? moment(values[`${PREFIX}_${FORM_FIELD?.joinDay?.name}`]).format(DATE_FORMAT_FROM_SERVER)
      : null,
    avatar: !isEmpty(avatar) ? avatar[0] : null,
    roleName: values[`${PREFIX}_${FORM_FIELD?.roleName?.name}`] || null,
    roleId: values[`${PREFIX}_${FORM_FIELD?.roleId?.name}`] || null,
    orderNumber: values[`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`] || null,
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`]
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.name?.name}`]: record?.name,
    [`${PREFIX}_${FORM_FIELD?.email?.name}`]: record?.email,
    [`${PREFIX}_${FORM_FIELD?.type?.name}`]: record?.type,
    [`${PREFIX}_${FORM_FIELD?.phone?.name}`]: record?.phone,
    [`${PREFIX}_${FORM_FIELD?.birthday?.name}`]: record?.birthday ? moment(record?.birthday) : null,
    [`${PREFIX}_${FORM_FIELD?.joinDay?.name}`]: record?.joinDay ? moment(record?.joinDay) : null,
    [`${PREFIX}_${FORM_FIELD?.avatar?.name}`]: record?.avatar,
    [`${PREFIX}_${FORM_FIELD?.roleName?.name}`]: record?.roleName,
    [`${PREFIX}_${FORM_FIELD?.roleId?.name}`]: record?.roleId,
    [`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`]: record?.orderNumber || 100,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive
  };
};
