import { Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import { PREFIX, FORM_FIELD } from '../../constants';
import CardForm from '../../../../common/components/CardForm';
import SelectBoxPageQuickSearch from '../../../../common/components/SelectBox/SelectBoxPost';
import { ECategoryType } from '../../../Category/constants';

const FooterInfo = ({ disabled, requiredItems = [], disabledItems = [] }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <CardForm title="Hỗ Trợ Mua Hàng">
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.purchaseMethodLink.name}
            label={FORM_FIELD.footerInfo.purchaseMethodLink.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.purchaseMethodLink.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.purchaseMethodLink.name) || disabled}
            component={
              <SelectBoxPageQuickSearch
                type={ECategoryType.page}
                prefix={PREFIX}
                name={FORM_FIELD.footerInfo.purchaseMethodLink.name}
                required={requiredItems.includes(FORM_FIELD.footerInfo.purchaseMethodLink.name)}
                disabled={disabledItems.includes(FORM_FIELD.footerInfo.purchaseMethodLink.name) || disabled}
              />
            }
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.guaranteeLink.name}
            label={FORM_FIELD.footerInfo.guaranteeLink.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.guaranteeLink.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.guaranteeLink.name) || disabled}
            component={
              <SelectBoxPageQuickSearch
                type={ECategoryType.page}
                prefix={PREFIX}
                name={FORM_FIELD.footerInfo.guaranteeLink.name}
                required={requiredItems.includes(FORM_FIELD.footerInfo.guaranteeLink.name)}
                disabled={disabledItems.includes(FORM_FIELD.footerInfo.guaranteeLink.name) || disabled}
              />
            }
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.paymentAndDeliveryLink.name}
            label={FORM_FIELD.footerInfo.paymentAndDeliveryLink.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.paymentAndDeliveryLink.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.paymentAndDeliveryLink.name) || disabled}
            component={
              <SelectBoxPageQuickSearch
                type={ECategoryType.page}
                prefix={PREFIX}
                name={FORM_FIELD.footerInfo.paymentAndDeliveryLink.name}
                required={requiredItems.includes(FORM_FIELD.footerInfo.paymentAndDeliveryLink.name)}
                disabled={disabledItems.includes(FORM_FIELD.footerInfo.paymentAndDeliveryLink.name) || disabled}
              />
            }
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.repairAndMaintenanceLink.name}
            label={FORM_FIELD.footerInfo.repairAndMaintenanceLink.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.repairAndMaintenanceLink.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.repairAndMaintenanceLink.name) || disabled}
            component={
              <SelectBoxPageQuickSearch
                type={ECategoryType.page}
                prefix={PREFIX}
                name={FORM_FIELD.footerInfo.repairAndMaintenanceLink.name}
                required={requiredItems.includes(FORM_FIELD.footerInfo.repairAndMaintenanceLink.name)}
                disabled={disabledItems.includes(FORM_FIELD.footerInfo.repairAndMaintenanceLink.name) || disabled}
              />
            }
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.privacyPolicyLink.name}
            label={FORM_FIELD.footerInfo.privacyPolicyLink.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.privacyPolicyLink.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.privacyPolicyLink.name) || disabled}
            component={
              <SelectBoxPageQuickSearch
                type={ECategoryType.page}
                prefix={PREFIX}
                name={FORM_FIELD.footerInfo.privacyPolicyLink.name}
                required={requiredItems.includes(FORM_FIELD.footerInfo.privacyPolicyLink.name)}
                disabled={disabledItems.includes(FORM_FIELD.footerInfo.privacyPolicyLink.name) || disabled}
              />
            }
          />
        </CardForm>
        <CardForm title="Giới thiệu">
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.mst.name}
            label={FORM_FIELD.footerInfo.mst.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.mst.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.mst.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.address.name}
            label={FORM_FIELD.footerInfo.address.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.address.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.address.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.emailSupport.name}
            label={FORM_FIELD.footerInfo.emailSupport.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.emailSupport.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.emailSupport.name) || disabled}
          />
        </CardForm>
        <CardForm title="Ngân hàng">
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.bankVietcombank.name}
            label={FORM_FIELD.footerInfo.bankVietcombank.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.bankVietcombank.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.bankVietcombank.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.bankTechcombank.name}
            label={FORM_FIELD.footerInfo.bankTechcombank.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.bankTechcombank.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.bankTechcombank.name) || disabled}
          />
        </CardForm>
      </Col>
      <Col span={12}>
        <CardForm title="Bảo hành, bảo trì, vệ sinh máy, mua bán máy dưới 45tr, xin vui lòng ghé:">
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.emailSupport1.name}
            label={FORM_FIELD.footerInfo.emailSupport1.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.emailSupport1.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.emailSupport1.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.hotline1.name}
            label={FORM_FIELD.footerInfo.hotline1.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.hotline1.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.hotline1.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.workingTime1.name}
            label={FORM_FIELD.footerInfo.workingTime1.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.workingTime1.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.workingTime1.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.address1.name}
            label={FORM_FIELD.footerInfo.address1.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.address1.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.address1.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.googleMap1.name}
            label={FORM_FIELD.footerInfo.googleMap1.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.googleMap1.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.googleMap1.name) || disabled}
          />
        </CardForm>
        <CardForm title="Cần tư vấn máy cao cấp, modding, cnc, xin vui lòng ghé:">
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.emailSupport2.name}
            label={FORM_FIELD.footerInfo.emailSupport2.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.emailSupport2.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.emailSupport2.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.hotline2.name}
            label={FORM_FIELD.footerInfo.hotline2.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.hotline2.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.hotline2.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.workingTime2.name}
            label={FORM_FIELD.footerInfo.workingTime2.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.workingTime2.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.workingTime2.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.address2.name}
            label={FORM_FIELD.footerInfo.address2.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.address2.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.address2.name) || disabled}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.footerInfo.googleMap2.name}
            label={FORM_FIELD.footerInfo.googleMap2.label}
            required={requiredItems.includes(FORM_FIELD.footerInfo.googleMap2.name)}
            disabled={disabledItems.includes(FORM_FIELD.footerInfo.googleMap2.name) || disabled}
          />
        </CardForm>
      </Col>
    </Row>
  );
};

export default FooterInfo;
