import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/users`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/users/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/users`, { params });
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/users/${id}`, body);
};
