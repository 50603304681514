import { Col, Row } from 'antd';
import React from 'react';
import CartStatus from '../../../../common/components/CartStatus';
import FormItem from '../../../../common/components/FormItem';
import { FormCreateCartLayout } from '../../../../constants/form';
import { FORM_FIELD, PREFIX } from '../../constants';

const GeneralInfo = ({ disabled, requiredItems = [], disabledItems = [], status }) => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.status.name}
          label={FORM_FIELD.status.label}
          component={<CartStatus status={status} />}
        />

        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.userName.name}
          label={FORM_FIELD.userName.label}
          required={requiredItems.includes(FORM_FIELD.userName.name)}
          disabled={disabledItems.includes(FORM_FIELD.userName.name) || disabled}
        />

        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.userEmail.name}
          label={FORM_FIELD.userEmail.label}
          required={requiredItems.includes(FORM_FIELD.userEmail.name)}
          disabled={disabledItems.includes(FORM_FIELD.userEmail.name) || disabled}
        />

        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.userPhone.name}
          label={FORM_FIELD.userPhone.label}
          required={requiredItems.includes(FORM_FIELD.userPhone.name)}
          disabled={disabledItems.includes(FORM_FIELD.userPhone.name) || disabled}
        />

        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          type="textArea"
          rows={4}
          name={FORM_FIELD.deliveryAddress.name}
          label={FORM_FIELD.deliveryAddress.label}
          required={requiredItems.includes(FORM_FIELD.deliveryAddress.name)}
          disabled={disabledItems.includes(FORM_FIELD.deliveryAddress.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.totalAmount.name}
          label={FORM_FIELD.totalAmount.label}
          disabled
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.totalPrice.name}
          label={FORM_FIELD.totalPrice.label}
          disabled
          type="CurrencyVNDInput"
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.voucherPrice.name}
          label={FORM_FIELD.voucherPrice.label}
          disabled={disabledItems.includes(FORM_FIELD.voucherPrice.name) || disabled}
          type="CurrencyVNDInput"
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.shipPrice.name}
          label={FORM_FIELD.shipPrice.label}
          disabled={disabledItems.includes(FORM_FIELD.shipPrice.name) || disabled}
          type="CurrencyVNDInput"
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.finalPrice.name}
          label={FORM_FIELD.finalPrice.label}
          disabled
          type="CurrencyVNDInput"
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.importPrice.name}
          label={FORM_FIELD.importPrice.label}
          disabled
          type="CurrencyVNDInput"
        />
        <FormItem
          showUnit
          prefix={PREFIX}
          {...FormCreateCartLayout}
          name={FORM_FIELD.profitPrice.name}
          label={FORM_FIELD.profitPrice.label}
          disabled
          type="CurrencyVNDInput"
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
