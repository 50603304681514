const FormItemLayoutArea = {
  labelCol: {
    span: 6
  },
  wrapperCol: {
    span: 18
  },
  labelAlign: 'left'
};

const FormCreateCartLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  },
  labelAlign: 'left'
};
const FormCreateProductLayout = {
  labelCol: {
    span: 12
  },
  wrapperCol: {
    span: 16
  },
  labelAlign: 'left'
};

const FormType = {
  create: 'create',
  update: 'update',
  view: 'view'
};

const FormCreateType = FormType.create;
const FormUpdateType = FormType.update;
const FormViewType = FormType.view;

export {
  FormCreateCartLayout,
  FormItemLayoutArea,
  FormType,
  FormCreateType,
  FormUpdateType,
  FormViewType,
  FormCreateProductLayout
};
