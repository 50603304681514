import { Table } from 'antd';
import React from 'react';
import CardForm from '../../../common/components/CardForm';

const TableComponent = ({ title, color, columns, dataSource }) => {
  return (
    <CardForm title={title} style={{ width: '100%' }} headStyle={{ color: color }}>
      <Table pagination={false} dataSource={dataSource} columns={columns} style={{ minHeight: '180px' }} />
    </CardForm>
  );
};

export default TableComponent;
