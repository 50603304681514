import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER, DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';
import { makeKey, textAreaReplaceNewLine } from '../../utils/index';

export const mapFormDataToSearchRecord = (payload, status) => {
  return {
    description: payload[`${PREFIX}_${FORM_FIELD?.description?.name}`],
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    paymentDateFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    paymentDateTo: payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToPaymentDate?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    status,
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`],
    userId: payload[`${PREFIX}_${FORM_FIELD?.userId?.name}`],
    userName: payload[`${PREFIX}_${FORM_FIELD?.userName?.name}`],
    userEmail: payload[`${PREFIX}_${FORM_FIELD?.userEmail?.name}`],
    userPhone: payload[`${PREFIX}_${FORM_FIELD?.userPhone?.name}`],
    deliveryAddress: payload[`${PREFIX}_${FORM_FIELD?.deliveryAddress?.name}`],
    code: payload[`${PREFIX}_${FORM_FIELD?.code?.name}`],
    priceFrom: payload[`${PREFIX}_${FORM_FIELD?.priceFrom?.name}`],
    priceTo: payload[`${PREFIX}_${FORM_FIELD?.priceTo?.name}`],
    shipPriceFrom: payload[`${PREFIX}_${FORM_FIELD?.shipPriceFrom?.name}`],
    shipPriceTo: payload[`${PREFIX}_${FORM_FIELD?.shipPriceTo?.name}`],
    voucherPriceFrom: payload[`${PREFIX}_${FORM_FIELD?.voucherPriceFrom?.name}`],
    voucherPriceTo: payload[`${PREFIX}_${FORM_FIELD?.voucherPriceTo?.name}`]
  };
};

export const mapFormDataToCreateRecord = (values) => {
  return {
    deliveryAddress: values[`${PREFIX}_${FORM_FIELD?.deliveryAddress?.name}`] || null,
    isNewUser: values[`${PREFIX}_${FORM_FIELD?.isNewUser?.name}`] || false,
    userId: values[`${PREFIX}_${FORM_FIELD?.userId?.name}`] || null,
    password: values[`${PREFIX}_${FORM_FIELD?.password?.name}`],
    isSendPassword: values[`${PREFIX}_${FORM_FIELD?.isSendPassword?.name}`],
    userName: values[`${PREFIX}_${FORM_FIELD?.userName?.name}`] || null,
    userEmail: values[`${PREFIX}_${FORM_FIELD?.userEmail?.name}`] || null,
    userPhone: values[`${PREFIX}_${FORM_FIELD?.userPhone?.name}`] || null,
    cartDetails: values.products.map((item) => ({
      productId: item.id,
      serialIds: typeof item.serialIds === 'number' ? [item.serialIds] : item.serialIds,
      amount: item.amountInput,
      priceType: item.priceType,
      price: item.priceInput
    })),
    amount: values[`${PREFIX}_${FORM_FIELD?.totalAmount?.name}`] || 0,
    price: values[`${PREFIX}_${FORM_FIELD?.totalPrice?.name}`] || 0,
    shipPrice: values[`${PREFIX}_${FORM_FIELD?.shipPrice?.name}`] || 0,
    voucherPrice: values[`${PREFIX}_${FORM_FIELD?.voucherPrice?.name}`] || 0,
    finalPrice: values[`${PREFIX}_${FORM_FIELD?.finalPrice?.name}`] || 0,
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`] || null
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.totalAmount?.name}`]: record?.amount || 0,
    [`${PREFIX}_${FORM_FIELD?.totalPrice?.name}`]: record?.price || 0,
    [`${PREFIX}_${FORM_FIELD?.voucherPrice?.name}`]: record?.voucherPrice || 0,
    [`${PREFIX}_${FORM_FIELD?.shipPrice?.name}`]: record?.shipPrice || 0,
    [`${PREFIX}_${FORM_FIELD?.finalPrice?.name}`]: record?.finalPrice || 0,
    [`${PREFIX}_${FORM_FIELD?.importPrice?.name}`]: record?.importPrice || 0,
    [`${PREFIX}_${FORM_FIELD?.profitPrice?.name}`]: record?.finalPrice - record?.importPrice || 0,
    [`${PREFIX}_${FORM_FIELD?.voucherCode?.name}`]: record?.voucher?.code,
    [`${PREFIX}_${FORM_FIELD?.code?.name}`]: record?.code,
    [`${PREFIX}_${FORM_FIELD?.createdAt?.name}`]: record?.createdAt
      ? moment(record?.createdAt).format(DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS)
      : null,
    [`${PREFIX}_${FORM_FIELD?.isNewUser?.name}`]: false,
    [`${PREFIX}_${FORM_FIELD?.userId?.name}`]: record?.userId,
    [`${PREFIX}_${FORM_FIELD?.password?.name}`]: makeKey(10),
    [`${PREFIX}_${FORM_FIELD?.userName?.name}`]: record?.userName,
    [`${PREFIX}_${FORM_FIELD?.userEmail?.name}`]: record?.userEmail || record?.user?.email,
    [`${PREFIX}_${FORM_FIELD?.userPhone?.name}`]: record?.userPhone || record?.user?.phone,
    [`${PREFIX}_${FORM_FIELD?.deliveryAddress?.name}`]: record?.deliveryAddress || record?.user?.address,
    [`${PREFIX}_${FORM_FIELD?.paymentDate?.name}`]: record?.paymentDate ? moment(record?.paymentDate) : null,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: textAreaReplaceNewLine(record?.description)
  };
};

export const getPriceByType = (record, priceType) => {
  let price = record.price || 0;
  switch (priceType) {
    case 'BH1':
      price = +record.price1 || 0;
      break;
    case 'BH2':
      price = +record.price2 || 0;
      break;
    case 'BH3':
      price = +record.price3 || 0;
      break;

    default:
      break;
  }
  return price;
};

export const getPriceDefault = (record) => {
  if (record.price1) return record.price1;
  if (record.price2) return record.price2;
  if (record.price3) return record.price3;
  return record.price || 0;
};

export const getTypeDefault = (record) => {
  if (record.price1) return 'BH1';
  if (record.price2) return 'BH2';
  if (record.price3) return 'BH3';
  return 'Default';
};

export const getPriceTypeOption = (record) => {
  let priceTypes = [];
  if (record.price1) priceTypes.push('BH1');
  if (record.price2) priceTypes.push('BH2');
  if (record.price3) priceTypes.push('BH3');

  return priceTypes;
};

export const getSerialDefault = (record, serialSearchProductId, serialCode, serialNumbers) => {
  if (!record) return null;
  if (!serialSearchProductId) return null;
  if (!serialCode) return null;
  if (!serialNumbers) return null;
  if (record.id !== serialSearchProductId) return null;

  const serial = serialNumbers.find((item) => item.title === serialCode);

  return serial ? [serial.id] : null;
};
