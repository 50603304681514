import { Form } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useGetListSerialNumber } from '../../../helpers/hookSerialNumber';
import { useRemoteTable } from '../../../helpers/hookTable';
import ListSearch from '../components/listSearch';
import { SEARCH_DEFAULT } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';

const Find = () => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const { get: getListSerialNumber } = useGetListSerialNumber();

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  const { loading, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, null],
    promiseFn: getListSerialNumber
  });

  useHandleErrorCode(APP_MESSAGE.serialNumber.name, [errorList], APP_MESSAGE.serialNumber.missData);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    (values) => {
      setSearchParams(mapFormDataToSearchRecord(values));
    },
    [setSearchParams]
  );

  const columns = useMemo(() => getTableColumns({ page, order }), [order, page]);

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.serialNumber.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <RemoteTable
          columns={columns}
          data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
          loading={loading}
          page={page}
          limit={limit}
          total={data?.total ?? 0}
          onChange={onChange(() => {})}
          rowKey="id"
        />
      </CardForm>
    </PageContent>
  );
};

export default Find;
