/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import ActionButton from '../../../common/components/ActionButton';
import ActiveLabel from '../../../common/components/ActiveLabel';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';
import { toFormatCurrencyVND } from '../../../utils/numberFormat';

export const getTableColumns = ({ onEdit, onDelete, page, limit }) => [
  {
    title: 'STT',
    fixed: 'left',
    align: 'center',
    render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
  },
  {
    title: 'Mã voucher',
    fixed: 'left',
    render: (text, record) => <Typography.Link onClick={() => onEdit(record.id)}>{record.code}</Typography.Link>
  },

  {
    title: 'Phần trăm giảm (%)',
    align: 'center',
    dataIndex: 'percentReduction'
  },
  {
    title: 'Số tiền giảm (đ)',
    align: 'right',
    render: (text, record) => toFormatCurrencyVND(record.priceReduction)
  },
  {
    title: 'Giới hạn giảm (đ)',
    align: 'right',
    render: (text, record) => toFormatCurrencyVND(record.priceLimit)
  },
  {
    title: 'Ngày hiệu lực',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.fromDate, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Ngày hết hiệu lực',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.toDate, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Số lượng',
    align: 'center',
    dataIndex: 'amount'
  },
  {
    title: 'Đã dùng',
    align: 'center',
    dataIndex: 'useCount'
  },
  // {
  //   title: 'Người tạo',
  //   dataIndex: ['createdBy', 'name']
  // },
  {
    title: 'Người cập nhật',
    dataIndex: ['updatedBy', 'name']
  },
  {
    title: 'Tình Trạng',
    align: 'center',
    render: (text, record) => <ActiveLabel isActive={record.isActive} />
  },
  {
    title: 'Hành động',
    fixed: 'right',
    dataIndex: undefined,
    width: '80px',
    render: (text, record) => {
      return (
        <ActionButton id={record.id} record={record} onEdit={onEdit} onDelete={record.useCount ? null : onDelete} />
      );
    }
  }
];
