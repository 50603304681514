import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER, DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    fromDate:
      payload[`${PREFIX}_${FORM_FIELD?.fromToDate?.name}`] && payload[`${PREFIX}_${FORM_FIELD?.fromToDate?.name}`][0]
        ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToDate?.name}`][0]).format(
            DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS
          )
        : undefined,
    toDate:
      payload[`${PREFIX}_${FORM_FIELD?.fromToDate?.name}`] && payload[`${PREFIX}_${FORM_FIELD?.fromToDate?.name}`][1]
        ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToDate?.name}`][1]).format(
            DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS
          )
        : undefined,
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    userId: payload[`${PREFIX}_${FORM_FIELD?.userId?.name}`] || undefined,
    productId: payload[`${PREFIX}_${FORM_FIELD?.productId?.name}`] || undefined,
    code: payload[`${PREFIX}_${FORM_FIELD?.code?.name}`] || undefined,
    amount: payload[`${PREFIX}_${FORM_FIELD?.amount?.name}`] || undefined,
    percentReduction: payload[`${PREFIX}_${FORM_FIELD?.percentReduction?.name}`] || undefined,
    priceReduction: payload[`${PREFIX}_${FORM_FIELD?.priceReduction?.name}`] || undefined,
    description: payload[`${PREFIX}_${FORM_FIELD?.description?.name}`] || undefined
  };
};

export const mapFormDataToCreateRecord = (values) => {
  return {
    type: values[`${PREFIX}_${FORM_FIELD?.type?.name}`] || 'Common',
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    userId: values[`${PREFIX}_${FORM_FIELD?.userId?.name}`] || null,
    productId: values[`${PREFIX}_${FORM_FIELD?.productId?.name}`] || null,
    code: values[`${PREFIX}_${FORM_FIELD?.code?.name}`] || null,
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`] || null,
    amount: values[`${PREFIX}_${FORM_FIELD?.amount?.name}`] || null,
    percentReduction: values[`${PREFIX}_${FORM_FIELD?.percentReduction?.name}`] || null,
    priceReduction: values[`${PREFIX}_${FORM_FIELD?.priceReduction?.name}`] || null,
    priceLimit: values[`${PREFIX}_${FORM_FIELD?.priceLimit?.name}`] || null,
    fromDate: values[`${PREFIX}_${FORM_FIELD?.fromDate?.name}`]
      ? moment(values[`${PREFIX}_${FORM_FIELD?.fromDate?.name}`]).format(DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS)
      : null,
    toDate: values[`${PREFIX}_${FORM_FIELD?.toDate?.name}`]
      ? moment(values[`${PREFIX}_${FORM_FIELD?.toDate?.name}`]).format(DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS)
      : null
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.type?.name}`]: record?.type || 'Common',
    [`${PREFIX}_${FORM_FIELD?.priceType?.name}`]: record?.priceReduction ? 'Money' : 'Percent',
    [`${PREFIX}_${FORM_FIELD?.userId?.name}`]: record?.userId,
    [`${PREFIX}_${FORM_FIELD?.productId?.name}`]: record?.productId,
    [`${PREFIX}_${FORM_FIELD?.code?.name}`]: record?.code,
    [`${PREFIX}_${FORM_FIELD?.amount?.name}`]: record?.amount,
    [`${PREFIX}_${FORM_FIELD?.useCount?.name}`]: record?.useCount,
    [`${PREFIX}_${FORM_FIELD?.percentReduction?.name}`]: record?.percentReduction,
    [`${PREFIX}_${FORM_FIELD?.priceReduction?.name}`]: record?.priceReduction,
    [`${PREFIX}_${FORM_FIELD?.priceLimit?.name}`]: record?.priceLimit,
    [`${PREFIX}_${FORM_FIELD?.fromDate?.name}`]: record?.fromDate ? moment(record?.fromDate) : null,
    [`${PREFIX}_${FORM_FIELD?.toDate?.name}`]: record?.toDate ? moment(record?.toDate) : null,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: record?.description,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive,
    [`${PREFIX}_${FORM_FIELD?.price?.name}`]: record?.product?.price,
    [`${PREFIX}_${FORM_FIELD?.sellPrice?.name}`]: record?.product?.sellPrice
  };
};
