import { axiosInstance } from './common';

export const getCurrentUser = async () => {
  return await axiosInstance.get(`/accounts/me`);
};

export const create = async (body) => {
  return await axiosInstance.post(`/accounts`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/accounts/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/accounts/${id}`);
};

export const meDetail = async () => {
  return await axiosInstance.get(`/accounts/me`);
};

export const getAccountAll = async () => {
  return await axiosInstance.get(`/accounts/all`, { params: {} });
};

export const list = async (params) => {
  return await axiosInstance.get(`/accounts`, { params });
};

export const putChangePasswordAccount = async (body) => {
  return await axiosInstance.put(`/accounts/change-password`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/accounts/${id}`);
};

export const roleList = async () => {
  return await axiosInstance.get(`/accounts/roles`);
};
