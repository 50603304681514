import { Form, Modal, Tabs } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { customToast } from '../../../common/components/Toast';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import ListSearch from '../components/listSearch';
import { EPostStatus, SEARCH_DEFAULT } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';
import { useDeletePost, useGetCountPost, useGetListPost } from '../../../helpers/hookPost';
import { setLocalStorage } from '../../../utils/localStorage';
import { ECategoryType } from '../../Category/constants';

const Find = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const { get: getListPost } = useGetListPost();
  const { error: errorDelete, delete: deletePost } = useDeletePost();
  const [postCounts, setPostCounts] = useState(null);

  const { get: getCountPost } = useGetCountPost();

  const onGetCountPost = useCallback(async () => {
    const response = await getCountPost({});
    if (response) {
      setPostCounts(response);
    }
  }, [getCountPost]);

  useEffect(() => {
    onGetCountPost();
  }, [onGetCountPost]);

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  const { loading, reload, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc',
        categoryType: ECategoryType.post
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, null],
    promiseFn: getListPost
  });

  useHandleErrorCode(APP_MESSAGE.post.name, [errorList], APP_MESSAGE.post.missData);
  useHandleErrorCode(APP_MESSAGE.post.name, [errorDelete], APP_MESSAGE.post.deleteError);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onEdit = useCallback(
    (id) => {
      history.push(`${ROUTER_URL.POST_EDIT}/${id}`);
    },
    [history]
  );

  const onDelete = useCallback(
    (record) => {
      setLocalStorage('type-enter', 'modal');
      Modal.confirm({
        title: getMessageFromName(APP_MESSAGE.post.deleteConfirm, record.title),
        onOk: async () => {
          setLocalStorage('type-enter', '');
          deletePost(record.id).then((response) => {
            if (response?.success) {
              customToast({
                content: getMessageFromName(APP_MESSAGE.post.deleteSuccess, record.title),
                type: 'success'
              });
              reload();
            }
          });
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [deletePost, reload]
  );

  const onFinish = useCallback(
    (values) => {
      setSearchParams(mapFormDataToSearchRecord(values));
    },
    [setSearchParams]
  );

  const columns = useMemo(() => getTableColumns({ page, order, onEdit, onDelete, limit }), [
    page,
    order,
    onEdit,
    onDelete,
    limit
  ]);

  const onChangeTab = (value) => {
    setSearchParams((pre) => ({
      ...pre,
      status: value || undefined
    }));
  };

  const getContent = () => {
    return (
      <RemoteTable
        columns={columns}
        data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
        loading={loading}
        page={page}
        limit={limit}
        total={data?.total ?? 0}
        onChange={onChange(() => {})}
        rowKey="id"
        scroll={{
          x: 1800
        }}
      />
    );
  };

  const items = [
    {
      key: '',
      label: `Tất cả (${postCounts?.total || 0})`,
      children: getContent()
    },
    {
      key: EPostStatus.New,
      label: `Mới tạo (${postCounts?.new || 0})`,
      children: getContent()
    },
    {
      key: EPostStatus.Updating,
      label: `Đang cập nhật (${postCounts?.updating || 0})`,
      children: getContent()
    },
    {
      key: EPostStatus.Pending,
      label: `Chờ duyệt (${postCounts?.pedding || 0})`,
      children: getContent()
    },
    {
      key: EPostStatus.Success,
      label: `Chờ phát hành (${postCounts?.success || 0})`,
      children: getContent()
    },
    {
      key: EPostStatus.Published,
      label: `Đã đăng (${postCounts?.published || 0})`,
      children: getContent()
    },
    {
      key: EPostStatus.Reject,
      label: `Từ chối (${postCounts?.reject || 0})`,
      children: getContent()
    }
  ];

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.post.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <Tabs defaultActiveKey="" items={items} onChange={onChangeTab} />
      </CardForm>
    </PageContent>
  );
};

export default Find;
