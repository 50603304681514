export const PREFIX = 'postComment';

export const FORM_FIELD = {
  email: { name: 'email', label: 'Email' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: undefined
};
