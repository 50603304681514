import { Col, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import SelectBoxGroupValue from '../../../../common/components/SelectBox/SelectBoxGroupValue';
import { FORM_FIELD, PREFIX } from '../../constants';

const GeneralInfo = ({ disabled, requiredItems = [], disabledItems = [] }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <SelectBoxGroupValue
          prefix={PREFIX}
          name={FORM_FIELD.groupId.name}
          label={FORM_FIELD.groupId.label}
          required={requiredItems.includes(FORM_FIELD.groupId.name)}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.valueCode.name}
          label={FORM_FIELD.valueCode.label}
          required={requiredItems.includes(FORM_FIELD.valueCode.name)}
          disabled={disabledItems.includes(FORM_FIELD.valueCode.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.valueName.name}
          label={FORM_FIELD.valueName.label}
          required={requiredItems.includes(FORM_FIELD.valueName.name)}
          disabled={disabledItems.includes(FORM_FIELD.valueName.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
        <SelectBoxStatus prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.isActive.name)} />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
