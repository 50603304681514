import { Image } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const EImage = ({ imageUrl }) => {
  return imageUrl ? <Image width={80} src={imageUrl} /> : 'N/A';
};

EImage.propTypes = {
  imageUrl: PropTypes.boolean
};

const MemoizedEImage = React.memo(EImage);
export default MemoizedEImage;
