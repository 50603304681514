import React from 'react';
import { contentTypeOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxContentType = ({ prefix = '', name = 'type', label = 'Loại', ...props }) => {
  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="select"
      options={contentTypeOptions}
      allowClear
      {...props}
    />
  );
};

SelectBoxContentType.propTypes = propTypes;

export default SelectBoxContentType;
