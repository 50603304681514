/**
 * @file auth
 */
import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { login, loginConfirm, logout, forgotPassword, changePassword } from '../../api/auth';
import { getCurrentUser } from '../../api/account';
import { hideLoading, showLoading } from '../../common/globalLoading/actions';
import ROUTER_URL from '../../constants/router';
import { setLocalToken, removeLocalToken } from '../../utils/localStorage';
import * as actions from './actions';
import { successModal, errorModal } from '../../common/components/Modal';
// import { getInfoApp } from '../../api/info';

export function* handleLogin(action) {
  const { payload } = action;
  try {
    yield put(showLoading());
    const { data: authObject } = yield call(login, payload);
    if (authObject?.data?.accessToken) {
      setLocalToken(authObject?.data?.accessToken);
      yield put(push(authObject?.data?.redirectUrl));
      yield put(actions.loginSuccess());
    } else if (authObject?.errors?.length) {
      yield put(actions.loginFailed(authObject?.errors[0].code));
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
  } finally {
    yield put(hideLoading());
  }
}

export function* handleLoginConfirm(action) {
  const { payload } = action;
  try {
    yield put(showLoading());
    const { data: authObject } = yield call(loginConfirm, payload);
    if (authObject?.data?.accessToken) {
      setLocalToken(authObject?.data?.accessToken);
      yield put(push(authObject?.data?.redirectUrl));
      yield put(actions.loginSuccess());
    } else if (authObject?.errors?.length) {
      yield put(actions.loginFailed(authObject?.errors[0].code));
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
  } finally {
    yield put(hideLoading());
  }
}

export function* handleLogout() {
  yield put(showLoading());

  try {
    const { data } = yield call(logout);
    if (data?.data?.success) {
      removeLocalToken();

      yield put(push(ROUTER_URL.LOGIN));
      yield put(actions.logoutSuccess());
    } else {
      actions.logoutFailed();
    }
  } catch (error) {
    yield put(actions.logoutFailed(error));
  } finally {
    yield put(hideLoading());
  }
}

export function* handleResetPassword(action) {
  const { payload } = action;
  yield put(showLoading());
  try {
    const response = yield call(forgotPassword, payload);

    if (response?.data?.errors?.length) {
      errorModal({
        content: 'Gửi email thay đổi mật khẩu thất bại'
      });
    } else {
      successModal({
        content: 'Gửi email thay đổi mật khẩu thành công. Link đổi mật khẩu đã được gửi vào email của bạn.'
      });
    }
  } catch (error) {
    errorModal({
      content: 'Gửi email thay đổi mật khẩu thất bại'
    });
  } finally {
    yield put(hideLoading());
  }
}

export function* handleGetCurrentUser() {
  yield put(showLoading());
  try {
    const {
      data: { data }
    } = yield call(getCurrentUser);
    yield put(actions.getCurrentUserSuccess(data.user));

    // const { data: infoApp } = yield call(getInfoApp);
    // yield put(actions.storeInfoApp(infoApp?.data));
  } catch (error) {
    yield put(push(ROUTER_URL.LOGIN));
  } finally {
    yield put(hideLoading());
  }
}

export function* handleChangePassword(action) {
  const { payload } = action;
  yield put(showLoading());
  try {
    yield call(changePassword, payload);
    successModal({
      content: 'Thay đổi mật khẩu thành công'
    });
    yield put(actions.logoutPending());
  } catch (error) {
    errorModal({
      content: 'Thay đổi mật khẩu thất bại'
    });
  } finally {
    yield put(hideLoading());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.loginPending, handleLogin),
    takeLatest(actions.logoutPending, handleLogout),
    takeLatest(actions.resetPasswordPending, handleResetPassword),
    takeLatest(actions.loginConfirmPending, handleLoginConfirm),
    takeLatest(actions.getCurrentUserPending, handleGetCurrentUser),
    takeLatest(actions.changePassword, handleChangePassword)
  ]);
}
