import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateFeedback } from '../../../helpers/hookFeedback';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { error: errorCreateFeedback, post: createFeedback } = useCreateFeedback();

  useHandleErrorCode(APP_MESSAGE.feedback.name, [errorCreateFeedback], APP_MESSAGE.feedback.createError);

  const onCreate = useCallback(
    (values) => {
      createFeedback({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.feedback.createSuccess, type: 'success' });

          history.push(`${ROUTER_URL.FEEDBACK_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createFeedback]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.FEEDBACK_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.feedback.name}`} footerCreatorVisible>
      <CustomForm
        name="feedback"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.feedback.createConfirm}
        contentCancel={APP_MESSAGE.feedback.createCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo requiredItems={[FORM_FIELD.mvFunctionId.name, FORM_FIELD.name.name]} />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
