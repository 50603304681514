/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';

export const getTableColumns = ({ page, limit }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    fixed: 'left',
    render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Nội dung',
    width: '400px',
    dataIndex: 'content'
  },
  {
    title: 'Khách hàng nhận',
    dataIndex: ['user', 'name']
  },
  {
    title: 'Nhân viên nhận',
    dataIndex: ['account', 'name']
  }
];
