import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDetailGroupValue, useUpdateGroupValue } from '../../../helpers/hookGroupValue';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const { error: errorDetailGroupValue, get: detailGroupValue } = useDetailGroupValue();
  const { error: errorUpdateGroupValue, put: updateGroupValue } = useUpdateGroupValue(id);

  useHandleErrorCode(APP_MESSAGE.groupValue.name, [errorDetailGroupValue], APP_MESSAGE.groupValue.detailError);
  useHandleErrorCode(APP_MESSAGE.groupValue.name, [errorUpdateGroupValue], APP_MESSAGE.groupValue.editError);

  const onGetDetail = useCallback(async () => {
    const response = await detailGroupValue(id);
    if (response) {
      setData(response);
    }
  }, [detailGroupValue, id]);

  const onUpdate = useCallback(
    (values) => {
      updateGroupValue(id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.groupValue.editSuccess, type: 'success' });
          history.push(`${ROUTER_URL.GROUP_VALUE_EDIT}/${id}`);
        }
      });
    },
    [history, id, updateGroupValue]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.GROUP_VALUE_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onUpdate(mapFormDataToCreateRecord(values));
    });
  };

  const onCreate = () => {
    history.push(ROUTER_URL.GROUP_VALUE_CREATE);
  };

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.groupValue.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
      onCreate={onCreate}
    >
      <CustomForm
        name="groupValue"
        module="GroupValue"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.groupValue.editConfirm}
        contentCancel={APP_MESSAGE.groupValue.editCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo requiredItems={[FORM_FIELD.code.name, FORM_FIELD.name.name, FORM_FIELD.isActive.name]} />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Edit;
