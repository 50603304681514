/* eslint-disable react/display-name */
import { Col, Row, Typography, Form, Input, Select, Segmented } from 'antd';
import React from 'react';
import EImage from '../../../../common/components/EImage';
import RemoteTable from '../../../../common/components/RemoteTable';
import ActionButton from '../../../../common/components/ActionButton';
import { toFormatCurrencyVND } from '../../../../utils/numberFormat';
import { FormCreateType, FormViewType, FormUpdateType } from '../../../../constants/form';
import { getPriceTypeOption } from '../../utils';
import FormItem from '../../../../common/components/FormItem';

const ProductInfo = ({
  mode = FormCreateType,
  products,
  onDelete,
  onChangeSerialNumbers,
  onChangeBHPrice,
  disabled = false
}) => {
  let columns = [
    {
      title: 'STT',
      align: 'center',
      render: (text, record, index) => <Typography.Text>{index + 1}</Typography.Text>
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'thumbnail',
      align: 'center',
      render: (text, record) => <EImage imageUrl={record?.thumbnail?.url} />
    },
    {
      title: 'Mã sản phẩm',
      align: 'center',
      width: '150px',
      // dataIndex: 'code',
      render: (text, record) => (
        <a className="ant-typography" href={`/product/edit/${record?.id}`} target="_blank">
          {record?.code}
        </a>
      )
    },
    {
      title: 'Sản phẩm',
      width: '200px',
      render: (text, record) => <Typography.Text>{record?.title}</Typography.Text>
    },
    {
      title: 'Serial number',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        // if (mode === FormCreateType && record.serialIds?.length > record.amountInput) {
        //   record.serialIds?.splice(0, record.serialIds?.length - record.amountInput);
        //   onChangeSerialNumbers(record, record.serialIds);
        // }

        if (mode === FormViewType) {
          return record?.textSerialNumbers;
        }

        return (
          <>
            <Form.Item name={`serialInput-${record.id}`} required>
              <Select
                style={{ width: '100%' }}
                disabled={disabled}
                options={record.serialNumbers
                  ?.filter((item) => !item.isUsed || record.serialNumberIds?.includes(item.id))
                  ?.sort((a, b) => b.orderNumber - a.orderNumber)
                  ?.map((item) => ({
                    label: item.title,
                    value: item.id
                  }))}
                mode={record?.amountInput > 1 ? 'multiple' : ''}
                onChange={(value) => onChangeSerialNumbers(record, value)}
                allowClear
              />
            </Form.Item>
            {record?.priceType !== 'Default' && (
              <Segmented
                style={{ marginTop: '10px' }}
                block
                defaultValue={record?.priceType}
                options={getPriceTypeOption(record)}
                onChange={(value) => onChangeBHPrice(record, value)}
              />
            )}
          </>
        );
      }
    },
    {
      title: 'Tồn kho',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        if (mode === FormCreateType) {
          return record.inventoryAmount ? record.inventoryAmount + record?.amount : 0;
        }
        return mode === FormUpdateType ? record.inventoryAmount : record.inventoryAmount || 0;
      }
    },
    {
      title: 'Số lượng',
      width: '60px',
      align: 'center',
      render: (text, record) => {
        return mode === FormViewType ? (
          record.amountInput
        ) : (
          <Form.Item name={`amountInput-${record.id}`} required>
            <Input type="number" min="1" max={record.inventoryAmount + record?.amount || 0} />
          </Form.Item>
        );
      }
    },
    {
      title: 'Giá (đ)',
      width: '150px',
      align: 'right',
      dataIndex: 'price',
      render: (text, record) =>
        mode === FormViewType ? (
          <Typography.Text>
            <span style={{ display: 'block' }}>{toFormatCurrencyVND(record.priceInput)}</span>
            <span>{record?.priceType !== 'Default' && mode === FormViewType ? `(${record?.priceType})` : ''}</span>
          </Typography.Text>
        ) : (
          <FormItem
            prefix={''}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            name={`priceInput-${record.id}`}
            type="CurrencyVNDInput"
            showUnit={false}
            onChange={() => {}}
            style={{ textAlign: 'right' }}
          />
        )
    },
    {
      title: 'Thành tiền (đ)',
      width: '120px',
      align: 'right',
      render: (text, record) => (
        <Typography.Text>{toFormatCurrencyVND(+record.amountInput * record.priceInput)}</Typography.Text>
      )
    },
    {
      title: '',
      dataIndex: undefined,
      width: '60px',
      align: 'center',
      render: (text, record) => {
        return <ActionButton id={record.id} record={record} onDelete={() => onDelete(record.id)} module={'Cart'} />;
      }
    }
  ];

  columns = mode !== FormViewType ? columns : columns.filter((item) => !['Tồn kho', ''].includes(item.title));

  return (
    <Row gutter={24}>
      <Col span={24} style={{ minHeight: '100px' }}>
        <RemoteTable columns={columns} data={products} loading={false} rowKey="id" pagination={false} />
      </Col>
    </Row>
  );
};

export default ProductInfo;
