import { Modal } from 'antd';
import { MODEL_MESSAGE } from '../constants/messages';

const { confirm } = Modal;

export const modelSuccess = ({ title, msg, onEventOk }) => {
  Modal.success({
    title: title || MODEL_MESSAGE.SUCCESS,
    content: msg,
    onOk() {
      onEventOk && onEventOk();
    },
    okText: MODEL_MESSAGE.YES,
    cancelText: MODEL_MESSAGE.NO
  });
};

export const modelError = ({ title, msg, onEventOk }) => {
  Modal.error({
    title: title || MODEL_MESSAGE.ERROR,
    content: msg,
    onOk() {
      onEventOk && onEventOk();
    },
    okText: MODEL_MESSAGE.YES,
    cancelText: MODEL_MESSAGE.NO
  });
};

export const modelInfo = ({ title, msg }) => {
  Modal.info({
    title: title || MODEL_MESSAGE.INFO,
    content: msg,
    okText: MODEL_MESSAGE.YES,
    cancelText: MODEL_MESSAGE.NO
  });
};

export const modelWarning = ({ title, msg }) => {
  Modal.warning({
    title: title || MODEL_MESSAGE.WARNING,
    content: msg,
    okText: MODEL_MESSAGE.YES,
    cancelText: MODEL_MESSAGE.NO
  });
};

export const showConfirm = ({ title, msg, onEventOk, onEventCancel }) => {
  confirm({
    title: title || MODEL_MESSAGE.CONFIRM_ACTION,
    content: msg,
    okText: MODEL_MESSAGE.YES,
    cancelText: MODEL_MESSAGE.NO,
    onOk() {
      onEventOk && onEventOk();
    },
    onCancel() {
      onEventCancel && onEventCancel();
    }
  });
};

export const showDeleteConfirm = ({ title, msg, onEventOk, onEventCancel }) => {
  confirm({
    title: title || MODEL_MESSAGE.CONFIRM_CANCEL,
    content: msg,
    okType: 'danger',
    okText: MODEL_MESSAGE.YES,
    cancelText: MODEL_MESSAGE.NO,
    onOk() {
      onEventOk && onEventOk();
    },
    onCancel() {
      onEventCancel && onEventCancel();
    }
  });
};
