import { Form } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useCreateBranch } from '../../../helpers/hookBranch';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { fieldFromRecord, mapFormDataToCreateRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { error: errorCreateBranch, post: createBranch } = useCreateBranch();

  useHandleErrorCode(APP_MESSAGE.branch.name, [errorCreateBranch], APP_MESSAGE.branch.createError);

  const onCreate = useCallback(
    (values) => {
      createBranch({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.branch.createSuccess, type: 'success' });
          history.push(`${ROUTER_URL.BRANCH_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createBranch]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.BRANCH_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.branch.name}`} footerCreatorVisible>
      <CustomForm
        name="branch"
        defaultValue={fieldFromRecord({ isActive: true })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.branch.createConfirm}
        contentCancel={APP_MESSAGE.branch.createCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo requiredItems={[FORM_FIELD.title.name, FORM_FIELD.isActive.name, FORM_FIELD.orderNumber.name]} />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
