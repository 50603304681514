import { list, deleteById, changeStatus } from '../api/postComment';
import { useGetListTable, useSendAPIById, useSendPutAPI } from './hookAPI';

const useGetListPostComment = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useDeletePostComment = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const usePutPostCommentChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

export { useGetListPostComment, useDeletePostComment, usePutPostCommentChangeStatus };
