import { analytic, analyticListProduct, analyticListProductSales, analyticStatusCart } from '../api/analytic.js';
import { useGetDetail } from './hookAPI';

const useGetAnalytic = () => {
  const getAnalytic = useGetDetail(analytic);
  const getAnalyticStatusCart = useGetDetail(analyticStatusCart);

  return {
    getAnalytic: getAnalytic.get,
    getStatusCart: getAnalyticStatusCart.get,
    getListProduct: analyticListProduct,
    getListProductSales: analyticListProductSales
  };
};

export { useGetAnalytic };
