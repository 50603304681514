/* eslint-disable react/display-name */
import { DATE_TIME_FORMAT } from '../../constants';
import { displayDateFromString } from '../../utils/dateFormat';
import { toFormatCurrencyVND } from '../../utils/numberFormat';
import EImage from '../../common/components/EImage';
import React from 'react';

export const columns = [
  {
    title: 'STT',
    width: '50px',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    render: (text, record, index) => index + 1
  },
  {
    title: 'Ảnh đại diện',
    width: '150px',
    dataIndex: 'avatar',
    align: 'center',
    render: (text, record) => <EImage imageUrl={record?.avatar?.url} />
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    render: (text, record) => (
      <a className="ant-typography" href={`/user/edit/${record.id}`}>
        {record?.name ? record.name : record.nickName}
      </a>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)
  }
];

export const productColumns = [
  {
    title: 'STT',
    width: '50px',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    render: (text, record, index) => index + 1
  },
  {
    title: 'Ảnh đại diện',
    width: '150px',
    dataIndex: 'thumbnail',
    align: 'center',
    render: (text, record) => <EImage imageUrl={record?.thumbnail?.url} />
  },
  {
    title: 'Mã sản phẩm',
    dataIndex: 'code',
    key: 'code',
    render: (text, record) => (
      <a className="ant-typography" href={`/product/edit/${record.id}`}>
        {record?.code}
      </a>
    )
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)
  }
];

export const cartColumns = [
  {
    title: 'STT',
    width: '50px',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    render: (text, record, index) => index + 1
  },
  {
    title: 'Mã đơn hàng',
    dataIndex: 'code',
    key: 'code',
    render: (text, record) => (
      <a className="ant-typography" href={`/cart/detail/${record.id}`}>
        {record?.code}
      </a>
    )
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)
  },
  {
    title: 'Khách hàng',
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: 'Giá (đ)',
    align: 'right',
    render: (text, record) => toFormatCurrencyVND(record.price)
  },
  {
    title: 'Giảm giá (đ)',
    align: 'right',
    render: (text, record) => toFormatCurrencyVND(record.voucherPrice)
  },
  {
    title: 'Thành tiền (đ)',
    align: 'right',
    render: (text, record) => toFormatCurrencyVND(record.finalPrice)
  }
];
