/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import ActionButton from '../../../common/components/ActionButton';
// import ActiveLabel from '../../../common/components/ActiveLabel';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';

export const getTableColumns = ({ onDelete, page }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    render: (text, record, index) => <Typography.Text>{(page - 1) * 10 + index + 1}</Typography.Text>
  },
  {
    title: 'Email',
    dataIndex: 'email'
  },
  // {
  //   title: 'Tình Trạng',
  //   align: 'center',
  //   render: (text, record) => <ActiveLabel isActive={record.isActive} />
  // },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  // {
  //   title: 'Người cập nhật',
  //   dataIndex: ['updatedBy', 'name']
  // },
  // {
  //   title: 'Ngày cập nhật',
  //   align: 'center',
  //   columnIndex: 7,
  //   render: (text, record) => (
  //     <Typography.Text>{displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)}</Typography.Text>
  //   )
  // },
  {
    title: 'Hành động',
    dataIndex: undefined,
    width: '80px',
    render: (text, record) => {
      return <ActionButton id={record.id} record={record} onDelete={onDelete} module={'RegisterReceiptEmail'} />;
    }
  }
];
