import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useGetListSetting, useUpdateSetting } from '../../../helpers/hookSetting.js';
import GeneralInfo from '../components/generalInfo';
import BankInfo from '../components/bankInfo';
// import StatusButton from '../components/StatusButton';
// import { FORM_FIELD } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';
import FooterInfo from '../components/footerInfo';
import GalleryInfo from '../components/gelleryInfo';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [data, setData] = useState(null);

  const { error: errorListSetting, get: listSetting } = useGetListSetting();
  const { error: errorUpdateSetting, put: updateSetting } = useUpdateSetting(1);

  useHandleErrorCode(APP_MESSAGE.config.name, [errorListSetting], APP_MESSAGE.config.detailError);
  useHandleErrorCode(APP_MESSAGE.config.name, [errorUpdateSetting], APP_MESSAGE.config.editError);

  const onGetDetail = useCallback(async () => {
    const response = await listSetting({ searchOption: null, pagination: null });
    if (response) {
      setData(response?.items);
    }
  }, [listSetting]);

  const onUpdate = useCallback(
    (values) => {
      updateSetting(1, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.config.editSuccess, type: 'success' });
        }
      });
    },
    [updateSetting]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.HOME);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onUpdate(mapFormDataToCreateRecord(values));
    });
  };

  if (!data) return null;

  const defaultFileList = data.find((item) => item.key === 'logo')?.image || null;
  const defaultBankFileList = data.find((item) => item.key === 'bankQR')?.image || null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.config.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
    >
      <CustomForm
        name="config"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.config.editConfirm}
        contentCancel={APP_MESSAGE.config.editCancel}
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[]}
            disabledItems={[]}
            defaultFileList={defaultFileList ? [defaultFileList] : []}
          />
        </CardForm>
        <CardForm title="Thông tin nhận chuyển khoản">
          <BankInfo
            requiredItems={[]}
            disabledItems={[]}
            defaultFileList={defaultBankFileList ? [defaultBankFileList] : []}
          />
        </CardForm>
        <CardForm title="Cài đặt bài viết Gallery">
          <GalleryInfo requiredItems={[]} disabledItems={[]} />
        </CardForm>
        <CardForm title="Thông tin footer">
          <FooterInfo requiredItems={[]} disabledItems={[]} defaultFileList={[]} />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Edit;
