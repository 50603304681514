export const PREFIX = 'branch';

export const FORM_FIELD = {
  title: { name: 'title', label: 'Tiêu đề' },
  website: { name: 'website', label: 'Website' },
  description: { name: 'description', label: 'Thông tin thêm' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  logo: { name: 'logo', label: 'Hình ảnh' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};
