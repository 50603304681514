import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/posts`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/posts/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/posts`, {
    params
  });
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/posts/${id}`, body);
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/posts/change-status/${id}`, body);
};

export const approved = async (id, body) => {
  return await axiosInstance.put(`/posts/approved/${id}`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/posts/${id}`);
};

export const count = async (params) => {
  return await axiosInstance.get(`/posts/count`, { params });
};
