import { useCallback } from 'react';
import { useAsync } from 'react-async';
import { getGroupValues } from '../api/groupValue';
import config from '../constants/config';
import map from 'lodash/map';
import {
  useGetListTable,
  useGetQuickSearch,
  useSendPostAPI,
  useGetDetailById,
  useSendPutAPI,
  useSendAPIById
} from './hookAPI';
import { create, detail, update, deleteById } from '../api/groupValue';

const checkSkipSearch = (text) => (text || '').length < config.minSuggestionInputSearchLength;
const convertToOptions = (data) =>
  map(data?.data?.items, (item) => ({
    label: item.code,
    value: item.id,
    name: item.name
  }));

const useGroupValueCodeQuickSearch = () => {
  const convertToPayload = useCallback((name) => {
    return {
      skip: 0,
      limit: config.maxInt,
      code: name || undefined
    };
  }, []);
  const { get } = useGetQuickSearch(getGroupValues, checkSkipSearch, convertToPayload, convertToOptions);

  return {
    get
  };
};

const useGroupValueInputCodeQuickSearch = () => {
  const convertToPayload = useCallback((name) => {
    return {
      skip: 0,
      limit: config.maxInt,
      code: name || undefined
    };
  }, []);
  const { get } = useGetQuickSearch(getGroupValues, checkSkipSearch, convertToPayload, convertToOptions);

  return {
    get
  };
};

const useGetListGroupValue = () => {
  const { get } = useGetListTable(getGroupValues);
  return {
    get
  };
};

const useGetListGroupValueOption = () => {
  const { isPending, data, error } = useAsync({
    promiseFn: getGroupValues,
    limit: config.maxInt
  });
  return {
    loading: isPending,
    data: map(data?.data?.data?.items ?? [], (item) => ({
      label: item.name,
      value: item.id,
      name: item.name
    })),
    error
  };
};

const useCreateGroupValue = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailGroupValue = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateGroupValue = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteGroupValue = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

export {
  useGroupValueCodeQuickSearch,
  useGroupValueInputCodeQuickSearch,
  useGetListGroupValue,
  useGetListGroupValueOption,
  useCreateGroupValue,
  useDetailGroupValue,
  useUpdateGroupValue,
  useDeleteGroupValue
};
