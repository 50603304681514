/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import SelectBoxReviewNoLabelStatus from '../../../common/components/SelectBox/SelectBoxReviewNoLabelStatus';
import ActionButton from '../../../common/components/ActionButton';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';

export const getTableColumns = ({ onDelete, onChangeStatus, page }) => [
  {
    title: 'STT',
    fixed: 'left',
    width: '50px',
    align: 'center',
    render: (text, record, index) => <Typography.Text>{(page - 1) * 10 + index + 1}</Typography.Text>
  },
  {
    title: 'Ngày tạo',
    fixed: 'left',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Nội dung',
    fixed: 'left',
    width: '300px',
    render: (text, record) => <Typography.Text>{record.content}</Typography.Text>
  },
  {
    title: 'Bài đăng',
    width: '300px',
    dataIndex: ['post', 'title']
  },
  {
    title: 'Thứ tự',
    align: 'center',
    dataIndex: 'orderNumber'
  },
  {
    title: 'Tình trạng',
    align: 'center',
    width: '150px',
    render: (text, record) => (
      <SelectBoxReviewNoLabelStatus
        onChange={(value) => onChangeStatus(record, { status: value })}
        value={record.status}
      />
    )
  },
  {
    title: 'Người tạo',
    dataIndex: ['user', 'name']
  },
  {
    title: 'Người cập nhật',
    dataIndex: ['updatedBy', 'name']
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Hành động',
    align: 'center',
    fixed: 'right',
    dataIndex: undefined,
    width: '80px',
    render: (text, record) => {
      return <ActionButton id={record.id} record={record} onDelete={onDelete} module={'PostComment'} />;
    }
  }
];
