import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHandleGetPublicPresignedUrl } from '../../helpers/hookFile';
import LoadingOverlay from '../../common/components/LoadingOverlay';

const PublicFiles = () => {
  const key = new URLSearchParams(useLocation().search).get('key');
  const [publicLink, setPublicLink] = useState('');
  const { post: postFilePublicWithPresignedUrl } = useHandleGetPublicPresignedUrl();

  useEffect(() => {
    async function fetchData() {
      if (key) {
        const urlPublic = await postFilePublicWithPresignedUrl(key);
        setPublicLink(urlPublic);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    if (publicLink) {
      window.location.replace(publicLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicLink]);

  return <LoadingOverlay loading />;
};

export default PublicFiles;
