import React from 'react';
import { feedbackStatusOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxFeedbackStatus = ({ prefix = '', name = 'status', label = 'Trạng thái', ...props }) => {
  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="select"
      options={feedbackStatusOptions}
      allowClear
      {...props}
    />
  );
};

SelectBoxFeedbackStatus.propTypes = propTypes;

export default SelectBoxFeedbackStatus;
