import React from 'react';
import { Tag } from 'antd';
import { postStatusOptions } from '../../../constants/data';

const PostStatus = ({ status }) => {
  const record = postStatusOptions.find((x) => x.value === status);

  return <Tag color={record?.color}>{record?.label || ''}</Tag>;
};

export default PostStatus;
