import React, { useMemo } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import './style.scss';

const propTypes = {
  loading: PropTypes.bool
};

const LoadingOverlay = ({ loading = false }) => {
  return useMemo(() => {
    return (
      loading && (
        <div className="loading-overlay-container">
          <Spin size="large" />
        </div>
      )
    );
  }, [loading]);
};

LoadingOverlay.propTypes = propTypes;

export default LoadingOverlay;
