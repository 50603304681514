import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string
};

export const PageRoute = ({ title, ...rest }) => {
  useEffect(() => {
    document.title = title || 'Ecommerce';
  }, [title]);
  return <Route {...rest} />;
};

PageRoute.propTypes = propTypes;

export default PageRoute;
