import { useGetListTable, useGetDetailById, useSendPutAPI, useSendAPIById, useSendForumAPI } from './hookAPI';
import { create, list, detail, update, changeStatus, deleteById, count } from '../api/forum';

const useGetListForum = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateForum = () => {
  const { loading, action, error, setError } = useSendForumAPI(create);
  return {
    loading,
    forum: action,
    error,
    setError
  };
};

const useDetailForum = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateForum = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const usePutForumChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteForum = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const useGetCountForum = () => {
  const { get } = useGetListTable(count);
  return {
    get
  };
};

export {
  useGetListForum,
  useDetailForum,
  useUpdateForum,
  usePutForumChangeStatus,
  useDeleteForum,
  useCreateForum,
  useGetCountForum
};
