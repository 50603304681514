/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import ActiveLabel from '../../../common/components/ActiveLabel';
import EImage from '../../../common/components/EImage';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';
import { toFormatCurrencyVND } from '../../../utils/numberFormat';
import ActionButton from '../../../common/components/ActionButton';

export const getTableColumns = ({ onEdit, onDelete, page, order = [], limit }) => {
  return [
    {
      title: 'STT',
      align: 'center',
      fixed: 'left',
      width: '50px',
      render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
    },
    {
      title: 'Mã sản phẩm',
      fixed: 'left',
      sorter: false,
      // render: (text, record) => <Typography.Link onClick={() => onEdit(record.id)}>{record.code}</Typography.Link>
      render: (text, record) => (
        <a className="ant-typography" href={`/product/edit/${record?.id}`}>
          {record?.code}
        </a>
      )
    },
    {
      title: 'Ảnh đại diện',
      dataIndex: 'thumbnail',
      align: 'center',
      fixed: 'left',
      render: (text, record) => <EImage imageUrl={record?.thumbnail?.url} />
    },
    {
      title: 'Sản phẩm',
      width: '300px',
      sorter: false,
      sortOrder: order[3],
      columnIndex: 3,
      dataIndex: ['title']
    },
    {
      title: 'Danh mục',
      dataIndex: ['category', 'title']
    },
    {
      title: 'Thương hiệu',
      dataIndex: ['branch', 'title']
    },
    {
      title: 'Loại',
      align: 'center',
      sorter: true,
      sortOrder: order[11],
      columnIndex: 11,
      render: (text, record) => (record.type === 'New' ? 'Mới' : 'Cũ')
    },
    {
      title: 'Số lượng',
      dataIndex: ['amount'],
      sorter: true,
      sortOrder: order[4],
      columnIndex: 4,
      align: 'center'
    },
    {
      title: 'Tồn kho',
      dataIndex: ['inventoryAmount'],
      sorter: true,
      sortOrder: order[5],
      columnIndex: 5,
      align: 'center'
    },
    {
      title: 'Giá nhập (đ)',
      align: 'right',
      sorter: true,
      sortOrder: order[6],
      columnIndex: 6,
      render: (text, record) => toFormatCurrencyVND(record.importPrice)
    },
    {
      title: 'Giá bán (đ)',
      align: 'right',
      sorter: true,
      sortOrder: order[7],
      columnIndex: 7,
      render: (text, record) => toFormatCurrencyVND(record.price)
    },
    {
      title: 'Giá BH1 (đ)',
      align: 'right',
      sorter: true,
      sortOrder: order[8],
      columnIndex: 8,
      render: (text, record) => toFormatCurrencyVND(record.price1)
    },
    {
      title: 'Giá BH2 (đ)',
      align: 'right',
      sorter: true,
      sortOrder: order[9],
      columnIndex: 9,
      render: (text, record) => toFormatCurrencyVND(record.price2)
    },
    {
      title: 'Giá BH3 (đ)',
      align: 'right',
      sorter: true,
      sortOrder: order[10],
      columnIndex: 10,
      render: (text, record) => toFormatCurrencyVND(record.price3)
    },
    {
      title: 'VIP',
      align: 'center',
      sorter: true,
      sortOrder: order[11],
      columnIndex: 11,
      render: (text, record) => (record.isRequiredVip ? 'Có' : 'Không')
    },
    {
      title: 'Trạng thái',
      align: 'center',
      sorter: true,
      sortOrder: order[12],
      columnIndex: 12,
      render: (text, record) => <ActiveLabel isActive={record.isActive} />
    },
    {
      title: 'Ngày tạo',
      align: 'center',
      sorter: true,
      sortOrder: order[13],
      columnIndex: 13,
      render: (text, record) => (
        <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
      )
    },
    {
      title: 'Hiển thị web',
      align: 'center',
      sorter: true,
      sortOrder: order[14],
      columnIndex: 14,
      render: (text, record) => (record.isViewWeb ? 'Có' : 'Không')
    },
    {
      title: 'Hiển thị mobile',
      align: 'center',
      sorter: true,
      sortOrder: order[15],
      columnIndex: 15,
      render: (text, record) => (record.isViewMobile ? 'Có' : 'Không')
    },
    {
      title: 'Người tạo',
      dataIndex: ['createdBy', 'name']
    },
    {
      title: 'Hành động',
      dataIndex: undefined,
      width: '80px',
      render: (text, record) => {
        return <ActionButton id={record.id} record={record} onEdit={onEdit} onDelete={onDelete} module={'Product'} />;
      }
    }
  ];
};
