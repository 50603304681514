export const PREFIX = 'postComment';

export const FORM_FIELD = {
  postId: { name: 'postId', label: 'Bài đăng' },
  userId: { name: 'userId', label: 'Người tạo' },
  content: { name: 'content', label: 'Nội dung' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  status: { name: 'status', label: 'Tình trạng' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {};
