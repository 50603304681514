import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_FROM_SERVER } from '../constants';
import { isEmpty } from 'lodash';

const displayDateFromString = (stringDate, format = DATE_FORMAT) =>
  isEmpty(stringDate) ? '' : moment(stringDate).format(format);

const convertDateToString = (dateObj, format = DATE_FORMAT_FROM_SERVER) =>
  dateObj && dateObj instanceof moment ? dateObj.format(format) : dateObj;

export { displayDateFromString, convertDateToString };
