import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import FormItem from '../../../../common/components/FormItem';
import { FORM_FIELD, PREFIX } from '../../constants';
import SelectBoxGroupValueAutoComplete from '../../../../common/components/SelectBox/SelectBoxGroupValueAutoComplete';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import SelectBoxAccountQuickSearch from '../../../../common/components/SelectBox/SelectBoxAccount';

const ListSearch = ({ onSelectedInputGroupCodeQuickSearch, listGroup, listValue }) => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.groupCode.name}
            label="Mã nhóm giá trị"
            component={
              <SelectBoxGroupValueAutoComplete
                onSelect={onSelectedInputGroupCodeQuickSearch}
                capitalize
                hideInputLabel
              />
            }
          />
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.groupParentCode.name}
            label="Nhóm giá trị cha"
            type="select"
            options={listGroup}
            allowClear
          />
        </Col>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.groupName.name} label="Nhóm giá trị" />
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.parentId.name}
            label="Mã giá trị cha"
            type="select"
            options={listValue}
            allowClear
          />
        </Col>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.valueCode.name} label="Mã giá trị" className="uppcase" />
        </Col>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.parentValueName.name} label="Tên giá trị cha" />
        </Col>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.valueName.name} label="Tên giá trị" />
          <SelectBoxStatus prefix={PREFIX} name={FORM_FIELD.isActive.name} label="Trạng thái" allowClear />
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.createdById.name}
            label={FORM_FIELD.createdById.label}
            component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.updatedById.name}
            label={FORM_FIELD.updatedById.label}
            component={<SelectBoxAccountQuickSearch prefix={PREFIX} />}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToCreatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToCreatedAt.label}
          />
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToUpdatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToUpdatedAt.label}
          />
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {
  listGroup: PropTypes.array,
  listValue: PropTypes.array
};

export default ListSearch;
