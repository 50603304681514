/* eslint-disable */
import { Col, Form, Row } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import { FORM_FIELD, PREFIX } from '../../constants';
import './style.scss';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import UploadImage from '../../../../common/components/UploadImage';
import { FormItemLayoutArea } from '../../../../constants/form';

const GeneralInfo = ({
  disabled,
  requiredItems = [],
  disabledItems = [],
  defaultImage = null
}) => {

  return (
    <Row gutter={24} className="tag-info">
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.title.name}
          label={FORM_FIELD.title.label}
          required={requiredItems.includes(FORM_FIELD.title.name)}
          disabled={disabledItems.includes(FORM_FIELD.title.name) || disabled}
        />

        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.isShowHome.name}
          label={FORM_FIELD.isShowHome.label}
          type="switch"
          required={requiredItems.includes(FORM_FIELD.isShowHome.name)}
          disabled={disabledItems.includes(FORM_FIELD.isShowHome.name) || disabled}
        />
        <Form.Item
          {...FormItemLayoutArea}
          name={`${PREFIX}_${FORM_FIELD.image.name}`}
          label={FORM_FIELD.image.label}
          required={requiredItems.includes(FORM_FIELD.image.name)}
          disabled={disabledItems.includes(FORM_FIELD.image.name) || disabled}
          valuePropName="fileList"
        >
          <UploadImage defaultFileList={defaultImage} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <SelectBoxStatus prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.isActive.name)} />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          type={'number'}
          min={0}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
      </Col>
      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
