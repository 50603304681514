import { axiosInstance } from './common';

export const list = async (params) => {
  return await axiosInstance.get(`/forum-comments`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/forum-comments/${id}`);
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/forum-comments/change-status/${id}`, body);
};
