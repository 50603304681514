import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import FormItem from '../../../../common/components/FormItem';
import { PREFIX, FORM_FIELD } from '../../constants';

const ListSearch = () => {
  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <FormItem prefix={PREFIX} name={FORM_FIELD.email.name} label={FORM_FIELD.email.label} />
        </Col>
        <Col span={12}>
          <FormItem
            prefix={PREFIX}
            name={FORM_FIELD.fromToCreatedAt.name}
            type="dateRange"
            label={FORM_FIELD.fromToCreatedAt.label}
          />
        </Col>
      </Row>
    </>
  );
};

ListSearch.propTypes = {
  listAvailable: PropTypes.array
};

export default ListSearch;
