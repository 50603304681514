import React from 'react';
import { toast } from 'react-toastify';

export const customToast = ({
  content = '',
  title = '',
  type = 'default',
  autoClose = 3000,
  position = 'top-right'
}) => {
  const arrType = ['info', 'success', 'warning', 'error'];
  let autoCloseValue = 3000;

  if (autoClose) {
    autoCloseValue = autoClose;
  } else if (type === 'error') {
    autoCloseValue = 5000;
  }

  const options = {
    position,
    autoClose: autoCloseValue,
    type,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  };

  const contentCustom = () => (
    <>
      {title ? <div className="font-bold">{title}</div> : ''}
      {content ? <div>{content}</div> : ''}
    </>
  );

  if (type !== 'default' && arrType.includes(type)) {
    toast[type](contentCustom(), options);
  } else {
    toast(contentCustom(), options);
  }
};
