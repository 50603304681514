import React from 'react';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import LoginPage from './modules/Auth/Login';
import LoginConfirmPage from './modules/Auth/LoginConfirm';
import Home from './modules/Home';
import ProtectedRoute from './utils/ProtectedRoute';
import ForgotPassword from './modules/Auth/ForgotPassword';
import ChangePassword from './modules/Auth/ChangePassword';
import NotFoundPage from './modules/NotFoundPage';
import ROUTER_URL from './constants/router';
import GroupValueFind from './modules/GroupValue/Find';
import GroupValueCreate from './modules/GroupValue/Create';
import GroupValueEdit from './modules/GroupValue/Edit';
import MasterValueFind from './modules/MasterValue/Find';
import MasterValueCreate from './modules/MasterValue/Create';
import MasterValueEdit from './modules/MasterValue/Edit';
import AccountFind from './modules/Account/Find';
import AccountCreate from './modules/Account/Create';
import AccountEdit from './modules/Account/Edit';
import MeDetail from './modules/Account/Me';
import UserFind from './modules/User/Find';
import UserEdit from './modules/User/Edit';
import UserCreate from './modules/User/Create';
import CategoryFind from './modules/Category/Find';
import CategoryCreate from './modules/Category/Create';
import CategoryEdit from './modules/Category/Edit';
import BranchFind from './modules/Branch/Find';
import BranchCreate from './modules/Branch/Create';
import BranchEdit from './modules/Branch/Edit';
import ConfigEdit from './modules/Setting/Edit';
import { PageRoute } from './utils/PageRoute';
import CreateFeedBack from './modules/Feedback/Create';
import EditFeedBack from './modules/Feedback/Edit';
import FeedbackFind from './modules/Feedback/List';
import Forbidden from './modules/Auth/Forbidden';
// import withAuth from './utils/authHOC';
import ResetPassword from './modules/Auth/ResetPassword';
import CartFind from './modules/Cart/Find';
import CartDetail from './modules/Cart/Detail';
import CartCreate from './modules/Cart/Create';
import CartEdit from './modules/Cart/Edit';
import EVoucherCreate from './modules/EVoucher/Create';
import EVoucherEdit from './modules/EVoucher/Edit';
import EVoucherFind from './modules/EVoucher/Find';
import ContentFind from './modules/Content/Find';
import ContentCreate from './modules/Content/Create';
import ContentEdit from './modules/Content/Edit';
import PostCommentFind from './modules/PostComment/Find';
import RegisterReceiptEmailFind from './modules/RegisterReceiptEmail/Find';
import PostCreate from './modules/Post/Create';
import PostEdit from './modules/Post/Edit';
import PostFind from './modules/Post/Find';
import PublicFiles from './modules/PublicFiles';
import ProductFind from './modules/Product/Find';
import ProductEdit from './modules/Product/Edit';
import ProductCreate from './modules/Product/Create';
import ProductSerialNumberFind from './modules/ProductSerialNumber/Find';
import ProductTitleFind from './modules/ProductTitle/Find';
import ProductTitleCreate from './modules/ProductTitle/Create';
import ProductTitleEdit from './modules/ProductTitle/Edit';
import Analytic from './modules/Analytic';
import AnalyticSales from './modules/Analytic/Sales';
import TagCreate from './modules/Tag/Create';
import TagEdit from './modules/Tag/Edit';
import TagFind from './modules/Tag/Find';
import ProductReviewFind from './modules/ProductReview/Find';
import NotificationFind from './modules/Notification/Find';

import ForumCommentFind from './modules/ForumComment/Find';
import CommentReportFind from './modules/CommentReport/Find';
import ForumCreate from './modules/Forum/Create';
import ForumEdit from './modules/Forum/Edit';
import ForumFind from './modules/Forum/Find';

const Routes = () => {
  return (
    <Switch>
      <PageRoute exact path={ROUTER_URL.FORBIDDEN} component={Forbidden} title="Trang không có quyền" />
      <PageRoute exact path={ROUTER_URL.LOGIN_CONFIRM} component={LoginConfirmPage} title="Xác thực đăng nhập" />
      <PageRoute exact path={ROUTER_URL.LOGIN} component={LoginPage} title="Đăng nhập" />
      <PageRoute exact path={ROUTER_URL.PASSWORD_FORGOT} component={ForgotPassword} title="Quên mật khẩu" />
      <PageRoute exact path={ROUTER_URL.PASSWORD_RESET} component={ResetPassword} title="Khôi phục mật khẩu" />
      <Redirect exact from="/" to={ROUTER_URL.HOME} />
      <ProtectedRoute exact path={ROUTER_URL.HOME}>
        <Home />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.ME}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.ME_DETAIL} component={MeDetail} title="Thông tin cá nhân" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute exact path={ROUTER_URL.CONFIG}>
        <ConfigEdit />
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.ACCOUNT} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.ACCOUNT_CREATE} component={AccountCreate} title="Tạo nhân viên" />
          <PageRoute exact path={`${ROUTER_URL.ACCOUNT_EDIT}/:id`} component={AccountEdit} title="Cập nhật nhân viên" />
          <PageRoute exact path={ROUTER_URL.ACCOUNT_FIND} component={AccountFind} title="Tìm nhân viên" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.USER} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.USER_FIND} component={UserFind} title="Tìm khách hàng" />
          <PageRoute exact path={`${ROUTER_URL.USER_CREATE}`} component={UserCreate} title="Tạo khách hàng" />
          <PageRoute exact path={`${ROUTER_URL.USER_EDIT}/:id`} component={UserEdit} title="Cập nhật khách hàng" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.CATEGORY} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.CATEGORY_CREATE} component={CategoryCreate} title="Tạo danh mục" />
          <PageRoute
            exact
            path={`${ROUTER_URL.CATEGORY_EDIT}/:id`}
            component={CategoryEdit}
            title="Cập nhật danh mục"
          />
          <PageRoute exact path={ROUTER_URL.CATEGORY_FIND} component={CategoryFind} title="Tìm danh mục" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.BRANCH} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.BRANCH_CREATE} component={BranchCreate} title="Tạo thương hiệu" />
          <PageRoute exact path={`${ROUTER_URL.BRANCH_EDIT}/:id`} component={BranchEdit} title="Cập nhật thương hiệu" />
          <PageRoute exact path={ROUTER_URL.BRANCH_FIND} component={BranchFind} title="Tìm thương hiệu" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.GROUP_VALUE}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.GROUP_VALUE_FIND} component={GroupValueFind} title="Nhóm giá trị" />
          <PageRoute exact path={ROUTER_URL.GROUP_VALUE_CREATE} component={GroupValueCreate} title="Tạo Nhóm giá trị" />
          <PageRoute
            exact
            path={`${ROUTER_URL.GROUP_VALUE_EDIT}/:id`}
            component={GroupValueEdit}
            title="Cập nhật Nhóm giá trị"
          />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.MASTER_VALUE} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.MASTER_VALUE_FIND} component={MasterValueFind} title="Giá trị" />
          <PageRoute exact path={ROUTER_URL.MASTER_VALUE_CREATE} component={MasterValueCreate} title="Tạo Giá trị" />
          <PageRoute
            exact
            path={`${ROUTER_URL.MASTER_VALUE_EDIT}/:id`}
            component={MasterValueEdit}
            title="Cập nhật Giá trị"
          />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.PASSWORD_CHANGE}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.PASSWORD_CHANGE} component={ChangePassword} title="Thay đổi mật khẩu" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.FEEDBACK}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.FEEDBACK_FIND} component={FeedbackFind} title="Tìm Góp ý - Báo lỗi" />
          <PageRoute exact path={ROUTER_URL.FEEDBACK_CREATE} component={CreateFeedBack} title="Tạo Góp ý - Báo lỗi" />
          <PageRoute
            exact
            path={`${ROUTER_URL.FEEDBACK_EDIT}/:id`}
            component={EditFeedBack}
            title="Cập nhật Góp ý - Báo lỗi"
          />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.CART} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.CART_FIND} component={CartFind} title="Tìm đơn hàng" />
          <PageRoute exact path={ROUTER_URL.CART_CREATE} component={CartCreate} title="Tạo đơn hàng" />
          <PageRoute exact path={`${ROUTER_URL.CART_EDIT}/:id`} component={CartEdit} title="Cập nhật đơn hàng" />
          <PageRoute exact path={`${ROUTER_URL.CART_DETAIL}/:id`} component={CartDetail} title="Xem đơn hàng" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.PRODUCT} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.PRODUCT_FIND} component={ProductFind} title="Tìm sản phẩm" />
          <PageRoute exact path={ROUTER_URL.PRODUCT_CREATE} component={ProductCreate} title="Tạo sản phẩm" />
          <PageRoute exact path={`${ROUTER_URL.PRODUCT_EDIT}/:id`} component={ProductEdit} title="Cập nhật sản phẩm" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.PRODUCT_REVIEW} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.PRODUCT_REVIEW_FIND} component={ProductReviewFind} title="Tìm đánh giá" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.PRODUCT_SERIAL_NUMBER} forAdmin>
        <Switch>
          <PageRoute
            exact
            path={ROUTER_URL.PRODUCT_SERIAL_NUMBER_FIND}
            component={ProductSerialNumberFind}
            title="Tìm serial number"
          />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.E_VOUCHER} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.E_VOUCHER_CREATE} component={EVoucherCreate} title="Tạo E-Voucher" />
          <PageRoute
            exact
            path={`${ROUTER_URL.E_VOUCHER_EDIT}/:id`}
            component={EVoucherEdit}
            title="Cập nhật E-Voucher"
          />
          <PageRoute exact path={ROUTER_URL.E_VOUCHER_FIND} component={EVoucherFind} title="Tìm E-Voucher" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.CONTENT} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.CONTENT_CREATE} component={ContentCreate} title="Tạo nội dung" />
          <PageRoute exact path={`${ROUTER_URL.CONTENT_EDIT}/:id`} component={ContentEdit} title="Cập nhật nội dung" />
          <PageRoute exact path={ROUTER_URL.CONTENT_FIND} component={ContentFind} title="Tìm nội dung" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.POST_COMMENT} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.POST_COMMENT_FIND} component={PostCommentFind} title="Tìm bình luận" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.REGISTER_RECEIPT_EMAIL} forAdmin>
        <Switch>
          <PageRoute
            exact
            path={ROUTER_URL.REGISTER_RECEIPT_EMAIL_FIND}
            component={RegisterReceiptEmailFind}
            title="Tìm đăng ký nhận email"
          />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.PRODUCT_TITLE}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.PRODUCT_TITLE_FIND} component={ProductTitleFind} title="Tìm tên sản phẩm" />
          <PageRoute
            exact
            path={ROUTER_URL.PRODUCT_TITLE_CREATE}
            component={ProductTitleCreate}
            title="Tạo tên sản phẩm"
          />
          <PageRoute
            exact
            path={`${ROUTER_URL.PRODUCT_TITLE_EDIT}/:id`}
            component={ProductTitleEdit}
            title="Cập nhật tên sản phẩm"
          />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.POST}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.POST_FIND} component={PostFind} title="Tìm Bài đăng" />
          <PageRoute exact path={ROUTER_URL.POST_CREATE} component={PostCreate} title="Tạo Bài đăng" />
          <PageRoute exact path={`${ROUTER_URL.POST_EDIT}/:id`} component={PostEdit} title="Tạo Bài đăng" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.TAG}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.TAG_FIND} component={TagFind} title="Tìm tag" />
          <PageRoute exact path={ROUTER_URL.TAG_CREATE} component={TagCreate} title="Tạo tag" />
          <PageRoute exact path={`${ROUTER_URL.TAG_EDIT}/:id`} component={TagEdit} title="Tạo tag" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.POST_COMMENT} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.POST_COMMENT_FIND} component={PostCommentFind} title="Tìm bình luận" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.PUBLIC}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.PUBLIC_FILES} component={PublicFiles} />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.ANALYTICS}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.ANALYTICS_GENERAL} component={Analytic} title="Thống kê sản phẩm" />
          <PageRoute exact path={ROUTER_URL.ANALYTICS_SALES} component={AnalyticSales} title="Thống kê doanh số" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.NOTIFICATION}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.NOTIFICATION_FIND} component={NotificationFind} title="Tất cả thông báo" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.FORUM}>
        <Switch>
          <PageRoute exact path={ROUTER_URL.FORUM_FIND} component={ForumFind} title="Tìm diễn đàn" />
          <PageRoute exact path={ROUTER_URL.FORUM_CREATE} component={ForumCreate} title="Tạo diễn đàn" />
          <PageRoute exact path={`${ROUTER_URL.FORUM_EDIT}/:id`} component={ForumEdit} title="Sửa diễn đàn" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.FORUM_COMMENT} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.FORUM_COMMENT_FIND} component={ForumCommentFind} title="Tìm bình luận" />
        </Switch>
      </ProtectedRoute>
      <ProtectedRoute path={ROUTER_URL.COMMENT_REPORT} forAdmin>
        <Switch>
          <PageRoute exact path={ROUTER_URL.COMMENT_REPORT_FIND} component={CommentReportFind} title="Tìm báo xấu" />
        </Switch>
      </ProtectedRoute>

      {/* final */}
      <PageRoute path="*" title="Trang không tìm thấy">
        <NotFoundPage />
      </PageRoute>
      {/* --- */}
    </Switch>
  );
};

export default withRouter(Routes);
