import { Col, Form, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import CustomFormItem from '../../../common/components/FormItem';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { usePutChangePasswordAccount } from '../../../helpers/hookAccount';
// import { validatePassword } from '../../../utils';

const ChangePassword = () => {
  const [form] = Form.useForm();
  const { push } = useHistory();
  const { error, put: putChangePasswordAccount } = usePutChangePasswordAccount();

  useHandleErrorCode('Thay đổi mật khẩu', [error], 'Thay đổi mật khẩu thất bại');

  const onCancel = () => {
    push(ROUTER_URL.HOME);
  };

  const onSave = (values) => {
    const { change_password_password, change_password_newPassword, change_password_confirmPassword } = values;

    // if (!validatePassword(change_password_newPassword)) {
    //   errorModal({
    //     content: 'Yêu cầu từ 6 ký tự trở lên, có ít nhất 1 chữ cái và 1 chữ số'
    //   });
    //   return;
    // }

    if (change_password_newPassword !== change_password_confirmPassword) {
      customToast({ content: 'Mật khẩu mới và Xác nhận mật khẩu mới không khớp', type: 'error' });
      return;
    }

    putChangePasswordAccount(null, {
      password: change_password_password,
      newPassword: change_password_newPassword,
      confirmPassword: change_password_confirmPassword
    }).then((response) => {
      if (response?.success) {
        customToast({ content: 'Thay đổi mật khẩu thành công', type: 'success' });
        window.location.reload();
      }
    });
  };

  return (
    <PageContent title="Thay đổi mật khẩu">
      <CustomForm
        name="change-password"
        onSubmit={onSave}
        contentOK="Bạn xác nhận muốn đổi mật khẩu?"
        contentCancel="Bạn muốn hủy quá trình đổi mật khẩu?"
        onCancel={onCancel}
        form={form}
      >
        <CardForm title="Nhập mật khẩu mới">
          <Row gutter={8}>
            <Col span={24}>
              <CustomFormItem
                prefix="change_password"
                name="password"
                label="Mật khẩu cũ"
                required
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                type="password"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <CustomFormItem
                prefix="change_password"
                name="newPassword"
                label="Mật khẩu mới"
                required
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                type="password"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <CustomFormItem
                prefix="change_password"
                name="confirmPassword"
                label="Xác nhận mật khẩu mới"
                required
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                type="password"
              />
            </Col>
          </Row>
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default ChangePassword;
