import { Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { FormViewType } from '../../../constants/form';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useGetDetailCart } from '../../../helpers/hookCart';
import GeneralInfo from '../components/generalInfo';
import PaymentInfo from '../components/paymentInfo';
import ProductInfo from '../components/productInfo';
import { ECartStatus } from '../constants';
import { fieldFromRecord } from '../utils';
import StatusButton from '../components/StatusButton';

const Detail = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);

  const { error: errorDetailCart, get: getDetailCart } = useGetDetailCart();

  useHandleErrorCode(APP_MESSAGE.cart.name, [errorDetailCart], APP_MESSAGE.cart.detailError);

  const onGetDetail = useCallback(async () => {
    const response = await getDetailCart(id);
    if (response) {
      setData(response);
      setProducts(
        response?.cartDetails?.map((item) => ({
          id: item.product.id,
          thumbnail: item.product.thumbnail,
          code: item.product.code,
          title: item.product.title,
          amount: item.amount,
          amountInput: item.amount,
          description: item.description,
          serialNumbers: item.product.serialNumbers,
          textSerialNumbers: item.cartDetailSerials?.map((item) => item.productSerialNumber?.title).join(', '),
          serialIds: item.cartDetailSerials.map((item) => item.productSerialId),
          serialNumberIds: item.cartDetailSerials.map((item) => item.productSerialId),
          priceType: item.priceType,
          priceInput: item.price,
          price: item.product.price,
          price1: item.product.price1,
          price2: item.product.price2,
          price3: item.product.price3
        }))
      );
    }
  }, [getDetailCart, id]);

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.CART_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    history.push(`${ROUTER_URL.CART_EDIT}/${id}`);
  };

  if (!data) return null;

  return (
    <PageContent
      title={`Xem ${APP_MESSAGE.cart.name}`}
      footerCreatorVisible
      createdBy={data?.user}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
    >
      {data?.status !== ECartStatus.Canceled && (
        <StatusButton
          data={data}
          id={id}
          status={data.status}
          createdById={data.createdById}
          onGetDetail={onGetDetail}
        />
      )}
      <CustomForm
        name="cart"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        form={form}
        onCancel={onCancel}
        labelCancel="Đóng"
        labelOk="Cập nhật"
        hideOk={[ECartStatus.Canceled].includes(data?.status)}
      >
        <Row gutter={24}>
          <Col span={16}>
            <CardForm title="Sản phẩm">
              <ProductInfo products={products} mode={FormViewType} type={data?.type} disabled />
            </CardForm>
            <CardForm title="Thanh toán">
              <PaymentInfo disabled mode={FormViewType} />
            </CardForm>
          </Col>
          <Col span={8}>
            <CardForm title={`# ${data?.code}`}>
              <GeneralInfo status={data?.status} mode={FormViewType} disabled />
            </CardForm>
          </Col>
        </Row>
      </CustomForm>
    </PageContent>
  );
};

export default Detail;
