import { Form, Input, Modal, Row, Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CustomButton from '../../../common/components/Button';
import { EPostStatus } from '../constants';
import { useSelector } from 'react-redux';
import ROUTER_URL from '../../../constants/router';
import { customToast } from '../../../common/components/Toast';
import { confirmModal } from '../../../common/components/Modal';
import { useDeletePost, usePutPostChangeStatus } from '../../../helpers/hookPost';

const StatusButton = ({ id, status = 'New', createdById = -1, onGetDetail }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { user } = useSelector((state) => state.Auth);
  const [disableButtonSend, setDisableButtonSend] = useState(true);
  const [visibleModalReject, setVisibleModalReject] = useState(false);
  const { put: putChangeStatusPost } = usePutPostChangeStatus();
  const { delete: deletePost } = useDeletePost();

  const handleChangeStatus = async (willUpdateStatus) => {
    const { reason } = form.getFieldsValue(['reason']);
    return await putChangeStatusPost(id, { status: willUpdateStatus, reasonForRefusal: reason || '' });
  };

  const onSubmit = async () => {
    confirmModal({
      content: 'Xác nhận gửi duyệt Bài đăng',
      onOk: async () => {
        const resData = await handleChangeStatus(EPostStatus.Pending);
        if (resData?.success) {
          onGetDetail();
          customToast({ content: 'Xác nhận gửi duyệt Bài đăng thành công', type: 'success' });
        } else {
          customToast({ content: 'Xác nhận gửi duyệt Bài đăng thất bại', type: 'error' });
        }
      }
    });
  };

  const onDelete = async () => {
    confirmModal({
      content: 'Thực hiện thao tác này sẽ xóa dữ liệu khỏi hệ thống. Xác nhận xóa?',
      onOk: async () => {
        const resData = await deletePost(id);
        if (resData?.success) {
          customToast({ content: 'Xác nhận xóa Bài đăng thành công', type: 'success' });
          history.push(`${ROUTER_URL.POST_FIND}`);
        } else {
          customToast({ content: 'Xác nhận xóa Bài đăng thất bại', type: 'error' });
        }
      }
    });
  };

  const onValuesChange = (changeValues) => {
    const { reason } = changeValues;
    setDisableButtonSend(isEmpty(reason));
  };

  const onReject = async () => {
    const { success } = await handleChangeStatus(EPostStatus.Reject);
    if (success) {
      onRejectModalClose();
      onGetDetail();
      customToast({ content: 'Xác nhận từ chối Bài đăng thành công', type: 'success' });
      return;
    }
    customToast({ content: 'Xác nhận từ chối Bài đăng thất bại', type: 'error' });
  };

  const onRejectModalOpen = () => {
    setVisibleModalReject(true);
  };

  const onRejectModalClose = () => {
    setVisibleModalReject(false);
  };

  const onSuccess = async () => {
    confirmModal({
      content: 'Xác nhận đã duyệt và chờ phát hành Bài đăng?',
      onOk: async () => {
        const { success } = await handleChangeStatus(EPostStatus.Success);
        if (success) {
          onGetDetail();
          customToast({ content: 'Xác nhận đã duyệt và chờ phát hành Bài đăng thành công', type: 'success' });
          return;
        }
        customToast({ content: 'Xác nhận đã duyệt và chờ phát hành Bài đăng thất bại', type: 'error' });
      }
    });
  };

  const onPublished = async () => {
    confirmModal({
      content: 'Xác nhận phát hành Bài đăng?',
      onOk: async () => {
        const { success } = await handleChangeStatus(EPostStatus.Published);
        if (success) {
          onGetDetail();
          customToast({ content: 'Xác nhận phát hành Bài đăng thành công', type: 'success' });
          return;
        }
        customToast({ content: 'Xác nhận phát hành Bài đăng thất bại', type: 'error' });
      }
    });
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Modal
        visible={visibleModalReject}
        onOk={onReject}
        okButtonProps={{
          disabled: disableButtonSend
        }}
        onCancel={onRejectModalClose}
        title="Xác nhận từ chối?"
      >
        <div>
          <Form form={form} onValuesChange={onValuesChange}>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              labelAlign="left"
              name="reason"
              label="Lý do"
              required
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Row justify="end">
        <Space wrap size="large">
          <CustomButton
            type="danger"
            className="button-container button-delete"
            onClick={onDelete}
            visible={
              (user?.type === 'Admin' || user.id === createdById) &&
              [EPostStatus.New, EPostStatus.Updating, EPostStatus.Reject].includes(status)
            }
          >
            Xóa
          </CustomButton>
          <CustomButton
            type="primary"
            className="button-container button-pending"
            onClick={onSubmit}
            visible={
              (user?.type === 'Admin' || user.id === createdById) &&
              [EPostStatus.New, EPostStatus.Updating].includes(status)
            }
          >
            Gửi duyệt
          </CustomButton>
          <CustomButton
            type="primary"
            className="button-container button-rejected"
            onClick={onRejectModalOpen}
            visible={(user?.type === 'Admin' || user.id === createdById) && EPostStatus.Pending.includes(status)}
          >
            Từ chối
          </CustomButton>
          <CustomButton
            type="primary"
            className="button-container button-processed"
            onClick={onSuccess}
            visible={
              (user?.type === 'Admin' || user.id === createdById) &&
              [EPostStatus.Pending, EPostStatus.Published].includes(status)
            }
          >
            {status === EPostStatus.Pending ? 'Chờ phát hành' : 'Hủy phát hành'}
          </CustomButton>

          <CustomButton
            type="primary"
            className="button-container button-finished"
            onClick={onPublished}
            visible={(user?.type === 'Admin' || user.id === createdById) && EPostStatus.Success.includes(status)}
          >
            Phát hành
          </CustomButton>
        </Space>
      </Row>
    </div>
  );
};

export default StatusButton;
