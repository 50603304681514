import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDetailProduct } from '../../../helpers/hookProduct';
import { useDetailEVoucher, useUpdateEVoucher } from '../../../helpers/hookEVoucher';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD, PREFIX } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [product, setProduct] = useState(null);
  const [priceType, setPriceType] = useState('Percent');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { error: errorDetailEVoucher, get: detailEVoucher } = useDetailEVoucher();
  const { error: errorUpdateEVoucher, put: updateEVoucher } = useUpdateEVoucher(id);
  const { error: errorDetailProduct, get: detailProduct } = useDetailProduct();

  useHandleErrorCode(APP_MESSAGE.eVoucher.name, [errorDetailEVoucher], APP_MESSAGE.eVoucher.detailError);
  useHandleErrorCode(APP_MESSAGE.eVoucher.name, [errorUpdateEVoucher], APP_MESSAGE.eVoucher.editError);
  useHandleErrorCode(APP_MESSAGE.product.name, [errorDetailProduct], APP_MESSAGE.product.detailError);

  const onGetProductDetail = useCallback(
    async (id) => {
      let response = await detailProduct(id);
      if (response) {
        setProduct(response);
      }
    },
    [detailProduct]
  );

  const onGetDetail = useCallback(async () => {
    const response = await detailEVoucher(id);
    if (response) {
      setData(response);
      setPriceType(response?.priceReduction ? 'Money' : 'Percent');
    }
  }, [detailEVoucher, id]);

  const onUpdate = useCallback(
    (values) => {
      updateEVoucher(id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.eVoucher.editSuccess, type: 'success' });
          onGetDetail();
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, updateEVoucher]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.E_VOUCHER_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onUpdate(mapFormDataToCreateRecord(values));
    });
  };

  const onCreate = () => {
    history.push(ROUTER_URL.E_VOUCHER_CREATE);
  };

  const handleValueChange = (value) => {
    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.productId.name}`)) {
      const productId = value[`${PREFIX}_${FORM_FIELD.productId.name}`];
      // form.setFieldsValue({
      //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: undefined,
      //   [`${PREFIX}_${FORM_FIELD.percentReduction.name}`]: undefined
      // });
      if (productId) {
        onGetProductDetail(value[`${PREFIX}_${FORM_FIELD.productId.name}`]);
      } else {
        form.setFieldsValue({
          [`${PREFIX}_${FORM_FIELD.price.name}`]: undefined,
          [`${PREFIX}_${FORM_FIELD.sellPrice.name}`]: undefined
        });
      }
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.percentReduction.name}`)) {
      const percentReduction = value[`${PREFIX}_${FORM_FIELD.percentReduction.name}`];
      const sellPrice = form.getFieldValue(`${PREFIX}_${FORM_FIELD.sellPrice.name}`);
      if (+percentReduction && sellPrice) {
        // form.setFieldsValue({
        //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]:
        //     percentReduction >= 100 ? sellPrice : Math.floor((+percentReduction * sellPrice) / 100)
        // });
        if (percentReduction >= 100) {
          // form.setFieldsValue({
          //   [`${PREFIX}_${FORM_FIELD.percentReduction.name}`]: 100
          // });
        }
      } else if (percentReduction === '0') {
        // form.setFieldsValue({
        //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: sellPrice
        // });
      } else {
        // form.setFieldsValue({
        //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: undefined
        // });
      }
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.priceReduction.name}`)) {
      const priceReduction = value[`${PREFIX}_${FORM_FIELD.priceReduction.name}`];
      const sellPrice = form.getFieldValue(`${PREFIX}_${FORM_FIELD.sellPrice.name}`);
      if (sellPrice && +priceReduction) {
        form.setFieldsValue({
          [`${PREFIX}_${FORM_FIELD.percentReduction.name}`]:
            +priceReduction >= sellPrice ? 100 : Math.floor((+priceReduction / sellPrice) * 100)
        });
        if (+priceReduction >= sellPrice) {
          form.setFieldsValue({ [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: sellPrice });
        }
      }
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.priceType.name}`)) {
      setPriceType(value[`${PREFIX}_${FORM_FIELD.priceType.name}`]);
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.priceType.name}`)) {
      const priceType = value[`${PREFIX}_${FORM_FIELD.priceType.name}`];
      setPriceType(priceType);
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.fromDate.name}`)) {
      setFromDate(value[`${PREFIX}_${FORM_FIELD.fromDate.name}`]);
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.toDate.name}`)) {
      setToDate(value[`${PREFIX}_${FORM_FIELD.toDate.name}`]);
    }
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.price.name}`]: product?.price,
        [`${PREFIX}_${FORM_FIELD.sellPrice.name}`]: product?.sellPrice
      });
    }
  }, [product, form]);

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.eVoucher.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
      onCreate={onCreate}
    >
      <CustomForm
        name="eVoucher"
        module="EVoucher"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        // hideOk={data?.useCount}
        contentOK={APP_MESSAGE.eVoucher.editConfirm}
        contentCancel={APP_MESSAGE.eVoucher.editCancel}
        onCancel={onCancel}
        form={form}
        onValuesChange={handleValueChange}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.code.name,
              FORM_FIELD.type.name,
              FORM_FIELD.percentReduction.name,
              FORM_FIELD.priceReduction.name,
              FORM_FIELD.isActive.name
            ]}
            disabledItems={[FORM_FIELD.userId.name]}
            disabled={!!data?.useCount}
            priceType={priceType}
            fromDate={fromDate}
            toDate={toDate}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Edit;
