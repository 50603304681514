import React from 'react';
import { useCategoriesQuickSearch } from '../../../../helpers/hookCategory';
import SuggestionInput from '../../SuggestionInput';

const SelectBoxCategoriesQuickSearch = ({ parentId, exceptId, exceptParentId, types = ['Product'], ...props }) => {
  const { get: getCodeQuickSearch } = useCategoriesQuickSearch(parentId, exceptId, exceptParentId, types);
  return <SuggestionInput promiseFn={getCodeQuickSearch} hideInputLabel allowClear modeLikeSearch {...props} />;
};

export default SelectBoxCategoriesQuickSearch;
