import React, { useCallback, useState } from 'react';
import { Col, Row, AutoComplete, Typography } from 'antd';
import { useAsync } from 'react-async';
import './style.scss';

const AutoCompleteInput = ({
  label,
  value,
  onChange,
  placeholder,
  promiseFn,
  hideInputLabel,
  capitalize,
  className,
  ...inputProps
}) => {
  const [currentText, setText] = useState('');
  const [inputText, setInputText] = useState(value);

  const { data: options } = useAsync({
    promiseFn,
    name: currentText,
    watch: currentText
  });

  const onChangeSearch = useCallback((newValue) => {
    if (newValue) {
      setText(newValue);
    }
  }, []);

  const onChangeSelect = (text, option) => {
    const dataText = option?.label || `${text}`;
    capitalize ? setInputText(dataText.toUpperCase()) : setInputText(dataText);
    onChange && onChange(text, option);
  };

  return (
    <div className="autocompleted-input-container">
      <Row align="middle" justify="start">
        {!hideInputLabel && (
          <Col span={8} className="autocompleted-input-label-block">
            <Typography.Text className="autocompleted-input-label">{label}</Typography.Text>
          </Col>
        )}
        <Col span={!hideInputLabel ? 16 : 24}>
          <AutoComplete
            options={options}
            value={inputText}
            onChange={onChangeSelect}
            onSearch={onChangeSearch}
            placeholder={placeholder}
            size="middle"
            className={`select-value ${className}`}
            showSearch
            notFoundContent={null}
            autoComplete="off"
            dropdownMatchSelectWidth={false}
            {...inputProps}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AutoCompleteInput;
