import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import './GlobalLoading.scss';
import { name as nameOfReducer } from './reducer';

/**
 * @name globalLoading
 * @description This is Commom Global Loading
 * @param {Object} props
 * @param {boolean} props.showLoading
 * @memberof module:Common
 */
const GlobalLoading = (props) => {
  const { showLoading } = props;
  return showLoading ? (
    <div className="globalLoading-container">
      <Spin size="large" />
    </div>
  ) : null;
};

GlobalLoading.propTypes = {
  showLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    showLoading: state[nameOfReducer].showLoading
  };
};

export default connect(mapStateToProps, null)(GlobalLoading);
