import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import CardForm from '../../../common/components/CardForm';

const CountComponent = ({ title, count, colorClass }) => {
  return (
    <Col span={12} sm={6} md={6}>
      <CardForm title={title} className="p-card">
        <p className={colorClass}>
          <Tooltip placement="bottomLeft" title="Tổng">
            <span>{count?.total}</span>
          </Tooltip>
        </p>
        <Row className="col-2">
          <Col span={8} className="p-active">
            <Tooltip placement="bottomLeft" title="Chờ xử lý">
              <span>{count?.pending}</span>
            </Tooltip>
          </Col>
          <Col span={8} className="p-active">
            <Tooltip placement="bottomLeft" title="Thành công">
              <span>{count?.success}</span>
            </Tooltip>
          </Col>
          <Col span={8} className="p-inactive">
            <Tooltip placement="bottomLeft" title="Từ chối">
              <span>{count?.reject}</span>
            </Tooltip>
          </Col>
        </Row>
      </CardForm>
    </Col>
  );
};

export default CountComponent;
