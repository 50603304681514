import { Col, Row, Form } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImage from '../../../../common/components/UploadImage';
import SelectBoxContentType from '../../../../common/components/SelectBox/SelectBoxContentType';
import SelectBoxStatus from '../../../../common/components/SelectBox/SelectBoxStatus';
import SelectBoxContent from '../../../../common/components/SelectBox/SelectBoxContent';
import SelectBoxProduct from '../../../../common/components/SelectBox/SelectBoxProduct';
import { PREFIX, FORM_FIELD } from '../../constants';
import { FormItemLayoutArea } from '../../../../constants/form';

const GeneralInfo = ({ disabled, requiredItems = [], disabledItems = [], defaultFileList, defaultMobileFileList }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <SelectBoxContentType prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.type.name)} />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.title.name}
          label={FORM_FIELD.title.label}
          required={requiredItems.includes(FORM_FIELD.title.name)}
          disabled={disabledItems.includes(FORM_FIELD.title.name) || disabled}
        />

        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
        <SelectBoxStatus prefix={PREFIX} required={requiredItems.includes(FORM_FIELD.isActive.name)} />
        <Form.Item {...FormItemLayoutArea} name={`${PREFIX}_${FORM_FIELD.image.name}`} label={FORM_FIELD.image.label}>
          <UploadImage defaultFileList={defaultFileList} />
        </Form.Item>

        <Form.Item
          {...FormItemLayoutArea}
          name={`${PREFIX}_${FORM_FIELD.mobileImage.name}`}
          label={FORM_FIELD.mobileImage.label}
        >
          <UploadImage defaultFileList={defaultMobileFileList} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.parentId.name}
          label={FORM_FIELD.parentId.label}
          required={requiredItems.includes(FORM_FIELD.parentId.name)}
          disabled={disabledItems.includes(FORM_FIELD.parentId.name) || disabled}
          component={<SelectBoxContent allowClear showSearch hideInputLabel parentId={null} />}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.link.name}
          label={FORM_FIELD.link.label}
          required={requiredItems.includes(FORM_FIELD.link.name)}
          disabled={disabledItems.includes(FORM_FIELD.link.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.productId.name}
          label={FORM_FIELD.productId.label}
          required={requiredItems.includes(FORM_FIELD.productId.name)}
          disabled={disabledItems.includes(FORM_FIELD.productId.name) || disabled}
          component={<SelectBoxProduct prefix={PREFIX} />}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
