import LoginLeftBannerImage from '../assets/images/login_left_banner.jpg';
import NoImage from '../assets/images/no_image.png';
import LogoImage from '../assets/images/logo.png';
import LogoFinalImage from '../assets/images/logo-final.png';
import PlayVideo from '../assets/images/videoThumbnail.webp';

export const ErrorCode = {
  UNAUTHENTICATED: 401,
  FORBIDDEN: 403
};

export const DATE_FORMAT_FROM_SERVER_WITHOUT_SECONDS = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_FROM_SERVER = 'YYYY-MM-DD';
export const DATE_FORMAT_FROM_SERVER_PATTEN = /^\d{4}-\d{2}-\d{2}$/;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const MONTH_FORMAT_FROM_SERVER = 'YYYY-MM';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const TIME_HOUR_MINUTES = 'HH:mm';
export const DATE_TIME_SECOND_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const SELECT_ALL_PLACEHOLDER = 'Chọn tất cả';
export const SELECT_NONE_PLACEHOLDER = 'Chọn một';
export const DECIMAL_NUMBER_FORMAT = '0,0.00';
export const PERCENTAGE_NUMBER_FORMAT = '0,0.00';
export const BILION_DECIMAL_NUMBER_FORMAT = '0,0.00a';
export const TIME_NUMBER_FORMAT = '0,0';
export const EMPTY = '';
export const IS_EDIT_PAGE_PATTERN = /\/edit\/\d+$/;
export const IS_CREATE_PAGE_PATTERN = /\/create\/\d+$/;
export const IS_DUPLIATE_PAGE_PATTERN = /\?isDuplicate=true$/;
export const IS_VIEW_PAGE_PATTERN = /\/view\/\d+$/;

export const IMAGE_APP = {
  LoginLeftBannerImage,
  LogoImage,
  NoImage,
  PlayVideo,
  LogoFinalImage
};

export const STATUS_OPTIONS = [
  {
    label: 'Active',
    value: 'true'
  },
  {
    label: 'DeActive',
    value: 'false'
  }
];

export const InputLayout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

export const NOTE_NAME = {
  account: 'Nhân viên'
};

export const PAGINATION = {
  pageSize: 10,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} kết quả`
};

export const AccountType = {
  ADMIN: 'Admin'
};

export const RULES_REQUIRED = [
  {
    required: true,
    message: 'Field bắt buộc, vui lòng bổ sung'
  }
];
