import { isEmpty } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_FROM_SERVER } from '../../constants';
import { FORM_FIELD, PREFIX } from './constants';

export const mapFormDataToSearchRecord = (payload) => {
  return {
    createdAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    createdAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToCreatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtFrom: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][0]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    updatedAtTo: payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`]
      ? moment(payload[`${PREFIX}_${FORM_FIELD?.fromToUpdatedAt?.name}`][1]).format(DATE_FORMAT_FROM_SERVER)
      : undefined,
    isActive: payload[`${PREFIX}_${FORM_FIELD?.isActive?.name}`],
    createdById: payload[`${PREFIX}_${FORM_FIELD?.createdById?.name}`],
    updatedById: payload[`${PREFIX}_${FORM_FIELD?.updatedById?.name}`],
    title: payload[`${PREFIX}_${FORM_FIELD?.title?.name}`] || undefined,
    website: payload[`${PREFIX}_${FORM_FIELD?.website?.name}`] || undefined,
    description: payload[`${PREFIX}_${FORM_FIELD?.description?.name}`] || undefined
  };
};

export const mapFormDataToCreateRecord = (values) => {
  const logo = values[`${PREFIX}_${FORM_FIELD?.logo?.name}`];
  return {
    title: values[`${PREFIX}_${FORM_FIELD?.title?.name}`] || null,
    website: values[`${PREFIX}_${FORM_FIELD?.website?.name}`] || null,
    description: values[`${PREFIX}_${FORM_FIELD?.description?.name}`] || null,
    logo: !isEmpty(logo) ? logo[0] : null,
    orderNumber: values[`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`],
    isActive: values[`${PREFIX}_${FORM_FIELD?.isActive?.name}`]
  };
};

export const fieldFromRecord = (record) => {
  return {
    [`${PREFIX}_${FORM_FIELD?.title?.name}`]: record?.title,
    [`${PREFIX}_${FORM_FIELD?.website?.name}`]: record?.website,
    [`${PREFIX}_${FORM_FIELD?.description?.name}`]: record?.description,
    [`${PREFIX}_${FORM_FIELD?.logo?.name}`]: record?.logo,
    [`${PREFIX}_${FORM_FIELD?.orderNumber?.name}`]: record?.orderNumber || 100,
    [`${PREFIX}_${FORM_FIELD?.isActive?.name}`]: record?.isActive
  };
};
