import { Form, Modal } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { customToast } from '../../../common/components/Toast';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import ListSearch from '../components/listSearch';
import { SEARCH_DEFAULT } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';
import { useDeleteProductTitle, useGetListProductTitle } from '../../../helpers/hookProductTitle';
import { setLocalStorage } from '../../../utils/localStorage';

const Find = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const { get: getListProductTitle } = useGetListProductTitle();
  const { error: errorDelete, delete: deleteProductTitle } = useDeleteProductTitle();

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  const { loading, reload, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'createdAt',
        order: 'desc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, null],
    promiseFn: getListProductTitle
  });

  useHandleErrorCode(APP_MESSAGE.productTitle.name, [errorList], APP_MESSAGE.productTitle.missData);
  useHandleErrorCode(APP_MESSAGE.productTitle.name, [errorDelete], APP_MESSAGE.productTitle.deleteError);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onEdit = useCallback(
    (id) => {
      history.push(`${ROUTER_URL.PRODUCT_TITLE_EDIT}/${id}`);
    },
    [history]
  );

  const onDelete = useCallback(
    (record) => {
      setLocalStorage('type-enter', 'modal');
      Modal.confirm({
        title: getMessageFromName(APP_MESSAGE.productTitle.deleteConfirm, record.title),
        onOk: async () => {
          setLocalStorage('type-enter', '');
          deleteProductTitle(record.id).then((response) => {
            if (response?.success) {
              customToast({
                content: getMessageFromName(APP_MESSAGE.productTitle.deleteSuccess, record.title),
                type: 'success'
              });
              reload();
            }
          });
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [deleteProductTitle, reload]
  );

  const onFinish = useCallback(
    (values) => {
      setSearchParams(mapFormDataToSearchRecord(values));
    },
    [setSearchParams]
  );

  const columns = useMemo(() => getTableColumns({ page, order, onEdit, onDelete, limit }), [
    page,
    order,
    onEdit,
    onDelete,
    limit
  ]);

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.productTitle.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <RemoteTable
          columns={columns}
          data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
          loading={loading}
          page={page}
          limit={limit}
          total={data?.total ?? 0}
          onChange={onChange(() => {})}
          rowKey="id"
        />
      </CardForm>
    </PageContent>
  );
};

export default Find;
