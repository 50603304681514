import React from 'react';
import { productTypeOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxProductType = ({ prefix = '', name = 'type', label = 'Loại', ...props }) => {
  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="select"
      options={productTypeOptions}
      allowClear
      {...props}
    />
  );
};

SelectBoxProductType.propTypes = propTypes;

export default SelectBoxProductType;
