import { create, detail, list, update, deleteById } from '../api/category';
import { useGetDetailById, useGetListTable, useSendPostAPI, useSendPutAPI, useSendAPIById } from './hookAPI';
import { useCallback, useRef } from 'react';
import { map, uniqBy } from 'lodash';

const useGetListCategory = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateCategory = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailCategory = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateCategory = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteCategory = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const useCategoryQuickSearch = (parentId, exceptId, exceptParentId, type) => {
  const latestAbortToken = useRef(null);
  const get = useCallback(
    ({ name }) => {
      return new Promise((resolve, reject) => {
        if (latestAbortToken.current) {
          clearTimeout(latestAbortToken.current);
        }
        const cancelToken = setTimeout(() => {
          list({
            type,
            parentId,
            exceptId: exceptId || undefined,
            exceptParentId,
            title: name || undefined,
            isActive: true,
            orderField: 'orderNumber',
            order: 'asc'
          })
            .then(({ data }) => {
              return resolve(
                uniqBy(
                  map(data?.data?.items, (item) => ({
                    label: item.title,
                    value: item.id
                  })),
                  (item) => item.value
                )
              );
            })
            .catch((error) => {
              return reject(error);
            });
        }, 1000);
        latestAbortToken.current = cancelToken;
      });
    },
    [exceptId, parentId, exceptParentId, type]
  );
  return {
    get
  };
};

const useCategoriesQuickSearch = (parentId, exceptId, exceptParentId, types) => {
  const get = useCallback(
    async ({ name }) => {
      const listData = [];
      for (let i = 0; i < types.length; i++) {
        const resData = await list({
          type: types[i],
          parentId,
          exceptId: exceptId || undefined,
          exceptParentId,
          title: name || undefined,
          isActive: true
        });
        listData.push(...resData.data.data.items);
      }
      // console.log('listData', listData);
      return uniqBy(
        map(listData, (item) => ({
          label: item.title,
          value: item.id
        })),
        (item) => item.value
      );
    },
    [exceptId, parentId, exceptParentId, types]
  );
  return {
    get
  };
};

export {
  useGetListCategory,
  useCreateCategory,
  useDetailCategory,
  useUpdateCategory,
  useDeleteCategory,
  useCategoryQuickSearch,
  useCategoriesQuickSearch
};
