import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { customToast } from '../../../common/components/Toast';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDetailProduct } from '../../../helpers/hookProduct';
import { useCreateEVoucher } from '../../../helpers/hookEVoucher';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD, PREFIX } from '../constants';
import { mapFormDataToCreateRecord, fieldFromRecord } from '../utils';

const Create = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [priceType, setPriceType] = useState('Percent');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const user = useStore().getState()?.Auth.user;

  const { error: errorCreateEVoucher, post: createEVoucher } = useCreateEVoucher();
  const { error: errorDetailProduct, get: detailProduct } = useDetailProduct();

  useHandleErrorCode(APP_MESSAGE.eVoucher.name, [errorCreateEVoucher], APP_MESSAGE.eVoucher.createError);
  useHandleErrorCode(APP_MESSAGE.product.name, [errorDetailProduct], APP_MESSAGE.product.detailError);

  const onGetProductDetail = useCallback(
    async (id) => {
      let response = await detailProduct(id);
      if (response) {
        setProduct(response);
      }
    },
    [detailProduct]
  );

  const onCreate = useCallback(
    (values) => {
      createEVoucher({ ...values }).then((rs) => {
        if (rs?.id) {
          customToast({ content: APP_MESSAGE.eVoucher.createSuccess, type: 'success' });
          history.push(`${ROUTER_URL.E_VOUCHER_EDIT}/${rs?.id}`);
        }
      });
    },
    [history, createEVoucher]
  );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.E_VOUCHER_FIND);
  }, [history]);

  const onSave = () => {
    form.validateFields().then((values) => {
      onCreate(mapFormDataToCreateRecord(values));
    });
  };

  const handleValueChange = (value) => {
    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.productId.name}`)) {
      const productId = value[`${PREFIX}_${FORM_FIELD.productId.name}`];
      // form.setFieldsValue({
      //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: undefined,
      //   [`${PREFIX}_${FORM_FIELD.percentReduction.name}`]: undefined
      // });
      if (productId) {
        onGetProductDetail(value[`${PREFIX}_${FORM_FIELD.productId.name}`]);
      } else {
        form.setFieldsValue({
          [`${PREFIX}_${FORM_FIELD.price.name}`]: undefined,
          [`${PREFIX}_${FORM_FIELD.sellPrice.name}`]: undefined
        });
      }
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.percentReduction.name}`)) {
      const percentReduction = value[`${PREFIX}_${FORM_FIELD.percentReduction.name}`];
      const sellPrice = form.getFieldValue(`${PREFIX}_${FORM_FIELD.sellPrice.name}`);
      if (+percentReduction && sellPrice) {
        // form.setFieldsValue({
        //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]:
        //     percentReduction >= 100 ? sellPrice : Math.floor((+percentReduction * sellPrice) / 100)
        // });
        // if (percentReduction >= 100) {
        //   form.setFieldsValue({
        //     [`${PREFIX}_${FORM_FIELD.percentReduction.name}`]: 100
        //   });
        // }
      } else if (percentReduction === '0') {
        // form.setFieldsValue({
        //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: sellPrice
        // });
      } else {
        // form.setFieldsValue({
        //   [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: undefined
        // });
      }
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.priceReduction.name}`)) {
      const priceReduction = value[`${PREFIX}_${FORM_FIELD.priceReduction.name}`];
      const sellPrice = form.getFieldValue(`${PREFIX}_${FORM_FIELD.sellPrice.name}`);

      if (sellPrice && +priceReduction) {
        // form.setFieldsValue({
        //   [`${PREFIX}_${FORM_FIELD.percentReduction.name}`]:
        //     +priceReduction >= sellPrice ? 100 : Math.floor((+priceReduction / sellPrice) * 100)
        // });
        if (+priceReduction >= sellPrice) {
          // form.setFieldsValue({ [`${PREFIX}_${FORM_FIELD.priceReduction.name}`]: sellPrice });
        }
      }
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.priceType.name}`)) {
      setPriceType(value[`${PREFIX}_${FORM_FIELD.priceType.name}`]);
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.fromDate.name}`)) {
      setFromDate(value[`${PREFIX}_${FORM_FIELD.fromDate.name}`]);
    }

    if (Object.prototype.hasOwnProperty.call(value, `${PREFIX}_${FORM_FIELD.toDate.name}`)) {
      setToDate(value[`${PREFIX}_${FORM_FIELD.toDate.name}`]);
    }
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.price.name}`]: product?.price,
        [`${PREFIX}_${FORM_FIELD.sellPrice.name}`]: product?.sellPrice
      });
    }
  }, [product, form]);

  return (
    <PageContent title={`Tạo ${APP_MESSAGE.eVoucher.name}`} footerCreatorVisible>
      <CustomForm
        name="eVoucher"
        defaultValue={fieldFromRecord({ isActive: true, userId: user?.id })}
        onSubmit={onSave}
        contentOK={APP_MESSAGE.eVoucher.createConfirm}
        contentCancel={APP_MESSAGE.eVoucher.createCancel}
        onCancel={onCancel}
        form={form}
        onValuesChange={handleValueChange}
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[
              FORM_FIELD.code.name,
              FORM_FIELD.type.name,
              FORM_FIELD.percentReduction.name,
              FORM_FIELD.priceReduction.name,
              FORM_FIELD.isActive.name
            ]}
            disabledItems={[FORM_FIELD.userId.name]}
            priceType={priceType}
            fromDate={fromDate}
            toDate={toDate}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Create;
