import { errorModal } from '../common/components/Modal/errorModal';

const alertErrorPreLoadingPage = () => {
  errorModal({
    content: 'Vui lòng tải lại trang để tiếp tục',
    okText: 'Tải lại',
    onOk() {
      window.location.reload();
    }
  });
};

export { alertErrorPreLoadingPage };
