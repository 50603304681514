import { useSelector } from 'react-redux';
import ROUTER_URL from '../constants/router';

const useIsAdminOrOwner = (ownerId) => {
  const { user } = useSelector((state) => state.Auth);
  return user?.type === 'Admin' || user?.id === ownerId;
};

const useIsCheckFeature = (module, name) => {
  const { user } = useSelector((state) => state.Auth);
  if (user?.type === 'Admin' || !module || !name) {
    return true;
  }

  return user?.accessFeatures?.[module]?.includes(name) ? true : false;
};

const useIsCheckPermissionPath = (path) => {
  const { user } = useSelector((state) => state.Auth);

  if (
    user === null ||
    path === null ||
    [(ROUTER_URL.ME_DETAIL, ROUTER_URL.PASSWORD_CHANGE)].includes(path) ||
    user?.type === 'Admin'
  ) {
    return true;
  }

  if (path.includes('/edit/') || path.includes('/detail/')) {
    const tmpPaths = path.split('/');
    tmpPaths.pop();
    path = tmpPaths.join('/');
  }

  return user?.accessPaths?.includes(path) ? true : false;
};

export { useIsCheckFeature, useIsCheckPermissionPath, useIsAdminOrOwner };
