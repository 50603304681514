export const PREFIX = 'category';

export const FORM_FIELD = {
  type: { name: 'type', label: 'Loại' },
  parentId: { name: 'parentId', label: 'Danh mục cha' },
  title: { name: 'title', label: 'Tiêu đề' },
  description: { name: 'description', label: 'Mô tả' },
  orderNumber: { name: 'orderNumber', label: 'Thứ tự' },
  isActive: { name: 'isActive', label: 'Trạng thái' },
  isShowHome: { name: 'isShowHome', label: 'Hiện thị trang chủ' },
  image: { name: 'image', label: 'Hình ảnh' },
  createdById: { name: 'createdById', label: 'Người tạo' },
  updatedById: { name: 'updatedById', label: 'Người cập nhật' },
  fromToCreatedAt: { name: 'fromToCreatedAt', label: 'Ngày tạo' },
  fromToUpdatedAt: { name: 'fromToUpdatedAt', label: 'Ngày cập nhật' }
};

export const SEARCH_DEFAULT = {
  isActive: true
};

export const ECategoryType = {
  forum: 'Forum',
  post: 'Post',
  product: 'Product',
  page: 'Page',
  gallery: 'Gallery'
};
