import { create, detail, list, update, deleteById } from '../api/eVoucher';
import { useGetDetailById, useGetListTable, useSendPostAPI, useSendPutAPI, useSendAPIById } from './hookAPI';

const useGetListEVoucher = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreateEVoucher = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailEVoucher = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdateEVoucher = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeleteEVoucher = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

export { useGetListEVoucher, useCreateEVoucher, useDetailEVoucher, useUpdateEVoucher, useDeleteEVoucher };
