import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/product-titles`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/product-titles/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/product-titles/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/product-titles`, { params });
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/product-titles/change-status/${id}`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/product-titles/${id}`);
};
