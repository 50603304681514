import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const loginPending = createAction(CONST.LOGIN_PENDING);
export const loginSuccess = createAction(CONST.LOGIN_SUCCESS);
export const loginFailed = createAction(CONST.LOGIN_FAILED);
export const loginConfirmPending = createAction(CONST.LOGIN_CONFIRM_PENDING);
export const logoutPending = createAction(CONST.LOGOUT_PENDING);
export const logoutSuccess = createAction(CONST.LOGOUT_SUCCESS);
export const logoutFailed = createAction(CONST.LOGOUT_FAILED);
export const changePassword = createAction(CONST.CHANGE_PASSWORD);
export const changePasswordSuccess = createAction(CONST.CHANGE_PASSWORD_SUCCESS);
export const changePasswordFailed = createAction(CONST.CHANGE_PASSWORD_FAILED);
// export const changePasswordWithCode = createAction(CONST.CHANGE_PASSWORD_WITH_CODE);
// export const changePasswordWithCodeSuccess = createAction(CONST.CHANGE_PASSWORD_WITH_CODE_SUCCESS);
// export const changePasswordWithCodeFailed = createAction(CONST.CHANGE_PASSWORD_WITH_CODE_FAILED);
export const resetPasswordPending = createAction(CONST.SEND_PASSWORD_RESET_EMAIL_PENDING);
export const resetPasswordSuccess = createAction(CONST.SEND_PASSWORD_RESET_EMAIL_SUCCESS);
export const resetPasswordFailed = createAction(CONST.SEND_PASSWORD_RESET_EMAIL_FAILED);
export const getCurrentUserPending = createAction(CONST.GET_CURRENT_USER_PENDING);
export const getCurrentUserSuccess = createAction(CONST.GET_CURRENT_USER_SUCCESS);
export const getCurrentUserFailed = createAction(CONST.GET_CURRENT_USER_FAILED);
export const storeInfoApp = createAction(CONST.STORE_INFO_APP);
