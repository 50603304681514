import { Form, Modal, Tabs } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { customToast } from '../../../common/components/Toast';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDeleteCategory, useGetListCategory } from '../../../helpers/hookCategory';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import ListSearch from '../components/listSearch';
import { ECategoryType, SEARCH_DEFAULT } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';
import { setLocalStorage } from '../../../utils/localStorage';

const Find = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const { get: getListCategory } = useGetListCategory();
  const { error: errorDelete, delete: deleteCategory } = useDeleteCategory();

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  const { loading, reload, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams,
        orderField: 'orderNumber',
        order: 'asc'
      }),
      [searchParams]
    ),
    defaultOrder: [false, false, false, false, false, false, false, false, false],
    columnSchema: [null, null, null, null, null, null, null, null, null],
    promiseFn: getListCategory
  });

  useHandleErrorCode(APP_MESSAGE.category.name, [errorList], APP_MESSAGE.category.missData);
  useHandleErrorCode(APP_MESSAGE.category.name, [errorDelete], APP_MESSAGE.category.deleteError);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onEdit = useCallback(
    (id) => {
      history.push(`${ROUTER_URL.CATEGORY_EDIT}/${id}`);
    },
    [history]
  );

  const onDelete = useCallback(
    (record) => {
      setLocalStorage('type-enter', 'modal');
      Modal.confirm({
        title: getMessageFromName(APP_MESSAGE.category.deleteConfirm, record.title),
        onOk: async () => {
          setLocalStorage('type-enter', '');
          deleteCategory(record.id).then((response) => {
            if (response?.success) {
              customToast({
                content: getMessageFromName(APP_MESSAGE.category.deleteSuccess, record.title),
                type: 'success'
              });
              reload();
            }
          });
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [deleteCategory, reload]
  );

  const onFinish = useCallback(
    (values) => {
      setSearchParams(mapFormDataToSearchRecord(values));
    },
    [setSearchParams]
  );

  const columns = useMemo(() => getTableColumns({ page, order, onEdit, onDelete, limit }), [
    page,
    order,
    onEdit,
    onDelete,
    limit
  ]);

  const onChangeTab = (value) => {
    setSearchParams((pre) => ({
      ...pre,
      type: value || undefined
    }));
  };

  const getContent = () => {
    return (
      <RemoteTable
        columns={columns}
        data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
        loading={loading}
        page={page}
        limit={limit}
        total={data?.total ?? 0}
        onChange={onChange(() => {})}
        rowKey="id"
      />
    );
  };

  const items = [
    {
      key: '',
      label: `Tất cả`,
      children: getContent()
    },
    {
      key: ECategoryType.product,
      label: `Sản phẩm`,
      children: getContent()
    },
    {
      key: ECategoryType.post,
      label: `Bài đăng`,
      children: getContent()
    },
    {
      key: ECategoryType.forum,
      label: `Diễn đàn`,
      children: getContent()
    },
    {
      key: ECategoryType.page,
      label: `Trang`,
      children: getContent()
    },
    {
      key: ECategoryType.gallery,
      label: `Thư viện ảnh`,
      children: getContent()
    }
  ];

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.category.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm title="Kết quả tìm kiếm">
        <Tabs defaultActiveKey="" items={items} onChange={onChangeTab} />
      </CardForm>
    </PageContent>
  );
};

export default Find;
