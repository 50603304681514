/* eslint-disable complexity */
import { find } from 'lodash';
import { Modal } from 'antd';
import { findIndex, values, size } from 'lodash';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { alertErrorPreLoadingPage } from '../utils/modal';
import { modelError } from '../helpers/model';
import { useLocation } from 'react-router';
import { IS_EDIT_PAGE_PATTERN, NOTE_NAME } from '../constants';
import { customToast } from '../common/components/Toast';

const useAlertErrorRefresh = (...listError) => {
  useEffect(() => {
    const errorFound = find(listError, (err) => err instanceof Error);
    if (errorFound) {
      alertErrorPreLoadingPage();
    }
  }, [listError]);
};

const showErrorUserInput = () => {
  modelError({
    msg: 'Có lỗi nhập liệu xảy ra, vui lòng kiểm tra lại.'
  });
};

const getMsgFromErrorCodeForServer = (errorData) => {
  const codeError = errorData?.code || errorData?.response?.data?.statusCode;
  let msg = '';
  switch (codeError) {
    case 'DeleteError':
      msg = errorData?.message;
      break;
    case 'DisplayUIError':
      msg = errorData?.message;
      break;
    case 403:
      msg = errorData?.response?.data?.message;
      break;
    default:
      msg = 'Có lỗi xảy ra. Vui lòng thử lại.';
  }
  return msg;
};

const getMsgFromErrorCode = ({ errorData, defaultMessage }) => {
  const hasErrorFromServer = !errorData || !errorData.errorCode;
  const msgErrorSystemDefault = hasErrorFromServer
    ? 'Có lỗi hệ thống xin vui lòng liên hệ với admin.'
    : 'Có lỗi nhập liệu xảy ra. Mời kiểm tra lại.';
  const msg = getMsgFromErrorCodeForServer(errorData) || defaultMessage || msgErrorSystemDefault;
  return msg;
};

// eslint-disable-next-line no-unused-vars
const showErrorFromAPI = ({ errorData, noteName, noteNamePrev, defaultMessage, onOk, showErrorCodeInfo = true }) => {
  const title = getMsgFromErrorCode({ errorData, noteName, noteNamePrev, defaultMessage });
  let errorCodeDetail = '';
  if (showErrorCodeInfo && errorData?.statusCode) {
    errorCodeDetail = errorData.statusCode ? `Mã lỗi: ${errorData.statusCode} ${errorData.errorCode}` : '';
    errorCodeDetail += errorData?.entity ? ` (${errorData.entity})` : '';
  }
  customToast({ title, content: errorCodeDetail || undefined, type: 'error' });
};

const showErrorModalFromAPI = ({
  errorData,
  noteName,
  noteNamePrev,
  defaultMessage,
  onOk,
  showErrorCodeInfo = true
}) => {
  const title = getMsgFromErrorCode({ errorData, noteName, noteNamePrev, defaultMessage });
  let errorCodeDetail = '';
  if (showErrorCodeInfo && errorData?.statusCode) {
    errorCodeDetail = errorData.statusCode ? `Mã lỗi: ${errorData.statusCode} ${errorData.errorCode}` : '';
    errorCodeDetail += errorData?.entity ? ` (${errorData.entity})` : '';
  }
  modelError({
    title,
    msg: errorCodeDetail || undefined,
    onEventOk: onOk
  });
};

const useGetErrorMsgFromCode = (note, defaultMessage) => {
  return useCallback(
    (errorData) => {
      const notePrev = note && values(NOTE_NAME)[findIndex(values(NOTE_NAME), (o) => o === note) - 1];
      showErrorFromAPI({ errorData, noteName: note, noteNamePrev: notePrev, defaultMessage });
    },
    [defaultMessage, note]
  );
};

const useHandleErrorCode = (note, Errors = [], defaultMessage = '') => {
  const errorApi = useGetErrorMsgFromCode(note, defaultMessage);
  const errorData = Errors.find((e) => e);
  useEffect(() => {
    if (errorData) {
      errorApi(errorData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorData]);
};

const useCheckDataIsValid = (checkDataIsValid, data) => {
  const location = useLocation();
  const isEditPage = useMemo(() => {
    if (IS_EDIT_PAGE_PATTERN.test(location.pathname)) {
      return true;
    }
    return false;
  }, [location.pathname]);
  const [isDataValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState({});
  const checkDataValid = useCallback(async () => {
    if (data && typeof checkDataIsValid === 'function') {
      try {
        const errors = await checkDataIsValid(data);
        setErrors(errors);
        setIsValid(!size(errors));
      } catch (error) {
        setErrors({});
        setIsValid(true);
      }
    }
  }, [checkDataIsValid, data]);
  useEffect(() => {
    if (isEditPage) {
      checkDataValid();
    }
  }, [checkDataValid, isEditPage]);
  useEffect(() => {
    if (!isDataValid) {
      Modal.error({
        title: 'Thông tin không hợp lệ, vui lòng kiểm tra lại'
      });
    }
  }, [isDataValid]);
  return { isDataValid, errors };
};

export {
  useAlertErrorRefresh,
  useGetErrorMsgFromCode,
  useHandleErrorCode,
  getMsgFromErrorCode,
  showErrorUserInput,
  showErrorFromAPI,
  showErrorModalFromAPI,
  useCheckDataIsValid
};
