import { Col, Form, Row, Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { FormUpdateType } from '../../../constants/form';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useGetDetailCart, useUpdateCart } from '../../../helpers/hookCart';
import UserInfo from '../components/userInfo';
import PaymentInfo from '../components/paymentInfo';
import ProductInfo from '../components/productInfo';
import { FORM_FIELD, PREFIX, ECartStatus } from '../constants';
import { fieldFromRecord, getPriceByType, mapFormDataToCreateRecord } from '../utils';
import { customToast } from '../../../common/components/Toast';
import FindProduct from '../components/findProduct';
import { setLocalStorage } from '../../../utils/localStorage';
import StatusButton from '../components/StatusButton';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);
  const [isNewUser, setIsNewUser] = useState(false);

  const { error: errorDetailCart, get: getDetailCart } = useGetDetailCart();
  const { error: errorUpdateCart, put: updateCart } = useUpdateCart(id);

  useHandleErrorCode(APP_MESSAGE.cart.name, [errorDetailCart], APP_MESSAGE.cart.detailError);
  useHandleErrorCode(APP_MESSAGE.cart.name, [errorUpdateCart], APP_MESSAGE.cart.editError);

  const onGetDetail = useCallback(async () => {
    const response = await getDetailCart(id);
    if (response) {
      setData(response);
      setProducts(
        response?.cartDetails?.map((item) => ({
          id: item.product.id,
          thumbnail: item.product.thumbnail,
          code: item.product.code,
          title: item.product.title,
          inventoryAmount: item.product.inventoryAmount,
          amount: item.amount,
          amountInput: item.amount,
          description: item.description,
          serialNumbers: item.product.serialNumbers,
          serialIds: item.cartDetailSerials.map((item) => item.productSerialId),
          // selectSerialNumberIds: item.cartDetailSerials.map((item) => item.productSerialId),
          serialNumberIds: item.cartDetailSerials.map((item) => item.productSerialId),
          priceType: item.priceType,
          priceInput: item.price,
          price: item.product.price,
          price1: item.product.price1,
          price2: item.product.price2,
          price3: item.product.price3
        }))
      );
    }
  }, [getDetailCart, id]);

  const onUpdate = useCallback(
    (values) => {
      updateCart(id, { ...values }).then((rs) => {
        if (rs) {
          customToast({ content: APP_MESSAGE.cart.editSuccess, type: 'success' });
          history.push(`${ROUTER_URL.CART_DETAIL}/${id}`);
        }
      });
    },
    [history, id, updateCart]
  );

  const onCancel = useCallback(() => {
    history.push(`${ROUTER_URL.CART_FIND}`);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  const onSave = () => {
    form.validateFields().then((values) => {
      const errors = [];
      products.map((item) => {
        const serialIdsLength = typeof item.serialIds === 'number' ? 1 : item.serialIds?.length;
        if (serialIdsLength !== item.amountInput) {
          errors.push({ name: `serialInput-${item.id}`, errors: ['Số lượng serial chưa đúng'] });
        }
      });

      if (errors.length) {
        form.setFields(errors);
      } else {
        onUpdate(mapFormDataToCreateRecord({ ...values, products }));
      }
    });
  };

  const handleChangeValues = (values) => {
    const [key] = Object.keys(values);

    if (key.includes('amountInput')) {
      const [nameField, idValue] = key.split('-');
      setProducts((pre) => {
        return pre.map((item) => ({
          ...item,
          [nameField]: item.id === +idValue ? +values[key] : item.amountInput
        }));
      });
    }

    if (key.includes('isNewUser')) {
      setIsNewUser(!!values[key]);
    }

    if (key.includes('priceInput')) {
      const [nameField, idValue] = key.split('-');
      setProducts((pre) => {
        return pre.map((item) => ({
          ...item,
          [nameField]: item.id === +idValue ? +values[key] : item.priceInput
        }));
      });
    }

    if (key.includes('voucherPrice') || key.includes('shipPrice')) {
      changePrice();
    }
  };

  const handleRemoveProduct = (id) => {
    setProducts((pre) => {
      return pre.filter((item) => item.id !== id);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changePrice = () => {
    const totalAmount = products.reduce((sum, item) => sum + item.amountInput, 0);
    const totalPrice = products.reduce((sum, item) => sum + item.amountInput * item.priceInput, 0);
    const voucherPrice = +form.getFieldValue(`${PREFIX}_voucherPrice`) || 0;
    const shipPrice = +form.getFieldValue(`${PREFIX}_shipPrice`) || 0;
    const finalPrice = totalPrice - voucherPrice + shipPrice;
    products.map((item) => {
      form.setFieldsValue({
        [`serialInput-${item.id}`]: item.serialIds,
        [`amountInput-${item.id}`]: item.amountInput,
        [`priceInput-${item.id}`]: item.priceInput || 0,
        [`${PREFIX}_totalAmount`]: totalAmount,
        [`${PREFIX}_totalPrice`]: totalPrice,
        // [`${PREFIX}_shipPrice`]: voucherPrice,
        // [`${PREFIX}_voucherPrice`]: shipPrice,
        [`${PREFIX}_finalPrice`]: finalPrice
      });
    });
  };

  useEffect(() => {
    changePrice();
  }, [products, form, changePrice]);

  const onChangeSerialNumbers = (record, value) => {
    setProducts((pre) => {
      return pre.map((item) => ({
        ...item,
        serialIds: item.id === record.id ? value : item?.serialIds
      }));
    });
  };

  const onChangeBHPrice = (record, value) => {
    setProducts((pre) => {
      return pre.map((item) => ({
        ...item,
        priceType: item.id === record.id ? value : item?.priceType,
        priceInput: item.id === record.id ? getPriceByType(record, value) : item.priceInput
      }));
    });
  };

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.cart.name} Mã # ${data?.code}`}
      footerCreatorVisible
      createdBy={data?.user}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
    >
      {data?.status !== ECartStatus.Canceled && (
        <StatusButton
          data={data}
          id={id}
          status={data.status}
          createdById={data.createdById}
          onGetDetail={onGetDetail}
        />
      )}
      <CustomForm
        name="cart"
        defaultValue={fieldFromRecord(data)}
        onSubmit={onSave}
        form={form}
        onCancel={onCancel}
        labelCancel="Hủy"
        onValuesChange={handleChangeValues}
        hideOk={[ECartStatus.Canceled].includes(data?.status)}
      >
        <Row gutter={24}>
          <Col span={24}>
            <CardForm title="Sản phẩm">
              <Button
                type="default"
                className="mb-4"
                onClick={() => {
                  setVisible(true);
                  setLocalStorage('type-modal-search', true);
                }}
              >
                Thêm sản phẩm
              </Button>
              <ProductInfo
                products={products}
                mode={FormUpdateType}
                onDelete={handleRemoveProduct}
                onChangeSerialNumbers={onChangeSerialNumbers}
                onChangeBHPrice={onChangeBHPrice}
                type={data?.type}
              />
            </CardForm>
          </Col>
          <Col span={8}>
            <CardForm title="Thông tin khách hàng">
              <UserInfo
                form={form}
                disabledItems={[]}
                mode={FormUpdateType}
                isNewUser={isNewUser}
                requiredItems={[
                  FORM_FIELD.userId.name,
                  FORM_FIELD.userName.name,
                  FORM_FIELD.userEmail.name,
                  FORM_FIELD.userPhone.name,
                  FORM_FIELD.deliveryAddress.name,
                  isNewUser ? FORM_FIELD.password.name : undefined
                ]}
              />
            </CardForm>
          </Col>
          <Col span={16}>
            <CardForm title="Thanh toán">
              <PaymentInfo />
            </CardForm>
          </Col>
        </Row>
      </CustomForm>
      <FindProduct visible={visible} setVisible={setVisible} products={products} setProducts={setProducts} />
    </PageContent>
  );
};

export default Edit;
