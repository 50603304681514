import { axiosInstance } from './common';

export const list = async (params) => {
  return await axiosInstance.get(`/comment-reports`, { params });
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/comment-reports/${id}`);
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/comment-reports/change-status/${id}`, body);
};
