import { axiosInstance } from './common';

export const create = async (body) => {
  return await axiosInstance.post(`/products`, body);
};

export const update = async (id, body) => {
  return await axiosInstance.put(`/products/${id}`, body);
};

export const detail = async (id) => {
  return await axiosInstance.get(`/products/${id}`);
};

export const list = async (params) => {
  return await axiosInstance.get(`/products`, { params });
};

export const changeStatus = async (id, body) => {
  return await axiosInstance.put(`/products/change-status/${id}`, body);
};

export const approved = async (id, body) => {
  return await axiosInstance.put(`/products/approved/${id}`, body);
};

export const deleteById = async (id) => {
  return await axiosInstance.delete(`/products/${id}`);
};

export const count = async (params) => {
  return await axiosInstance.get(`/products/count`, { params });
};
