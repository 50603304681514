import React from 'react';
import FormItem from '../FormItem';
import { useGetMasterValues } from './hook';
import GroupCode from '../../../constants/groupCode';

const FunctionSelect = ({ prefix, disabled, name, label, ...props }) => {
  const { data } = useGetMasterValues(GroupCode.Function);

  return (
    <FormItem
      prefix={prefix}
      name={name}
      label={label}
      type="selectFilter"
      disabled={disabled}
      options={data}
      autoComplete="bypass-autocomplete"
      {...props}
    />
  );
};

export default FunctionSelect;
