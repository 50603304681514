import { Form, Modal, Tabs, Row, Col } from 'antd';
import map from 'lodash/map';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import GroupFindButton from '../../../common/components/GroupButton/Find';
import PageContent from '../../../common/components/PageContent';
import RemoteTable from '../../../common/components/RemoteTable';
import { customToast } from '../../../common/components/Toast';
import { availableOptions } from '../../../constants/data';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDeleteProduct, useGetCountProduct, useGetListProduct } from '../../../helpers/hookProduct';
import { useRemoteTable } from '../../../helpers/hookTable';
import { getMessageFromName } from '../../../utils/getMessages';
import ListSearch from '../components/listSearch';
import { SEARCH_DEFAULT } from '../constants';
import { fieldFromRecord, mapFormDataToSearchRecord } from '../utils';
import { getTableColumns } from './columns';
import { setLocalStorage } from '../../../utils/localStorage';
import { toFormatCurrencyVND } from '../../../utils/numberFormat';

const Find = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState(SEARCH_DEFAULT);
  const { get: getListProduct } = useGetListProduct();
  const { error: errorDelete, delete: deleteProduct } = useDeleteProduct();
  const [productCounts, setProductCounts] = useState(null);
  const { get: getCountProduct } = useGetCountProduct();
  const onGetCountProduct = useCallback(async () => {
    const response = await getCountProduct({});
    if (response) {
      setProductCounts(response);
    }
  }, [getCountProduct]);

  useEffect(() => {
    form.setFieldsValue(fieldFromRecord(searchParams));
  }, [form, searchParams]);

  useEffect(() => {
    onGetCountProduct();
  }, [onGetCountProduct]);

  const { loading, reload, data, error: errorList, onChange, page, limit, order } = useRemoteTable({
    searchOption: useMemo(
      () => ({
        ...searchParams
      }),
      [searchParams]
    ),
    defaultOrder: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      'descend',
      false
    ],
    columnSchema: [
      null,
      null,
      'code',
      'title',
      'amount',
      'inventoryAmount',
      'importPrice',
      'price',
      'price1',
      'price2',
      'price3',
      'isRequiredVip',
      'isActive',
      'createdAt',
      null
    ],
    promiseFn: getListProduct
  });

  useHandleErrorCode(APP_MESSAGE.product.name, [errorList], APP_MESSAGE.product.detailError);
  useHandleErrorCode(APP_MESSAGE.product.name, [errorDelete], APP_MESSAGE.product.deleteError);

  const onClear = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onFinish = useCallback(
    (values) => {
      setSearchParams((pre) => ({
        ...pre,
        ...mapFormDataToSearchRecord(values, searchParams.isActive)
      }));
    },
    [setSearchParams, searchParams]
  );

  const onChangeTab = (value) => {
    let condition = {};
    if (value === '1') condition = { isActive: true, inventoryAmount: 1 };
    if (value === '2') condition = { isActive: undefined, inventoryAmount: 0 };
    if (value === '3') condition = { isActive: true, inventoryAmount: undefined };
    if (value === '4') condition = { isActive: false, inventoryAmount: undefined };
    if (value === '5') condition = { isActive: undefined, inventoryAmount: undefined };
    setSearchParams((pre) => ({
      ...pre,
      ...condition
    }));
  };

  const onEdit = useCallback(
    (id) => {
      history.push(`${ROUTER_URL.PRODUCT_EDIT}/${id}`);
    },
    [history]
  );

  const onDelete = useCallback(
    (record) => {
      setLocalStorage('type-enter', 'modal');
      Modal.confirm({
        title: getMessageFromName(APP_MESSAGE.product.deleteConfirm, record.title),
        onOk: async () => {
          setLocalStorage('type-enter', '');
          deleteProduct(record.id).then((response) => {
            if (response?.success) {
              customToast({
                content: getMessageFromName(APP_MESSAGE.product.deleteSuccess, record.title),
                type: 'success'
              });
              reload();
            }
          });
        },
        onCancel: () => {
          setLocalStorage('type-enter', '');
        }
      });
    },
    [deleteProduct, reload]
  );

  const columns = useMemo(() => getTableColumns({ page, order, onEdit, onDelete, limit }), [
    order,
    page,
    onEdit,
    onDelete,
    limit
  ]);

  const getContent = () => {
    return (
      <RemoteTable
        columns={columns}
        data={map(data?.items ?? [], (item) => ({ key: item.id, ...item }))}
        loading={loading}
        page={page}
        limit={limit}
        total={data?.total ?? 0}
        onChange={onChange(() => {})}
        rowKey="id"
        scroll={{
          x: 2200
        }}
      />
    );
  };

  const items = [
    {
      key: '1',
      label: `Tồn kho (${productCounts?.inventoryAmount || 0})`,
      children: getContent()
    },
    {
      key: '2',
      label: `Hết hàng (${productCounts?.outOfStock || 0})`,
      children: getContent()
    },
    {
      key: '3',
      label: `Đang hoạt động (${productCounts?.available || 0})`,
      children: getContent()
    },
    {
      key: '4',
      label: `Đã ẩn (${productCounts?.unavailable || 0})`,
      children: getContent()
    },
    {
      key: '5',
      label: `Tất cả (${productCounts?.total || 0})`,
      children: getContent()
    }
  ];

  return (
    <PageContent title={`Tìm ${APP_MESSAGE.product.name}`}>
      <CardForm title="Thông tin tìm kiếm">
        <Form form={form} onFinish={onFinish}>
          <ListSearch listAvailable={availableOptions} />
          <GroupFindButton onOk={() => form.submit()} onClean={onClear} />
        </Form>
      </CardForm>
      <CardForm>
        <Row gutter={16} className="mb-4">
          <Col span="8">Tổng sản phẩm: {data?.total ?? 0}</Col>
          <Col span="8">Tổng tồn kho: {data?.inventoryAmount ?? 0}</Col>
          <Col span="8">Tổng giá tồn kho: {toFormatCurrencyVND(data?.inventoryPrice) ?? 0} đ</Col>
        </Row>
        <Tabs defaultActiveKey="" items={items} onChange={onChangeTab} type="card" />
      </CardForm>
    </PageContent>
  );
};

export default Find;
