import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CardForm from '../../../common/components/CardForm';
import CustomForm from '../../../common/components/Form';
import PageContent from '../../../common/components/PageContent';
import { APP_MESSAGE } from '../../../constants/messages';
import ROUTER_URL from '../../../constants/router';
import { useHandleErrorCode } from '../../../helpers/error.hooks';
import { useDetailFeedback } from '../../../helpers/hookFeedback';
import StatusButton from '../components/StatusButton';
import GeneralInfo from '../components/generalInfo';
import { FORM_FIELD } from '../constants';
import { fieldFromRecord } from '../utils';

const Edit = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const { error: errorDetailFeedback, get: detailFeedback } = useDetailFeedback();
  // const { error: errorUpdateFeedback, put: updateFeedback } = useUpdateFeedback(id);

  useHandleErrorCode(APP_MESSAGE.feedback.name, [errorDetailFeedback], APP_MESSAGE.feedback.detailError);
  // useHandleErrorCode(APP_MESSAGE.feedback.name, [errorUpdateFeedback], APP_MESSAGE.feedback.editError);

  const onGetDetail = useCallback(async () => {
    const response = await detailFeedback(id);
    if (response) {
      setData(response);
      form.setFieldsValue(fieldFromRecord(response));
    }
  }, [detailFeedback, form, id]);

  // const onUpdate = useCallback(
  //   (values) => {
  //     updateFeedback(id, { ...values }).then((rs) => {
  //       if (rs) {
  //         customToast({ content: APP_MESSAGE.feedback.editSuccess, type: 'success' });

  //         history.push(`${ROUTER_URL.FEEDBACK_EDIT}/${id}`);
  //       }
  //     });
  //   },
  //   [history, id, updateFeedback]
  // );

  const onCancel = useCallback(() => {
    history.push(ROUTER_URL.FEEDBACK_FIND);
  }, [history]);

  useEffect(() => {
    onGetDetail();
  }, [onGetDetail]);

  // const onSave = () => {
  //   form.validateFields().then((values) => {
  //     onUpdate(mapFormDataToCreateRecord(values));
  //   });
  // };

  if (!data) return null;

  return (
    <PageContent
      title={`Cập nhật ${APP_MESSAGE.feedback.name}`}
      footerCreatorVisible
      createdBy={data?.createdBy}
      createdAt={data?.createdAt}
      updatedBy={data?.updatedBy}
      updatedAt={data?.updatedAt}
    >
      <StatusButton id={id} status={data.status} createdById={data.createdById} onGetDetail={onGetDetail} />
      <CustomForm
        name="feedback"
        defaultValue={fieldFromRecord(data)}
        // onSubmit={onSave}
        // contentOK={APP_MESSAGE.feedback.editConfirm}
        // contentCancel={APP_MESSAGE.feedback.editCancel}
        onCancel={onCancel}
        form={form}
        hideOk
      >
        <CardForm title="Thông tin chung">
          <GeneralInfo
            requiredItems={[FORM_FIELD.mvFunctionId.name, FORM_FIELD.name.name]}
            disabledItems={[
              FORM_FIELD.mvFunctionId.name,
              FORM_FIELD.name.name,
              FORM_FIELD.email.name,
              FORM_FIELD.description.name,
              FORM_FIELD.reasonForRefusal.name,
              FORM_FIELD.attachments.name
            ]}
            defaultFileList={data.attachments}
            status={data.status}
          />
        </CardForm>
      </CustomForm>
    </PageContent>
  );
};

export default Edit;
