import axios from 'axios';
import config from '../constants/config';
import { getLocalToken, removeLocalToken } from '../utils/localStorage';
import { ErrorCode } from '../constants';
import ROUTER_URL from '../constants/router';

const requestConfig = {
  headers: {
    'Content-Type': 'Application/json'
  },
  baseURL: `${config.rootApi}${config.namespaceApi}`,
  timeout: 10000,
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  }
};

const axiosInstance = axios.create(requestConfig);

const axiosExternalInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
  const token = await getLocalToken();

  if (token) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${token}`
    });
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      removeLocalToken();
      window.location.href = `${window.location.origin}/login`;
    }
    if (error?.response?.data?.errorCode === ErrorCode.FORBIDDEN) {
      window.location.href = `${window.location.origin}${ROUTER_URL.FORBIDDEN}`;
      return Promise.reject();
    }

    return Promise.reject(error);
  }
);

const axiosMsgInstance = axios.create({
  headers: { 'Content-Type': 'Application/json' },
  baseURL: `${config.msgApi}${config.namespaceApi}`,
  timeout: 10000,
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  }
});
axiosMsgInstance.interceptors.request.use(async (config) => {
  const token = getLocalToken();

  if (token) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${token}`
    });
  }
  return config;
});
axiosMsgInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeLocalToken();
      window.location.href = `${window.location.origin}/login`;
    }
    if (error?.response?.status === ErrorCode.FORBIDDEN || error?.response?.data?.errorCode === ErrorCode.FORBIDDEN) {
      window.location.href = `${window.location.origin}${ROUTER_URL.FORBIDDEN}`;
      return Promise.reject();
    }

    return Promise.reject(error);
  }
);

export { axiosInstance, axiosMsgInstance, axiosExternalInstance };
