import { Form, Input, Modal, Row, Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import CustomButton from '../../../common/components/Button';
import {
  usePutFeedbackChangeStatus,
  useDeleteFeedback,
  usePutFeedbackApprovedStatus
} from '../../../helpers/hookFeedback';
import { FeedbackStatus } from '../constants';
import { useSelector } from 'react-redux';
import ROUTER_URL from '../../../constants/router';
import { customToast } from '../../../common/components/Toast';
import { confirmModal } from '../../../common/components/Modal';
import { useIsCheckFeature } from '../../../helpers/hookPermission';

const StatusButton = ({ id, status = 'New', createdById = -1, onGetDetail }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { user } = useSelector((state) => state.Auth);
  const [disableButtonSend, setDisableButtonSend] = useState(true);
  const [visibleModalReject, setVisibleModalReject] = useState(false);
  const { put: putChangeStatusFeedback } = usePutFeedbackChangeStatus();
  const { put: putApprovedStatusFeedback } = usePutFeedbackApprovedStatus();
  const { delete: deleteFeedback } = useDeleteFeedback();

  const isApproved = useIsCheckFeature('Feedback', 'Approve');

  const handleChangeStatus = async (willUpdateStatus) => {
    const { reason } = form.getFieldsValue(['reason']);
    return await putChangeStatusFeedback(id, { status: willUpdateStatus, reasonForRefusal: reason || '' });
  };

  const handleApprovedStatus = async (willUpdateStatus) => {
    const { reason } = form.getFieldsValue(['reason']);
    return await putApprovedStatusFeedback(id, { status: willUpdateStatus, reasonForRefusal: reason || '' });
  };

  const onSubmit = async () => {
    confirmModal({
      content: 'Xác nhận gửi Góp ý?',
      onOk: async () => {
        const resData = await handleChangeStatus(FeedbackStatus.Pending);
        if (resData?.success) {
          onGetDetail();
          customToast({ content: 'Xác nhận gửi Góp ý thành công', type: 'success' });
        } else {
          customToast({ content: 'Xác nhận gửi Góp ý thất bại', type: 'error' });
        }
      }
    });
  };

  const onDelete = async () => {
    confirmModal({
      content: 'Thông tin đang ở trạng thái nháp. Thực hiện thao tác này sẽ xóa dữ liệu khỏi hệ thống. Xác nhận xóa?',
      onOk: async () => {
        const resData = await deleteFeedback(id);
        if (resData?.success) {
          customToast({ content: 'Xác nhận xóa Góp ý thành công', type: 'success' });
          history.push(`${ROUTER_URL.FEEDBACK_FIND}`);
        } else {
          customToast({ content: 'Xác nhận xóa Góp ý thất bại', type: 'error' });
        }
      }
    });
  };

  const onValuesChange = (changeValues) => {
    const { reason } = changeValues;
    setDisableButtonSend(isEmpty(reason));
  };

  const onReject = async () => {
    const resData = await handleApprovedStatus(FeedbackStatus.Reject);
    if (resData?.success) {
      onRejectModalClose();
      onGetDetail();
      customToast({ content: 'Xác nhận từ chối Góp ý thành công', type: 'success' });
      return;
    }
    customToast({ content: 'Xác nhận từ chối Góp ý thất bại', type: 'error' });
  };

  const onRejectModalOpen = () => {
    setVisibleModalReject(true);
  };

  const onRejectModalClose = () => {
    setVisibleModalReject(false);
  };

  const onProcessing = async () => {
    confirmModal({
      content: 'Xác nhận tiếp nhận Góp ý?',
      onOk: async () => {
        const resData = await handleChangeStatus(FeedbackStatus.Processing);
        if (resData?.success) {
          onRejectModalClose();
          onGetDetail();
          customToast({ content: 'Xác nhận tiếp nhận Góp ý thành công', type: 'success' });
        } else {
          customToast({ content: 'Xác nhận tiếp nhận Góp ý thất bại', type: 'error' });
        }
      }
    });
  };

  const onProcessed = async () => {
    confirmModal({
      content: 'Xác nhận đã xử lý hoàn tất Góp ý?',
      onOk: async () => {
        const resData = await handleApprovedStatus(FeedbackStatus.Success);
        if (resData?.success) {
          onRejectModalClose();
          onGetDetail();
          customToast({ content: 'Xác nhận đã xử lý hoàn tất Góp ý thành công', type: 'success' });
        } else {
          customToast({ content: 'Xác nhận đã xử lý hoàn tất Góp ý thất bại', type: 'error' });
        }
      }
    });
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Modal
        visible={visibleModalReject}
        onOk={onReject}
        okButtonProps={{
          disabled: disableButtonSend
        }}
        onCancel={onRejectModalClose}
        title="Xác nhận từ chối?"
      >
        <div>
          <Form form={form} onValuesChange={onValuesChange}>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              labelAlign="left"
              name="reason"
              label="Lý do"
              required
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Row justify="end">
        <Space wrap size="large">
          <CustomButton
            type="danger"
            className="button-container button-delete"
            onClick={onDelete}
            visible={(user?.type === 'Admin' || user.id === createdById) && FeedbackStatus.New.includes(status)}
          >
            Xóa
          </CustomButton>
          <CustomButton
            type="primary"
            className="button-container button-pending"
            onClick={onSubmit}
            visible={(user?.type === 'Admin' || user.id === createdById) && FeedbackStatus.New.includes(status)}
          >
            Gửi
          </CustomButton>
          <CustomButton
            type="primary"
            className="button-container button-rejected"
            onClick={onRejectModalOpen}
            visible={(user?.type === 'Admin' || isApproved) && FeedbackStatus.Pending.includes(status)}
          >
            Từ chối
          </CustomButton>
          <CustomButton
            type="primary"
            className="button-container button-processing"
            onClick={onProcessing}
            visible={(user?.type === 'Admin' || isApproved) && FeedbackStatus.Pending.includes(status)}
          >
            Tiếp nhận
          </CustomButton>
          <CustomButton
            type="primary"
            className="button-container button-processed"
            onClick={onProcessed}
            visible={(user?.type === 'Admin' || isApproved) && FeedbackStatus.Processing.includes(status)}
          >
            Hoàn thành
          </CustomButton>
        </Space>
      </Row>
    </div>
  );
};

export default StatusButton;
