import numeral from 'numeral';
import { BILION_DECIMAL_NUMBER_FORMAT, DECIMAL_NUMBER_FORMAT, PERCENTAGE_NUMBER_FORMAT } from '../constants';

export const isValidNumber = (value) => {
  return value || value === 0;
};

export const toFormatCurrencyBil = (value) => {
  return isValidNumber(value) ? numeral(value).format(BILION_DECIMAL_NUMBER_FORMAT) : '';
};

export const toFormatCurrencyVND = (value) => {
  return isValidNumber(value) ? numeral(value || 0).format() : '';
};

export const toFormatDecimal = (value) => {
  return isValidNumber(value) ? numeral(value).format(DECIMAL_NUMBER_FORMAT) : '';
};

export const toFormatPercentage = (value) => {
  return isValidNumber(value) ? `${numeral(value * 100).format(PERCENTAGE_NUMBER_FORMAT)}%` : '';
};
