import { Input } from 'antd';
import numeral from 'numeral';
import { isBoolean, isNumber } from 'lodash';
import round from 'lodash/round';
import React, { useCallback, useEffect, useState } from 'react';
import { isValidCalculateNumber } from '../../../utils/formValidators';
import { isValidNumber } from '../../../utils/numberFormat';
import './style.scss';

const CurrencyVNDInput = ({ value, onChange, showUnit, defaultValueInput, ...inputProps }) => {
  const [currentValue, setCurrentValue] = useState('');
  const getDisplayCurrentValue = useCallback(
    (valueFormat) => (isValidCalculateNumber(valueFormat) ? numeral(valueFormat).format() : undefined),
    []
  );
  useEffect(() => {
    if (isValidCalculateNumber(value)) {
      setCurrentValue(getDisplayCurrentValue(value));
    } else {
      setCurrentValue();
    }
  }, [getDisplayCurrentValue, value, currentValue]);
  const onChangeFormatted = useCallback(() => {
    if (isValidNumber(currentValue)) {
      const number = numeral(currentValue);
      onChange(number._value ? round(number._value) : 0);
      if (number._value === value) {
        setCurrentValue(getDisplayCurrentValue(number._value));
      }
    } else {
      if (isBoolean(defaultValueInput)) {
        onChange(0);
        setCurrentValue(getDisplayCurrentValue(0));
      } else if (isNumber(defaultValueInput)) {
        onChange(defaultValueInput);
        setCurrentValue(getDisplayCurrentValue(defaultValueInput));
      } else {
        onChange();
      }
    }
  }, [currentValue, getDisplayCurrentValue, defaultValueInput, onChange, value]);

  const handleValueChange = (e) => {
    setCurrentValue(e.target.value);
    const number = numeral(e.target.value);
    onChange(number._value || number._value === 0 ? round(number._value) : null);
  };

  return (
    <Input
      className="vnd-input"
      value={currentValue}
      onChange={handleValueChange}
      onBlur={onChangeFormatted}
      addonAfter={showUnit ? 'đ' : undefined}
      {...inputProps}
    />
  );
};
export default CurrencyVNDInput;
