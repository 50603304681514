import React, { useCallback, useEffect } from 'react';
import { Col, Image, Row, Form } from 'antd';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { IMAGE_APP } from '../../../constants';
import ROUTER_URL from '../../../constants/router';
import LoadingOverlay from '../../../common/components/LoadingOverlay';
import CustomButton from '../../../common/components/Button';
import APP_MESSAGE from '../../../constants/message';
import ForgotRequest from './components/ForgotRequest';
import './style.scss';
import { loginConfirmPending } from '../actions';
import { customToast } from '../../../common/components/Toast';

const { LoginLeftBannerImage, LogoImage } = IMAGE_APP;

const divLeftLoginStyle = {
  backgroundImage: `url(${LoginLeftBannerImage})`
};

/**
 * @name LoginConfirm
 * @memberof module:Modules
 */
const LoginConfirm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values) => {
      const { verifyNumber } = values;
      dispatch(loginConfirmPending({ verifyNumber }));
    },
    [dispatch]
  );

  const onFailed = useCallback(() => {
    customToast({ title: 'Xác thực đăng nhập', content: APP_MESSAGE.FIELD_INPUT_ERROR, type: 'error' });
  }, []);

  const handleOnClose = useCallback(() => {
    dispatch(push(ROUTER_URL.LOGIN));
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Xác thực đăng nhập';
  }, []);

  // TODO will implement
  const loading = false;

  return (
    <div>
      <div className="login-container forgot-container w-full">
        <LoadingOverlay loading={loading} />
        <Row>
          <Col span={12}>
            <div className="image-left" style={divLeftLoginStyle} />
          </Col>
          <Col span={12}>
            <Row justify="end">
              <Col span={6}>
                <Image className="w-56" preview={false} src={LogoImage} />
              </Col>
            </Row>
            <Col span={10} offset={8} className="login-form-wrapper">
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <span className="text-2xl font-bold">Mã xác thực đăng nhập</span>
                </Col>
                <Form
                  name="forgot-request-form"
                  className="forgot-form mt-5"
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFailed}
                  style={{ width: '100%' }}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24 }}
                  form={form}
                >
                  <ForgotRequest />

                  <Row className="mt-5">
                    <Col span={24}>
                      <CustomButton type="primary" htmlType="submit">
                        Xác nhận
                      </CustomButton>
                      <CustomButton className=" ml-1" onClick={handleOnClose}>
                        Đóng
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default compose(withRouter)(LoginConfirm);
