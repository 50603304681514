/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import { DATE_TIME_FORMAT } from '../../../constants';
import ActionButton from '../../../common/components/ActionButton';
import { displayDateFromString } from '../../../utils/dateFormat';

export const getMasterValueTableColumns = ({ onEdit, onDelete, page, limit }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    render: (text, record, index) => <Typography.Text>{(page - 1) * limit + index + 1}</Typography.Text>
  },
  {
    title: 'Mã giá trị',
    columnIndex: 1,
    dataIndex: ['valueCode']
  },
  {
    title: 'Tên giá trị',
    columnIndex: 2,
    dataIndex: ['valueName']
  },
  {
    title: 'Tên nhóm',
    columnIndex: 3,
    dataIndex: ['groupValue', 'name']
  },
  {
    title: 'Mã nhóm',
    columnIndex: 4,
    dataIndex: ['groupValue', 'code']
  },
  {
    title: 'Thứ tự',
    align: 'center',
    columnIndex: 5,
    dataIndex: 'orderNumber'
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    columnIndex: 6,
    render: (text, record) => displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)
  },
  {
    title: 'Người tạo',
    dataIndex: ['createdBy', 'name']
  },
  {
    title: 'Người cập nhật',
    dataIndex: ['updatedBy', 'name']
  },
  {
    title: 'Hành động',
    dataIndex: undefined,
    width: '80px',
    render: (text, record) => {
      return <ActionButton id={record.id} record={record} onEdit={onEdit} onDelete={onDelete} module={'MasterValue'} />;
    }
  }
];
