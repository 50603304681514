/* eslint-disable react/display-name */
import { Typography } from 'antd';
import React from 'react';
import { DATE_TIME_FORMAT } from '../../../constants';
import { displayDateFromString } from '../../../utils/dateFormat';
import FeedbackStatus from '../../../common/components/FeedbackStatus';

export const getTableColumns = ({ onEdit, page }) => [
  {
    title: 'STT',
    align: 'center',
    width: '50px',
    render: (text, record, index) => <Typography.Text>{(page - 1) * 10 + index + 1}</Typography.Text>
  },
  {
    title: 'Tên',
    render: (text, record) => <Typography.Link onClick={() => onEdit(record.id)}>{record.name}</Typography.Link>
  },
  {
    title: 'Email',
    dataIndex: ['email']
  },
  {
    title: 'Chức năng',
    dataIndex: ['mvFunction', 'valueName']
  },
  {
    title: 'Mô tả',
    width: '300px',
    dataIndex: 'description'
  },
  {
    title: 'Trạng thái',
    align: 'center',
    render: (text, record) => <FeedbackStatus status={record.status} />
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.createdAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    columnIndex: 7,
    render: (text, record) => (
      <Typography.Text>{displayDateFromString(record?.updatedAt, DATE_TIME_FORMAT)}</Typography.Text>
    )
  }
];
