import { Col, Row, Form } from 'antd';
import React from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImage from '../../../../common/components/UploadImage';
import { PREFIX, FORM_FIELD } from '../../constants';
import { FormItemLayoutArea } from '../../../../constants/form';

const GeneralInfo = ({ disabled, requiredItems = [], disabledItems = [], defaultFileList }) => {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.emailSupport.name}
          label={FORM_FIELD.emailSupport.label}
          required={requiredItems.includes(FORM_FIELD.emailSupport.name)}
          disabled={disabledItems.includes(FORM_FIELD.emailSupport.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.hotline.name}
          label={FORM_FIELD.hotline.label}
          required={requiredItems.includes(FORM_FIELD.hotline.name)}
          disabled={disabledItems.includes(FORM_FIELD.hotline.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.workingTime.name}
          label={FORM_FIELD.workingTime.label}
          required={requiredItems.includes(FORM_FIELD.workingTime.name)}
          disabled={disabledItems.includes(FORM_FIELD.workingTime.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.address.name}
          label={FORM_FIELD.address.label}
          required={requiredItems.includes(FORM_FIELD.address.name)}
          disabled={disabledItems.includes(FORM_FIELD.address.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.productDescription.name}
          label={FORM_FIELD.productDescription.label}
          required={requiredItems.includes(FORM_FIELD.productDescription.name)}
          disabled={disabledItems.includes(FORM_FIELD.productDescription.name) || disabled}
        />
      </Col>
      <Col span={12}>
        <Form.Item {...FormItemLayoutArea} name={`${PREFIX}_${FORM_FIELD.logo.name}`} label={FORM_FIELD.logo.label}>
          <UploadImage defaultFileList={defaultFileList} />
        </Form.Item>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.facebookLink.name}
          label={FORM_FIELD.facebookLink.label}
          required={requiredItems.includes(FORM_FIELD.facebookLink.name)}
          disabled={disabledItems.includes(FORM_FIELD.facebookLink.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.twitterLink.name}
          label={FORM_FIELD.twitterLink.label}
          required={requiredItems.includes(FORM_FIELD.twitterLink.name)}
          disabled={disabledItems.includes(FORM_FIELD.twitterLink.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.googleLink.name}
          label={FORM_FIELD.googleLink.label}
          required={requiredItems.includes(FORM_FIELD.googleLink.name)}
          disabled={disabledItems.includes(FORM_FIELD.googleLink.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.hangoutLink.name}
          label={FORM_FIELD.hangoutLink.label}
          required={requiredItems.includes(FORM_FIELD.hangoutLink.name)}
          disabled={disabledItems.includes(FORM_FIELD.hangoutLink.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.hideProductMobile.name}
          label={FORM_FIELD.hideProductMobile.label}
          type="switch"
          required={requiredItems.includes(FORM_FIELD.hideProductMobile.name)}
          disabled={disabledItems.includes(FORM_FIELD.hideProductMobile.name) || disabled}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;
