import React from 'react';
import { typeUserOptions } from '../../../../constants/data';
import FormItem from '../../FormItem';

const propTypes = {};

const SelectBoxTypeUsers = ({ prefix = '', name = 'type', label = 'Loại tài khoản', ...props }) => {
  return (
    <FormItem prefix={prefix} name={name} label={label} type="select" options={typeUserOptions} allowClear {...props} />
  );
};

SelectBoxTypeUsers.propTypes = propTypes;

export default SelectBoxTypeUsers;
