/* eslint-disable */
import { Checkbox, Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormItem from '../../../../common/components/FormItem';
import UploadImagesAsync from '../../../../common/components/UploadImagesAsync';
import { FORM_FIELD, PREFIX } from '../../constants';
import { EPostStatus } from '../../constants';
import PostStatus from '../../../../common/components/PostStatus';
import SelectBoxCategoryQuickSearch from '../../../../common/components/SelectBox/SelectBoxCategory';
import Tags from '../../../../common/components/Tags';
import CustomCKEditor from '../../../../common/components/CKEditor';
import { isEmpty } from 'lodash';
import './style.scss';
import UploadImage from '../../../../common/components/UploadImage';
import SelectBoxCategoriesQuickSearch from '../../../../common/components/SelectBox/SelectBoxCategories';
import { ECategoryType } from '../../../Category/constants';

const GeneralInfo = ({
  disabled,
  requiredItems = [],
  disabledItems = [],
  status = 'New',
  defaultThumbnail = null,
  defaultImages = [],
  form,
  defaultCover = null,
  defaultTags = [],
  defaultContent = null
}) => {
  const thumbnailImageRef = useRef(defaultThumbnail);
  const [thumbnailImage, setThumbnailImage] = useState(defaultThumbnail);
  const [tagsValue, setTagsValue] = useState(defaultTags);

  useEffect(() => {
    let thumbnailValue = null;
    if (!isEmpty(thumbnailImage)) {
      // eslint-disable-next-line no-unused-vars
      const { url, uid, ...res } = thumbnailImage;
      thumbnailValue = { ...res };
    }
    form &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.thumbnail.name}`]: thumbnailValue
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailImage]);

  // eslint-disable-next-line react/display-name
  const handleItemRenderImg = (thumbnailRef, setThumbnail) => (originNode, file) => {
    const { fileName, key, url, mimeType, size } = file;

    return (
      <>
        <div>
          <Checkbox
            checked={thumbnailRef?.current?.key === key || thumbnailImage?.key === key}
            onChange={(e) => {
              if (e.target.checked) {
                thumbnailRef.current = { key, fileName, url, mimeType, size };
                setThumbnail(thumbnailRef.current);
              }
              if (!e.target.checked) {
                thumbnailRef.current = null;
                setThumbnail(thumbnailRef.current);
              }
            }}
          />
        </div>
        {originNode}
      </>
    );
  };

  const handleDeleteThumbnail = (file) => {
    if (file?.url === thumbnailImage?.url) {
      setThumbnailImage(null);
    }
  };

  const handleChangeImage = (file) => {
    if (isEmpty(thumbnailImage)) {
      setThumbnailImage(file[0]);
    }
  };

  const renderUpload = useCallback(() => {
    return (
      <UploadImagesAsync
        disabled={disabled}
        defaultFileList={defaultImages}
        itemRender={handleItemRenderImg(thumbnailImageRef, setThumbnailImage)}
        thumbnailMsg="Click vào ô checkbox để chọn ảnh đại diện"
        onDelete={handleDeleteThumbnail}
        onChange={handleChangeImage}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailImage, disabled]);

  const handleGetTags = async (tags = []) => {
    form &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.tags.name}`]: tags
      });
    setTagsValue(tags);
  };

  const handleGetContent = (contents) => {
    form &&
      form.setFieldsValue({
        [`${PREFIX}_${FORM_FIELD.content.name}`]: contents
      });
  };

  return (
    <Row gutter={24} className="post-info">
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.title.name}
          label={FORM_FIELD.title.label}
          required={requiredItems.includes(FORM_FIELD.title.name)}
          disabled={disabledItems.includes(FORM_FIELD.title.name) || disabled}
        />

        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.source.name}
          label={FORM_FIELD.source.label}
          required={requiredItems.includes(FORM_FIELD.source.name)}
          disabled={disabledItems.includes(FORM_FIELD.source.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.status.name}
          label={FORM_FIELD.status.label}
          component={<PostStatus status={status} />}
        />
      </Col>
      <Col span={12}>
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.categoryId.name}
          label={FORM_FIELD.categoryId.label}
          required={requiredItems.includes(FORM_FIELD.categoryId.name)}
          disabled={disabledItems.includes(FORM_FIELD.categoryId.name) || disabled}
          component={<SelectBoxCategoriesQuickSearch allowClear showSearch hideInputLabel types={[ECategoryType.post, ECategoryType.page, ECategoryType.gallery]} />}
        />
        <FormItem
          prefix={PREFIX}
          type="number"
          name={FORM_FIELD.orderNumber.name}
          label={FORM_FIELD.orderNumber.label}
          required={requiredItems.includes(FORM_FIELD.orderNumber.name)}
          disabled={disabledItems.includes(FORM_FIELD.orderNumber.name) || disabled}
        />
        <FormItem
          prefix={PREFIX}
          name={FORM_FIELD.isHot.name}
          label={FORM_FIELD.isHot.label}
          type="switch"
          required={requiredItems.includes(FORM_FIELD.isHot.name)}
          disabled={disabledItems.includes(FORM_FIELD.isHot.name) || disabled}
        />
      </Col>
      {status === EPostStatus.Reject && (
        <Col span={24}>
          <FormItem
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            prefix={PREFIX}
            type="textArea"
            rows={3}
            name={FORM_FIELD.reasonForRefusal.name}
            label={FORM_FIELD.reasonForRefusal.label}
            required={requiredItems.includes(FORM_FIELD.reasonForRefusal.name)}
            disabled
          />
        </Col>
      )}
      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          name={FORM_FIELD.tags.name}
          label={FORM_FIELD.tags.label}
          required={requiredItems.includes(FORM_FIELD.tags.name)}
          disabled={disabledItems.includes(FORM_FIELD.tags.name) || disabled}
          component={<Tags getTags={handleGetTags} defaultTags={tagsValue} />}
        />
      </Col>
      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          type="textArea"
          rows={4}
          name={FORM_FIELD.description.name}
          label={FORM_FIELD.description.label}
          required={requiredItems.includes(FORM_FIELD.description.name)}
          disabled={disabledItems.includes(FORM_FIELD.description.name) || disabled}
        />
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          labelAlign="left"
          name={`${PREFIX}_${FORM_FIELD.cover.name}`}
          label={FORM_FIELD.cover.label}
          valuePropName="fileList"
        >
          <UploadImage defaultFileList={defaultCover} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          labelAlign="left"
          name={`${PREFIX}_${FORM_FIELD.images.name}`}
          label={FORM_FIELD.images.label}
          valuePropName="fileList"
        >
          {renderUpload()}
        </Form.Item>
      </Col>
      <Col offset={3}>
        <p>Kích thước hình ảnh tỉ lệ 4x3 (Ví dụ: 640x480)</p>
      </Col>
      <Col span={24}>
        <Form.Item
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          labelAlign="left"
          name={`${PREFIX}_${FORM_FIELD.thumbnail.name}`}
          label={FORM_FIELD.thumbnail.label}
        >
          {!isEmpty(thumbnailImage) && <img className="thumbnail-img" alt="thumbnail" src={thumbnailImage.url} />}
        </Form.Item>
      </Col>

      <Col span={24}>
        <FormItem
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          prefix={PREFIX}
          name={FORM_FIELD.content.name}
          label={FORM_FIELD.content.label}
          required={requiredItems.includes(FORM_FIELD.content.name)}
          disabled={disabledItems.includes(FORM_FIELD.content.name) || disabled}
          component={<CustomCKEditor height={'550px'} handleOnBlur={handleGetContent} defaultValue={defaultContent} />}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfo;