import { useGetListTable, useGetDetailById, useSendPutAPI, useSendAPIById, useSendPostAPI } from './hookAPI';
import { create, list, detail, update, changeStatus, deleteById, count } from '../api/post';
import { useCallback, useRef } from 'react';
import { map, uniqBy } from 'lodash';

const useGetListPost = () => {
  const { get } = useGetListTable(list);
  return {
    get
  };
};

const useCreatePost = () => {
  const { loading, action, error, setError } = useSendPostAPI(create);
  return {
    loading,
    post: action,
    error,
    setError
  };
};

const useDetailPost = () => {
  const { loading, get, error, setError } = useGetDetailById(detail);
  return {
    loading,
    get,
    error,
    setError
  };
};

const useUpdatePost = () => {
  const { loading, action, error, setError } = useSendPutAPI(update);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const usePutPostChangeStatus = () => {
  const { loading, action, error, setError } = useSendPutAPI(changeStatus);
  return {
    loading,
    put: action,
    error,
    setError
  };
};

const useDeletePost = () => {
  const { loading, action, error, setError } = useSendAPIById(deleteById);
  return {
    loading,
    delete: action,
    error,
    setError
  };
};

const usePostQuickSearch = (type) => {
  const latestAbortToken = useRef(null);
  const get = useCallback(
    ({ name }) => {
      return new Promise((resolve, reject) => {
        if (latestAbortToken.current) {
          clearTimeout(latestAbortToken.current);
        }
        const cancelToken = setTimeout(() => {
          list(
            {
              categoryType: type,
              name: name || undefined,
              isActive: true
            },
            {}
          )
            .then(({ data }) => {
              return resolve(
                uniqBy(
                  map(data?.data?.items, (item) => ({
                    value: item.id,
                    label: item.title
                  })),
                  (item) => item.value
                )
              );
            })
            .catch((error) => {
              return reject(error);
            });
        }, 1000);
        latestAbortToken.current = cancelToken;
      });
    },
    [type]
  );
  return {
    get
  };
};

const useGetCountPost = () => {
  const { get } = useGetListTable(count);
  return {
    get
  };
};

export {
  useGetListPost,
  useDetailPost,
  useUpdatePost,
  usePutPostChangeStatus,
  useDeletePost,
  useCreatePost,
  usePostQuickSearch,
  useGetCountPost
};
