import { v4 } from 'uuid';
import { result, find, findIndex } from 'lodash';
import { DATE_TIME_FORMAT } from '../constants';
import moment from 'moment';
import config from '../constants/config';
import { showConfirm } from '../helpers/model';

const ALLOW_IMAGES_TYPE = ['JPEG', 'JPG', 'PNG', 'WEBP'];

const dumpPromise = (res) => () => new Promise((resolve) => resolve(res));

const makeId = (prefix = 'id') => `${prefix}-${v4()}`;

const findSelectNameByValue = (items, id) => {
  return result(
    find(items, (obj) => {
      return `${obj.value}` === `${id}`;
    }),
    'label'
  );
};

const isTypeImage = (mimeType) => {
  const findExist = findIndex(ALLOW_IMAGES_TYPE, (type) => mimeType?.toUpperCase().indexOf(type?.toUpperCase()) !== -1);
  return findExist !== -1;
};

const formatDateTime = (dateStr) => {
  return dateStr ? moment(new Date(dateStr)).format(DATE_TIME_FORMAT) : '';
};

function validatePassword(string) {
  return /[A-Za-z]/.test(string) && /\d/.test(string) && string.length > config.minLengthPassword;
}

const userConfirmation = (message, callback) => {
  showConfirm({
    msg: message,
    onEventOk: async () => {
      callback(true);
    },
    onEventCancel: async () => {
      callback(false);
    }
  });
};

const makeKey = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

function textAreaReplaceNewLine(input) {
  if (!input) return null;
  input = input?.replaceAll('\\n', String.fromCharCode(13, 10));
  input = input?.replaceAll('\\r', String.fromCharCode(13, 10));
  return input;
}

function getDateByType(type) {
  let formDate = null;
  let toDate = null;
  if (type === 'today') {
    formDate = moment();
    toDate = moment();
  }
  if (type === 'preDay') {
    formDate = moment().subtract(1, 'days');
    toDate = moment().subtract(1, 'days');
  }
  if (type === 'week') {
    formDate = moment().subtract(0, 'weeks').startOf('isoWeek');
    toDate = moment().subtract(0, 'weeks').endOf('isoWeek');
  }
  if (type === 'preWeek') {
    formDate = moment().subtract(1, 'weeks').startOf('isoWeek');
    toDate = moment().subtract(1, 'weeks').endOf('isoWeek');
  }
  if (type === 'month') {
    formDate = moment().subtract(0, 'months').startOf('month');
    toDate = moment().subtract(0, 'months').endOf('month');
  }
  if (type === 'preMonth') {
    formDate = moment().subtract(1, 'months').startOf('month');
    toDate = moment().subtract(1, 'months').endOf('month');
  }
  if (type === 'year') {
    formDate = moment().subtract(0, 'years').startOf('year');
    toDate = moment().subtract(0, 'years').endOf('year');
  }
  if (type === 'preYear') {
    formDate = moment().subtract(1, 'years').startOf('year');
    toDate = moment().subtract(1, 'years').endOf('year');
  }
  return {
    formDate,
    toDate
  };
}

export {
  dumpPromise,
  makeId,
  findSelectNameByValue,
  isTypeImage,
  formatDateTime,
  validatePassword,
  userConfirmation,
  makeKey,
  textAreaReplaceNewLine,
  getDateByType
};
