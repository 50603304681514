import React from 'react';
import { Form } from 'antd';
import GroupCreateButton from '../GroupButton/Create';
import { customToast } from '../Toast';
import { useIsCheckFeature } from '../../../helpers/hookPermission';

const CustomForm = ({
  children,
  form,
  onSubmit,
  name,
  onCancel,
  contentCancel,
  contentOK,
  defaultValue,
  hideOk,
  hideCancel,
  labelCancel,
  labelOk,
  justCallback,
  onValuesChange,
  disabledOk,
  module = null
}) => {
  const isCreated = useIsCheckFeature(module, 'Create');
  const isUpdated = useIsCheckFeature(module, 'Update');

  const onSave = () => {
    if (justCallback) {
      onSubmit();
      return;
    }
    form
      .validateFields()
      .then((values) => {
        onSubmit(values);
      })
      .catch(() => {
        customToast({ content: 'Có lỗi nhập liệu xảy ra. Mời kiểm tra lại.', type: 'error' });
      });
  };

  return (
    <>
      <GroupCreateButton
        contentCancel={contentCancel}
        onCancel={onCancel}
        contentOK={contentOK}
        onOk={onSave}
        hideOk={!hideOk && (isCreated || isUpdated) ? false : true}
        hideCancel={hideCancel}
        labelCancel={labelCancel}
        labelOk={labelOk}
        disabledOk={disabledOk}
      />
      <Form initialValues={defaultValue} form={form} name={name} onValuesChange={onValuesChange}>
        {children}
        <GroupCreateButton
          contentCancel={contentCancel}
          onCancel={onCancel}
          contentOK={contentOK}
          onOk={onSave}
          hideOk={!hideOk && (isCreated || isUpdated) ? false : true}
          hideCancel={hideCancel}
          labelCancel={labelCancel}
          labelOk={labelOk}
          disabledOk={disabledOk}
        />
      </Form>
    </>
  );
};

export default CustomForm;
